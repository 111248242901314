import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { StoreService } from "./store.service";
import { UsuarioService } from "./usuario.service";
import { ACTION_SHOPCAR, ACTION_LOGOUT } from "../store/actions/appAction";
import { DataLayerService } from "./data-layer.service";
import { Product } from '../interfaces/product';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()
export class CarritoService {

	private crmUrl: string = environment.apiUrl;

	public newNavPasosCarritoSubject = new Subject<any>();
	public newFacturaSubject = new Subject<any>();

	constructor(
		public http: HttpClient,
		private storeService: StoreService,
		private usuarioService: UsuarioService,
		private dataLayerService: DataLayerService,
		private csasr: CheckSessionAfterServiceResponseService,
	) { }

	updateEnviromentPasosCarrito(data) {
		this.newNavPasosCarritoSubject.next(data)
	}

	updateFacturaCurrent(data) {
		this.newFacturaSubject.next(data)
	}

	getFactura(crmData) {

		let formData = new FormData();

		formData.append('method', 'factura_getResumen');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map(response => {
			this.csasr.check(response);
			return response
		}))

	}

	getDetalleFactura(crmData) {
		let formData = new FormData();

		formData.append('method', 'factura_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map(response => {
			this.csasr.check(response);

			return response

		}))
	}


	newFactura(crmData) {

		let formData = new FormData();

		formData.append('method', 'factura_getCarrito');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map(response => {
			this.csasr.check(response);

			return response;

		}))

	}

	mergeFactura(crmData) {

		let formData = new FormData();

		formData.append('method', 'factura_merge');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map(response => {
			this.csasr.check(response);

			return response

		}))

	}

	addProduct(crmData) {

		if (crmData[0] == null) {
			if (localStorage.getItem('session')) {
				crmData[0] = JSON.parse(localStorage.getItem('session'));
			}
		}

		let formData = new FormData();

		formData.append('method', 'factura_grupo_addSetProducto');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			if (response.producto) {
				const products: Array<Product> = [];
				const product: Product = {
					name: response.producto.name,
					id: response.producto.plu_sku_erp_code,
					slug: response.producto.slug,
					plu: response.producto.plu_sku_erp_code,
					price: String(response.producto.price_dcto),
					brand: response.producto.marca_producto,
					category: response.producto.categoria_producto,
					quantity: 1,
					type: response.producto.type.valor
				}

				response.factura.grupos[0].lineas.productos.forEach( product => {
					const productDataLayer: Product = {
						name: product.producto.name,
						id: product.producto.plu_sku_erp_code,
						plu: product.producto.plu_sku_erp_code,
						slug: product.producto.slug,
						price: product.producto.price_dcto,
						type: product.producto.type.valor,
						quantity: product.cantidad
					};
					products.push(productDataLayer);
				});

				try {
					this.dataLayerService.addToCar(product);
					this.dataLayerService.onCheckout(1, 'Agregar al carrito', products);
				} catch (error) {
					console.log(error);
				}
			}

			if (response.producto) {

			}

			return response

		}))
	}

	getBooksAsaGift(data) {

		let crmData = [
			data.session,
			{
				factura_id: data.factura_id
			}
		];
		let formData = new FormData();
		formData.append('method', 'opcionesregalo_getOpcionesPedido');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))

	}

	registerBookAsaGift(data) {

		let crmData = [
			data.session,
			{
				factura_id: data.factura_id,
				productos: data.productos
			}
		];
		let formData = new FormData();
		formData.append('method', 'opcionesregalo_batchSet');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))

	}

	//SET DIRECCIÓN DE ENVÍO

	setDireccionEnvio(crmData) {
		let formData = new FormData();

		formData.append('method', 'factura_setDireccionEnvio');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

  setDireccionEnvioOrden(crmData) {
		let formData = new FormData();

		formData.append("method", "factura_actualizarDireccionEnvio");
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => response))
	}

	//SET DIRECCIÓN DE FACTURACIÓN

	setDireccionFacturacion(crmData) {
		let formData = new FormData();

		formData.append('method', 'factura_setDireccionFacturacion');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//GET ORDEN DE PAGO

	getNewOrdenPago(crmData) {
		let formData = new FormData();

		formData.append('method', 'factura_getPaymentOrder');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	stpPay_confirmarTransaccion(crmData) {
		let formData = new FormData();

		formData.append('method', 'stpPay_confirmarTransaccion');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}


	//GET ORDEN DE PAGO

	getPagoWebCheckOut(crmDataOrder) {
		let formData = new FormData();

		formData.append('method', 'orden_pago_getMercadopagoWebCheckout');
		formData.append('rest_data', JSON.stringify(crmDataOrder));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//LISTADO DE FACTURAS
	get_billList(crmDataOrder) {
		let formData = new FormData();

		formData.append('method', 'facturas_list');
		formData.append('rest_data', JSON.stringify(crmDataOrder));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))

	}

	//LISTADO DE METODOS DE PAGO
	get_ListMetodosPago(crmDataOrder) {
		let formData = new FormData();

		formData.append('method', 'stpPay_getMetodosPagos');
		formData.append('rest_data', JSON.stringify(crmDataOrder));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))

	}

	//LISTADO DE TC CREADAS
	/* get_ListTCExist(dataListTCExist) {
		let formData = new FormData();

		formData.append('method', 'stpPay_SearchTokenCreditCard');
		formData.append('rest_data', JSON.stringify(dataListTCExist));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	} */
	//LISTADO DE TIPO TC
	get_ListTC(dataTC) {
		let formData = new FormData();

		formData.append('method', 'stpPay_getTiposTarjeta');
		formData.append('rest_data', JSON.stringify(dataTC));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}
	//CREACION DE  TC
	get_creatTC(dataTC) {
		let formData = new FormData();

		formData.append('method', 'stpPay_addCreditCard');
		formData.append('rest_data', JSON.stringify(dataTC));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}
	//ELIMINAR TC
	get_eliminarTC(dataTC) {
		let formData = new FormData();

		formData.append('method', 'stpPay_deleteTokenCreditCard');
		formData.append('rest_data', JSON.stringify(dataTC));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}


	//LISTADO DE BANCOS  PSE
	get_ListBancosPSE(dataPSE) {
		let formData = new FormData();

		formData.append('method', 'stpPay_getBancosPSE');
		formData.append('rest_data', JSON.stringify(dataPSE));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}
	//LISTADO DE TIPOS DE PERSONAS  PSE
	get_ListTipoPersonaPSE(dataPSE) {
		let formData = new FormData();

		formData.append('method', 'stpPay_getTiposPersonaPSE');
		formData.append('rest_data', JSON.stringify(dataPSE));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}
	//LISTADO DE TIPOS DE DOCUMENTOS  PSE
	get_ListTipoDocumentoPSE(dataPSE) {
		let formData = new FormData();

		formData.append('method', 'stpPay_getTiposDocumentosPSE');
		formData.append('rest_data', JSON.stringify(dataPSE));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response
		}))
	}

	//CREAR ORDEN DE PAGO
	get_createOrden(dataOrdenPago) {
		let formData = new FormData();

		formData.append('method', 'factura_getPaymentOrder');
		formData.append('rest_data', JSON.stringify(dataOrdenPago));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//PAGAR CON TC
	set_pagarTC(dataPagarTC) {
		let formData = new FormData();

		formData.append('method', 'stpPay_pagarTARJETA');
		formData.append('rest_data', JSON.stringify(dataPagarTC));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//PAGAR CON PSE
	set_pagarPSE(dataPagarPSE) {
		let formData = new FormData();

		formData.append('method', 'stpPay_pagarPSE');
		formData.append('rest_data', JSON.stringify(dataPagarPSE));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//PAGAR CON BALOTO
	set_pagarBaloto(dataPagarBaloto) {
		let formData = new FormData();

		formData.append('method', 'stpPay_pagarBALOTO');
		formData.append('rest_data', JSON.stringify(dataPagarBaloto));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//PAGAR CON EFECTY
	set_pagarEfecty(dataPagarEfecty) {
		let formData = new FormData();

		formData.append('method', 'stpPay_pagarEFECTY');
		formData.append('rest_data', JSON.stringify(dataPagarEfecty));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}
	// CONSULTAR ESTADO DE ORDEN DE PAGO --> RESUMEN O PAGE CON LA URL PERSONALIZADA
	get_statusOrden(dataStatusOrden) {
		let formData = new FormData();

		formData.append('method', 'orden_pago_get');
		formData.append('rest_data', JSON.stringify(dataStatusOrden));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}

	//METODOS DE TRATAMIENTO DE DATOS LOCAL
	getCarritoLocal() {
		if (localStorage.getItem('bill')) {
			return JSON.parse(localStorage.getItem('bill'))
		} else {
			return false
		}
	}

	setCarritoLocal(data) {
		localStorage.setItem('bill', JSON.stringify(data.id))
	}

	deleteCarritoLocal() {
		if (localStorage.getItem('bill')) {
			localStorage.removeItem('bill')
		}
	}

	//Metodo para adquiri informacion del formulario para pasarela de pago PAYU
	getPayuWebCheckout(crmData) {
		let formData = new FormData();
		formData.append('method', 'stpPay_pagarPayuWebCheckout');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
			this.csasr.check(response);
			return response

		}))
	}
}
