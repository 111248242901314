import { Store } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tuorden',
  templateUrl: './tuorden.component.html',
  styleUrls: ['./tuorden.component.scss']
})
export class TuordenComponent implements OnInit {
  urlComponent: string = '';
  shippingPrice: boolean = false
  @Input() totalsI: any;
  @Input() mensaje: boolean;

  constructor(
    private store: Store<any>,
    private router: Router
  ){}

  ngOnInit() {

    this.urlComponent = this.router.url;
    if(this.urlComponent.includes('informacion-pago')) {
      const parts = this.urlComponent.split('/');
      this.urlComponent = '/' + parts[1] + '/' + parts[2];
    }

    switch (this.urlComponent) {
      case '/carrito/mi-carrito':
        this.shippingPrice = false;
        break;
      case '/carrito/datos-envio':
        this.shippingPrice = false;
        break;
      case '/carrito/informacion-pago':
        this.shippingPrice = true;
        break;
      default:
        this.shippingPrice = true;
        break;
    }

    this.store.subscribe(({ appReducer }) => {});
  }

}
