import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.css']
})
export class QuienesSomosComponent implements OnInit {

  misionVision: boolean = false

  constructor
  (
    private titleService:Title,
    private meta:Meta,
  	private router: Router,
  	public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
  	//Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit() {
    window.scrollTo(0,0);

    let dataMetas = {
      title: 'Quiénes Somos',
      description: 'Somos la más prestigiosa librería colombiana donde encontrarás más de 60 mil referencias, más de 500 novedades por mes y lo más representativo del mundo editorial en español e inglés.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

  }

  setMetaData(data) {
    
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

}
