import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UsuarioService } from "../../../services/usuario.service";
import * as shajs from 'sha.js';
import { Usuario} from "../../../clases";
import { ACTION_LOGIN, ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { StoreService } from "../../../services/store.service";
import { CarritoService } from "../../../services/carrito.service";
import { Store } from "@ngrx/store";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-confirmar-codigo',
  templateUrl: './confirmar-codigo.component.html',
  styleUrls: ['./confirmar-codigo.component.css']
})
export class ConfirmarCodigoComponent implements OnInit {
  usuario:any;
  shopcar:any;
  origin:any;
  emailUser:any;
  loginMessage:any;
  forma:FormGroup;
  constructor(
    private router: Router,
    private store: Store<any>,
    private storeService:StoreService,
    private route: ActivatedRoute,
    private carritoService:CarritoService,
    private usuarioService:UsuarioService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
    this.emailUser = this.route.snapshot.queryParamMap.get('emailUser');
    if(this.route.snapshot.queryParamMap.get('origin')){
      this.origin = this.route.snapshot.queryParamMap.get('origin');
      console.log(this.origin);
    }

    this.validateFormRegistro();
  }

  validateFormRegistro(){
    this.forma = new FormGroup({ 'codigo' : new FormControl('',[ Validators.required ]) });
  }

  formSendCode(){
    console.log(this.forma);
    //let code = CryptoJS.MD5(this.forma.controls['codigo'].value).toString();
    let code = shajs('sha256').update(this.forma.controls['codigo'].value).digest('hex');
    let data = {
      user_name: this.emailUser,
      codigo_acceso : code
    };

    console.log(data);

    this.usuarioService.setLoginCode(data).subscribe(response => {
      if(response.id){
        this.loginUserCrmSuccess(response);
      } else if (response.number == 2010) {
        this.loginMessage = response.description;
      }
    })
  }

  loginUserCrmSuccess(response) {
    let currentUser = new Usuario();
    currentUser.login = true;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
    this.usuarioService.getUser(dataCrm).subscribe(resp => {
      if (resp.status == 'ok') {
        currentUser.userId = resp.config.id;
        currentUser.clienteId = resp.cuenta.cliente_id;
        currentUser.tokenCrm = resp.config.token;
        currentUser.estado_registro = resp.config.estado_registro;
        currentUser.estado_login = resp.config.estado_login;
        currentUser.nombres = resp.config.nombres;
        currentUser.apellidos = resp.config.apellidos;
        currentUser.tipo_identificacion = resp.config.tipo_identificacion;
        currentUser.identificacion = resp.config.identificacion;
        currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
        currentUser.email = resp.config.email;
        currentUser.telefono = resp.config.telefono;
        currentUser.telefono_movil = resp.cuenta.telefono_movil;

        //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
        this.usuarioService.setUserLocal(currentUser);
        this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

        //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
        let carritoLS = this.carritoService.getCarritoLocal();
        if (carritoLS && (this.shopcar.cliente_user_id == '' || this.shopcar.cliente_user_id == null) ){
          //MERGE DE CARRITOS CUANDO SI HAY LOCAL
          let dataCrmMergeCarrito = [
            currentUser.sessionCrm,
            {
              factura_merge_id  : carritoLS
            }
          ];
          this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
            if(response.id){
              this.updateCarritoLocal(response);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
            }
            else
            {
              this.carritoService.deleteCarritoLocal()
              this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
            }
          })
        } else {
          let dataCrmCarrito = {
            session_id : currentUser.sessionCrm
          };
          this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
            if(dataCarrito.id){
              this.updateCarritoLocal(dataCarrito);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
            }
          });
        }
        if(this.origin=='datos-personales'){
          let msg = 'Ahora puede editar sus datos personales';
          this.router.navigate(['/', 'mi-cuenta', 'datos-personales'], { queryParams: { message: msg }, skipLocationChange:true });
        } else if(this.origin=='datos-envio'){
          this.router.navigate(['/', 'carrito', 'datos-envio']);
        } else {
          if(localStorage.getItem('last-url')) {
            this.router.navigate(JSON.parse(localStorage.getItem('last-url')));
          } else {
            this.router.navigate(['/', 'mi-cuenta']);
          }
        }
      }
    })
  }
  updateCarritoLocal(dataCarrito){
    this.carritoService.setCarritoLocal(dataCarrito);
  }
}
