import { Action } from '@ngrx/store';

export enum AccionesColecciones {
    CARGAR_COLECCIONES = '[COLECCIONES] => Cargar',
    CARGA_COLECCIONES_EXITOSA = '[COLECCIONES] => Carga Exitosa',
    CARGA_COLECCIONES_FALLIDA = '[COLECCIONES] => Carga Fallida'
}

export class CargarColecciones implements Action {
    readonly type = AccionesColecciones.CARGAR_COLECCIONES;

    constructor( public payload?: any ) {}
}

export class CargarColeccionesFail implements Action {
    readonly type = AccionesColecciones.CARGA_COLECCIONES_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarColeccionesSuccess implements Action {
    readonly type = AccionesColecciones.CARGA_COLECCIONES_EXITOSA;

    constructor( public colecciones: any ) {}
}

export type UnionAccionesColecciones = CargarColecciones | CargarColeccionesFail | CargarColeccionesSuccess;
