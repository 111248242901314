import { UnionAccionesDirecciones, AccionesDirecciones } from "./actions";

export interface DireccionesStateI {
  data: any[];
  direccion: any;
  loaded: boolean;
  loading_direccion: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: DireccionesStateI = {
  data: [],
  direccion: {},
  loaded: false,
  loading_direccion: false,
  loading: false,
  error: null,
};

export function direccionesReducer(
  state: any = estadoInicial,
  action: UnionAccionesDirecciones
) {
  switch (action.type) {
    case AccionesDirecciones.CARGAR_DIRECCIONES:
      return {
        ...state,
        loading: true,
        loaded: false,
        data: [],
        direccion: {},
        error: null,
      };

    case AccionesDirecciones.CARGA_DIRECCIONES_EXITOSA:{
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...action.direcciones.map(obj => {
          obj.selected = "text-muted";
          obj.bgSelected = "border shadow-bx";
          return obj;
        })],
      };
    }

    case AccionesDirecciones.CARGA_DIRECCIONES_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload },
      };

    case AccionesDirecciones.ACTUALIZAR_DIRECCION:
    case AccionesDirecciones.ELIMINAR_DIRECCION:
    case AccionesDirecciones.AGREGAR_DIRECCION:
      return {
        ...state,
        loading_direccion: true,
        error: null,
      };

    case AccionesDirecciones.ACTUALIZAR_DIRECCION_EXITOSA:
    case AccionesDirecciones.ELIMINAR_DIRECCION_EXITOSA:
    case AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA:{
      return {
        ...state,
        loading_direccion: false,
        direccion: { ...action.direccion },
      };
    }

    case AccionesDirecciones.ACTUALIZAR_DIRECCION_FALLIDA:
    case AccionesDirecciones.ELIMINAR_DIRECCION_FALLIDA:
    case AccionesDirecciones.AGREGAR_DIRECCION_FALLIDA:
      return {
        ...state,
        loading_direccion: true,
        error: { ...action.payload },
      };

    case AccionesDirecciones.SET_DIRECCION:{

      let dirSet = {...state.data.find(dir => dir.id === action.direccion.id)};
      let index = state.data.findIndex(dir => dir.id === action.direccion.id);

      dirSet.selected = "text--blue-medium";
      dirSet.bgSelected = "border-left border-right-0 border-bottom-0 border-width-4 border-blue-medium bg-gray-light";

      let newState = [
        ...state.data.map((item) => {
          item.selected = "text-muted";
          item.bgSelected = "border shadow-bx";
          return item;
        }),
      ];

      newState[index] = dirSet;

      return {
        ...state,
        data: newState,
        direccion: {
          ...action.direccion,
          selected: dirSet.selected,
          bgSelected: dirSet.bgSelected,
        },
      };
    }

    case AccionesDirecciones.CANCELAR:
      return {...state};
    case AccionesDirecciones.RESET_DIRECCIONES:
      return {...estadoInicial};

    default:
      return state;
  }
}
