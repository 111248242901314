import { Action } from "@ngrx/store";

export enum AccionesModalRestriccionesCovid{
  MODAL_RESTRICCIONES = "[MODEL RESTRICCIONES COVID] => Cambiar estado",
}

export class CambiarEstadoModalRestricciones implements Action {
  readonly type = AccionesModalRestriccionesCovid.MODAL_RESTRICCIONES;
  constructor(public payload: any) {}
}

export type UnionAccionesModalRestriccionesCovid =
  | CambiarEstadoModalRestricciones;
