// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * Variables de enorto
 * Produccion: 'https://crm.librerianacional.com/custom/service/v4_1_custom/rest.php'
 * Stage: 'https://crmstage.ln2018.existaya.com/custom/service/v4_1_custom/rest.php'
 *
 * LibrosJsonUrl: https://crm.librerianacional.com/custom/include/autocomplete/cache/
 * LibrosJsonUrlStage: https://crmstage.ln2018.existaya.com/custom/include/autocomplete/cache/
 *
 * productSearch stage: https://crmstage.ln2018.existaya.com/custom/service/v4_1_custom/rest.php
 * productSearch produccion: https://crmsearch.librerianacional.com/custom/service/v4_1_custom/rest.php
 *
 */

export const environment = {
  production: false,
  // apiUrl: 'https://crmstage.ln2018.existaya.com/custom/service/v4_1_custom/rest.php',
  //apiUrl: 'http://192.168.1.182/ln2018_ecom_crm/custom/service/v4_1_custom/rest.php', // darwin
  //apiUrl: 'https://crmln.librerianacional.com/custom/service/v4_1_custom_test/rest.php',
  apiUrl: 'https://crm.librerianacional.com/custom/service/v4_1_custom/rest.php',
  // apiUrl: 'http://34.66.165.215/custom/service/v4_1_custom/rest.php', //stage
  //apiUrl: 'http://192.168.100.15/ln2018_ecom_crm/custom/service/v4_1_custom/rest.php', //desarrollo
  librosJsonUrl: 'https://crmln.librerianacional.com/custom/include/autocomplete/cache/',
  productSearch: 'https://crmstage.ln2018.existaya.com/custom/service/v4_1_custom/rest.php',
  // urlActionFormPayu: 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/',
  urlActionFormPayu: 'https://checkout.payulatam.com/ppp-web-gateway-payu/',
  passAgm: process.env["ENV_VAR_KEY"] || 'AIzaSyAJYmvn15AGCd-7OcxlBVWZlRHXvZOQl3g'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
