import { Direccion } from './index';
export class Usuario {
	login: boolean;
	idCrm: string;
	sessionCrm:string;
	tokenCrm: string;
	userId : string;
	clienteId:string;
	imagen: string;
	provider: string;
	tokenFb: string;
	tokenGo: string;
	nameSocial: string;
	idSocialGo: string;
	idSocialFb: string;
	idTokenGo: string;
	fecha_nacimiento:string;
	estado_registro:string;
	estado_login:string;
	name:string;
	nombres: string;
	apellidos: string;
	tipo_identificacion: string;
	identificacion: string;
	telefono: string;
	telefono_movil: string;
	email: string;
	direcciones : Direccion[];
	constructor() {
		
	}
}
