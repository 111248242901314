import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';

@Injectable()

export class SedesService {
	
	private crmUrl : string = environment.apiUrl;

	constructor(public http: HttpClient) { }

	getMasCercana(data) {
		let crmData = [
			{
				//ciudad: data.ciudad,
				latitud: data.latitud,
				longitud: data.longitud
			}
		]

		let formData = new FormData();

		formData.append('method', 'bodegas_getMasCercanas');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map(response => response));
	}
}
