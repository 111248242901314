import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
	selector: 'app-porquecomprar',
	templateUrl: './porquecomprar.component.html',
	styleUrls: ['./porquecomprar.component.scss'],
	host: {
		'(window:resize)': 'onResize($event)'
	}
})
export class PorquecomprarComponent implements OnInit {
	isMobile: boolean;

	constructor() { }

	ngOnInit() {
		this.onResize();
	}

	onResize(event?) {
		let wW = event ? event.target.innerWidth : window.innerWidth;

		if (wW < 768) {
			this.isMobile = true;
			$(function () {
				setTimeout(() => {
					$('.carousel-porquecomprar').owlCarousel({
						items: 5,
						margin: 0,
						nav: false,
						dots: false,
						navText: ['', ''],
						autoplay: true,
						loop: true,
						responsive: {
							600: {
								items: 3,
								autoplay: true,
								dots: false,
							},
							400: {
								items: 2,
								autoplay: true,
								dots: false,
							},
							320: {
								items: 1,
								autoplay: true,
								dots: false,
							}
						}
					});
				}, 500)
			});

		} else {
			this.isMobile = false;
			$('.carousel-porquecomprar').owlCarousel('destroy');
		}
	}

}
