import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()

export class MenusService {

	private crmUrl : string = environment.apiUrl;

	constructor(private http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,) {
	}

	getMenus () {
		var crmData =  [{}];
		let formData = new FormData();
		formData.append('method', 'menu_list');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl+'?ws=noload', formData).pipe( map(response => {
      this.csasr.check(response);
			return response
		}))
	}
}
