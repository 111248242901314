import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { StoreService } from '../../services/store.service'
import { ACTION_HEADER_STATE } from '../../store/actions/appAction'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  menus:any;
  constructor(
  	private store: Store<any>,
  	private storeService: StoreService
  )
  {}

  ngOnInit(){
    window.scrollTo(0,0);
  	this.store.subscribe( response => { this.menus = response.appReducer.menus });
    this.storeService.updateAppState({
      action: ACTION_HEADER_STATE,
      payload: false
    })
  }

  ngAfterViewInit(){
  	setTimeout( () => {
	  	//CAMBIAR DE ESTADO EL HEADER
			this.storeService.updateAppState({
				action: ACTION_HEADER_STATE,
				payload: false
			})
  	})
  }

  ngOnDestroy() {
  	//CAMBIAR DE ESTADO EL HEADER
		this.storeService.updateAppState({
			action: ACTION_HEADER_STATE,
			payload: true
		})
  }
}
