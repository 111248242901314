import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { NgxJsonLdModule } from 'ngx-json-ld';

//COMPONENTES
import { HeaderComponent }  from './components/header/header.component';
import { FooterComponent }  from './components/footer/footer.component';
import { HomeComponent }    from './components/home/home.component';
import { LibrosComponent }  from './components/libros/libros.component';
import { LibroComponent }  from './components/libro/libro.component';
import { CarritoComponent }  from './components/carrito/carrito.component';
import { MiCarritoComponent } from "./components/carrito/mi-carrito/mi-carrito.component";
import { SolicitudCorreoComponent } from "./components/carrito/solicitud-correo/solicitud-correo.component";
import { DatosEnvioComponent } from "./components/carrito/datos-envio/datos-envio.component";
import { InformacionPagoComponent } from "./components/carrito/informacion-pago/informacion-pago.component";
import { ConfirmacionPagoComponent } from "./components/carrito/confirmacion-pago/confirmacion-pago.component";
import { IngresoComponent } from "./components/login/ingreso/ingreso.component";
import { IniciarSesionComponent } from "./components/login/iniciar-sesion/iniciar-sesion.component";
import { LoginModalComponent } from "./components/modules/login-modal/login-modal.component";
import { AccesoRapidoComponent } from "./components/login/acceso-rapido/acceso-rapido.component";
import { LoginComponent } from "./components/login/login.component";
import { RegistroComponent } from "./components/login/registro/registro.component";
import { MiCuentaComponent } from "./components/mi-cuenta/mi-cuenta.component";
import { DatosPersonalesComponent } from './components/mi-cuenta/datos-personales/datos-personales.component';
import { MetodosPagoComponent } from './components/mi-cuenta/metodos-pago/metodos-pago.component';
import { LibretaDireccionesComponent } from './components/mi-cuenta/libreta-direcciones/libreta-direcciones.component';
import { HistorialOrdenesComponent } from './components/mi-cuenta/historial-ordenes/historial-ordenes.component';
import { MisFavoritosComponent } from './components/mi-cuenta/mis-favoritos/mis-favoritos.component';
import { EbooksComponent } from './components/mi-cuenta/ebooks/ebooks.component';
import { ModalCarritoComponent } from "./components/modals/modal-carrito/modal-carrito.component";
import { ModalGenericaComponent } from './components/modals/modal-generica/modal-generica.component';
import { ModalRestriccionesPorCovidComponent } from './components/modals/modal-restricciones-por-covid/modal-restricciones-por-covid.component';
import { ConfirmarCodigoComponent } from './components/login/confirmar-codigo/confirmar-codigo.component';
import { ColeccionesComponent } from "./components/colecciones/colecciones.component";
import { DetalleColeccionComponent } from "./components/colecciones/detalle-coleccion/detalle-coleccion.component";
import { PromocionesComponent } from "./components/promociones/promociones.component";
import { DetallePromocionComponent } from "./components/promociones/detalle-promocion/detalle-promocion.component";
import { EventosComponent } from "./components/eventos/eventos.component";
import { DetalleEventoComponent } from "./components/eventos/detalle-evento/detalle-evento.component";
import { QuienesSomosComponent } from "./components/quienes-somos/quienes-somos.component";
import { NuestrasSedesComponent } from "./components/nuestras-sedes/nuestras-sedes.component";
import { RegalaUnBonoComponent } from "./components/regala-un-bono/regala-un-bono.component";
import { PerfilAutorComponent } from "./components/perfil-autor/perfil-autor.component";
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { PqrsComponent } from './components/ayuda/pqrs/pqrs.component';
import { PreguntasFrecuentesComponent } from './components/ayuda/preguntas-frecuentes/preguntas-frecuentes.component';
import { PoliticaDatosComponent } from './components/ayuda/politica-datos/politica-datos.component';
import { PoliticaMercanciaComponent } from './components/ayuda/politica-mercancia/politica-mercancia.component';
import { ManualesComponent } from './components/ayuda/manuales/manuales.component';
import { RecuperarClaveComponent } from "./components/login/recuperar-clave/recuperar-clave.component";
import { FavoritosService } from "./services/favoritos.service";
import { PorquecomprarComponent } from './components/globales/porquecomprar/porquecomprar.component';
import { SedesService } from './services/sedes.service';
import { ContactoHeaderComponent } from './components/globales/contacto-header/contacto-header.component';
import { FacturaService } from "./services/factura.service";
import { PromocionesService } from "./services/promociones.service";
import { ComunicadosLibreriaNacionalComponent } from "./components/comunicados-libreria-nacional/comunicados-libreria-nacional.component";
import { CambiarClaveComponent } from "./components/login/cambiar-clave/cambiar-clave.component";
import { MisbonosvirtualesComponent } from './components/globales/misbonosvirtuales/misbonosvirtuales.component';
import { TuordenComponent } from './components/globales/tuorden/tuorden.component';
import { EbooksService } from './services/ebooks.service';
import { EnmantenimientoComponent } from "./components/enmantenimiento/enmantenimiento.component";

import { EliminarCuentaComponent } from './components/eliminar-cuenta/eliminar-cuenta.component';

// MAPAS
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { BlockModalComponent } from "./components/modules/block-modal/block-modal.component";

// REDUX
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// PIPES
import { CapitalizeFirstPipe } from './pipes/capitalizefirst.pipe';
import { VideoYoutubePipe } from './pipes/video-youtube.pipe';
import { StripHtmlPipe } from "./pipes/striphtml.pipe";
import { ColorRamdomPipe } from "./pipes/colorramdom.pipe";
import { limitString } from "./pipes/limitstring.pipe";
import { redirectUrl } from "./pipes/redirecturl.pipe";

//SET LANGUAGE
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
import localeEsExtra from '@angular/common/locales/extra/es-CO';

//PROVIDERS
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ModalService } from './services/modal.service';
import { MenusService } from './services/menus.service';
import { UsuarioService } from './services/usuario.service';
import { ProductosService } from './services/productos.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardInverseService } from './services/auth-guard-inverse.service';
import { AuthGuardRegisterStateService } from './services/auth-guard-register-state.service';
import { AuthNoGuardService } from './services/auth-noguard.service';
import { AuthGuardDatosEnvioService } from './services/auth-guard-datos-envio.service';
import { NoAuthGuardService } from './services/no-auth-guard.service';
import { ColeccionesService } from './services/colecciones.service';
import { CarritoService } from './services/carrito.service';
import { ContenidosService } from './services/contenidos.service';
import { StoreService } from './services/store.service';
import { SessionService } from "./services/session.service";
import { EliminarCuentaService } from './services/eliminar-cuenta.service';
import { WindowScrolling } from "./services/window-scrolling.service";
import { AddressService } from "./services/address.service";
import { CardService } from "./services/card.service";
import { PseService } from "./services/pse.service";
import { LocationService } from "./services/location.service";
import { AyudaService } from './services/ayuda.service';
import { AuthorService } from './services/author.service';
import { SearchService } from './services/search.service';
import { AddTrakingTopleadsService } from './services/add-traking-topleads.service';

//SHARE SOCIAL MEDIA
import { ShareModule } from '@ngx-share-pat/core';

//LOGIN SOCIAL
//import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider} from "angular-6-social-login";
//import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
//import { GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

//CARGADOR HTTP
import { NgHttpLoaderModule } from 'ng-http-loader'

// REGISTRAR IDIOMA ESPAÑOL EN ANGULAR
registerLocaleData(localeEs, 'es-ES', localeEsExtra);

//GTM
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';

// CAPTCHA
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { NotFound404Component } from './components/not-found404/not-found404.component';
import { MenuLoginHeaderComponent } from './components/globales/menu-login-header/menu-login-header.component';
import { TerminosYCondicionesComponent } from './components/ayuda/terminos-y-condiciones/terminos-y-condiciones.component';
import { LoaderComponent } from './components/globales/loader/loader.component';

// EFFECTS
import { EffectsModule } from '@ngrx/effects';
import { effectsRoot } from './store/effectsRoot';

// NG Lazy Load Images
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { ReversionesComponent } from './components/ayuda/reversiones/reversiones.component';
import { TycLibreroMamaComponent } from './components/ayuda/tyc-librero-mama/tyc-librero-mama.component';
import { DatosEnvioCarritoComponent } from './components/carrito/datos-envio.component';
import { environment } from 'src/environments/environment';
import { ConfirmadasComponent } from './components/mi-cuenta/historial-ordenes/confirmadas/confirmadas.component';
import { PendientesComponent } from './components/mi-cuenta/historial-ordenes/pendientes/pendientes.component';
import { CanceladasComponent } from './components/mi-cuenta/historial-ordenes/canceladas/canceladas.component';
import { ListasComponent } from './components/mi-cuenta/libreta-direcciones/listas-direcciones/listas-direcciones.component';
import { AgregarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/agregar-direccion/agregar-direccion.component';
import { EditarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/editar-direccion/editar-direccion.component';
import { FormularioComponent } from './components/mi-cuenta/libreta-direcciones/formulario/formulario.component';
import { EliminarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/eliminar-direccion/eliminar-direccion.component';
import { ConfirmarDireccionComponent } from './components/mi-cuenta/historial-ordenes/confirmar-direccion/confirmar-direccion.component';
import { NewsLetterComponent } from './components/gracias/news-letter/news-letter.component';
// CONFIGURACIÓN LOGIN REDES SOCIALES
/* export function getAuthServiceConfigs(){
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("529648729383744")
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("409539900182-089qc0j76vm6kjr7v4d7q1thc6c2a1gs.apps.googleusercontent.com")
      }
    ]
  );
  return config;
} */

@NgModule({
  declarations: [
    CapitalizeFirstPipe,
    VideoYoutubePipe,
    StripHtmlPipe,
    ColorRamdomPipe,
    limitString,
    redirectUrl,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LibrosComponent,
    LibroComponent,
    CarritoComponent,
    MiCarritoComponent,
    DatosEnvioCarritoComponent,
    SolicitudCorreoComponent,
    DatosEnvioComponent,
    InformacionPagoComponent,
    ConfirmacionPagoComponent,
    LoginComponent,
    IngresoComponent,
    AccesoRapidoComponent,
    ConfirmarCodigoComponent,
    IniciarSesionComponent,
    RecuperarClaveComponent,
    CambiarClaveComponent,
    LoginModalComponent,
    BlockModalComponent,
    RegistroComponent,
    MiCuentaComponent,
    DatosPersonalesComponent,
    MetodosPagoComponent,
    LibretaDireccionesComponent,
    HistorialOrdenesComponent,
    EbooksComponent,
    MisFavoritosComponent,
    ModalCarritoComponent,
    ModalGenericaComponent,
    ModalRestriccionesPorCovidComponent,
    ColeccionesComponent,
    DetalleColeccionComponent,
    PromocionesComponent,
    DetallePromocionComponent,
    EventosComponent,
    DetalleEventoComponent,
    QuienesSomosComponent,
    NuestrasSedesComponent,
    RegalaUnBonoComponent,
    PerfilAutorComponent,
    AyudaComponent,
    PqrsComponent,
    PreguntasFrecuentesComponent,
    PoliticaDatosComponent,
    PoliticaMercanciaComponent,
    ManualesComponent,
    NotFound404Component,
    ContactoHeaderComponent,
    MenuLoginHeaderComponent,
    PorquecomprarComponent,
    MisbonosvirtualesComponent,
    TuordenComponent,
    ComunicadosLibreriaNacionalComponent,
    TerminosYCondicionesComponent,
    LoaderComponent,
    ReversionesComponent,
    TycLibreroMamaComponent,
    EnmantenimientoComponent,
    ConfirmadasComponent,
    PendientesComponent,
    CanceladasComponent,
    ListasComponent,
    AgregarDireccionComponent,
    EditarDireccionComponent,
    FormularioComponent,
    EliminarDireccionComponent,
    ConfirmarDireccionComponent,
    NewsLetterComponent,
    EliminarCuentaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ln2018'}),
    AppRoutingModule,
    Angulartics2Module.forRoot([Angulartics2GoogleTagManager],{
      pageTracking: {
        clearHash: true,
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    IonRangeSliderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgHttpLoaderModule,
    //SocialLoginModule,
    ShareModule.forRoot(),
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 10 }) : [],
    EffectsModule.forRoot(effectsRoot),
    RecaptchaModule,
    RecaptchaFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.passAgm
    }),
    LazyLoadImageModule.forRoot({ preset: intersectionObserverPreset }),
    NgxJsonLdModule,
    SocialLoginModule
  ],
  providers: [
    ModalService,
    MenusService,
    UsuarioService,
    ProductosService,
    AuthGuardService,
    AuthGuardInverseService,
    AuthGuardRegisterStateService,
    AuthNoGuardService,
    AuthGuardDatosEnvioService,
    NoAuthGuardService,
    ColeccionesService,
    PromocionesService,
    CarritoService,
    ContenidosService,
    StoreService,
    SessionService,
    EliminarCuentaService,
    AddressService,
    CardService,
    PseService,
    LocationService,
    FacturaService,
    AyudaService,
    AuthorService,
    SedesService,
    FavoritosService,
    SearchService,
    EbooksService,
    WindowScrolling,
    GoogleMapsAPIWrapper,
    CapitalizeFirstPipe,
    CurrencyPipe,
    Angulartics2GoogleAnalyticsEnhancedEcommerce,
    AddTrakingTopleadsService,
    /* {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    { provide: LOCALE_ID, useValue: 'es-ES' } */
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '409539900182-089qc0j76vm6kjr7v4d7q1thc6c2a1gs.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('529648729383744')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
