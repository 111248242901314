import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { EliminarDireccion, AccionesDirecciones, CargarDirecciones, SetDireccion } from 'src/app/store/direcciones/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import { Subscription } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: "app-eliminar-direccion",
  template: ``,
})
export class EliminarDireccionComponent implements OnInit, OnDestroy {
  id: string;

  susA: Subscription = new Subscription();

  url = "/mi-cuenta/libreta-direcciones";

  usuario: any;
  sus: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private actions: Actions,
    private userService: UsuarioService
  ) {
    if (this.router.url.indexOf("/carrito") >= 0) {
      this.url = "/carrito/datos-envio";
    }

    this.id = this.route.snapshot.params["direccion"];

    this.susA = this.actions.subscribe((action) => {
      console.log(action);

      if (action.type === AccionesDirecciones.ELIMINAR_DIRECCION_EXITOSA) {
        Swal.fire("¡Eliminada!", "", "success");
        this.store.dispatch(
          new CargarDirecciones({
            session_id: JSON.parse(localStorage.session),
          })
        );
        this.router.navigateByUrl(this.url);
      }
    });

    this.sus = this.store.subscribe((data: AppState) => {
      this.usuario = data.appReducer.user;
    });
  }

  ngOnDestroy() {
    this.susA.unsubscribe();
  }

  ngOnInit() {
    this.store.dispatch(new SetDireccion({ id: this.id }));

    if(this.usuario.estado_login === 'LOGIN_PARCIAL'){
      this.userService.updateLoginModalState(true);
      this.userService.updateLoginModalOrigin("datos-envio");
      return;
    }

    Swal.fire({
      title: "¿Estas seguro?",
      text: "La dirección se eliminara",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new EliminarDireccion({
            session_id: JSON.parse(localStorage.session),
            direccion_id: this.id,
          })
        );
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop
      ) {
        this.router.navigateByUrl(this.url);
      }
    });
  }
}
