import { UnionAccionesRecomendadosHome, AccionesRecomendadosHome } from './actions';
import { State } from '@ngrx/store';

export interface RecomendadosHomeStateI {
  data: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: RecomendadosHomeStateI = {
  data: [],
  loaded: false,
  loading: false,
  error: null
};

export function recomendadosHomeReducer(state: any = estadoInicial, action: UnionAccionesRecomendadosHome) {
  switch (action.type) {
    case AccionesRecomendadosHome.CARGAR_RECOMENDADOS_HOME:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesRecomendadosHome.CARGA_RECOMENDADOS_HOME_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {...action.recomendados}
      };

    case AccionesRecomendadosHome.CARGA_RECOMENDADOS_HOME_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    default:
      return state;
  }
}
