import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorramdom'
})
export class ColorRamdomPipe implements PipeTransform {
  transform(value: string): string {

    if (value === null) return 'Not assigned';

    let color: any = [
        '#EF9A9A',
        '#F48FB1',
        '#CE93D9',
        '#B39DDB',
        '#9FA8DA',
        '#90CAF9',
        '#81D5FA',
        '#FFE082',
        '#FEF59D',
        '#E6EE9C',
        '#C5E1A4',
        '#A5D6A7',
        '#7FCBC4',
        '#7FDEEA',
        '#FFCC80',
        '#FFAB91',
        '#BCAAA4',
        '#EEEEEE',
        '#B0BEC5'
    ]

    return color[Math.floor(Math.random()*color.length)];

  }
}