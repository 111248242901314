import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import { Subscription } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { AccionesDirecciones } from 'src/app/store/direcciones/actions';
import { AccionesOrdenes } from 'src/app/store/ordenes/actions';
import Swal from 'sweetalert2';

declare const $;

@Component({
  selector: "app-confirmar-direccion",
  templateUrl: "./confirmar-direccion.component.html",
  styles: [
    `
      .btn-rojo {
        font-size: 2rem;
        color: maroon !important;
      }
    `,
  ],
})
export class ConfirmarDireccionComponent implements OnInit, OnDestroy {
  id: string;
  direccion_id: string;
  direcciones$ = this.store.select((store) => store.direcciones);
  page: string = "";

  susA: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private actions: Actions
  ) {
    this.susA = this.actions.subscribe((action: any) => {
      // console.log(action);

      if (action.type === AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA) {
        this.page = "";
      }

      if(action.type === AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN_FALLIDA){debugger
        Swal.fire('Error', action.data.description, 'error');
      }
    });
  }

  ngOnInit() {
    // console.log(this.route.snapshot.params);

    this.id = this.route.snapshot.params["orden"];
    this.direccion_id = this.route.snapshot.params["direccion"];

    $("#modalDirecciones").modal("show");
  }

  ngOnDestroy() {
    this.susA.unsubscribe();
  }
}
