import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';
import { formData } from '../../environments/global';

@Injectable({
  providedIn: 'root'
})
export class PqrsService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private csasr: CheckSessionAfterServiceResponseService,
  ) { }

  tipo_pqr_list(  ) {
    let crmData = [ null, {} ];
    let formData = new FormData();
    formData.append('method', 'tipo_pqr_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response
    }))
  }

  sedes_list(  ) {
    let crmData = [ null, {} ];
    let formData = new FormData();
    formData.append('method', 'sedes_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response
    }))
  }

  caso_set( data ) {
    return this.http.post(this.crmUrl, formData('caso_set', data)).pipe( map(response =>  response))
  }
}
