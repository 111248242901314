import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarPromocionesSuccess, CargarPromocionesFail, AccionesPromociones } from './actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { PromocionesService } from 'src/app/services/promociones.service';

@Injectable()
export class PromocionesEffects {

    constructor(
        private actions$: Actions,
        private promocionService: PromocionesService,
    ) {}

    @Effect()
    cargarPromociones$ = this.actions$.pipe( ofType(AccionesPromociones.CARGAR_PROMOCIONES) )
        .pipe(
            switchMap( (data: any) => {
                return this.promocionService.getPromocionList(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (!response.hasErrors) {
                                // validar si la porpiedad promociones existe
                                if (!('promociones' in response)) {
                                    return new CargarPromocionesSuccess(response);
                                }
                                return new CargarPromocionesSuccess(response.promociones);
                            } else {
                                return new CargarPromocionesFail(response);
                            }
                        } ),
                        catchError( error => of(new CargarPromocionesFail(error)) )
                    );
            })
        );
}
