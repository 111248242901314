import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UsuarioService } from "../../../services/usuario.service";
import { ACTION_LOGIN, ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { StoreService } from "../../../services/store.service";
import { Usuario } from "../../../clases";
import { CarritoService } from "../../../services/carrito.service";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Store } from "@ngrx/store";

@Component({
  selector: 'app-solicitiud-correo',
  templateUrl: './solicitud-correo.component.html',
  styleUrls: ['./solicitud-correo.component.css']
})
export class SolicitudCorreoComponent implements OnInit {
  forma:FormGroup;
  dir:boolean = false;
  invalidRegisterMessages: String = '';
  invalidRegisterState: boolean = false;
  emailUser:any;
  usuario:any;
  shopcar:any;
  
  constructor(
    private router:Router,
    private store: Store<any>,
    private carritoService:CarritoService,
    private storeService:StoreService,
    private usuarioService: UsuarioService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit()
  {
    window.scrollTo(0,0);
    this.validateFormRegistro();

    this.store.subscribe(response => {

      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

    });

  }

  validateFormRegistro(){
    this.forma = new FormGroup({
      'email'					  : new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ])
    });
  }

  formEmailLoginRapido(){
    this.invalidRegisterState = false;
    if (this.forma.valid == true){
      let data = {
        email: this.forma.controls['email'].value
      };
      //let email = this.forma.controls['email'].value;
      this.emailUser = data.email;
      this.usuarioService.fastLoginRegister(data).subscribe(response => {
        if(response && response.id){
          this.loginUserCrmSuccess(response, this.emailUser);
        }
      });
    } else {
      //ERROR CUANDO SE ENCUENTRA UN CAMPO NO VALIDO
      this.forma.controls['email'].markAsTouched();
      return false;
    }
  }

  loginUserCrmSuccess(response, emailUser) {
    let currentUser = new Usuario();
    currentUser.login = true;
    currentUser.email = emailUser;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
    this.usuarioService.getUser(dataCrm).subscribe(resp => {
      if (resp.status == 'ok'){
        currentUser.userId = resp.config.id;
        currentUser.clienteId = resp.cuenta.cliente_id;
        currentUser.tokenCrm = resp.config.token;
        currentUser.estado_registro = resp.config.estado_registro;
        currentUser.estado_login = resp.config.estado_login;
        currentUser.nombres = resp.config.nombres;
        currentUser.apellidos = resp.config.apellidos;
        currentUser.tipo_identificacion = resp.config.tipo_identificacion;
        currentUser.identificacion = resp.config.identificacion;
        currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
        currentUser.telefono = resp.config.telefono;
        currentUser.telefono_movil = resp.cuenta.telefono_movil;

        //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
        this.usuarioService.setUserLocal(currentUser);
        this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

        //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
        let carritoLS = this.carritoService.getCarritoLocal();

        // console.log(carritoLS,this.shopcar)

        if (carritoLS && (this.shopcar.cliente_user_id == '' || this.shopcar.cliente_user_id == null) ){
          //MERGE DE CARRITOS CUANDO SI HAY LOCAL
          let dataCrmMergeCarrito = [
            currentUser.sessionCrm,
            {
              factura_merge_id  : carritoLS
            }
          ];
          this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
            if(response.id){
              this.updateCarritoLocal(response);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
            }
            else 
            {
              this.carritoService.deleteCarritoLocal()
              this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
            }
          })
        } else {
          let productos = [];
          if(this.shopcar.grupos[0].lineas.productos.length > 0) {
            productos = this.shopcar.grupos[0].lineas.productos;
          }
          let dataCrmCarrito = {
            session_id : currentUser.sessionCrm
          };
          this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
            if(dataCarrito.id){

              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
              
              if(productos.length > 0) {
                
              } else {
                this.updateCarritoLocal(dataCarrito);
                this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
              }
            }
          });
        }
        this.router.navigate(['/', 'carrito', 'datos-envio']);
      }
    })
  }

  updateCarritoLocal(dataCarrito){
    this.carritoService.setCarritoLocal(dataCarrito);
  }
}
