import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UsuarioService } from "../../../services/usuario.service";
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
  styleUrls: ['./recuperar-clave.component.scss']
})
export class RecuperarClaveComponent implements OnInit {
  forma:FormGroup;
  invalidRegisterMessages: String = '';
  constructor(
    private router: Router,
    public usuarioService : UsuarioService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) 
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }
  ngOnInit() {
    window.scrollTo(0,0);
    this.validateFormRegistro();
  }

  validateFormRegistro(){
    this.forma = new FormGroup({
      'email' : new FormControl('',[ Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$') ]) });
  }

  formaSendPassword(){
    console.log(this.forma);
    if (this.forma.valid == true){
      let data = {
        email : this.forma.controls['email'].value
      };
      this.usuarioService.recoveryUserPass(data).subscribe((response:any) => {
        if(response === true){
          this.invalidRegisterMessages = 'Se ha enviado un e-mail al correo suministrado con un link para restablecer tu contraseña.';
        } else if(response.number == 1) {
          this.invalidRegisterMessages = response.description;
        } else if(response.number == 2003) {
          this.invalidRegisterMessages = response.description;
        } else if(response.number == 2001){
          this.invalidRegisterMessages = 'El correo ingresado fue registrado mediante la opción redes sociales. Debe loguearse mediante la opción redes sociales en el paso anterior';
        }
      });
    }
  }
}
