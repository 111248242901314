import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-editar-direccion",
  templateUrl: "./editar-direccion.component.html",
})
export class EditarDireccionComponent implements OnInit {

  id: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.id = this.route.snapshot.params["direccion"];

    if(!this.id){
      this.router.navigateByUrl('/mi-cuenta/libreta-direcciones');
    }
  }
}
