import { Injectable } from '@angular/core';
import { Bill } from '../interfaces/bill';
import { Product } from '../interfaces/product';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor() { }

  /**
   *
   * @param product type interface product
   */
  detail(product: Product): void {
    if (dataLayer.length) {
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [{
            item_name: product.name, // Name or ID is required.
            item_id: product.id,
            price: product.price,
            item_brand: product.brand,
            item_category: product.category,
            quantity: 1
          }]
        }
      });
    }
  }

  /**
   *
   * @param product type interface product
   */
  addToCar(product: Product): void {
    if (dataLayer.length) {
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [{
            item_name: product.name, // Name or ID is required.
            item_id: product.id,
            price: product.price,
            item_brand: product.brand,
            item_category: product.category,
            index: 1,
            quantity: product.quantity
          }]
        }
      });
    }
  }

  /**
   * Medir la compra, una transacción o intento de compra
   * @param bill type interface Bill
   * @param products Array of type intercace products
   */
  purchase(bill: Bill, products: Array<Product>): void {

    if (dataLayer.length) {
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: bill.id,
            affiliation: bill.affiliation,
            value: bill.revenue,
            tax: bill.tax,
            shipping: bill.shipping,
            currency: "COP",
            coupon: bill.coupon,
            items: products // array de los productos de la compra
        }
      });
    }
  }

  /**
   * Medir los pasos de tramitación de la compra "Funnel"
   * @param event: evento personalizado, para configurar los TAGS en el GTM
   */
  customEvent(event: String): void {
    if (dataLayer.length) {
      dataLayer.push({ 'event': event });
    }
  }

  /**
   * Medir las opciones de pago
   * @param step: paso del proceso de la compra en el que estoy
   * @param option: texto descriptivo que acompaña el numero del paso en el que estoy
   * @param products: colección de los productos que tengo en elcarrito de compras
   */
  onCheckout(step: number, option: string, products: Array<Product>): void {
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        actionField: { 'step': step, 'option': option },
        items: products
      }
    });
  }

  /**
   *
   * @param step: paso del proceso de la compra en el que estoy
   * @param checkoutOption: texto descriptivo que acompaña el numero del paso en el que estoy, relacionado a la opcion de pago
   */
  onCheckoutOption(step: number, checkoutOption: string): void {
    dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': step, 'option': checkoutOption }
        }
      }
    });
  }

}
