import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-y-condiciones',
  templateUrl: './terminos-y-condiciones.component.html',
  styleUrls: ['./terminos-y-condiciones.component.scss']
})
export class TerminosYCondicionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
