import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { CarritoService } from './carrito.service';
import {Observable} from "rxjs";

@Injectable()
export class AuthGuardInverseService implements CanActivate {
  constructor(
  	private carritoService : CarritoService
  	){
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    let sessionExists = (this.carritoService.getCarritoLocal()) ? (true) : (false);
	  return (sessionExists);
  }
}
