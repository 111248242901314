import { AppState } from './../../appState';
import { UnionAccionesColeccionDetalle, AccionesColeccionDetalle } from './actions';
import { State } from '@ngrx/store';

export interface ColeccionDetalleStateI {
  data: any;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ColeccionDetalleStateI = {
  data: {},
  loaded: false,
  loading: false,
  error: null
};

export function coleccionDetalleReducer(state: any = estadoInicial, action: UnionAccionesColeccionDetalle) {
  switch (action.type) {
    case AccionesColeccionDetalle.CARGAR_COLECCION_DETALLE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesColeccionDetalle.CARGA_COLECCION_DETALLE_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {...action.coleccion}
      };

    case AccionesColeccionDetalle.CARGA_COLECCION_DETALLE_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    case AccionesColeccionDetalle.RESET_COLECCION_DETALLE:
      return {
        data: {},
        loaded: false,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}
