import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UsuarioService } from "../../../services/usuario.service";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-cambiar-clave',
  templateUrl: './cambiar-clave.component.html',
  styleUrls: ['./cambiar-clave.component.scss']
})

export class CambiarClaveComponent implements OnInit {
  forma: FormGroup;
  paramsURL:any;
  invalidRegisterMessages: String = '';
  constructor(
    private router: Router,
    private usuarioService:UsuarioService,
    private activatedRoute:ActivatedRoute,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ){
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.validateFormPass();
    this.activatedRoute.params.subscribe(params => {
      this.paramsURL = params;
    });
  }

  validateFormPass(){
    this.forma = new FormGroup({
      'contrasena'			 : new FormControl(),
      'repeatContrasena' : new FormControl()
    });

    this.forma.controls['contrasena'].setValidators([Validators.required, Validators.minLength(6)]);
    this.forma.controls['repeatContrasena'].setValidators([Validators.required, this.passwordIgual.bind(this.forma)]);
  }

  passwordIgual(control:FormControl): { [s:string]: boolean }{
    let forma : any = this;
    if (control.value !== forma.controls['contrasena'].value){
      return {
        passwordigual: true
      }
    }
    return null;
  }

  formaChangePassword(){
    if (this.forma.valid == true){
      let data = {
        guid  : this.paramsURL.guid,
        email : this.paramsURL.email,
        pass  : this.forma.controls['contrasena'].value,
        repass: this.forma.controls['repeatContrasena'].value
      };
      this.usuarioService.updateUserPass(data).subscribe((response:any) => {
        if(response === true){
          this.invalidRegisterMessages = 'La contraseña ha sido actualizada.';
        } else if(response.number =='1' || response.number == 1 ){
          this.invalidRegisterMessages = response.description;
        }
      });
    } else {
      this.forma.controls['contrasena'].markAsTouched();
      this.forma.controls['repeatContrasena'].markAsTouched();
      return false;
    }
  }
}
