import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Subject } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { Usuario } from '../clases/index'
import { Store } from "@ngrx/store"
import { environment } from '../../environments/environment'

import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()
export class FavoritosService {
	
  private crmUrl : string = environment.apiUrl;

	constructor(
	  public http: HttpClient,
    private csasr: CheckSessionAfterServiceResponseService,
  ){

  }

  getFavorites(data){
    let crmData = data;
    let formData = new FormData();
    formData.append('method', 'favlist_get');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }))
  }
}
