import { Action } from '@ngrx/store';

export enum AccionesPromocionDetalle {
    CARGAR_PROMOCION_DETALLE = '[PROMOCION DETALLE] => Cargar',
    CARGA_PROMOCION_DETALLE_EXITOSA = '[PROMOCION DETALLE] => Carga Exitosa',
    CARGA_PROMOCION_DETALLE_FALLIDA = '[PROMOCION DETALLE] => Carga Fallida',
    RESET_PROMOCION_DETALLE = '[PROMOCION DETALLE] => Reset',
}

export class CargarPromocionDetalle implements Action {
    readonly type = AccionesPromocionDetalle.CARGAR_PROMOCION_DETALLE;

    constructor( public payload?: any ) {}
}

export class CargarPromocionDetalleFail implements Action {
    readonly type = AccionesPromocionDetalle.CARGA_PROMOCION_DETALLE_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarPromocionDetalleSuccess implements Action {
    readonly type = AccionesPromocionDetalle.CARGA_PROMOCION_DETALLE_EXITOSA;

    constructor( public promocion: any ) {}
}

export class ResetPromocionDetalle implements Action {
    readonly type = AccionesPromocionDetalle.RESET_PROMOCION_DETALLE;
}

export type UnionAccionesPromocionDetalle = CargarPromocionDetalle | CargarPromocionDetalleFail | CargarPromocionDetalleSuccess | ResetPromocionDetalle;
