import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  menus: any;
  constructor(
    private store: Store<any>
  )
  {

  }

  ngOnInit() {
    this.store.subscribe( response => {
      this.menus = response.appReducer.menus
    })
  }
}
