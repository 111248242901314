import { Action } from '@ngrx/store';

export enum AccionesNovedadesHome {
    CARGAR_NOVEDADES_HOME = '[NOVEDADES HOME] => Cargar',
    CARGA_NOVEDADES_HOME_EXITOSA = '[NOVEDADES HOME] => Carga Exitosa',
    CARGA_NOVEDADES_HOME_FALLIDA = '[NOVEDADES HOME] => Carga Fallida'
}

export class CargarNovedadesHome implements Action {
    readonly type = AccionesNovedadesHome.CARGAR_NOVEDADES_HOME;

    constructor( public payload?: any ) {}
}

export class CargarNovedadesHomeFail implements Action {
    readonly type = AccionesNovedadesHome.CARGA_NOVEDADES_HOME_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarNovedadesHomeSuccess implements Action {
    readonly type = AccionesNovedadesHome.CARGA_NOVEDADES_HOME_EXITOSA;

    constructor( public novedades: any ) {}
}

export type UnionAccionesNovedadesHome = CargarNovedadesHome | CargarNovedadesHomeFail | CargarNovedadesHomeSuccess;
