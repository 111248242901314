import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()

export class PromocionesService {

	private crmUrl : string = environment.apiUrl;
  
	constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,) {
	}

  getPromocionData (data) {
		var crmData =  [data];
		let formData = new FormData();

		formData.append('method', 'promocion_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
		}));
	}

  getPromocionList(data){
    var crmData =  [null, data];
    let formData = new FormData();

    formData.append('method', 'promociones_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }

  getPromocionProductosList(data){
    var crmData =  [null, data];
    let formData = new FormData();

    formData.append('method', 'promocion_productosList');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }
}
