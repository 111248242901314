import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ProductosService } from 'src/app/services/productos.service';
import { Store, ReducerManager } from '@ngrx/store';
import { CarritoService } from 'src/app/services/carrito.service';
import { ACTION_SHOPCAR, ACTION_DEL_SHOPCAR, UPDATE_TOTALS_SHOPCAR } from "../../store/actions/appAction";
import { StoreService } from 'src/app/services/store.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PlatformLocation } from '@angular/common';

import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-regala-un-bono',
  templateUrl: './regala-un-bono.component.html',
  styleUrls: ['./regala-un-bono.component.css']
})
export class RegalaUnBonoComponent implements OnInit {

  private readonly bonovirtual = 'BONO_FISICO';
  user: any = null;
  bonos: any = [];
  bonosVirtuales: any = [];
  currentBono: any = '';
  currentBonoVirtual: any = '';
  bonoVirtualEmail: string  = '';
  bonoVirtualName: string = '';
  bonoVirtualMensaje: string = '';
  validated: boolean = false;
  shopcar: any = null;
  bonoImage: string = '';
  bonoVirtualImage: string = '';

  showWarningMessage: any

  amount:number = 1;
  showAdd2CartModal:boolean = false;

  constructor(
    private titleService:Title,
    private meta:Meta,
    private productService: ProductosService,
    private carritoService: CarritoService,
    private store: Store<any>,
    private storeService: StoreService,
    private router: Router,
    private usuarioService: UsuarioService,
    private platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';

    let getCarritoFlag: boolean = true;
    this.store.subscribe( res => {
      this.user = res.appReducer.user;
      if('id' in res.appReducer.shopcar && this.shopcar == null) {
        this.shopcar = res.appReducer.shopcar;
      } else if(this.shopcar == null && getCarritoFlag) {
        this.getCarrito();
        getCarritoFlag = false;
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0,0);

    this.getBonos();

    let dataMetas = {
      title: 'Quiénes Somos',
      description: 'El mejor regalo para cada ocasión es un libro, por eso regala literatura a través de nuestros bonos regalos.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  private getCarrito() {
    this.carritoService.newFactura([null,{}]).toPromise().then( (res: any) => {
      if(res.id) {
        this.shopcar = res;
        this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: res});
      }
    });
  }

  private getBonos() {
    this.productService.productSearch({tipo:this.bonovirtual, limite: 1000, offset: 0}).toPromise().then( (res: any) => {
      if(res != null) {
        if('productos' in res) {
          if(res.productos != null && res.productos.length > 0) {
            this.bonos = res.productos;
          }
        }
      }
    });
    this.productService.bonosvirtuales_productosList().toPromise().then( (res: any) => {
      if(res != null) {
        if('bonos' in res) {
          if(res.bonos != null && res.bonos.length > 0) {
            this.bonosVirtuales = res.bonos;
          }
        }
      }
    });
  }

  productDownCount() {
    if(this.amount > 1) {
      this.amount -= 1;
    }
  }

  productUpCount() {
    this.amount += 1;
  }

  selectCurrentBono(bono) {
    this.currentBono = bono;
  }

  selectCurrentBonoVirtual(bono) {
    this.currentBonoVirtual = bono;
  }

  validateEmail(email) {
    let valid = email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g);
    let res = valid && valid.length > 0 ? true : false;
    return res;
  }

  private getUserSession() {
    let res = null;
    if(this.user != null) {
      res = this.user.sessionCrm;
    }
    return res;
  }

  comprarBono() {
    if(this.shopcar == null)
      return false;

    if((this.currentBono == null || this.currentBono == '')) {
      return false;
    }

    let sessionCrm = this.getUserSession();

    let data = [
      sessionCrm,
      {
        factura_id: this.shopcar.id,
        grupo_id: this.shopcar.grupos[0].id,
        producto_id: this.currentBono.id,
        cantidad: this.amount
      }
    ];
    this.carritoService.addProduct(data).toPromise().then( (res: any) => {
      if(res.factura) {
        if(res.factura.id) {
          this.shopcar = res.factura;
          this.carritoService.setCarritoLocal(res.factura);
          this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: res.factura});
          let addedToCart = {
            cantidad: this.currentBono.cantidad,
            slug: this.currentBono.slug ? this.currentBono.slug : null,
            name: this.currentBono.name,
            image: this.bonoImage,
            price: ((this.currentBono.price_dcto < this.currentBono.price) ? this.currentBono.price_dcto : this.currentBono.price) * this.amount
          };
          let dataModal = {
            tipo: 'quickView',
            data: this.currentBono,
            productToAdd: addedToCart,
            shop_total: {
              subtotal:   this.shopcar.totales.subtotal,
              gran_total: this.shopcar.totales.gran_total,
              total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
            },
            noCoverImg: this.bonoImage,
          };
          this.usuarioService.updateNotificacionesModal(dataModal);
          this.usuarioService.updateDataModalState(true);
        }
        else
        {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      }
      else
      {
        this.carritoService.deleteCarritoLocal()
        this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
      }
    });
  }

  comprarBonoVirtual() {
    if(this.shopcar == null)
      return false;

    this.validated = true;

    if((this.currentBonoVirtual == null || this.currentBonoVirtual == '')
    || !this.validateEmail(this.bonoVirtualEmail)
    || this.bonoVirtualName == ''
    ) {
      return false;
    }

    let sessionCrm = this.getUserSession();

    let data = {
      sessionCrm,
      factura_id: this.shopcar.id,
      grupo_id: this.shopcar.grupos[0].id,
      producto_id: this.currentBonoVirtual.id,
      envio: {
        nombre: this.bonoVirtualName,
        email: this.bonoVirtualEmail,
        mensaje: this.bonoVirtualMensaje
      }
    }
    this.productService.bonosvirtuales_new(data).toPromise().then( (res: any) => {
      if(res && 'id' in res) {
        this.carritoService.getFactura([null,{factura_id: this.shopcar.id}]).toPromise().then( (res: any) => {
          if(res && 'id' in res) {
            this.shopcar = res;
            this.carritoService.setCarritoLocal(res);
            this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: res});
            let addedToCart = {
              cantidad: this.currentBonoVirtual.cantidad,
              slug: this.currentBonoVirtual.slug ? this.currentBonoVirtual.slug : null,
              name: this.currentBonoVirtual.name,
              image: this.bonoVirtualImage,
              price: ((this.currentBonoVirtual.price_dcto < this.currentBonoVirtual.price) ? this.currentBonoVirtual.price_dcto : this.currentBonoVirtual.price) * this.amount
            };
            let dataModal = {
              tipo: 'quickView',
              data: this.currentBonoVirtual,
              productToAdd: addedToCart,
              shop_total: {
                subtotal:   this.shopcar.totales.subtotal,
                gran_total: this.shopcar.totales.gran_total,
                total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
              },
              noCoverImg: this.bonoVirtualImage,
            };
            this.usuarioService.updateNotificacionesModal(dataModal);
            this.usuarioService.updateDataModalState(true);
          }
          else
          {
            this.carritoService.deleteCarritoLocal()
            this.storeService.updateAppState({ action: UPDATE_TOTALS_SHOPCAR, payload: {} });
          }
        });
      }
    });

  }
}
