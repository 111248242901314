import { Store } from '@ngrx/store';
import { AppState } from './../../store/appState';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColeccionesService } from "../../services/colecciones.service";
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ContenidosService } from "../../services/contenidos.service";
import { Subscription } from 'rxjs';
import { Meta, Title } from "@angular/platform-browser";

import * as AccionesEventos from '../../store/eventos/actions';
import * as AccionesEventodestacado from '../../store/eventos/destacado/actions';

import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit, OnDestroy {
  eventosTitle:any;
  eventosData:any= [];
  eventoDestacadoId:any;
  eventoDestacado:any = [];
  eventoDestacadoTitle:any;

  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = null;

  // loaders
  cargandoED = false;
  cargaContenidoED = false;

  // error
  errorED: any = null;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;
  constructor(
    private titleService:Title,
    private meta:Meta,
    private router: Router,
    private coleccionesService:ColeccionesService,
    private contenidosService:ContenidosService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private store: Store<AppState>,
    private platformLocation: PlatformLocation
  ){
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    this.loadSubscription = this.store.subscribe(({ eventos, eventodestacado }) => {
      // Evento destacado
      this.errorED = eventodestacado.error;
      this.cargandoED = eventodestacado.loading;
      this.cargaContenidoED = eventodestacado.loaded;

      if(eventodestacado.data.items){
        if(eventodestacado.data.items.length > 0){
          this.eventoDestacadoTitle = eventodestacado.data.description;
          if(eventodestacado.data.items[0].relacionado){
            this.eventoDestacadoId = eventodestacado.data.items[0].relacionado_id;
          }
          this.eventoDestacado = eventodestacado.data.items;
        }
      }

      // Eventos
      this.error = eventos.error;
      this.cargando = eventos.loading;
      this.cargaContenido = eventos.loaded;
      //this.coleccionFull = eventos.data;

      if(eventos.data.length > 0 && this.eventosData.length == 0){
        if(this.eventoDestacadoId){
          for(let i =0; i < eventos.data.length; i ++){
            if(eventos.data[i].id != this.eventoDestacadoId){
              this.eventosData.push(eventos.data[i]);
            }
          }
        }else{
          this.eventosData = eventos.data;
        }
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0,0);

    let dataMetas = {
      title: 'Nuestros Eventos',
      description:  'Encuentra los últimos eventos del mundo literario cerca a tu ciudad.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    if(!this.eventoDestacadoId && !this.errorED){
      this.getEventoDestacado();
    }
    if(this.eventosData.length == 0 && !this.error){
      this.getEventosList();
    }
  }

  ngOnDestroy() {
    this.loadSubscription.unsubscribe();
  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  getEventoDestacado(){
    this.store.dispatch(new AccionesEventodestacado.CargarEventoDestacado({ slug:'evento-destacado' }));
  }

  getEventosList(){
    this.store.dispatch(new AccionesEventos.CargarEventos({ tipo : 'EVENTO' }));
  }
}
