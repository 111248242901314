import { AppState } from './../../../store/appState';
import { Component, OnInit, Pipe, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { Store } from "@ngrx/store";
import { StoreService } from "../../../services/store.service";
import { UsuarioService } from "../../../services/usuario.service";
import { CarritoService } from "../../../services/carrito.service";
import { FormGroup, FormControl } from '@angular/forms';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Meta, Title } from "@angular/platform-browser";

import { Subscription } from 'rxjs';

//import { data_coleccion } from './data_coleccion';

import * as AccionesColeccionDetalle from '../../../store/colecciones/detalle/actions';

@Component({
  selector: 'app-detalle-coleccion',
  templateUrl: './detalle-coleccion.component.html',
  styleUrls: ['./detalle-coleccion.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

@Pipe({
  name: 'strip_p_tag'
})

export class DetalleColeccionComponent implements OnInit, OnDestroy {
  showFiltersSidebar: boolean = false;
  filterText: string = 'Mostrar filtros';
  filterIcon: string = 'icon-filter';
  icon: string = 'empty';
  status: boolean = false;
  showFilterModal: boolean = false;
  coleccionData: any = {};

  pages: any = [];
  pageCurrent: number;
  pagesPerPage: number = 20;
  listProduct: any = [];
  dataProducts: any = [];
  dataProductsCopy: any = [];
  formaOrder: FormGroup;

  usuario: any;
  shopcar: any;
  addedToCart: any = {};
  detailsProduct: any = [];
  updateInProgress: boolean = false;
  active: number;
  noCoverImg: string = '';
  innerWidth: any;

  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = {};

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private store: Store<AppState>,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private carritoService: CarritoService,
    public platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    this.loadSubscription = this.store.select('coleccion').subscribe(({ data, loading, loaded, error }) => {
      this.error = error;
      this.cargando = loading;
      this.cargaContenido = loaded;
      this.coleccionData = data;

      if (Object.keys(this.coleccionData).length > 0) {
        if (this.coleccionData.items.length > 0) {
          try {
            this.buildForm();
          } catch (error) { }
          this.dataProductsCopy = this.coleccionData.items;
          this.orderArray(this.coleccionData.items, true);
          this.coleccionData.imgToshow = this.checkBrowserWidth(this.innerWidth);

          let dataMetas = {
            title: this.coleccionData.name,
            description: this.coleccionData.description ? this.coleccionData.description : '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
            keywords: 'libros,libreria,ebooks',
            image: this.coleccionData.imagen1 ? this.coleccionData.imagen1 : 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
            url: 'https://www.librerianacional.com' + this.router.url
          }
          try {
            this.setMetaData(dataMetas);
          } catch (error) { }
        }
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getBrowserWidth();

    this.activatedRoute.params.subscribe(params => {
      this.store.dispatch(new AccionesColeccionDetalle.CargarColeccionDetalle({ slug: params.slug }));
      //this.store.dispatch(new AccionesColeccionDetalle.CargarColeccionDetalleSuccess(data_coleccion[params.slug]));
    });
    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';
  }

  ngOnDestroy() {
    this.store.dispatch(new AccionesColeccionDetalle.ResetColeccionDetalle());
    this.loadSubscription.unsubscribe();
  }

  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.coleccionData.imgToshow = this.checkBrowserWidth(this.innerWidth);
  }

  buildForm() {
    this.formaOrder = new FormGroup({
      'productsOrder': new FormControl()
    });

    this.formaOrder.controls['productsOrder'].setValue('defecto');
  }

  getBrowserWidth() {
    this.innerWidth = window.innerWidth;
  }

  checkBrowserWidth(px) {
    if (px >= 0 && px <= 768) {
      // return this.coleccionData.imagen2;
      return this.coleccionData.imagenes2.full;
    } else if (px >= 768 && px <= 992) {
      // return this.coleccionData.imagen1;
      return this.coleccionData.imagenes1.full;
    } else if (px >= 992 ) { // && px <= 1920
      // return this.coleccionData.imagen3;
      return this.coleccionData.imagenes3.full;
    }
  }

  setMetaData(data) {
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  productToAddDetail(id) {
    this.addProductCarrito(id);
  }

  addProductCarrito(id) {
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id: null,
      grupo_id: null,
      producto_id: id,
      cantidad: 1
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)
    }
  }

  addProductCarritoCrm(dataCrm) {
    this.carritoService.addProduct(dataCrm).subscribe((response: any) => {

      this.updateInProgress = false;

      if (response.hasErrors) {
        if (response.number == 2204) {
          // producto NO existe
        }
        else {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      }
      else {
        if (response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }
        if (response.factura.id) {
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
        if (response.producto) {
          this.detailsProduct = response.producto;
        }
      }
    })
  }

  getNewBill(dataCrm) {

    let user = this.usuario;
    let producCurrent = dataCrm[1]


    let dataCrmNF: any;
    if (user) {
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res: any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito) {

    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });

    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        subtotal: this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };

    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);

  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  toggleFilter() {
    if (this.showFiltersSidebar == false) {
      this.showFiltersSidebar = true;
      this.filterText = 'Ocultar filtros';
      this.filterIcon = 'icon-close';
    } else {
      this.showFiltersSidebar = false;
      this.filterText = 'Mostrar filtros';
      this.filterIcon = 'icon-filter';
    }
  }

  toggleIcon() {
    if (this.icon == 'empty') {
      this.icon = 'rounded';
    } else {
      this.icon = 'empty';
    }
  }
  orderArray(productos, first = false) {
    this.pageCurrent = 1;
    let currentOrder = this.formaOrder.controls['productsOrder'].value;
    if (currentOrder == 'nameAZ' || currentOrder == 'nameZA') {
      this.dataProducts = productos.sort(function (a, b) {

        if (a.relacionado && b.relacionado) {
          if (a.relacionado.name < b.relacionado.name) {
            if (currentOrder == 'nameAZ') {
              return -1
            } else {
              return 1
            }
          }
          if (a.relacionado.name > b.relacionado.name) {
            if (currentOrder == 'nameAZ') {
              return 1
            } else {
              return -1
            }
          }
        }
        return 0;
      })
    } else if (currentOrder == 'priceAZ' || currentOrder == 'priceZA') {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.relacionado && b.relacionado) {
          if (a.relacionado.price_dcto < b.relacionado.price_dcto) {
            if (currentOrder == 'priceAZ') {
              return -1
            } else {
              return 1
            }
          }
          if (a.relacionado.price_dcto > b.relacionado.price_dcto) {
            if (currentOrder == 'priceAZ') {
              return 1
            } else {
              return -1
            }
          }
        }
        return 0;
      })
    } else if (currentOrder == 'descuento') {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.relacionado && b.relacionado) {
          if (a.relacionado.dcto_porcentaje < b.relacionado.dcto_porcentaje) {
            return -1
          }
          if (a.relacionado.dcto_porcentaje > b.relacionado.dcto_porcentaje) {
            return -1
          }
        }
        return 0;
      })
    } else {
      if (productos.length > 0) {
        this.dataProducts = Object.assign([{}], this.dataProductsCopy);
      }
      else {
        this.dataProducts = productos;
      }
    }
    this.paginarProductos(this.dataProducts);
    if(first){
      this.paginacion(1)
    }
  }

  paginarProductos(data) {
    let paginas = 0;
    this.pages = [];
    this.listProduct = data;
    for (var i = 0; i < data.length; ++i) {
      if (i % this.pagesPerPage == 0) {
        paginas++;
        this.pages.push(paginas);
      }
    }
  }

  paginacion(index) {
    if (index == 'next') {
      if (this.pageCurrent < this.pages.length) {
        this.pageCurrent++
        let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
        this.listProduct = newListData
      }
    } else if (index == 'prev') {
      if (this.pageCurrent > 1) {
        this.pageCurrent--
        let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
        this.listProduct = newListData
      }
    } else {
      this.pageCurrent = parseInt(index)
      let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
      this.listProduct = newListData
    }
    //SCROLL TOP
    window.scrollTo(0,0)
  }
}
