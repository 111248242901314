import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { UsuarioService } from './usuario.service';
import { Observable} from "rxjs";
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardRegisterStateService implements CanActivate {
  constructor(
    private usuarioService : UsuarioService,
    private sessionService: SessionService
  ){
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    let sessionExists = (this.usuarioService.getUserLocal()) ? (true) : (false);
    // si es falso redirecciono al usuario a la vista de iniciar sesion y limpio el redux y demas datos
    if(!sessionExists && !this.usuarioService.getEstadoRegistro()){
      this.sessionService.closeSesion('/usuario/ingresar');
    }

	  return (sessionExists && this.usuarioService.getEstadoRegistro());
  }
}
