import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private csasr: CheckSessionAfterServiceResponseService,
  ) { }

  cache_get( data ) {
		let crmData = [
			{
        tipo: data.tipo, // PRODUCTOS AUTORES EDITORIALES
        categoria_id : data.categoria_id, //SOLO EN SERVCIO DUMMY
        fecha_version: data.fecha_version,
      }
		];
		let formData = new FormData();
		formData.append('method', 'searchCache_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
  }

  get_current_date(): string {
    let date = new Date();
    let today = '';
    let year = date.getFullYear().toString();
    let m = date.getMonth() + 1;
    let month = m < 10 ? '0' + m.toString() : m.toString();
    let day = date.getDate().toString();
    today = year + '' + month + '' + day;
    return today;
  }
}
