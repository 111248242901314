import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

	private crmUrl : string = environment.apiUrl;

  	constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,) { }
  
	autor_get(slug) {
		let crmData = [
			{
        		slug: slug
      		}
		];
		let formData = new FormData();
		formData.append('method', 'autor_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( response => {
      this.csasr.check(response);
			return response
		}));
	}
}
