import { Component, Input, OnInit} from '@angular/core';
import * as shajs from 'sha.js';
import { Router} from "@angular/router";
import { UsuarioService} from "../../../services/usuario.service";
import { FormControl, FormGroup, Validators} from "@angular/forms";
import { Usuario} from "../../../clases";
import { StoreService} from "../../../services/store.service";
import { CarritoService} from "../../../services/carrito.service";
import { ACTION_LOGIN, ACTION_SHOPCAR } from "../../../store/actions/appAction";
//import { AuthService, FacebookLoginProvider, GoogleLoginProvider} from "angular-6-social-login";
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Store } from "@ngrx/store";

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {
  layoutToShow:any = 'links';
  formaMailPass:FormGroup;
  formaAccessCode:FormGroup;
  formaConfirmCode:FormGroup;
  formaRecoveryPass:FormGroup;
  loginMessage:any;
  dir:boolean = false;
  invalidRegisterMessages: String = '';
  invalidRegisterState: boolean = false;
  emailUser:any;
  statusForm: number = 0;
  usuario:any;
  shopcar:any;
  setEmails:boolean = false;

  @Input('stateModalLogin') stateModalLogin;
  @Input('originModalLogin') originModalLogin;

  constructor(
    private router: Router,
    private store: Store<any>,
    private storeService:StoreService,
    private carritoService:CarritoService,
    public usuarioService : UsuarioService,
    private socialAuthService: SocialAuthService
  ){

  }

  ngOnInit(){

    this.validateFormRegistro();

    this.store.subscribe(response => {

      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

      //SET VALUE EMAIL
      if(this.usuario.sessionCrm && !this.setEmails)
      {

        this.formaMailPass.controls['usuario'].setValue(this.usuario.email)
        this.formaAccessCode.controls['email'].setValue(this.usuario.email)
        this.formaRecoveryPass.controls['email'].setValue(this.usuario.email)

        this.setEmails = true;
      }

    });

  }

  validateFormRegistro(){

    this.formaConfirmCode  = new FormGroup({'codigo'    : new FormControl('',[ Validators.required ]) });
    this.formaAccessCode   = new FormGroup({'email'     : new FormControl('',[Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]) });
    this.formaRecoveryPass = new FormGroup({'email'     : new FormControl('',[Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]) });
    this.formaMailPass     = new FormGroup({'contrasena': new FormControl('', [Validators.required,Validators.minLength(6)]), 'usuario':new FormControl('',[ Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]) });

  }

  formaAccesoRapido(){
    this.invalidRegisterState = false;
    if (this.formaAccessCode.valid == true){
      let email = this.formaAccessCode.controls['email'].value;
      this.emailUser = email;
      this.usuarioService.enviarCodigoAcceso(email).subscribe(response => {
        if(response && response.status == 'ok'){
          this.invalidRegisterMessages = response.msg;
          this.layoutToShow = 'confirmar-codigo';
        } else if (response.number == 2010){
          this.invalidRegisterState = true;
          this.invalidRegisterMessages = response.description;
        }
      });
    } else {
      //ERROR CUANDO SE ENCUENTRA UN CAMPO NO VALIDO
      this.formaAccessCode.controls['email'].markAsTouched();
      return false;
    }
  }

  formSendCode(){
    //let code = CryptoJS.MD5(this.formaConfirmCode.controls['codigo'].value).toString();
    let code = shajs('sha256').update(this.formaConfirmCode.controls['codigo'].value).digest('hex');
    let data = { user_name: this.emailUser, codigo_acceso : code };
    this.usuarioService.setLoginCode(data).subscribe(response => {
      if(response.id){
        this.loginUserCrmSuccess(response);
      } else if (response.number == 2010) {
        this.loginMessage = response.description;
      }
    })
  }

  formaSendPassword(){
    if (this.formaRecoveryPass.valid == true) {
      let data = {
        email : this.formaRecoveryPass.controls['email'].value
      };
      this.usuarioService.recoveryUserPass(data).subscribe((response: any) => {
        console.log(response);
        if(response === true){
          this.invalidRegisterMessages = 'Se ha enviado un e-mail al correo suministrado con un link para restablecer tu contraseña';
        } else if(response.number == 1) {
          this.invalidRegisterMessages = response.description;
        } else if(response.number == 2001){
          this.invalidRegisterMessages = 'El correo ingresado fue registrado mediante la opción redes sociales. Debe loguearse mediante la opción redes sociales en el paso anterior';
        }
      });
    }
  }

  loginUsuarioEmail() {
    this.loginMessage='';
    if (this.formaMailPass.valid == true) {
      let userData = {
        provider	: 'email',
        email 		: this.formaMailPass.controls['usuario'].value,
        password 	: this.formaMailPass.controls['contrasena'].value
      };

      //LOGIN EN EL CRM
      this.usuarioService.setLogin(userData).subscribe(response => {
        if (response.number == 10) {
          this.loginMessage='Intento de login fallido, compruebe el nombre de usuario y contraseña';
        } else if (response.id) {
          this.loginUserCrmSuccess(response);
        }
      })
    } else {
      //ERROR CUANDO NO SE HAN VALIDADO TODOS LOS CAMPOS
      this.formaMailPass.controls['usuario'].markAsTouched();
      this.formaMailPass.controls['contrasena'].markAsTouched();
    }
  }

  public socialSignIn ( socialPlatform : string ){
    let socialPlatformProvider;
    if(socialPlatform == "facebook"){
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    }
    else if(socialPlatform == "google"){
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    //LOGIN SOCIAL
    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      let usuario = new Usuario();
      usuario.login = false;
      usuario.nameSocial = userData.name;
      usuario.email = userData.email;
      usuario.idSocialGo = userData.provider == 'GOOGLE' ? userData.id : null;
      usuario.idSocialFb = userData.provider == 'FACEBOOK' ? userData.id : null;
      usuario.tokenGo = userData.provider == 'GOOGLE' ? userData.idToken : null;
      usuario.tokenFb = userData.provider == 'FACEBOOK' ? userData.authToken : null;
      usuario.provider = userData.provider;
      usuario.imagen = userData.photoUrl ? userData.photoUrl : 'avatar.jpg';

      //LOGIN SOCIAL EN EL CRM
      this.usuarioService.setLogin(userData).subscribe( response => {
        if (response.number == 10){
          this.statusForm = 1;
        }
        else if (response.id) {
          this.loginUserCrmSuccess(response);
        }
        else if (response.number == 2010){
          if(response.name == 'Google_Login_Error'){
            this.statusForm = 2;
          } else if (response.name == 'Facebook_Login_Error'){
            this.statusForm = 3;
          }
          this.loginMessage = response.description;
        }
      })
    })
  }

  loginUserCrmSuccess(response) {
    let currentUser = new Usuario();
    currentUser.login = true;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
    this.usuarioService.getUser(dataCrm).subscribe(resp => {
      if (resp.status == 'ok') {
        currentUser.userId = resp.config.id;
        currentUser.clienteId = resp.cuenta.cliente_id;
        currentUser.tokenCrm = resp.config.token;
        currentUser.estado_registro = resp.config.estado_registro;
        currentUser.estado_login = resp.config.estado_login;
        currentUser.nombres = resp.config.nombres;
        currentUser.apellidos = resp.config.apellidos;
        currentUser.tipo_identificacion = resp.config.tipo_identificacion;
        currentUser.identificacion = resp.config.identificacion;
        currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
        currentUser.email = resp.config.email;
        currentUser.telefono = resp.config.telefono;
        currentUser.telefono_movil = resp.cuenta.telefono_movil;

        //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
        this.usuarioService.setUserLocal(currentUser);
        this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

        //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
        let carritoLS = this.carritoService.getCarritoLocal();
        if (carritoLS && (this.shopcar.cliente_user_id == '' || this.shopcar.cliente_user_id == null) ){
          //MERGE DE CARRITOS CUANDO SI HAY LOCAL
          let dataCrmMergeCarrito = [
            currentUser.sessionCrm,
            {
              factura_merge_id  : carritoLS
            }
          ];
          this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe(response => {
            if(response){
              this.updateCarritoLocal(response);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
            }
          })
        } else {
          let dataCrmCarrito = {
            session_id : currentUser.sessionCrm
          };
          this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
            if(dataCarrito.id){
              this.updateCarritoLocal(dataCarrito);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
            }
          });
        }
        //Cerrar Modal
        this.close();
      }
    })
  }
  updateCarritoLocal(dataCarrito){
    this.carritoService.setCarritoLocal(dataCarrito);
  }

  setLayoutToShow(layout){
    this.loginMessage = '';
    this.layoutToShow = layout;
    this.invalidRegisterMessages='';
  }

  close(){
    this.loginMessage = '';
    this.invalidRegisterMessages='';
    this.setLayoutToShow('links');
    this.usuarioService.updateLoginModalState(false);
  }
}
