import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import { forEach } from '@angular/router/src/utils/collection';
//import { nextTick } from 'q';
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()
export class ProductosService {

  private crmUrl: string = environment.apiUrl;

  constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService, ) {
  }

  productSearch(data) {
    let formData = new FormData();
    formData.append('method', 'productos_search');
    formData.append('rest_data', JSON.stringify([data]));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    /* return this.http.post(this.crmUrl + '?ws=noload', formData).pipe(map(response => { */
    return this.http.post('https://crmsearch.librerianacional.com/custom/service/v4_1_custom/rest.php' + '?ws=noload', formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  bonosvirtuales_productosList() {
    let crmData = [
      null,
      {}
    ];

    let formData = new FormData();
    formData.append('method', 'bonosvirtuales_productosList');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  bonosvirtuales_new(data) {
    let crmData = [
      data.sessionCrm,
      {
        factura_id: data.factura_id,
        grupo_id: data.grupo_id,
        producto_id: data.producto_id,
        envio: {
          nombre: data.envio.nombre,
          email: data.envio.email,
          mensaje: data.envio.mensaje,
        }
      }
    ];

    let formData = new FormData();
    formData.append('method', 'bonosvirtuales_new');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);

      return response;

    }));
  }

  getBonosVirtualesUsuario(data) {

    let formData = new FormData();
    formData.append('method', 'bonosvirtuales_list');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  redimirBonoVirtual(data) {

    let formData = new FormData();
    formData.append('method', 'factura_setBonoVirtual');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  activarBonoPorCodigo(data) {

    let formData = new FormData();
    // formData.append('method', 'bonosvirtuales_redimir');
    formData.append('method', 'factura_addBonoVirtualPromocion');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  getCategorydata(data) {
    let crmData = [
      {
        slug: data.slug,
        id: data.id
      }
    ];

    let formData = new FormData();
    formData.append('method', 'categorias_get');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  getCategoryList(data) {
    let crmData = [
      data
    ];
    let formData = new FormData();
    formData.append('method', 'categorias_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response: any) => {
      this.csasr.check(response);
      return response
    }));
  }

  productosComplementarios(data) {
    let crmData = [
      data
    ];
    let formData = new FormData();
    formData.append('method', 'productos_getComplementarios');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  getDetailProduct(data) {
    var crmData = [data];
    let formData = new FormData();
    formData.append('method', 'productos_get');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  getDetailProductLN(data) {
    var crmData = [data];
    let formData = new FormData();
    formData.append('method', 'productos_getResumenLN');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  productosRecomendados(data) {
    let crmData = [
      data
    ];

    let formData = new FormData();
    formData.append('method', 'productos_getRecomendados');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  productosListByAutor(data) {
    let crmData = [
      data
    ];

    let formData = new FormData();
    formData.append('method', 'productos_listByAutor');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => {
      this.csasr.check(response);
      return response;
    }));
  }

  reseniasLibro(data) {
    let crmData = [data];

    let formData = new FormData();
    formData.append('method', 'resenias_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  crearResenia(data) {
    let crmData = data;

    let formData = new FormData();
    formData.append('method', 'resenia_set');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  // autoComplete(data) {
  //   let crmData = data;

  //   let formData = new FormData();
  //   formData.append('method', 'search_Autocompletar');
  //   formData.append('rest_data', JSON.stringify(crmData));
  //   formData.append('input_type', 'JSON');
  //   formData.append('response_type', 'JSON');

  //   return this.http.post(this.crmUrl+'?ws=noload', formData).pipe(map(response => response));
  // }

  downloadLibrosJson(filename: string) {
    return this.http.get(environment.librosJsonUrl + filename + '?ws=noload').pipe(map(response => response));
  }

}
