import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'redirecturl'
})
export class redirectUrl implements PipeTransform {
  transform(value: string, flag: boolean = false): string {
    if (value === null) return 'Not assigned';

    if(flag && value != null && value != '')
    {
        if(value.indexOf('www') !== -1) {
            
            return value.replace("www", "crm");
            
        }
        else
        {
            return value;
        }
    }
    else
    {
        return value;
    }
    
  }
}
