import { CarritoService } from './carrito.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StoreService } from './store.service';
import { ACTION_LOGOUT } from "../store/actions/appAction";

@Injectable({
  providedIn: 'root'
})
export class CheckSessionAfterServiceResponseService {

  constructor(
    private route: Router,
    private storeService:StoreService,
  ) { }

  public check(response: any): void {
      if(response.name && response.number) {
          if(response.name == 'Invalid Session ID' && response.number == 11) {
            localStorage.removeItem('session');
            localStorage.removeItem('estado_registro');
            localStorage.removeItem('fecha_session');
            localStorage.removeItem('bill');

            try {
              this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: null });
            } catch (error) {
              console.log(error);
            }

            let parts: Array<string> = this.route.url.split('/');
            parts.unshift('/');
            parts = parts.filter(part => {
              return part !== '';
            });
            console.log(parts);
            localStorage.setItem('last-url', JSON.stringify(parts));
            Swal.fire({
              title: 'Tu sesión ha expirado',
              text: 'Inicia sesión nuevamente',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#B40001',
              confirmButtonText: 'Continuar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.value) {
                this.route.navigate(['/', 'usuario', 'ingresar']);
              }
            });
          }
      }
  }
}