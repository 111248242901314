export function formData(metodo, data): FormData {
  let formData = new FormData();
  formData.append("method", metodo);
  formData.append("rest_data", JSON.stringify(data));
  formData.append("input_type", "JSON");
  formData.append("response_type", "JSON");
  return formData;
}

export const expresionTelefono = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
