import { Action } from "@ngrx/store";

export enum AccionesOrdenes {
  CARGAR_ORDENES = "[ORDENES] => Cargar",
  CARGA_ORDENES_EXITOSA = "[ORDENES] => Carga Exitosa",
  CARGA_ORDENES_FALLIDA = "[ORDENES] => Carga Fallida",

  ACTUALIZAR_ORDEN = "[ORDENES] => Actualizar posición",
  ACTUALIZAR_ORDEN_EXITOSA = "[ORDENES] => Actualizar posición exitosa",
  ACTUALIZAR_ORDEN_FALLIDA = "[ORDENES] => Actualizar posición fallida",

  ACTUALIZAR_DIRECCION_ENVIO_ORDEN = "[ORDENES] => Actualizar dirección de envió orden",
  ACTUALIZAR_DIRECCION_ENVIO_ORDEN_EXITOSA = "[ORDENES] => Actualizar dirección de envió orden exitosa",
  ACTUALIZAR_DIRECCION_ENVIO_ORDEN_FALLIDA = "[ORDENES] => Actualizar dirección de envió orden fallida",

  CERRAR_ORDENES = "[ORDENES] => Cerrar ordenes",
  RESET_ORDENES = "[ORDENES] => Reset",
}

export class CargarOrdenes implements Action {
  readonly type = AccionesOrdenes.CARGAR_ORDENES;

  constructor(public payload?: any) {}
}

export class CargarOrdenesFail implements Action {
  readonly type = AccionesOrdenes.CARGA_ORDENES_FALLIDA;

  constructor(public payload: any) {}
}

export class CargarOrdenesSuccess implements Action {
  readonly type = AccionesOrdenes.CARGA_ORDENES_EXITOSA;

  constructor(public ordenes: any) {}
}

export class ActualizarOrden implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_ORDEN;

  constructor(public data: any) {}
}

export class ActualizarOrdenExitosa implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_ORDEN_EXITOSA;

  constructor(public data: any) {}
}

export class ActualizarOrdenFallida implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_ORDEN_FALLIDA;

  constructor(public data: any) {}
}

export class SetDireccionEnvioFactura implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN;

  constructor(public data: any) {}
}

export class SetDireccionEnvioFacturaExitosa implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN_EXITOSA;

  constructor(public data: any) {}
}

export class SetDireccionEnvioFacturaFallida implements Action {
  readonly type = AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN_FALLIDA;

  constructor(public data: any) {}
}

export class CerrarOrdenes implements Action {
  readonly type = AccionesOrdenes.CERRAR_ORDENES;
}

export class ResetOrdenes implements Action {
  readonly type = AccionesOrdenes.RESET_ORDENES;
}

export type UnionAccionesOrdenes =
  | CargarOrdenes
  | CargarOrdenesFail
  | CargarOrdenesSuccess
  | ActualizarOrden
  | ActualizarOrdenExitosa
  | ActualizarOrdenFallida
  | SetDireccionEnvioFactura
  | SetDireccionEnvioFacturaExitosa
  | SetDireccionEnvioFacturaFallida
  | CerrarOrdenes
  | ResetOrdenes;
