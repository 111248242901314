import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../../../services/location.service';
import { PqrsService } from 'src/app/services/pqrs.service';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta }     from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pqrs',
  templateUrl: './pqrs.component.html'
})
export class PqrsComponent implements OnInit, OnDestroy {

  formPQR: FormGroup;
  validacionPQRS: boolean;
  departments:any = [];
  cities:any = [];
  pqrTypeList: any = [];
  sedeList: any = [];

  usuario: any = {
    nombres: '',
    apellidos: '',
    email: '',
    telefono_movil: ''
  };
  sus: Subscription = new Subscription();

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    private locationService: LocationService,
    private pqrsService: PqrsService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private store: Store<AppState>
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    if(localStorage.session){
      this.sus = this.store.subscribe((data: AppState) => {
        console.log(data.appReducer.user);
        this.usuario = data.appReducer.user;
        if(this.usuario.login){
          this.validateForm();
        }
      });
    }
  }

  terms: boolean;

  ngOnDestroy() {
    if(localStorage.session){
      this.sus.unsubscribe();
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.validateForm();

    this.pqrsService.tipo_pqr_list().toPromise().then( (res: any) => {
      if(res != null) {
        for(let obj in res.tipos) {
          this.pqrTypeList.push(obj);
        }
      }
    });

    this.pqrsService.sedes_list().toPromise().then( (res: any) => {
      if(res != null) {
        this.sedeList = res.sedes;
      }
    });

    this.locationService.search({
      tipo: 'DPTO',
      ubicacion_id: '770c0c90-01f3-31df-0f00-59cc0f745717'
    }).toPromise().then( res => {
      if(res != null) {
        this.departments = res;
      }
    });

    let dataMetas = {
      title: 'PQRS - Tu opinión es importante para nosotros',
      description: 'Encuentra nuestros canales de atención para que nos envíes todas tus preguntas, recomendaciones y sugerencias.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }
  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  validateForm() {
    this.formPQR = new FormGroup({
      nombres: new FormControl(
        this.usuario.nombres,
        Validators.required
      ),
      apellidos: new FormControl(
        this.usuario.apellidos,
        Validators.required
      ),
      email: new FormControl(
        this.usuario.email, [
        Validators.required,
        Validators.pattern(
          "^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$"
        ),
      ]),
      telefono_movil: new FormControl(
        this.usuario.telefono_movil,
        Validators.required
      ),
      tipo_solicitud: new FormControl("", Validators.required),
      departamento_id: new FormControl("", Validators.required),
      ciudad_id: new FormControl("", Validators.required),
      bodega_id: new FormControl("", Validators.required),
      numero_pedido: new FormControl(""),
      asunto: new FormControl(""),
      mensaje: new FormControl(""),
      tratamiento_datos_acepta: new FormControl("", Validators.required),
      captcha: new FormControl(null, Validators.required),
    });
  }

  resolved(event) {
    // console.log(this.formPQR);
  }

  filterCities(e) {
    let departmentId = e.target.value;
    this.locationService.search({
      tipo: 'CIUDAD',
      ubicacion_id: departmentId
    }).toPromise().then( res => {
      if(res != null) {
        this.cities = res;
      }
    });
  }

  registerPQRS(){
    //console.log(this.formPQR);
    if (this.formPQR.valid == true) {
      this.formPQR.disable();
      let { asunto, numero_pedido } = this.formPQR.controls;

      let dataFormNewsLetter = [{
        mensaje: this.formPQR.controls['mensaje'].value,
        nombres: this.formPQR.controls['nombres'].value,
        apellidos: this.formPQR.controls['apellidos'].value,
        email: this.formPQR.controls['email'].value,
        telefono_movil: this.formPQR.controls['telefono_movil'].value,
        tratamiento_datos_acepta: this.formPQR.controls['tratamiento_datos_acepta'].value == true ? 'SI' : 'NO',
        asunto: `${numero_pedido.value ? `Número de pedido ${numero_pedido.value}: ` : ``}${asunto.value}`,
        departamento_id: this.formPQR.controls['departamento_id'].value,
        ciudad_id: this.formPQR.controls['ciudad_id'].value,
        bodega_id: this.formPQR.controls['bodega_id'].value,
        tipo_solicitud: this.formPQR.controls['tipo_solicitud'].value,
        pedido_nombre: numero_pedido.value
      }];

      this.pqrsService.caso_set(dataFormNewsLetter).subscribe((response: any) => {
        if (response.id) {
          this.validacionPQRS = true;
        }
        this.formPQR.enable();
        this.validateForm();
      });
    }
  }
}
