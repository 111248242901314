import {Component, Input, OnInit, Injectable} from '@angular/core';
import { ModalService } from '../../../services/modal.service'

@Component({
  selector: 'app-modal-generica',
  templateUrl: './modal-generica.component.html',
  styleUrls: ['./modal-generica.component.css'],

})
export class ModalGenericaComponent implements OnInit {
  
  @Input('estadoModalGenerica') estadoModalGenerica:boolean
  modal : any

  constructor(
    private modalService : ModalService
  )
  {
    this.modalService.newStateModal.subscribe( estado => {
      this.estadoModalGenerica = estado
    })

  }

  ngOnInit() {
  }
}
