import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { Usuario } from '../clases/index'
import { environment } from '../../environments/environment';
import { StoreService } from "./store.service";
import { ACTION_SHOPCAR, ACTION_LOGOUT } from "../store/actions/appAction";
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';
import { CloseModalsService } from './close-modals.service';

@Injectable()
export class UsuarioService {

	private crmUrl : string = environment.apiUrl;
	
	public newUserSubject = new Subject<any>();
	constructor(
		public http: HttpClient,
		private storeService:StoreService,
		private csasr: CheckSessionAfterServiceResponseService,
		private closeModalService: CloseModalsService,
  ){
  }

	updateEnviromentUser(data) {
		this.newUserSubject.next(data)
	}

	updateNotificacionesModal(data) {
		this.closeModalService.notificacionesModalSubject.next(data)
	}

	updateDataModalState(data) {
		this.closeModalService.dataModalStateSubject.next(data)
	}

	updateLoginModalState(data) {
		this.closeModalService.loginModalStateSubject.next(data)
	}

	updateLoginModalOrigin(data) {
		this.closeModalService.loginModalOriginSubject.next(data)
	}


	updateBlockModalState(data) {
		this.closeModalService.blockModalStateSubject.next(data)
	}

	updateBlockModalOrigin(data) {
		this.closeModalService.blockModalOriginSubject.next(data)
	}

	setLogin(data){
		let crmData : any = {};
		if (data.provider == 'email'){
			crmData = {
				user_auth: {
					user_name : data.email ? data.email : null,
					password : data.password ? data.password : null,
					encryption:"PLAIN"
				}
			}
		}
		else {
			crmData = {
				user_auth: {
					g_id : data.provider == 'GOOGLE' ? data.id : null,
					g_token : data.provider == 'GOOGLE' ? data.idToken : null,
					g_access_token : data.provider == 'GOOGLE' ? data.authToken : null,
					fb_id : data.provider == 'FACEBOOK' ? data.id : null,
					fb_token : data.provider == 'FACEBOOK' ? data.authToken : null,
				}
			}
		}

		let formData = new FormData();
		formData.append('method', 'login');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
		  return response
		}))
	}

  logOut(data){
    let crmData = [data];
    let formData = new FormData();
    formData.append('method', 'logout');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
		return response
    }))
  }

  setLoginCode(data){
    let crmData:any = {
      user_auth: {
        user_name: data.user_name,
        codigo_acceso: data.codigo_acceso
      }
    };

    let formData = new FormData();
    formData.append('method', 'login');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
		
		return response;

    }))
  }

	checklogin( session ) {
		
		let crmData = {
			session: session
		};

		let formData = new FormData();
		formData.append('method', 'seamless_login');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
			return response

		}))
	}

	enviarCodigoAcceso(data){
		let crmData = [{
		user_name: null,
		email: data
		}];
		let formData = new FormData();
		formData.append('method', 'user_enviarCodigoAcceso');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
			return response
		}))
	}

	fastLoginRegister(data){
		let crmData = [data];
		let formData = new FormData();
		formData.append('method', 'user_fastLoginRegister');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
			return response
		}))
	}

	setRegisterFull( session ) {
		let crmData = [
			session
		];
		let formData = new FormData();
		formData.append('method', 'user_setRegisterFull');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	createUser( data ){
		let crmData = {
			params: data
		};
		let formData = new FormData();
		formData.append('method', 'user_register');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
			return response
		}))
	}

	getUser(data) {
		let crmData = {
			params: data
		};
		let formData = new FormData();
		formData.append('method', 'user_getConfig');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	//FAV LIST GET
  favListGet(data){
    let crmData =
      [
        data.session,{}
      ];
    let formData = new FormData();
    formData.append('method', 'favlist_get');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
		return response
    }))
  }

  //FAV LIST PRODUCT SET
  favListProductSet(data){
    let crmData =
      [
        data.session,
        {
          producto_id : data.producto_id,
          deleted     : data.deleted
        }
      ];
    let formData = new FormData();
    formData.append('method', 'favlist_productoSet');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
		return response
    }))
  }

	//RECUPERAR CONTRASEÑA
	recoveryUserPass (data) {
		let crmData = [
			{ email: data.email }
		];

		let formData = new FormData();
		formData.append('method', 'user_password_recovery');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	//NUEVA CONTRASEÑA CON LOGIN
  updateUserPassLogin(data){
    let crmData =
      [
        data.session,
        {
          pass: data.pass,
          repass: data.repass
        }
      ];
    let formData = new FormData();
    formData.append('method', 'user_password_update');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
		return response
    }))
  }


	//NUEVA CONTRASEÑA SIN LOGIN
	updateUserPass (data){
		let crmData =
		[
			null,
			{
				guid : data.guid,
				email: data.email,
				pass: data.pass,
				repass: data.repass
			}
		];
		let formData = new FormData();
		formData.append('method', 'user_password_update');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
			return response
		}))
	}

	getCiudades(ubicacion) {
		let crmData = [
		  {
				tipo: null,
				ubicacion_id: ubicacion,
				niveles: 1
			}
		];

		let formData = new FormData();
		formData.append('method', 'ubicaciones_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
			return response
		}))
	}

	facturasList( data ) {
		let crmData = [
			data.session_id,
			{}
		];
		let formData = new FormData();
		formData.append('method', 'facturas_list');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map((response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	//METODOS DE TRATAMIENTO DE DATOS LOCAL
	getUserLocal(){
		if (localStorage.getItem('session')){
		  return JSON.parse(localStorage.getItem('session'));
		} else {
		  return false;
		}
	}

	//METODOS DE TRATAMIENTO DE DATOS LOCAL
	getUserLocalDate(){
		if (localStorage.getItem('fecha_session')){
		  return localStorage.getItem('fecha_session');
		} else {
		  return false;
		}
	}
	
	getEstadoRegistro(){
		let res: boolean = false;
		if (localStorage.getItem('estado_registro')){
		  if(localStorage.getItem('estado_registro').toLowerCase() != 'parcial') {
				res = true;
			}
		}
		return res;
	}

	//SETEAR EN LOCAL STORAGE EL SESION ID
  	setUserLocal(data:Usuario){
    	localStorage.setItem('session', JSON.stringify(data.sessionCrm));
    	localStorage.setItem('estado_registro', JSON.stringify(data.estado_registro));
    	localStorage.setItem('fecha_session', String(new Date()) );
	}

	deleteUserLocal(){
	    if (localStorage.getItem('session')){
	      localStorage.removeItem('session');
	    }
	    if (localStorage.getItem('estado_registro')){
	      localStorage.removeItem('estado_registro');
	    }
	    if (localStorage.getItem('fecha_session')){
	      localStorage.removeItem('fecha_session');
	    }
	}

	//ACTUALIZAR DATA (NOMBRES - APELLIDOS - TELÉFONOS - TIPO DE ID - No ID )
	updateCliente(data) {
		let crmData = [
      		data.session_id,
			{
		        nombres : data.nombres,
		        apellidos : data.apellidos,
		        tipo_identificacion : data.tipo_identificacion,
		        identificacion : data.identificacion,
		        telefono : data.telefono,
		        fecha_nacimiento : data.fecha_nacimiento,
		        telefono_movil : data.telefono_movil,
		        email : data.email,
			}
		];

		let formData = new FormData();
		formData.append('method', 'cliente_set');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	cliente_get(data) {
		let crmData = [
			data.sessionCrm,
			{
				cliente_id: data.cliente_id
			}
		];
		let formData = new FormData();
		formData.append('method', 'cliente_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	//AGREGAR DIRECCIÓN
	addDireccion (data) {
		let crmData = [
			data.session_id,
			{
				direccion :
				{
					name			: data.nombre,
					preguntar_por 	: data.datos_adicionales,
					direccion 		: data.direccion,
					ciudad 			: data.ciudad,
					barrio 			: data.barrio,
					tipo 			: data.tipo_vivienda,
				}
			}
		];
		let formData = new FormData();
		formData.append('method', 'direccion_set');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	deleteDirecciones(data) {
		let crmData = [
			data.session_id,
			{
				direccion_id : data.direccion_id,
			}
		];
		let formData = new FormData();
		formData.append('method', 'direccion_del');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	//CREAR CASO PARA CONTACTO -NEWSLETTER
	createCaso(data) {
		let crmData =
		[
			{
				asunto					: data.tipoAsunto,
				nombres					: data.nombre,
				email					: data.correo,
				telefono_fijo 			: data.telefono,
				tratamiento_datos_acepta: data.terminosCondiciones,
				pedido_codigo			: data.idPedido,
				ciudad_id 				: data.ciudad,
				mensaje					: data.mensaje
			}
		];
		let formData = new FormData();
		formData.append('method', 'lead_set');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}
	
	//CREAR CASO PARA PQRS
	createPQR(data) {
		let crmData =
		[
			{
				mensaje					: data.comentarios,
				nombres					: data.nombres,
				apellidos				: data.apellidos,
				email					: data.correo,
				telefono_movil 			: data.celular,
				tratamiento_datos_acepta: data.terminosCondiciones ? 'SI' : 'NO',
				asunto					: data.tipo_solicitud,
				departamento_id 		: data.departamento,
				ciudad_id 				: data.ciudad,
				sede					: data.sede
			}
		];
		let formData = new FormData();
		formData.append('method', 'caso_set');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
			return response
		}))
	}
}
