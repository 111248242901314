import { AppState } from './../appState';
import { UnionAccionesEventos, AccionesEventos } from './actions';
import { State } from '@ngrx/store';

export interface EventosStateI {
  data: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: EventosStateI = {
  data: [],
  loaded: false,
  loading: false,
  error: null
};

export function eventosReducer(state: any = estadoInicial, action: UnionAccionesEventos) {
  switch (action.type) {
    case AccionesEventos.CARGAR_EVENTOS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesEventos.CARGA_EVENTOS_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...action.eventos]
      };

    case AccionesEventos.CARGA_EVENTOS_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    default:
      return state;
  }
}
