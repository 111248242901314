import { Store } from '@ngrx/store';
import { Component } from '@angular/core';

@Component({
    selector: 'menu-login-header',
    templateUrl: './menu-login-header.component.html'
})
export class MenuLoginHeaderComponent {
  usuario: any;
  menus: any = { TOP: { items: [] }, menu: '' };

  constructor(private store: Store<any>,){
    this.store.subscribe( ({ appReducer }) => {
      this.usuario = appReducer.user;
        //console.warn(appReducer.menus);
        if(appReducer.menus){
            //console.clear();
            this.menus = appReducer.menus;
        }
    });
  }
}
