import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import { SetDireccion, CargarDirecciones } from 'src/app/store/direcciones/actions';
import Swal from 'sweetalert2';
import { SetDireccionEnvioFactura } from 'src/app/store/ordenes/actions';
import { ACTION_LOGIN } from "src/app/store/actions/appAction";
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Actions } from '@ngrx/effects';

@Component({
  selector: "app-listas-direcciones",
  templateUrl: "./listas-direcciones.component.html",
})
export class ListasComponent implements OnInit, OnDestroy {
  @Input("direcciones") direcciones: any = [];
  @Input("modal") modal: boolean;
  @Input("factura_id") factura_id: string;
  @Input("url") url: string = "/mi-cuenta/libreta-direcciones";

  usuario: any;
  sus: Subscription = new Subscription();
  susA: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private userService: UsuarioService,
    private actions: Actions
  ) {
    this.sus = this.store.subscribe((data: AppState) => {
      this.usuario = data.appReducer.user;
    });

    this.susA = this.actions.subscribe((action: any) => {
      if (action.type === ACTION_LOGIN) {
        this.cargarDirecciones();
      }
    });
  }

  ngOnDestroy() {
    this.sus.unsubscribe();
  }
  ngOnInit() {
    if (!this.direcciones.length) {
      this.cargarDirecciones();
    }
  }

  cargarDirecciones() {
    this.store.dispatch(
      new CargarDirecciones({ session_id: JSON.parse(localStorage.session) })
    );
  }

  mostrarModal() {
    this.userService.updateLoginModalState(true);
    this.userService.updateLoginModalOrigin("datos-envio");
  }

  setDireccionEditar(direccion, icono = false) {
    if (!this.modal) {
      this.store.dispatch(new SetDireccion(direccion));
      if (
        (this.usuario.estado_login !== "LOGIN_PARCIAL" && icono) ||
        this.router.url.indexOf("/mi-cuenta") >= 0
      ) {
        this.router.navigate([`${this.url}/editar/`, direccion.id]);
      } else {
        if (
          this.router.url.indexOf("/carrito") >= 0 &&
          this.router.url.indexOf("direccion/") >= 0
        ) {
          console.log("se requiere mover al root");
          console.log(this.url);
          this.router.navigateByUrl("/carrito/datos-envio");
        }
      }
    } else {
      console.log("modal", direccion);
      Swal.fire({
        title: "¿Estas seguro?",
        text: "La dirección de envió se cambiara",
        type: "question",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.store.dispatch(
            new SetDireccionEnvioFactura([
              JSON.parse(localStorage.session),
              {
                factura_id: this.factura_id,
                direccion_id: direccion.id,
                billAddress_set: false,
              },
            ])
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel ||
          result.dismiss === Swal.DismissReason.backdrop
        ) {
          // this.router.navigateByUrl("/mi-cuenta/libreta-direcciones");
        }
      });
    }
  }
}
