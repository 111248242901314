import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { StoreService } from "./store.service";
import { CarritoService } from "./carrito.service";
import { UsuarioService } from "./usuario.service";
import { ACTION_SHOPCAR, ACTION_LOGOUT } from "../store/actions/appAction";
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private carritoService:CarritoService,
    private usuarioService:UsuarioService,
    private storeService:StoreService,
    private csasr: CheckSessionAfterServiceResponseService,
    ) { }

  /**
   * This method works for create and update. But only update when the id property is not null (empty)
   * @param data [{session_id: '', {direccion: {id: '', name:'', preguntar_por:'', direccion:'', direccion_json:'', complemento:'', ciudad:'', barrio:'', latitud:'', longitud:'', tipo:''}}}]
   */
  createUpdate( data ) {
		let crmData = [
			data.session_id,
			{
        direccion: {
          id: data.id,
          name: data.name,
          preguntar_por: data.preguntar_por,
          direccion: data.direccion,
          tipo_direccion: data.tipo_direccion,
          direccion_postalcode: data.direccion_postalcode,
          pais_id: data.pais_id,
          dpto_id: data.dpto_id,
          ubicacion_id: data.ubicacion_id, // for a while city will be the lowest level...
          departamento_otro: data.departamento_otro,
          ciudad_otro: data.ciudad_otro,
          telefono_fijo: data.telefono_fijo,
          telefono_celular: data.telefono_celular,
        }
      }
		];
		let formData = new FormData();
		formData.append('method', 'direccion_set');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
		  return response
		}))
  }

  /**
   * 
   * @param data ["session_id",{}]
   */
  read( data ) {
    if(data.session_id) {
      // code...
      let crmData = [
        data.session_id,
        {}
      ];
      let formData = new FormData();
      formData.append('method', 'direccion_getByUser');
      formData.append('rest_data', JSON.stringify(crmData));
      formData.append('input_type', 'JSON');
      formData.append('response_type', 'JSON');
      return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
        this.csasr.check(response);
        return response
      }));
    }
  }

  /**
   * 
   * @param data [{session_id: '', {direction_id: ''}}]
   */
  delete( data ) {
		let crmData = [
			data.session_id,
			{
				direccion_id : data.direccion_id,
			}
		];
		let formData = new FormData();
		formData.append('method', 'direccion_del');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response
		}));
  }

  setBillAddress(data) {
		let crmData = [
			data.session_id,
			{
        factura_id: data.factura_id,
        direccion_id: data.direccion_id,
      }
		];
		let formData = new FormData();
		formData.append('method', 'factura_setDireccionFacturacion');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response
		}))
  }

  setSendAddress(data) {
		let crmData = [
			data.session_id,
			{
        factura_id: data.factura_id,
        direccion_id: data.direccion_id,
        billAddress_set: data.billAddress_set
      }
		];
		let formData = new FormData();
		formData.append('method', 'factura_setDireccionEnvio');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response
		}))
  }
}
