import { AppState } from './../../../store/appState';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location, PlatformLocation } from "@angular/common";
import { Title, Meta } from '@angular/platform-browser'
import { PromocionesService } from "../../../services/promociones.service";
import { FormControl, FormGroup } from '@angular/forms';
import { ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { Store } from "@ngrx/store";
import { StoreService } from "../../../services/store.service";
import { UsuarioService } from "../../../services/usuario.service";
import { CarritoService } from "../../../services/carrito.service";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Subscription } from 'rxjs';

import * as AccionesPromocionDetalle from '../../../store/promociones/detalle/actions';
import * as AccionesPromociones from '../../../store/promociones/actions';

//import { promocion_detalle_info } from './promocion_detalle_info';
//import { promocion_detalle_data } from './promocion_detalle_data';
//import { promociones_data } from '../promociones_data';


@Component({
  selector: 'app-detalle-promocion',
  templateUrl: './detalle-promocion.component.html',
  styleUrls: ['./detalle-promocion.component.css']
})
export class DetallePromocionComponent implements OnInit, OnDestroy {
  promocionData: any;
  promocionList: any;
  notFoundPromos: any;

  listProduct: any = [];
  pages: any = [];
  pageCurrent: number = 1;
  pagesPerPage: number = 20;
  dataProducts: any = [];
  dataProductsCopy: any = [];
  formaOrder: FormGroup;
  cargandoProductos: boolean = false;

  usuario: any;
  shopcar: any;
  addedToCart: any = {};
  detailsProduct: any = [];
  updateInProgress: boolean = false;
  active: number;
  currentURL: string;
  noCoverImg: string = '';

  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = {};

  // defaults images lazyload
  loaderImg = '';
  limit = 21;
  offset = 0;

  params: any;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private store: Store<AppState>,
    private location: Location,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private carritoService: CarritoService,
    public platformLocation: PlatformLocation,
    private getPromocionPrdsObj: PromocionesService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    try {
      this.buildForm();
    } catch (error) { }

    this.loadSubscription = this.store.select('promocion').subscribe(({ data, loading, loaded, error }) => {
      this.error = error;
      this.cargando = loading;
      this.cargaContenido = loaded;
      this.promocionData = data;

      if (Object.keys(this.promocionData).length > 0) {
        let dataMetas = {
          title: this.promocionData.name,
          description: this.promocionData.description ? this.promocionData.description : 'Â¡MÃ¡s de 200.000 tÃ­tulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a travÃ©s de nuestra Tienda Virtual',
          keywords: 'libros,libreria,ebooks',
          image: this.promocionData.imagen1 ? this.promocionData.imagen1 : 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
          url: 'https://www.librerianacional.com' + this.router.url
        }
        try {
          this.setMetaData(dataMetas);
        } catch (error) { }
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.currentURL = window.location.href;
    this.currentURL = (this.platformLocation as any)._location.origin + this.location.path(false);
    this.activatedRoute.params.subscribe(params => {
      this.params = params;
      this.getPromocionProductList();
      this.store.dispatch(new AccionesPromocionDetalle.CargarPromocionDetalle({ slug: params.slug }));
      //this.store.dispatch(new AccionesPromocionDetalle.CargarPromocionDetalleSuccess(promocion_detalle_info[params.slug]));
    });

    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
      this.promocionList = response.promociones.data;
    });

    // si se recarga la pagina y no existen las promociones las pedimos al crm
    if (this.promocionList.length === 0) {
      this.store.dispatch(new AccionesPromociones.CargarPromociones({ offset: 0, limit: 10 }));
      //this.store.dispatch(new AccionesPromociones.CargarPromocionesSuccess(promociones_data));
    }

  }

  ngOnDestroy() {
    this.resetDataPromomcion();
    this.loadSubscription.unsubscribe();
  }

  resetDataPromomcion() {
    this.pageCurrent = 1;
    this.offset = 0;
    this.store.dispatch(new AccionesPromocionDetalle.ResetPromocionDetalle());
  }

  buildForm() {
    this.formaOrder = new FormGroup({
      'productsOrder': new FormControl()
    });
  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  getPromocionProductList(params?) {
    let data = {
      slug: this.params.slug,
      limit: this.limit,
      offset: this.offset
    };

    /*  let products = promocion_detalle_data[params.slug];
     if(products.length){
       products.forEach(obj => {
         if (obj.promociones.length > 0) {
           let promociones = [];
           obj.promociones.forEach(promo => {
             promociones.push(promo.name);
           });
           obj.promocionesText = promociones.join(', ');
         }
       });
     }else{
       products = [];
     }
     this.dataProductsCopy = products;
     this.orderArray(products);
     //CARGAR FILTROS UNA ÃšNICA VEZ, CUANDO CARGA INICIALMENTE LA PÃGINA
     this.cargandoProductos = false */


    this.getPromocionPrdsObj.getPromocionProductosList(data).subscribe((response: any) => {
      this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';
      if (response) {
        //ORDENAR PRODUCTOS
        let products = response;
        products.forEach(obj => {
          if (obj.promociones.length > 0) {
            let promociones = [];
            obj.promociones.forEach(promo => {
              promociones.push(promo.name);
            });
            obj.promocionesText = promociones.join(', ');
          }
        });
        this.dataProductsCopy = products;
        this.orderArray(products);
        //CARGAR FILTROS UNA ÃšNICA VEZ, CUANDO CARGA INICIALMENTE LA PÃGINA
        this.cargandoProductos = false
      }
    });
  }

  addProductCarrito(id) {
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id: null,
      grupo_id: null,
      producto_id: id,
      cantidad: 1
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)
    }
  }

  addProductCarritoCrm(dataCrm) {
    this.carritoService.addProduct(dataCrm).subscribe((response: any) => {

      this.updateInProgress = false;

      if (response.hasErrors) {
        if (response.number == 2204) {
          // producto NO existe
        }
        else {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      }
      else {
        if (response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if (response.producto) {
          this.detailsProduct = response.producto;
        }

        if (response.factura.id) {
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      }
    })
  }

  getNewBill(dataCrm) {

    let user = this.usuario;
    let producCurrent = dataCrm[1]

    let dataCrmNF: any;
    if (user) {
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res: any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito) {
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        subtotal: this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }

  searchProductCarrito(id) {
    this.addProductCarrito(id);
  }

  //ORDER ARRAY
  orderArray(productos) {
    // this.pageCurrent = 1;
    let currentOrder = this.formaOrder.controls['productsOrder'].value;
    if (currentOrder == 'nameAZ' || currentOrder == 'nameZA') {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.name < b.name) {
          if (currentOrder == 'nameAZ') {
            return -1
          }
          else {
            return 1
          }
        }
        if (a.name > b.name) {
          if (currentOrder == 'nameAZ') {
            return 1
          }
          else {
            return -1
          }
        }
        return 0;
      })
    }
    else if (currentOrder == 'priceAZ' || currentOrder == 'priceZA') {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.price_dcto < b.price_dcto) {
          if (currentOrder == 'priceAZ') {
            return -1
          }
          else {
            return 1
          }
        }
        if (a.price_dcto > b.price_dcto) {
          if (currentOrder == 'priceAZ') {
            return 1
          }
          else {
            return -1
          }
        }
        return 0;
      })
    }
    else if (currentOrder == 'descuento') {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.dcto_porcentaje < b.dcto_porcentaje) {
          return -1
        }
        if (a.dcto_porcentaje > b.dcto_porcentaje) {
          return -1
        }
        return 0;
      })
    }
    else {
      this.dataProducts = Object.assign([], this.dataProductsCopy);
    }

    this.paginarProductos(this.dataProducts)
  }

  paginarProductos(data) {
    let paginas = 0;
    this.pages = [];
    this.listProduct = [];
    for (var i = 0; i < data.length; ++i) {
      if (i % this.pagesPerPage == 0) {
        paginas++;
        this.pages.push(paginas);
      }

      if (data[i] && i < this.pagesPerPage) {
        if (data[i].imagen) {
          var img = document.createElement('img');
          img.setAttribute('src', data[i].imagen);
        }
        this.listProduct.push(data[i]);
      }
    }
  }

  paginacion(index, scrollTop = false) {
    // console.log(this.pageCurrent);
    if (index == 'next') {
      // if (this.pageCurrent < this.pages.length) {
        if(this.listProduct.length === 0){
          return;
        }
        this.pageCurrent++;
        this.offset += this.limit;
        // console.log(this.pageCurrent);
        // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
        // this.listProduct = newListData
        this.getPromocionProductList();

        this.scroll(scrollTop);
        // }
      }
      else if (index == 'prev') {
        // if (this.pageCurrent > 1) {
        this.pageCurrent--;
        // console.log(this.pageCurrent);
        if(this.pageCurrent <= 0){
          this.pageCurrent = 1;
          return;
        }
        // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
        // this.listProduct = newListData
        this.offset -= this.limit;
        this.getPromocionProductList();

        this.scroll(scrollTop);
      // }
    }
    else {
      this.pageCurrent = parseInt(index);
      this.offset = 0;

      this.getPromocionProductList();

      this.scroll(scrollTop);
      // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
      // this.listProduct = newListData
    }

    //SCROLL TOP
    // window.scrollTo(0,0)
  }


  scroll(scrollTop){
    try {
        if (scrollTop) {
          window.scrollTo(0, document.querySelector('.sidebar-promociones-detalle').getBoundingClientRect().height)
        }
      } catch (error) {
        console.log('Error controlado => ', error);
      }
  }

}
