import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { Store } from '@ngrx/store'

@Injectable()

export class StoreService {
	constructor(private http: HttpClient, private store: Store<any>){
	}

	getAllState() {
		return this.store.select('appReducer')
	}

	updateAppState(obj) {
		// console.log('dispatch', obj);
		this.store.dispatch(
		{
			type: obj.action,
			payload: obj.payload
		})
	}
	
}
