import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-not-found404',
  templateUrl: './not-found404.component.html',
  styleUrls: ['./not-found404.component.scss']
})
export class NotFound404Component implements OnInit {

  constructor
  (
  	private router: Router,
  	public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
  	//Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit() {
  }

}
