import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterLink, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CarritoService } from "../../services/carrito.service";
import { ProductosService } from "../../services/productos.service";
import { WindowScrolling } from "../../services/window-scrolling.service";
import { StoreService } from '../../services/store.service';
import { UPDATE_BUSCADOR, UPDATE_MENU_MOBILE, UPDATE_MENU_MOBILE_SEARCH } from '../../store/actions/appAction';
import { SpinnerVisibilityService } from 'ng-http-loader';
import * as localForage from "localforage";
import * as _ from 'lodash';

declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {
  usuario:any;
  forma: FormGroup;
  formaMobile: FormGroup;
  menus:any = {}
  scroll: any;
  carrito: any = null;
  countCarrito 	: number = 0;
  searchIcon:string = 'icon-search';
  showSearchModal:boolean=false;
  showMobileModal:boolean=false;
  showTopSearch:boolean = false;
  buscadorCompleto: boolean;
  currentCategory: string = 'libros';
  alturaHeader: number = 1;

  private state = 'hide';
  private windowScrolling: WindowScrolling;
  private hoymanytime: number = 300;
  private processSearch: any;

  products: any = [];
  authors: any = [];
  publishers: any = [];
  searchProducts: any = [];
  searchAuthors: any = [];
  searchPublishers: any = [];
  showSearchNotification: boolean = false;
  currentSearch: object = {};
  searchLength: any = 0;

  searchTxt: string = '';

  constructor (
    private store: Store<any>,
    private renderer: Renderer2,
    private storeService: StoreService,
    private carritoService:CarritoService,
    private productosService: ProductosService,
    private router: Router,
    private spinner: SpinnerVisibilityService,
    windowScrolling: WindowScrolling
  ) {
    this.windowScrolling = windowScrolling;

    localForage.getItem('cache_get_productos').then( (res: any) => {
      if(res != null) {
        this.products = res.data;
      }
    });
    localForage.getItem('cache_get_autores').then( (res: any) => {
      if(res != null) {
        this.authors = res.data;
      }
    });

    this.store.subscribe( ({ appReducer }) => {

      let stateCurrent:any = this.store.select('appReducer');
      let actionCurrent:any = stateCurrent.actionsObserver.value.type;
      let payloadCurrent:any = stateCurrent.actionsObserver.value.payload;

      this.usuario = appReducer.user;
      this.menus = appReducer.menus;
      setTimeout( () => {

        this.showMobileModal = appReducer.menuMobileState;
        this.showSearchModal = appReducer.menuMobileSearchState;
        this.buscadorCompleto = appReducer.searchState;

      },100 );

      if(!this.buscadorCompleto) {
        // code...
        document.querySelector('body').style.overflow = 'auto';

      }

    });

  }

  ngOnInit(){
    this.validateSearch();

    this.renderer.listen(window,'scroll',($event) => {
      this.scroll = (window.scrollY);
    });

    this.store.select(state => state.appReducer).subscribe(item => {
      this.countCarrito = 0;
      if('id' in item.shopcar) {
        if('grupos' in item.shopcar) {
          if(item.shopcar.grupos.length > 0) {
            if('lineas' in item.shopcar.grupos[0]) {
              this.countCarrito = 0;
              if(item.shopcar.grupos[0].lineas.productos) {
                for (let linea of item.shopcar.grupos[0].lineas.productos){
                  let cantidadCurrent = parseInt(linea.cantidad);
                  this.countCarrito = this.countCarrito + cantidadCurrent;
                }
              }
            }
          }
        }
      }
    });

    this.eventoScroll();
  }

  eventoScroll(){
    var lastScrollTop = 0;
  	$(document).unbind('scroll').scroll(function(e){
      try {
        var subheader = document.querySelector('.router-content');
        var hHeader = document.querySelector('app-header header').getBoundingClientRect().height;
        var st = $(this).scrollTop();

        if (st > 1){
          $(subheader).css('margin-top', hHeader+'px');
        }else{
          $(subheader).css('margin-top', '0px');
        }

        lastScrollTop = st;
      } catch (error) {
        console.log('Error controlado => ', error);
      }
    });
  }

  toggleSearchIcon(disableScrollState = false){
    this.closeMobileModal();
    this.disableScrollSearch();
  }

  toggleHamburgerIcon(disableScrollState = false){
    this.closeSearchModal();
    if(!disableScrollState){
      this.disableScrollMenu();
    }else{
      this.windowScrolling.enable();
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false
      })
    }
  }

  closeSearchModal(){
    this.storeService.updateAppState({
      action: UPDATE_MENU_MOBILE_SEARCH,
      payload: false
    })
  }

  closeMobileModal(){
    this.storeService.updateAppState({
      action: UPDATE_MENU_MOBILE,
      payload: false
    })
  }

  disableScrollMenu(){
    if(this.showMobileModal){
      this.windowScrolling.disable();
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: true
      })
    } else {
      this.windowScrolling.enable();
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false
      })
    }
  }

  disableScrollSearch(){
    if(this.showSearchModal){
      this.windowScrolling.disable();
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: true
      })
    } else {
      this.windowScrolling.enable();
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: false
      })
    }
  }

  focusSearch(){
    this.showSearchNotification = true;
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: true
    })
  }

  focusOutSearch(){
    this.searchProducts = [];
    this.searchAuthors = [];
    this.searchPublishers = [];
    this.buscadorCompleto = false;
    this.showSearchNotification = false;
    this.forma.controls['buscar'].setValue('');
    this.forma.controls['buscar'].markAsUntouched();
    this.formaMobile.controls['buscar'].setValue('');
    this.formaMobile.controls['buscar'].markAsUntouched();
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    })
    this.closeSearchModal();
    this.closeMobileModal();
    this.windowScrolling.enable();
  }

  validateSearch()
  {
    this.forma = new FormGroup({
      'buscar' : new FormControl('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      'categoria' : new FormControl()
    });

    this.formaMobile = new FormGroup({
      'buscar' : new FormControl('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      'categoria' : new FormControl()
    });


    this.forma.controls['categoria'].setValue(this.currentCategory);
    this.formaMobile.controls['categoria'].setValue(this.currentCategory);

  }

  searchSite(formaOrg = null) {

    if(formaOrg) {
      if (this.forma.valid == true) {
        let search = this.forma.controls['buscar'].value;
        let categoria = this.forma.controls['categoria'].value;
        this.showTopSearch = false;
        this.forma.controls['buscar'].setValue('');
        this.forma.controls['buscar'].markAsUntouched();

        //CAMBIAR DE ESTADO EL HEADER
        this.storeService.updateAppState({
          action: UPDATE_BUSCADOR,
          payload: false
        });

        this.router.navigate(['categoria',categoria,'busqueda','texto', search]);
      } else {
        this.forma.controls['buscar'].markAsTouched();
        this.showTopSearch = false;
      }
    }
    else
    {
      if (this.formaMobile.valid == true) {

        this.buscadorCompleto = true;
        this.closeSearchModal()

        let search = this.formaMobile.controls['buscar'].value;
        let categoria = this.formaMobile.controls['categoria'].value;
        this.focusOutSearch()

        this.router.navigate(['categoria',categoria,'busqueda','texto',search]);
      } else {
        this.formaMobile.controls['buscar'].markAsTouched();
        this.showTopSearch = false;
      }
    }

  }

  changeCategory(selected){
    this.currentCategory = selected;
    this.forma.controls['categoria'].setValue(selected);
    this.formaMobile.controls['categoria'].setValue(selected);
    // this.searching(this.currentSearch);
    let obj = { target: { value: this.searchTxt } };
    this.keyUpLogger(obj);
  }

  keyUpLogger(e) {

    this.searchTxt = e.target.value;

    clearTimeout(this.processSearch);

    if(e.target.value.length < 3) {
      this.showSearchNotification = true;
      this.searchProducts = [];
      this.searchPublishers = [];
      this.searchAuthors = [];
      return;
    }

    this.showSearchNotification = false;

    let librosJson: any;
    if(this.currentCategory == 'libros') {
      if(JSON.parse(localStorage.getItem('librosJson')).data) {
        librosJson = JSON.parse(localStorage.getItem('librosJson')).data;
      }
    } else if(this.currentCategory == 'ebooks') {
      if(JSON.parse(localStorage.getItem('ebooksJson')).data) {
        librosJson = JSON.parse(localStorage.getItem('ebooksJson')).data;
      }
    }
    this.processSearch = setTimeout(() => {
      let search_text = e.target.value.toLowerCase();
      this.searchProducts = [];
      this.searchPublishers = [];
      this.searchAuthors = [];
      let pcounter: number = 0;
      let ecounter: number = 0;
      let acounter: number = 0;
      if(librosJson.length > 0 && librosJson != undefined && librosJson != null) {
        librosJson.forEach(el => {
          if(el.n) {
            if(el.n.toLowerCase().indexOf(search_text) > -1) {
              if(pcounter < 5) {
                if(this.searchProducts.indexOf(el.n) == -1) {
                  this.searchProducts.push(el.n);
                  pcounter++;
                }
              }
            }
          }
          if(el.e) {
            if(el.e.toLowerCase().indexOf(search_text) > -1) {
              if(ecounter < 5) {
                if(this.searchPublishers.indexOf(el.e) == -1) {
                  this.searchPublishers.push(el.e);
                  ecounter++;
                }
              }
            }
          }
          if(el.a) {
            el.a.forEach(a => {
              if(a.toLowerCase().indexOf(search_text) > -1) {
                if(acounter < 5) {
                  if(this.searchAuthors.indexOf(a) == -1) {
                    this.searchAuthors.push(a);
                    acounter++;
                  }
                }
              }
            });
          }
        });
      }
    },this.hoymanytime);
  }

  searching(e) {
    if(e.code && e.code.toLowerCase() == 'enter' && e.code.toLowerCase() == 'numpadenter') {
      this.focusOutSearch();
      return false;
    }

    if(!e.target) return;

    let v = e.target.value.toLowerCase();
    if(v.length < 3) {
      this.showSearchNotification = true;
      this.searchProducts = [];
      this.searchAuthors = [];
      this.searchPublishers = [];
      return false;
    } else {
      this.showSearchNotification = false;
    }
    this.currentSearch = e;
  }
}
