import { AppState } from './../../store/appState';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ColeccionesService } from './../../services/colecciones.service';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AccionesPromociones from '../../store/promociones/actions';
import { PlatformLocation } from '@angular/common';
//import { promociones_data } from './promociones_data';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})

export class PromocionesComponent implements OnInit, OnDestroy {
  promocionList: any = [];
  promocionDestacadaData: any = [];

  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = {};

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private getColeccionObj: ColeccionesService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private store: Store<AppState>,
    private platformLocation: PlatformLocation
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    // Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    this.loadSubscription = this.store.select('promociones').subscribe(({ data, loading, loaded, error }) => {
      this.error = error;
      this.cargando = loading;
      this.cargaContenido = loaded;
      this.promocionList = data;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.getPromocionDestacada();
    // validacion para no volver a pedir al crm la información
    if (this.promocionList.length === 0 || this.error) {
      this.store.dispatch(new AccionesPromociones.CargarPromociones({ offset: 0, limit: 10 }));
      //this.store.dispatch(new AccionesPromociones.CargarPromocionesSuccess(promociones_data));
    }

    const dataMetas = {
      title: 'Nuestras Promociones',
      description: 'Siempre queremos darte más por eso aquí encontrarás todas nuestras promociones.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com' + this.router.url
    };

    try {
      this.setMetaData(dataMetas);
    } catch (error) { }
  }

  ngOnDestroy() {
    this.loadSubscription.unsubscribe();
  }

  setMetaData(data): void {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  getPromocionDestacada(): void {
    const data = {
      slug: 'promocion-destacada'
    };
    this.getColeccionObj.getColeccionDefault(data).subscribe((response: any) => {
      if (response) {
        if (response.items[0]) {
          this.promocionDestacadaData = response.items[0];
        }
      }
    });
  }
}
