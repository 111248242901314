import { Action } from '@ngrx/store';

export enum AccionesColeccionDetalle {
    CARGAR_COLECCION_DETALLE = '[COLECCION DETALLE] => Cargar',
    CARGA_COLECCION_DETALLE_EXITOSA = '[COLECCION DETALLE] => Carga Exitosa',
    CARGA_COLECCION_DETALLE_FALLIDA = '[COLECCION DETALLE] => Carga Fallida',
    RESET_COLECCION_DETALLE = '[COLECCION DETALLE] => Reset',
}

export class CargarColeccionDetalle implements Action {
    readonly type = AccionesColeccionDetalle.CARGAR_COLECCION_DETALLE;

    constructor( public payload?: any ) {}
}

export class CargarColeccionDetalleFail implements Action {
    readonly type = AccionesColeccionDetalle.CARGA_COLECCION_DETALLE_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarColeccionDetalleSuccess implements Action {
    readonly type = AccionesColeccionDetalle.CARGA_COLECCION_DETALLE_EXITOSA;

    constructor( public coleccion: any ) {}
}

export class ResetColeccionDetalle implements Action {
    readonly type = AccionesColeccionDetalle.RESET_COLECCION_DETALLE;
}

export type UnionAccionesColeccionDetalle = CargarColeccionDetalle | CargarColeccionDetalleFail | CargarColeccionDetalleSuccess | ResetColeccionDetalle;
