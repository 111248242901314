import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {
  constructor(
  ){}

  ngOnInit() {
    //SCROLL TOP
    window.scrollTo(0,0);
  }
}