import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent implements OnInit {
  tycLbreroMama = `Términos y Condiciones "Librero de Mamá"`;
  currentView:any;
  constructor() {

  }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  setCurrentView(selected){
    this.currentView = selected;
  }
}
