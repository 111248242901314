import { Component, OnInit, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";
import { Router, NavigationEnd, Event, RouterEvent, ActivationEnd } from "@angular/router";
import { MenusService } from "./services/menus.service";
import { StoreService } from "./services/store.service";
import { CarritoService } from "./services/carrito.service";
import { UsuarioService } from "./services/usuario.service";
import { SessionService } from "./services/session.service";
import { AddTrakingTopleadsService } from "./services/add-traking-topleads.service";
import { ACTION_LOADMENU } from "./store/actions/appAction";
import { SearchService } from "./services/search.service";
import { environment } from "../environments/environment";
import * as localForage from "localforage";
import { CloseModalsService } from "./services/close-modals.service";
import { data_menu } from "./data_menus";
//import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { filter } from "rxjs/operators";
import { log } from "console";
declare var vgo: any;
@Injectable()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  usuario: any;
  shopcar: any = {};
  stateHeaderFooter: boolean = true;
  stateModal: boolean = false;
  stateModalLogin: boolean = false;
  originModalLogin: any;
  dataModal: any = {
    tipo: "",
    data: {},
  };
  user: SocialUser;
  loggedIn: boolean;
  title = process.env["NODE_VERSION"] || 'DEF_A';

  constructor(
    private router: Router,
    private store: Store<any>,
    private location: Location,
    private menusService: MenusService,
    private storeService: StoreService,
    private carritoService: CarritoService,
    private usuarioService: UsuarioService,
    private sessionService: SessionService,
    private searchService: SearchService,
    private addTrakingTopleadsService: AddTrakingTopleadsService,
    private closeModalService: CloseModalsService,
    private authService: SocialAuthService
  ) {
    //Start tracking GTM
    this.usuario = {};
  }

  ngOnInit() {
    this.routerEvent();
    this.sessionService.checkSession();
    this.getMenus();
    this.store.subscribe((response) => {
      setTimeout(() => {
        this.stateHeaderFooter = response.appReducer.headerFooterState;
      }, 100);
    });

    //ACTUALIZAR ESTADO DE LA MODAL DEL CARRITO
    this.closeModalService.dataModalStateSubject.subscribe((state) => {
      this.stateModal = state;
    });

    //ACTUALIZAR DATA DE LA MODAL DEL CARRITO
    this.closeModalService.notificacionesModalSubject.subscribe((data) => {
      this.dataModal = data;
    });

    //ACTUALIZAR ESTADO DE LA MODAL DEL LOGIN
    this.closeModalService.loginModalStateSubject.subscribe((data) => {
      this.stateModalLogin = data;
    });

    //ACTUALIZAR ORIGEN DE LA MODAL DEL LOGIN
    this.closeModalService.loginModalOriginSubject.subscribe((data) => {
      this.originModalLogin = data;
    });

    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }

  getMenus() {
    // this.storeService.updateAppState({
    //   action: ACTION_LOADMENU,
    //   payload: data_menu.data
    // })
    this.menusService.getMenus().subscribe(
      (response: any) => {
        if (response.data) {
          this.storeService.updateAppState({
            action: ACTION_LOADMENU,
            payload: response.data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * @description funcion que se subcribe a los eventos del roter cada que cambian de url, cuando finaliza
   * ejecuta las tres funciones del codigo de seguimiento de topleads que estan importadas en el index.html
   */

  routerEvent() {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
    ).subscribe((e: ActivationEnd) => {
      const url = this.router.url === '/' ? 'https://www.librerianacional.com/' : `https://www.librerianacional.com${this.router.url}`;
      this.addtraking(url);
    });

  }
  /**
   * Registra en topleads el historial de urls visitadas por el usuario en sesion
   * @param url string url de la pagina visitada
   */
  addtraking(url){
    // solo registra si hay una sesion activa
    if (localStorage.getItem('session')) {
      const session = JSON.parse(localStorage.session);
      const dataCrm = [
        session,{
          event: '__paginaVista',
          eventdata: url
        }
      ];
      this.addTrakingTopleadsService.trakingTopleads(dataCrm).subscribe((res:any) => {});
    }
  }
}
