export class Direccion {
	nombre:string;
	linea1:string;
	linea2:string;
	departamento:string;
	ciudad:string;
	barrio:string;
	latitud:string;
	longitud:string;
	constructor() {
	}
}
