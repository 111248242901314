import { Action } from '@ngrx/store';

export enum AccionesMasVendidosHome {
    CARGAR_MAS_VENDIDOS_HOME = '[MAS VENDIDOS HOME] => Cargar',
    CARGA_MAS_VENDIDOS_HOME_EXITOSA = '[MAS VENDIDOS HOME] => Carga Exitosa',
    CARGA_MAS_VENDIDOS_HOME_FALLIDA = '[MAS VENDIDOS HOME] => Carga Fallida'
}

export class CargarMasVendidosHome implements Action {
    readonly type = AccionesMasVendidosHome.CARGAR_MAS_VENDIDOS_HOME;

    constructor( public payload?: any ) {}
}

export class CargarMasVendidosHomeFail implements Action {
    readonly type = AccionesMasVendidosHome.CARGA_MAS_VENDIDOS_HOME_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarMasVendidosHomeSuccess implements Action {
    readonly type = AccionesMasVendidosHome.CARGA_MAS_VENDIDOS_HOME_EXITOSA;

    constructor( public masvendidos: any ) {}
}

export type UnionAccionesMasVendidosHome = CargarMasVendidosHome | CargarMasVendidosHomeFail | CargarMasVendidosHomeSuccess;
