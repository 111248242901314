import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { StoreService } from "./store.service";
import { CarritoService } from "./carrito.service";
import { UsuarioService } from "./usuario.service";
import { ACTION_SHOPCAR, ACTION_LOGOUT } from "../store/actions/appAction";
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private carritoService:CarritoService,
    private usuarioService:UsuarioService,
    private storeService:StoreService,
    private csasr: CheckSessionAfterServiceResponseService,
  ) { }

  /**
   *
   * @param data [{session_id: '', {creditCardToken: {name: "", identificationNumber: "", number: "", expirationDate: "", paymentMethod: ""}}}]
   */

  create( data ) {
		let crmData = [
			data.session_id,
			{
        creditCardToken: {
          name: data.name,
          identificationNumber: data.identificationNumber,
          number: data.number,
          expirationDate: data.expirationDate,
          paymentMethod: data.payment_method
        }
			}
		];
		let formData = new FormData();
		formData.append('method', 'stpPay_addCreditCard');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}))
  }

  /**
   * The properties start_date and end_date are using the following format yyyy-mm-ddThh:mm:ss. Please notice the T between dd and hh.
   * @param data ["session_id",{creditCardToken:{payerId: '', start_date: '', end_date: ''}}]
   */
  /* search( data ) {
    let crmData = [
      data.session_id,
      {
        creditCardToken: {
          start_date: data.start_date,
          end_date: data.end_date,
        }
      }
    ];
    let formData = new FormData();
    formData.append('method', 'stpPay_SearchTokenCreditCard');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);

      return response

    }));
  } */

  /**
   *
   * @param data [{session_id: '', {creditCardToken:{tokenId: ''}}}]
   */
  delete( data ) {
		let crmData = [
			data.session_id,
			{
				creditCardToken : {
          tokenId: data.tokenId
        },
			}
		];
		let formData = new FormData();
		formData.append('method', 'stpPay_deleteTokenCreditCard');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);

      return response

		}));
  }

  getDataPayu( ) {
    let crmData = [{}];
    let formData = new FormData();
    formData.append('method', 'stpPay_getInfoPayu');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {

      return response

    }));
  }

  getCardTypes( ) {
    let crmData = [{}];
    let formData = new FormData();
    formData.append('method', 'stpPay_getTiposTarjeta');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {

      return response

    }));
  }

  tcPayment( data ) {
		let crmData = [
			data.session_id,
			{...data}
		];
		let formData = new FormData();
		formData.append('method', 'stpPay_pagarTARJETA');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
			return response
		}));
  }


}
