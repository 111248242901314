//ACTIONS APP
export const ACTION_LOADMENU = 'LOADMENU';
export const ACTION_HEADER_STATE = 'HEADER_STATE';

//ACTIONS USER
export const ACTION_LOGOUT = 'LOGOUT';
export const ACTION_LOGIN = 'LOGIN';
export const ACTION_CLIENTE_SET = 'CLIENTE_SET';
export const ACTION_SET_REGISTER_FULL = 'SET_REGISTER_FULL';
export const ACTION_UPDATE = 'UPDATE';

//ACTIONS CAR
export const ACTION_SHOPCAR = 'SHOPCAR';
export const ACTION_SET_DIR_SHOPCAR = 'SET_DIR_SHOPCAR';

//ACTIONS CAR
export const ACTION_DEL_SHOPCAR = 'DEL_SHOPCAR';

// UPDATE TOTALS SHOPCAR
export const UPDATE_TOTALS_SHOPCAR = 'UPDATE_TOTALS_SHOPCAR';

//ACTIONS ADDRESS
export const ACTION_ADDRESS = 'ADDRESS';

//ACTIONS SHOPCAR_COPY
export const ACTION_SHOPCAR_COPY = 'SHOPCAR_COPY';

//ACTIONS PAYMENT_RESPONSE
export const ACTION_PAYMENT_RESPONSE = 'PAYMENT_RESPONSE';

//ACTIONS CAR
export const ACTION_PAYMENT_ORDER = 'PAYMENT_ORDER';

//UPDATE BUSCADOR
export const UPDATE_BUSCADOR = 'UPDATE_BUSCADOR';

//UPDATE_MENU_MOBILE
export const UPDATE_MENU_MOBILE = 'UPDATE_MENU_MOBILE';

//UPDATE_MENU_MOBILE_SEARCH
export const UPDATE_MENU_MOBILE_SEARCH = 'UPDATE_MENU_MOBILE_SEARCH';

//SET_SEDES
export const SET_SEDES = 'SET_SEDES';

//GET_MIS_BONOS
export const GET_MIS_BONOS = 'GET_MIS_BONOS';

//GET_MIS_BONOS
export const ADD_FAVORITOS = 'FAVORITOS';

//ESTADO DE CARGA DEL CARRITO
export const LOAD_SHOPCAR = 'LOAD_SHOPCAR';