import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { StoreService } from "./store.service";
import { CarritoService } from "./carrito.service";
import { UsuarioService } from "./usuario.service";
import { ACTION_SHOPCAR, ACTION_LOGOUT } from "../store/actions/appAction";
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class PseService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private carritoService:CarritoService,
    private usuarioService:UsuarioService,
    private storeService:StoreService,
    private csasr: CheckSessionAfterServiceResponseService,
  )
  {

  }

  banks() {
    let crmData = [{}];
    let formData = new FormData();
    formData.append('method', 'stpPay_getBancosPSE');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }

  personTypes() {
    let crmData = [{}];
    let formData = new FormData();
    formData.append('method', 'stpPay_getTiposPersonaPSE');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }

  documentTypes() {
    let crmData = [{}];
    let formData = new FormData();
    formData.append('method', 'stpPay_getTiposDocumentosPSE');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }

  psePayment( data ) {
		let crmData = [
			data.session_id,
			{
        orden_pago_id: data.orden_pago_id,
        pse_banco_codigo: data.pse_banco_codigo,
        pse_tipo_persona: data.pse_tipo_persona,
        pse_tipo_documento: data.pse_tipo_documento,
        pse_pagador_dni: data.pse_pagador_dni,
        pse_pagador_nombre: data.pse_pagador_nombre
			}
		];
		let formData = new FormData();
		formData.append('method', 'stpPay_pagarPSE');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => {
      this.csasr.check(response);
      return response;
		}));
  }
}
