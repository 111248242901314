import { Action } from '@ngrx/store';

export enum AccionesRecomendadosHome {
    CARGAR_RECOMENDADOS_HOME = '[RECOMENDADOS HOME] => Cargar',
    CARGA_RECOMENDADOS_HOME_EXITOSA = '[RECOMENDADOS HOME] => Carga Exitosa',
    CARGA_RECOMENDADOS_HOME_FALLIDA = '[RECOMENDADOS HOME] => Carga Fallida'
}

export class CargarRecomendadosHome implements Action {
    readonly type = AccionesRecomendadosHome.CARGAR_RECOMENDADOS_HOME;

    constructor( public payload?: any ) {}
}

export class CargarRecomendadosHomeFail implements Action {
    readonly type = AccionesRecomendadosHome.CARGA_RECOMENDADOS_HOME_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarRecomendadosHomeSuccess implements Action {
    readonly type = AccionesRecomendadosHome.CARGA_RECOMENDADOS_HOME_EXITOSA;

    constructor( public recomendados: any ) {}
}

export type UnionAccionesRecomendadosHome = CargarRecomendadosHome | CargarRecomendadosHomeFail | CargarRecomendadosHomeSuccess;
