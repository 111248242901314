import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import {
  CargarOrdenesSuccess,
  CargarOrdenesFail,
  AccionesOrdenes,
  ActualizarOrdenExitosa,
  ActualizarOrdenFallida,
  SetDireccionEnvioFacturaExitosa,
  SetDireccionEnvioFacturaFallida,
} from "./actions";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { CarritoService } from "src/app/services/carrito.service";

@Injectable()
export class OrdenesEffects {
  constructor(
    private actions$: Actions,
    public carritoService: CarritoService
  ) {}

  @Effect()
  cargarOrdenes$ = this.actions$
    .pipe(ofType(AccionesOrdenes.CARGAR_ORDENES))
    .pipe(
      switchMap((data: any) => {
        return this.carritoService.get_billList(data.payload).pipe(
          map((response: any) => {
            if (response && response.length) {
              return new CargarOrdenesSuccess(response);
            }
            // return new CargarOrdenesFail({valu:''});
          }),
          catchError((error) => of(new CargarOrdenesFail(error)))
        );
      })
    );

  @Effect()
  cargarInformacionOrden$ = this.actions$
    .pipe(ofType(AccionesOrdenes.ACTUALIZAR_ORDEN))
    .pipe(
      switchMap((data: any) => {
        let dataServicio = [data.data[0], data.data[1]];

        try {
          dataServicio[1].mostrar_despachos = 1;
        } catch (error) { console.log('Error controlado => ', error); }

        let dataOrden = data.data[2];

        return this.carritoService.getFactura(dataServicio).pipe(
          map((response: any) => {
            if (response && response.id) {
              return new ActualizarOrdenExitosa({response, dataOrden});
            }
            return new ActualizarOrdenFallida(response);
          }),
          catchError((error) => of(new ActualizarOrdenFallida(error)))
        );
      })
    );

  @Effect()
  setDireccionEnvioOrden$ = this.actions$
    .pipe(ofType(AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN))
    .pipe(
      switchMap((data: any) => {
        return this.carritoService.setDireccionEnvioOrden(data.data).pipe(
          map((response: any) => {
            if (response && response.id) {
              return new SetDireccionEnvioFacturaExitosa({ response });
            }
            return new SetDireccionEnvioFacturaFallida(response);
          }),
          catchError((error) => of(new SetDireccionEnvioFacturaFallida(error)))
        );
      })
    );
}
