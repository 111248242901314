import { AppReducer } from '../reducers/appReducer';
import { coleccionesReducer } from '../colecciones/reducer';
import { coleccionDetalleReducer } from '../colecciones/detalle/reducer';
import { promocionesReducer } from '../promociones/reducer';
import { promocionDetalleReducer } from '../promociones/detalle/reducer';
import { coleccionesHomeReducer } from '../home/reducer';
import { novedadesHomeReducer } from '../home/novedades/reducer';
import { masVendidosHomeReducer } from '../home/masvendidos/reducer';
import { recomendadosHomeReducer } from '../home/recomendados/reducer';
import { eventosReducer } from '../eventos/reducer';
import { eventoDestacadoReducer } from '../eventos/destacado/reducer';
import { ordenesReducer } from '../ordenes/reducer';
import { direccionesReducer } from "../direcciones/reducer";
import { modalRestriccionesCovidReducer } from '../modalRestriccionesCovid/reducer';

import { AppState } from '../appState';
import { schemaJsonLdReducer } from '../schemaJsonLd/reducer';

export const reducers: AppState = {
  appReducer : AppReducer,
  colecciones: coleccionesReducer,
  coleccion: coleccionDetalleReducer,
  promociones: promocionesReducer,
  promocion: promocionDetalleReducer,
  home: coleccionesHomeReducer,
  novedades: novedadesHomeReducer,
  masvendidos: masVendidosHomeReducer,
  recomendados: recomendadosHomeReducer,
  eventos: eventosReducer,
  eventodestacado: eventoDestacadoReducer,
  ordenes: ordenesReducer,
  direcciones: direccionesReducer,
  modalRestriccionesCovid: modalRestriccionesCovidReducer,
  schemaJsonLd: schemaJsonLdReducer
};
