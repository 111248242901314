import { Store } from '@ngrx/store';
import { Component } from '@angular/core';

@Component({
    selector: 'contacto-header',
    templateUrl: './contacto-header.component.html'
})
export class ContactoHeaderComponent {
    menus: any = [];

    constructor(private store: Store<any>,){
        this.store.subscribe( ({ appReducer }) => this.menus = appReducer.menus);
    }
}