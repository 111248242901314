import { AppState } from './../../store/appState';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColeccionesService } from '../../services/colecciones.service';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import * as AccionesColecciones from '../../store/colecciones/actions';
import { PlatformLocation } from '@angular/common';
//import { colecciones } from './data_colecciones';

@Component({
  selector: 'app-colecciones',
  templateUrl: './colecciones.component.html',
  styleUrls: ['./colecciones.component.css']
})
export class ColeccionesComponent implements OnInit, OnDestroy {
  count = 1;
  coleccionData: any = [];
  coleccionFull: any = [];

  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = {};

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private store: Store<AppState>,
    private platformLocation: PlatformLocation
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    // Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    this.loadSubscription = this.store.select('colecciones').subscribe(({ data, loading, loaded, error }) => {
      this.error = error;
      this.cargando = loading;
      this.cargaContenido = loaded;
      this.coleccionFull = data;

      if (this.coleccionFull.length > 0) {
        let position = 'L';
        for (let i = 0; i < this.coleccionFull.length; ++i) {
          if ((i - 1) % 2 === 0) {
            if (position === 'L') { position = 'C'; }
            else { position = 'L'; }
          }
          this.coleccionFull[i].posicion = position;
        }
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // validacion para no volver a pedir al crm la información
    if (this.coleccionFull.length === 0 || this.error) {
      this.store.dispatch(new AccionesColecciones.CargarColecciones({ slug: 'colecciones-para-ti' }));
      //this.store.dispatch(new AccionesColecciones.CargarColeccionesSuccess(colecciones));
    }

    const dataMetas = {
      title: 'Colecciones para ti',
      description:
        'Sabemos que te gusta leer al máximo, por eso mensualmente tenemos colecciones y novedades para que las incluyas en tu lista de lectura.',
      keywords: 'libros,libreria,ebooks',
      image:
        'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com' + this.router.url
    };
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }
  }

  ngOnDestroy() {
    this.loadSubscription.unsubscribe();
  }

  setMetaData(data) {
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ name: 'keywords', content: data.keywords });
    this.meta.updateTag({ name: 'description', content: data.description });
    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image'
    });
    this.meta.updateTag({ name: 'twitter:title', content: data.title });
    this.meta.updateTag({ name: 'twitter:text:title', content: data.title });
    this.meta.updateTag({
      name: 'twitter:description',
      content: data.description
    });
    this.meta.updateTag({ name: 'twitter:image', content: data.image });
    this.meta.updateTag({ name: 'twitter:image:alt', content: data.title });
    this.meta.updateTag({ property: 'og:title', content: data.title });
    this.meta.updateTag({ property: 'og:url', content: data.url });
    this.meta.updateTag({ property: 'og:image', content: data.image });
    this.meta.updateTag({ property: 'og:image:alt', content: data.title });
    this.meta.updateTag({
      property: 'og:description',
      content: data.description
    });
    this.meta.updateTag({ property: 'product:brand', content: '' });
    this.meta.updateTag({ property: 'product:availability', content: '' });
    this.meta.updateTag({ property: 'product:condition', content: '' });
    this.meta.updateTag({ property: 'product:price:amount', content: '' });
    this.meta.updateTag({ property: 'product:price:currency', content: '' });
    this.meta.updateTag({ property: 'product:retailer_item_id', content: '' });
    this.meta.updateTag({ property: 'fb:app_id', content: '529648729383744' });
    this.meta.updateTag({ property: 'fb:admins', content: '99999999' });
  }
}
