import { Action } from '@ngrx/store';

export enum AccionesPromociones {
    CARGAR_PROMOCIONES = '[PROMOCIONES] => Cargar',
    CARGA_PROMOCIONES_EXITOSA = '[PROMOCIONES] => Carga Exitosa',
    CARGA_PROMOCIONES_FALLIDA = '[PROMOCIONES] => Carga Fallida'
}

export class CargarPromociones implements Action {
    readonly type = AccionesPromociones.CARGAR_PROMOCIONES;

    constructor( public payload?: any ) {}
}

export class CargarPromocionesFail implements Action {
    readonly type = AccionesPromociones.CARGA_PROMOCIONES_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarPromocionesSuccess implements Action {
    readonly type = AccionesPromociones.CARGA_PROMOCIONES_EXITOSA;

    constructor( public promociones: any ) {}
}

export type UnionAccionesPromociones = CargarPromociones | CargarPromocionesFail | CargarPromocionesSuccess;
