import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string = null, tipo: string = 'first'): string {
    if (value === null) return 'Not assigned';
    if(tipo == 'each') {
    	let text = '';
    	let words = value.split(' ');
    	for (var i = 0; i < words.length; ++i) {
            if(words[i].length > 1) {
        		if (/^[a-zA-Z]+$/) {
        			text += words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    			} else {
    				text += words[i].charAt(0) + words[i].charAt(1).toUpperCase() + words[i].slice(1).toLowerCase();
    			}
            }
            else
            {
                text += words[i].toLowerCase();
            }

    		if((i+1) >= words.length) {
    			return text
    		} else {
    			text += ' '
    		}
    	}
    } else {
        if(value.length > 0)
        {
    	    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
        else
        {
             return '--'   
        }
    }
  }
}