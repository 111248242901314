import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private crmUrl : string = environment.apiUrl;

  constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,) { }

  search( data ) {
    let crmData = [
      null,
      {
        tipo: data.tipo, //PAIS, DPTO, CIUDAD, Barrio
        ubicacion_id: data.ubicacion_id // id del PAIS, DPTO, CIUDAD, Barrio (opcional según filtro)
      }
    ];
    let formData = new FormData();
    formData.append('method', 'ubicaciones_list');
    formData.append('rest_data', JSON.stringify(crmData));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }
}
