import {Injectable} from '@angular/core';
import {HttpClient, HttpClientJsonpModule} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class EbooksService {

  private crmUrl : string = environment.apiUrl;

  constructor(public http: HttpClient, public json: HttpClientJsonpModule){
  }

  getEbooks(data){

    let formData = new FormData();
    formData.append('method', 'listAllLibraryEbooksTagus');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  recuperarClaveTagus(data){

    let formData = new FormData();
    formData.append('method', 'rememberPasswordTagus');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  checkEmailTagus(data){

    let formData = new FormData();
    formData.append('method', 'checkEmailTagus');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  loginTagus(data){

    let formData = new FormData();
    formData.append('method', 'loginTagus');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  descargarEbook(data){

    let formData = new FormData();
    formData.append('method', 'descargarEbookTagusDesdePedido');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');

    return this.http.post(this.crmUrl, formData).pipe(map(response => response));
  }

  checkLinkDescarga(url){
    return this.http.jsonp(url, 'callback').pipe(map(response => response));
  }

  descargaDRM(url){
    return this.http.jsonp(url, 'callback').pipe(map(response => response));
  }
}
