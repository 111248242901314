import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

import { Usuario } from '../../../clases/index';
import { UsuarioService } from '../../../services/usuario.service';
import { CarritoService } from '../../../services/carrito.service';
import { StoreService } from "../../../services/store.service";
import { ACTION_LOGIN, ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { UPDATE_BUSCADOR } from '../../../store/actions/appAction';
import { Store } from "@ngrx/store";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.css']
})
export class IngresoComponent implements OnInit {
  usuario:any;
  shopcar:any;
  menus:any;
  origin:any;
  loginMessage:string;
  statusForm: number = 0;
  private stateLogin: any = null;
  @Input('origen') origen;

  constructor(
    private socialAuthService: SocialAuthService,
    private usuarioService: UsuarioService,
    private carritoService : CarritoService,
    private storeService: StoreService,
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
    if(this.route.snapshot.queryParamMap.get('origin')){
      this.origin = this.route.snapshot.queryParamMap.get('origin');
      console.log('origin', this.origin);
    }
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    })
  }

  public socialSignIn ( socialPlatform : string ){
    let socialPlatformProvider;
    if(socialPlatform == "facebook"){
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    }
    else if(socialPlatform == "GOOGLE"){
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    //LOGIN SOCIAL 
    this.socialAuthService.signIn(socialPlatformProvider).then(
    (userData) => {
      let usuario = new Usuario();
      usuario.login = false;
      usuario.nameSocial = userData.name;
      usuario.email = userData.email;
      usuario.idSocialGo = userData.provider == 'GOOGLE' ? userData.id : null;
      usuario.idSocialFb = userData.provider == 'FACEBOOK' ? userData.id : null;
      usuario.tokenGo = userData.provider == 'GOOGLE' ? userData.idToken : null;
      usuario.tokenFb = userData.provider == 'FACEBOOK' ? userData.authToken : null;
      usuario.provider = userData.provider;
      usuario.imagen = userData.photoUrl ? userData.photoUrl : 'avatar.jpg';

      //LOGIN SOCIAL EN EL CRM
      this.usuarioService.setLogin(userData).subscribe( response => {
        if (response.number == 10){
          this.statusForm = 1;
        } else if (response.number == 2010 || response.number == 2020){
          if(response.name == 'Google_Login_Error'){
            this.statusForm = 2;
          } else if (response.name == 'Facebook_Login_Error'){
            this.statusForm = 3;
          }
          this.loginMessage = response.description;
        } else if (response.id) {
          this.loginUserCrmSuccess(response);
        }
      })
    }).catch((e: any) => console.log('social error: ', e));
  }

  loginUserCrmSuccess(response){
    let currentUser = new Usuario();
    currentUser.login = true;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
    this.usuarioService.getUser(dataCrm).subscribe(resp => {
      if (resp.status == 'ok'){
        currentUser.userId = resp.config.id;
        currentUser.clienteId = resp.cuenta.cliente_id;
        currentUser.tokenCrm = resp.config.token;
        currentUser.estado_registro = resp.config.estado_registro;
        currentUser.estado_login = resp.config.estado_login;
        currentUser.nombres = resp.config.nombres;
        currentUser.apellidos = resp.config.apellidos;
        currentUser.tipo_identificacion = resp.config.tipo_identificacion;
        currentUser.identificacion = resp.config.identificacion;
        currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
        currentUser.email = resp.config.email;
        currentUser.telefono = resp.config.telefono;
        currentUser.telefono_movil = resp.cuenta.telefono_movil;

        //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
        this.usuarioService.setUserLocal(currentUser);
        this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

        //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
        let carritoLS = this.carritoService.getCarritoLocal();
        if (carritoLS && (this.shopcar.cliente_user_id == '' || this.shopcar.cliente_user_id == null) ){
          //MERGE DE CARRITOS CUANDO SI HAY LOCAL
          let dataCrmMergeCarrito = [
            currentUser.sessionCrm,
            {
              factura_merge_id  : carritoLS
            }
          ];
          this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
            if(response.id){
              this.updateCarritoLocal(response);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
            }
            else 
            {
              this.carritoService.deleteCarritoLocal()
              this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
            }
          })
        } else {
          let dataCrmCarrito = {
            session_id : currentUser.sessionCrm
          };
          this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
            if(dataCarrito.id){
              this.updateCarritoLocal(dataCarrito);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
            }
          });
        }
        if(this.origin=='datos-personales'){
          let msg = 'Ahora puede editar sus datos personales';
          this.router.navigate(['/', 'mi-cuenta', 'datos-personales'], { queryParams: { message: msg }, skipLocationChange:true });
        } else if(this.origin=='datos-envio'){
          this.router.navigate(['/', 'carrito', 'datos-envio']);
        } else {
          if(localStorage.getItem('last-url')) {
            this.router.navigate(JSON.parse(localStorage.getItem('last-url')));
          } else {
            this.router.navigate(['/', 'mi-cuenta']);
          }
        }
      }
    });
  }

  updateCarritoLocal(dataCarrito) {
    this.carritoService.setCarritoLocal(dataCarrito);
  }
}
