import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitstring'
})
export class limitString implements PipeTransform {
  transform(value: string, limit: number = 0): string {
    if (value === null) return 'Not assigned';
    if(limit > 0) {

        let strimText = value.substring(0,limit)

        if(value.length <= limit) {
            return value
        }
        else{
            return  strimText + '...'
        }

    } else {

    	return value

    }
  }
}
