import { PromocionesService } from './../../../services/promociones.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarPromocionDetalleSuccess, CargarPromocionDetalleFail, AccionesPromocionDetalle } from './actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class PromocionDetalleEffects {

    constructor(
        private actions$: Actions,
        public promocionesService: PromocionesService
    ) {}

    @Effect()
    cargarPromocionDetalle$ = this.actions$.pipe( ofType(AccionesPromocionDetalle.CARGAR_PROMOCION_DETALLE) )
        .pipe(
            switchMap( (data: any) => {
                return this.promocionesService.getPromocionData(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response) {
                                return new CargarPromocionDetalleSuccess(response);
                            }
                            return new CargarPromocionDetalleFail(response);
                        } ),
                        catchError( error => of(new CargarPromocionDetalleFail(error)) )
                    );
            })
        );
}
