import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';

@Injectable()

export class AyudaService {
	
	private crmUrl : string = environment.apiUrl;

	constructor(public http: HttpClient){}

	getPreguntasFrecuentes (data) {
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'contenidos_list');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => response));
	}
	
	getPaginaPorSlug (data) {
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'contenidos_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl, formData).pipe( map( (response:any) => response));
	}
}
