import { Component } from '@angular/core';

@Component({
  selector: "app-agregar-direccion",
  templateUrl: "./agregar-direccion.component.html",
})
export class AgregarDireccionComponent {

  constructor() { }
}
