
import { AppState } from './../../store/appState';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ProductosService } from "../../services/productos.service";
import { PlatformLocation } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { StoreService } from '../../services/store.service';
import { ACTION_SHOPCAR, UPDATE_BUSCADOR, UPDATE_MENU_MOBILE, UPDATE_MENU_MOBILE_SEARCH, ACTION_DEL_SHOPCAR } from '../../store/actions/appAction';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowScrolling } from "../../services/window-scrolling.service";
import { Store } from '@ngrx/store';
import { CarritoService } from "../../services/carrito.service";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta } from '@angular/platform-browser';
import * as moment from 'moment';

import * as AccionesHome from '../../store/home/actions';
import * as AccionesNovedadesHome from '../../store/home/novedades/actions';
import * as AccionesMasVendidosHome from '../../store/home/masvendidos/actions';
import * as AccionesRecomendadosHome from '../../store/home/recomendados/actions';
//import { data_colecciones } from './data_colecciones';
//import { data_novedades } from './data_novedades';
//import { data_mas_vendidos } from './data_mas_vendidos';
//import { data_recomendados } from './data_recomendados';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild("formNewsLetter") formNewsLetter: ElementRef;
  fechaActual = moment().format('DD/MM/YYYY hh:mm A');
  usuario: any;
  shopcar: any;
  images: any[];
  thumbs: any[];
  listBanners: any = [];
  listSliders: any = [];
  coleccionItems: any[];
  coleccionData: any = [];
  recommends: any = [];
  newness: any = [];
  bestSellers: any = [];
  noCoverImg: string = '';
  authorNoCoverImg: string = '';
  validarInputNewsletter: boolean;
  forma: FormGroup;
  validacionNewsLetter: boolean;
  author: any = null;
  authorBooks: any = [];
  showSearchModal: boolean;
  showMobileModal: boolean;

  detailsProduct: any = [];
  addedToCart: any = {};
  updateInProgress: boolean = false;
  private windowScrolling: WindowScrolling;

  stateModalBlock: boolean = false;
  originModalBlock: any;

  /****** DATA HOME REDUX ******/
  // subscripcion al store
  loadSubscription: Subscription = new Subscription();

  // loaders
  cargando = false;
  cargaContenido = false;

  // error
  error: any = {};
  /****** END DATA HOME REDUX ******/

  /****** DATA NOVEDADES HOME REDUX ******/
  // loaders
  cargandoN = false;
  cargaContenidoN = false;

  // error
  errorN: any = {};
  /****** END DATA NOVEDADES HOME REDUX ******/

  /****** DATA MAS VENDIDOS HOME REDUX ******/
  // loaders
  cargandoMV = false;
  cargaContenidoMV = false;

  // error
  errorMV: any = {};
  /****** END DATA MAS VENDIDOS HOME REDUX ******/

  /****** DATA RECOMENDADOS HOME REDUX ******/
  // loaders
  cargandoR = false;
  cargaContenidoR = false;

  // error
  errorR: any = {};
  /****** END DATA RECOMENDADOS HOME REDUX ******/

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private productosService: ProductosService,
    private carritoService: CarritoService,
    private platformLocation: PlatformLocation,
    private usuarioService: UsuarioService,
    private storeService: StoreService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private store: Store<AppState>,
    windowScrolling: WindowScrolling,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    if (!localStorage.getItem('librosJson')) {
      this.getJSONData('_libros.json', 'librosJson');
    } else {
      let fecha_version = JSON.parse(localStorage.getItem('librosJson')).fecha_version.split(' ')[0].replace(/-/g, '');
      if (fecha_version != this.getDt()) {
        this.getJSONData('_libros.json', 'librosJson');
      }
    }

    if (!localStorage.getItem('ebooksJson')) {
      this.getJSONData('_ebooks.json', 'ebooksJson');
    } else {
      let fecha_version = JSON.parse(localStorage.getItem('ebooksJson')).fecha_version.split(' ')[0].replace(/-/g, '');
      if (fecha_version != this.getDt()) {
        this.getJSONData('_ebooks.json', 'ebooksJson');
      }
    }

    this.activeRouter.params.subscribe(params => {
      if ('block' in params) {
        if (params.block == '1') {
          this.stateModalBlock = false;
        }
      }
    });

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)

    this.windowScrolling = windowScrolling;
    this.loadSubscription = this.store.subscribe(({ appReducer, home, novedades, masvendidos, recomendados }) => {
      this.usuario = appReducer.user;
      this.shopcar = appReducer.shopcar;
      this.showMobileModal = appReducer.menuMobileState;
      this.showSearchModal = appReducer.menuMobileSearchState;

      this.error = home.error;
      this.cargando = home.loading;
      this.cargaContenido = home.loaded;

      try {
        if (Object.keys(home.data).length > 0) {
          this.oldEvent(home.data);
        }
      } catch (e) { }

      // novedades
      this.errorN = novedades.error;
      this.cargandoN = novedades.loading;
      this.cargaContenidoN = novedades.loaded;

      try {
        if (novedades.data.productos.length > 0) {
          this.newness = novedades.data.productos;
          this.carouselNovedades();
        }
      } catch (e) { }

      // mas vendidos
      this.errorMV = masvendidos.error;
      this.cargandoMV = masvendidos.loading;
      this.cargaContenidoMV = masvendidos.loaded;

      try {
        if (masvendidos.data.productos.length > 0) {
          this.bestSellers = masvendidos.data.productos;
          this.carouselMasVendidos();
        }
      } catch (e) { }

      // recomendados
      this.errorR = recomendados.error;
      this.cargandoR = recomendados.loading;
      this.cargaContenidoR = recomendados.loaded;

      try {
        if (recomendados.data.productos.length > 0) {
          this.recommends = recomendados.data.productos;
          this.carouselRecomendados();
        }
      } catch (e) { }
    });

  }

  private getJSONData(extname: string, localData: string) {
    let filename = this.getDt() + extname;
    this.productosService.downloadLibrosJson(filename).toPromise().then(res => {
      if (res != null) {
        localStorage.setItem(localData, JSON.stringify(res));
      } else {
        localStorage.setItem(localData, JSON.stringify({ data: [], fecha_version: '1981-07-27 00:00:00' }));
      }
    }).catch(error => console.warn('ERROR =====>>>>>', error));
  }

  oldEvent(response) {
    let autorDestadado = response['autor-recomendado'];
    let librosAutorDestadado = response['libros-autor-recomendado'];
    let sliderHome = response['sliders-home'];
    let bannersHome = response['banners-home'];
    let coleccionesHome = response['colecciones-para-ti'];

    if (autorDestadado) {
      if ('items' in autorDestadado) {
        if (autorDestadado['items'].length > 0) {
          this.author = autorDestadado['items'][0].relacionado;
        }
      }
    }

    if (librosAutorDestadado) {
      if (librosAutorDestadado.items) {
        if (librosAutorDestadado.items.length > 0 && this.authorBooks.length == 0) {
          let books = librosAutorDestadado.items;
          books.forEach(book => {
            if ('autores' in book.relacionado) {
              book.relacionado.autores.forEach(author => {
                try {
                  if (author.id == this.author.id) {
                    this.authorBooks.push(book.relacionado);
                  }
                } catch (e) { }
              });
            }
          });
        }
      }
    }

    if (sliderHome) {
      if (sliderHome.items.length > 0 && this.listSliders.length == 0) {
        for (let banner of sliderHome.items) {
          if (banner.relacionado) {
            this.listSliders.push(banner.relacionado);
          }
        }
        this.carouselHome();
      }
    }

    if (bannersHome) {
      if (bannersHome.items.length > 0 && this.listBanners.length == 0) {
        this.listBanners = bannersHome.items;
        let position = 'L';
        for (var i = 0; i < this.listBanners.length; ++i) {
          if ((i - 1) % 2 == 0) {
            if (position == 'L')
              position = 'C';
            else
              position = 'L';
          }
          this.listBanners[i].posicion = position;
        }
      }
    }

    if (coleccionesHome) {
      if (coleccionesHome.items.length > 0 && !this.coleccionData.id) {
        this.coleccionData = coleccionesHome;
        this.carouselColecciones();
      }
    }
  }

  private getDt(): string {
    let date = new Date();
    let YYYY = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString();
    if (+mm < 10) {
      mm = '0' + mm.toString();
    }
    let dd = date.getDate().toString();
    if (+dd < 10) {
      dd = '0' + dd.toString();
    }
    return YYYY + mm + dd;
  }

  ngOnDestroy() {
    $('.slider-for').slick('slickPause');
    $('.slider-nav').slick('slickPause');
    localStorage.invisibleSlide = true;

    this.loadSubscription.unsubscribe();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (localStorage.invisibleSlide) {
      $('.wrap-banners').addClass('invisible');
    }

    let dataMetas = {
      title: 'Librería nacional | compra tus libros en linea desde cualquier lugar',
      description: '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com' + this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';
    this.authorNoCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/autor.jpg';

    if (this.listSliders.length === 0) {
      let dataCrm = {
        slugs: ['sliders-home', 'banners-home', 'colecciones-para-ti', 'autor-recomendado', 'libros-autor-recomendado'],
      }

      this.store.dispatch(new AccionesHome.CargarColecciones(dataCrm));
      //this.store.dispatch(new AccionesHome.CargarColeccionesSuccess(data_colecciones));
    }

    // carga novedades
    if (this.newness.length === 0) {
      let dataCrmN = {
        categoria_slug: 'novedades',
        tipo: 'LIBRO',
        limite: 20,
        offset: 0
      };

      this.store.dispatch(new AccionesNovedadesHome.CargarNovedadesHome(dataCrmN));
      //this.store.dispatch(new AccionesNovedadesHome.CargarNovedadesHomeSuccess(data_novedades));
    }

    // carga mas vendidos
    if (this.bestSellers.length === 0) {
      let dataCrmMV = {
        categoria_slug: 'mas-vendidos',
        tipo: 'LIBRO',
        limite: 20,
        offset: 0
      };

      this.store.dispatch(new AccionesMasVendidosHome.CargarMasVendidosHome(dataCrmMV));
      //this.store.dispatch(new AccionesMasVendidosHome.CargarMasVendidosHomeSuccess(data_mas_vendidos));
    }

    // carga recomendados
    if (this.recommends.length === 0) {
      let dataCrmR = {
        categoria_slug: 'recomendados',
        tipo: 'LIBRO',
        limite: 20,
        offset: 0
      };

      this.store.dispatch(new AccionesRecomendadosHome.CargarRecomendadosHome(dataCrmR));
      //this.store.dispatch(new AccionesRecomendadosHome.CargarRecomendadosHomeSuccess(data_recomendados));
    }

    this.validateFormRegistro();
    this.carouselPublicaciones();

    //CAMBIAR DE ESTADO EL HEADER
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    });

    if (window.innerWidth < 991) {
      if (this.showMobileModal || this.showSearchModal) {
        this.windowScrolling.enable();
      }

      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false
      })
      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: false
      })
    }

  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  getMainAuthor(authors) {
    let author = '';
    if (authors.length > 0) {
      authors.forEach(obj => {
        if (obj.id == this.author.id) {
          author = obj.name;
        }
      });
      if (author == '') {
        authors.forEach(obj => {
          author = obj.name;
        });
      }
    }
    return author;
  }

  validateFormRegistro() {
    this.forma = new FormGroup({
      'correo': new FormControl('', [
        Validators.required,
        Validators.pattern('^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$')
      ]),
      'terminosCondiciones': new FormControl('', Validators.required),
      'captcha': new FormControl(null, Validators.required)
    })
  }

  /**
   * @description envia formulario action del news leatter a topleas antes esta integrado al crm con la funcion registroNewsletter
   */
  submitFormNewsLetter(){
    this.fechaActual = moment().format('DD/MM/YYYY hh:mm A');
    this.formNewsLetter.nativeElement.submit();
  }

  registroNewsletter() {
    // console.log(this.forma);
    if (this.forma.valid == true) {
      let dataFormNewsLetter = {
        correo: this.forma.controls['correo'].value,
        terminosCondiciones: this.forma.controls['terminosCondiciones'].value
      };
      this.usuarioService.createCaso(dataFormNewsLetter).subscribe(response => {
        if (response.id) {
          this.validacionNewsLetter = true;
        }
        this.validateFormRegistro();
      });
    } else {
      // console.log('invalid');
    }
  }

  resolved(event) {
    // console.log(this.forma);
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  productToAddDetail(id) {
    this.addProductCarrito(id);
  }

  addProductCarrito(id) {
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id: null,
      grupo_id: null,
      producto_id: id,
      cantidad: 1
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)
    }
  }

  addProductCarritoCrm(dataCrm) {
    this.carritoService.addProduct(dataCrm).subscribe((response: any) => {

      this.updateInProgress = false;

      if (response.hasErrors) {
        if (response.number == 2204) {
          // producto NO existe
        }
        else {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      }
      else {
        if (response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if (response.producto) {
          this.detailsProduct = response.producto;
        }

        if (response.factura.id) {
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }

      }

    })
  }

  getNewBill(dataCrm) {

    let user = this.usuario;
    let producCurrent = dataCrm[1]


    let dataCrmNF: any;
    if (user) {
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano

    this.carritoService.newFactura(dataCrmNF).subscribe((res: any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito) {
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        subtotal: this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }

  carouselHome() {
    let carousel = document.getElementsByClassName('slider-for');
    let carouselnav = document.getElementsByClassName('slider-nav');

    if (carousel && carouselnav && $) {
      $(function () {
        if (localStorage.invisibleSlide) {
          delete localStorage.invisibleSlide;
          setTimeout(() => $('.wrap-banners').removeClass('invisible'), 1000);
        }
        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: false,
          draggable: false,
          infinite: false,
          autoplay: true,
          autoplaySpeed: 5000,
          asNavFor: carouselnav ? '.slider-nav' : null
        });
        $('.slider-nav').slick({
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          asNavFor: carousel ? '.slider-for' : null,
          dots: false,
          centerMode: false,
          focusOnSelect: true,
          draggable: false,
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: false,
          pauseOnFocus: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        });
      });
    }

  }

  carouselNovedades() {
    this.newness.forEach(obj => {
      if (obj.promociones.length > 0) {
        let promociones = [];
        obj.promociones.forEach(promo => {
          promociones.push(promo.name);
        });
        obj.promocionesText = promociones.join(', ');
      }
    });
    $(function () {
      var navPrev = '<i class="icon-arrow-thin-right"></i>';
      var navNext = '<i class="icon-arrow-thin-left"></i>';
      $('.carousel-novedades').owlCarousel({
        items: 5,
        nav: true,
        margin: 0,
        dots: false,
        autoplay: true,
        navText: [navNext, navPrev],
        navContainer: '#nav-carousel-novedades',
        responsive: {
          320: {
            items: 2,
            nav: false,
            margin: 15,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-novedades'
          },
          768: { items: 3 },
          991: { items: 3 },
          1200: { items: 4 }
        }
      });
    });
  }

  carouselMasVendidos() {
    this.bestSellers.forEach(obj => {
      if (obj.promociones.length > 0) {
        let promociones = [];
        obj.promociones.forEach(promo => {
          promociones.push(promo.name);
        });
        obj.promocionesText = promociones.join(', ');
      }
    });
    $(function () {
      var navPrev = '<i class="icon-arrow-thin-right"></i>';
      var navNext = '<i class="icon-arrow-thin-left"></i>';
      $('.carousel-mas-vendidos').owlCarousel({
        items: 5,
        nav: true,
        margin: 0,
        dots: false,
        autoplay: true,
        lazyLoad: true,
        navText: [navNext, navPrev],
        navContainer: '#nav-carousel-mas-vendidos',
        responsive: {
          320: {
            items: 2,
            nav: false,
            margin: 15,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-mas-vendidos'
          },
          768: {
            items: 3
          },
          991: {
            items: 3
          },
          1200: {
            items: 4
          }
        }
      });
    });
  }

  carouselColecciones() {
    $(function () {
      var navPrev = '<i class="icon-arrow-thin-right"></i>';
      var navNext = '<i class="icon-arrow-thin-left"></i>';
      $('.carousel-colecciones').owlCarousel({
        items: 2,
        nav: true,
        margin: 15,
        loop: true,
        center: true,
        dots: false,
        autoplay: true,
        lazyLoad: true,
        responsive: {
          320: {
            items: 1,
            navText: [navNext, navPrev],
            navContainer: '#nav-carousel-colecciones',
            navClass: ['owl-prev shadow-sm', 'owl-next shadow-sm']
          },
          768: {
            items: 2
          },
          992: {
            items: 2
          },
          1200: {
            items: 2,
            nav: true,
            margin: 15,
            loop: true,
            center: true,
            dots: false,
            autoplay: true,
            lazyLoad: true,
            navText: [navNext, navPrev],
            navContainer: '#nav-carousel-colecciones',
            navClass: ['owl-prev shadow-sm', 'owl-next shadow-sm']
          }
        }
      });
    });
  }

  carouselRecomendados() {
    this.recommends.forEach(obj => {
      obj.rc_prom_calificacion = obj.rc_prom_calificacion == '' ? 1 : parseInt(obj.rc_prom_calificacion);
      /*obj.starts = ['icon-star-no-fill','icon-star-no-fill','icon-star-no-fill','icon-star-no-fill','icon-star-no-fill'];
      for (let index = 0; index < obj.rc_prom_calificacion; index++) {
        obj.starts[index] = 'icon-star-fill';
      }*/
    });
    $(function () {
      var navPrev = '<i class="icon-arrow-thin-right"></i>';
      var navNext = '<i class="icon-arrow-thin-left"></i>';
      $('.carousel-recomendados').owlCarousel({
        items: 3,
        nav: false,
        margin: 15,
        dots: false,
        loop: true,
        autoplay: true,
        lazyLoad: true,
        responsive: {
          320: {
            items: 1,
            nav: false,
            dots: true,
            autoplay: false,
            dotsContainer: '#dots-carousel-recomendados'
          },
          768: {
            items: 2,
            nav: false,
            dots: true,
            autoplay: false,
            dotsContainer: '#dots-carousel-recomendados'
          },
          992: {
            items: 3,
            nav: false,
            dots: false,
            autoplay: false,
            dotsContainer: '#dots-carousel-recomendados'
          },
          1200: {
            items: 3,
            nav: false,
            margin: 15,
            dots: false,
            loop: true,
            autoplay: false,
            lazyLoad: true,
            navText: ['', navPrev],
            navContainer: '#nav-carousel-recomendados',
            navClass: ['owl-prev', 'owl-next shadow-sm'],
          }
        }
      });
    });
  }

  carouselPublicaciones() {
    $(function () {
      $('.carousel-publicaciones').owlCarousel({
        items: 3,
        nav: false,
        dots: false,
        margin: 15,
        lazyLoad: true,
        responsive: {
          320: {
            items: 1,
            nav: false,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-publicaciones'
          },
          768: {
            items: 2,
            nav: false,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-publicaciones'
          },
          991: {
            items: 3,
            nav: false,
            dots: false,
            autoplay: true,
            dotsContainer: '#dots-carousel-publicaciones'
          }
        }
      });
    });
  }
}
