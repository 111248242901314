import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { UsuarioService } from '../../../services/usuario.service';
import { CarritoService } from '../../../services/carrito.service';
import { Usuario, Direccion } from '../../../clases/index';
import { StoreService } from '../../../services/store.service';
import { ACTION_HEADER_STATE, ACTION_LOGIN, ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from '../../../store/actions/appAction';
import { UPDATE_BUSCADOR } from '../../../store/actions/appAction';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { expresionTelefono } from 'src/environments/global';

@Component({
	selector: 'app-registro',
	templateUrl: './registro.component.html',
	styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit, OnDestroy, AfterViewInit {
  forma: FormGroup;
  usuario: any = {};
  usuarioRdx:any;
  shopcarRdx:any;
  image_profile:any;
  dir: boolean = false;
  terms: boolean = false;
  direccion: Direccion = new Direccion();
  invalidRegisterState: boolean = false;
  invalidRegisterMessages: String = '';
  tiposDocumento: any = [
  {
    value: 'CC',
    label: 'Cédula de ciudadanía'
  },
  {
    value: 'PASAPORTE',
    label: 'Pasaporte'
  },
  {
    value: 'NIT',
    label: 'NIT'
  }];

  isSocialLogin: boolean = false;

  constructor(
    private meta: Meta,
    private router: Router,
    private store: Store<any>,
    private titleService: Title,
    private storeService: StoreService,
    private socialAuthService: SocialAuthService,
    private usuarioService: UsuarioService,
    private carritoService : CarritoService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

	ngOnInit(){
    //SCROLL TOP
    window.scrollTo(0,0);
    this.cargarUsuario();
    this.validateFormRegistro();

    let dataMetas = {
      title: 'Regístrate',
      description: 'Regístrate ahora para que te enteres de los últimos lanzamientos de la literatura.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    })

    this.store.subscribe(response => {
      this.usuarioRdx = response.appReducer.user;
      this.shopcarRdx = response.appReducer.shopcar;
    });
	}

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  ngAfterViewInit(){
    setTimeout( () => {
      //CAMBIAR DE ESTADO EL HEADER
      this.storeService.updateAppState({
        action: ACTION_HEADER_STATE,
        payload: false
      })

    })
  }

  ngOnDestroy(){
    //REMOVER USUARIO LOCAL
    if (this.usuario){
      if (!this.usuario.sessionCrm){
        this.usuarioService.deleteUserLocal();
      }
    }

  }

  cargarUsuario(){
    if (this.usuarioService.getUserLocal())
    {
      // this.usuario = this.usuarioService.getUserLocal();
    }
    else {
      this.usuario = {
        login: false,
        provider: 'email'
      };
    }
  }

  validateFormRegistro(){
    this.forma = new FormGroup({
      'email'					  : new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ]),
      'nombres'				      : new FormControl('', Validators.required),
      'apellidos'				    : new FormControl('', Validators.required),
      'tipo_identificacion'	: new FormControl('', Validators.required),
      'identificacion'      : new FormControl('',[Validators.required, Validators.minLength(6), Validators.pattern(/^[0-9a-z\-A-Z]+$/)]),
      'telefono'				    : new FormControl('',[
        Validators.minLength(7),
        Validators.maxLength(20),
        Validators.pattern(expresionTelefono)
      ]),
      'telefono_movil'      : new FormControl('',[
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(20),
        Validators.pattern(expresionTelefono)
      ]),
      'contrasena'			    : new FormControl(),
      'repeatContrasena'    : new FormControl(),
      'terminosCondiciones'	: new FormControl('', Validators.required),
    });

    //SETEAR ESTAS VALIDACIONES ÚNICAMENTE CUANDO SEA UN REGISTRO CON USUARIO Y CONTRASEÑA
    if (!this.usuario || this.usuario.provider == 'email'){
      this.forma.controls['contrasena'].setValidators([
        Validators.required,
        Validators.minLength(6)
      ]);
      this.forma.controls['repeatContrasena'].setValidators([
        Validators.required,
        this.passwordIgual.bind(this.forma)
      ]);
    }
  }

  passwordIgual(control:FormControl): { [s:string]: boolean }{
    let forma : any = this;
    if (control.value !== forma.controls['contrasena'].value){
      return {
        passwordigual: true
      }
    }
    return null;
  }

  registroUsuario(){
    //SET MENSAJE DE ERROR EN CERO
    this.invalidRegisterState = false;
    if (this.forma.valid == true){
      //FORMULARIO VALIDADO CORRECTAMENTE
      let nuevoUsuario = {
        email				        : this.forma.controls['email'].value,
        nombres			        : this.forma.controls['nombres'].value,
        apellidos		        : this.forma.controls['apellidos'].value,
        telefono_movil      : this.forma.controls['telefono_movil'].value,
        telefono		        : this.forma.controls['telefono'].value,
        pass				        : this.forma.controls['contrasena'] ? this.forma.controls['contrasena'].value : null,
        repass			        : this.forma.controls['repeatContrasena'] ? this.forma.controls['repeatContrasena'].value : null,
        identificacion      : this.forma.controls['identificacion'].value,
        identificacion_tipo : this.forma.controls['tipo_identificacion'].value,
        fb_token			        : this.usuario ? this.usuario.tokenFb : null,
        g_token			        : this.usuario ? this.usuario.tokenGo : null,
        g_access_token      : this.usuario ? this.usuario.g_access_token : null,
        fb_id				        : this.usuario ? this.usuario.idSocialFb : null,
        g_id				        : this.usuario ? this.usuario.idSocialGo : null,
      };

      if(this.usuario.imagen){
        nuevoUsuario['photo_file'] = this.usuario.imagen;
      }
      this.usuarioService.createUser(nuevoUsuario).subscribe(response => {
        if (response.id) {
          this.usuario.login = true;
          this.usuario.idCrm = response.id;
          this.usuario.sessionCrm = response.id;
          this.usuario.provider = this.usuario.provider ? this.usuario.provider : 'email';
          let dataCrmUser = {
            session_id: response.id
          };
          this.usuarioService.setRegisterFull(this.usuario.sessionCrm).subscribe((response:any) => {
            if(response.status == 'ok'){
              this.usuarioService.getUser(dataCrmUser).subscribe(resp => {
                if (resp.status == 'ok') {
                  this.usuario.userId = resp.config.id;
                  this.usuario.clienteId = resp.cuenta.cliente_id;
                  this.usuario.tokenCrm = resp.config.token;
                  this.usuario.estado_registro = resp.config.estado_registro;
                  this.usuario.estado_login = resp.config.estado_login;
                  this.usuario.nombres = resp.config.nombres;
                  this.usuario.apellidos = resp.config.apellidos;
                  this.usuario.tipo_identificacion = resp.config.tipo_identificacion;
                  this.usuario.identificacion = resp.config.identificacion;
                  this.usuario.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
                  this.usuario.email = resp.config.email;
                  this.usuario.telefono = resp.config.telefono;
                  this.usuario.telefono_movil = resp.cuenta.telefono_movil;

                  //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
                  this.usuarioService.setUserLocal(this.usuario);
                  this.storeService.updateAppState({ action: ACTION_LOGIN, payload: this.usuario });

                  //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
                  let carritoLS = this.carritoService.getCarritoLocal();
                  if (carritoLS && (this.shopcarRdx.cliente_user_id == '' || this.shopcarRdx.cliente_user_id == this.usuarioRdx.userId) ){
                    //MERGE DE CARRITOS CUANDO SI HAY LOCAL
                    let dataCrmMergeCarrito = [
                      this.usuario.sessionCrm,
                      {
                        factura_merge_id  : carritoLS
                      }
                    ];
                    this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
                      if(response.id){
                        this.updateCarritoLocal(response);
                        this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
                      }
                      else
                      {
                        this.carritoService.deleteCarritoLocal()
                        this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
                      }
                    })
                  } else {
                    let dataCrmCarrito = {
                      session_id : this.usuario.sessionCrm
                    };
                    this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
                      if(dataCarrito.id){
                        this.updateCarritoLocal(dataCarrito);
                        this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
                      }
                    });
                  }
                  this.router.navigate(['/', 'mi-cuenta', 'datos-personales'], { queryParams: { origen: 'registro' } });
                }
              })
            } else if(response.number == 10){
              this.invalidRegisterState = true;
              this.invalidRegisterMessages = 'Ha ocurrido un error en el proceso de registro: "'+response.error+'" inténtalo nuevamente.';
            }
          });
        }
        else if (response.error) {
          //MENSAJE DE ERROR EN TRUE
          this.invalidRegisterState = true;
          this.invalidRegisterMessages = 'Ha ocurrido un error en el proceso de registro: "'+response.error+'" inténtalo nuevamente.';
        } else {
          // MENSAJE DE ERROR EN TRUE
          this.invalidRegisterState = true;
          this.invalidRegisterMessages = 'Ha ocurrido un error en el proceso de registro, por favor inténtalo nuevamente.';
        }
      });
    } else {
      //ERROR CUANDO SE ENCUENTRA UN CAMPO NO VALIDO
      this.forma.controls['email'].markAsTouched();
      this.forma.controls['nombres'].markAsTouched();
      this.forma.controls['apellidos'].markAsTouched();
      this.forma.controls['tipo_identificacion'].markAsTouched();
      this.forma.controls['identificacion'].markAsTouched();
      this.forma.controls['telefono'].markAsTouched();
      this.forma.controls['telefono_movil'].markAsTouched();
      this.forma.controls['contrasena'].markAsTouched();
      this.forma.controls['repeatContrasena'].markAsTouched();
      this.forma.controls['terminosCondiciones'].markAsTouched();

      return false;
    }
  }

  public socialSignIn(socialPlatform : string){
    let socialPlatformProvider;
    if(socialPlatform == "facebook"){
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if(socialPlatform == "GOOGLE"){
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.isSocialLogin = true;
        this.forma.controls['email'].setValue(userData.email);
        this.forma.controls['nombres'].setValue(userData.name);

        //SETEAR VALIDACIÓN DEL PASSWORD
        this.forma.controls['contrasena'].clearValidators();
        this.forma.controls['contrasena'].updateValueAndValidity();
        this.forma.controls['repeatContrasena'].clearValidators();
        this.forma.controls['repeatContrasena'].updateValueAndValidity();

        this.usuario = {};
        this.usuario.login = false;
        this.usuario.email = userData.email;
        this.usuario.nameSocial = userData.name;
        this.usuario.idSocialGo = userData.provider == 'GOOGLE' ? userData.id : null;
        this.usuario.idSocialFb = userData.provider == 'FACEBOOK' ? userData.id : null;
        this.usuario.tokenGo = userData.provider == 'GOOGLE' ? userData.idToken : null;
        this.usuario.tokenFb = userData.provider == 'FACEBOOK' ? userData.authToken : null;
        this.usuario.g_access_token = userData.provider == 'GOOGLE' ? userData.authToken : null;
        this.usuario.provider = userData.provider;
        this.usuario.imagen = userData.photoUrl ? userData.photoUrl : 'avatar.jpg';
      
        this.usuarioService.setLogin(userData).subscribe(response => {
          if (response.number == 2010){
            // this.router.navigate(['/', 'usuario', 'registrarme']);
          } else if (response.id) {
            this.loginUserCrmSuccess(response);
          }
        })
      }
    )
  }

  loginUserCrmSuccess(response){
    let currentUser = new Usuario();
    currentUser = this.usuario;
    currentUser.login = true;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    this.usuarioService.setRegisterFull(currentUser.sessionCrm).subscribe((response:any) => {
      if(response.status == 'ok') {
        //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
        this.usuarioService.getUser(dataCrm).subscribe(resp => {
          if (resp.status == 'ok'){
            currentUser.sessionCrm = currentUser.sessionCrm;
            currentUser.userId = resp.config.id;
            currentUser.clienteId = resp.cuenta.cliente_id;
            currentUser.tokenCrm = resp.config.token;
            currentUser.estado_registro = resp.config.estado_registro;
            currentUser.estado_login = resp.config.estado_login;
            currentUser.nombres = resp.config.nombres;
            currentUser.apellidos = resp.config.apellidos;
            currentUser.tipo_identificacion = resp.config.tipo_identificacion;
            currentUser.identificacion = resp.config.identificacion;
            currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
            currentUser.email = resp.config.email;
            currentUser.telefono = resp.config.telefono;
            currentUser.telefono_movil = resp.cuenta.telefono_movil;

            //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
            this.usuarioService.setUserLocal(currentUser);
            this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

            //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
            let carritoLS = this.carritoService.getCarritoLocal();
            if (carritoLS && (this.shopcarRdx.cliente_user_id == '' || this.shopcarRdx.cliente_user_id == this.usuarioRdx.userId) ){
              //MERGE DE CARRITOS CUANDO SI HAY LOCAL
              let dataCrmMergeCarrito = [
                currentUser.sessionCrm,
                {
                  factura_merge_id  : carritoLS
                }
              ];
              this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
                if(response.id){
                  this.updateCarritoLocal(response);
                  this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
                }
                else
                {
                  this.carritoService.deleteCarritoLocal()
                  this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
                }
              })
            } else {
              let dataCrmCarrito = {
                session_id : currentUser.sessionCrm
              };
              this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
                if(dataCarrito.id){
                  this.updateCarritoLocal(dataCarrito);
                  this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
                }
              });
            }
            this.router.navigate(['/', 'mi-cuenta', 'datos-personales']);
          }
        });
      }
    });
  }

  updateCarritoLocal(dataCarrito) {
    this.carritoService.setCarritoLocal(dataCarrito);
  }
}
