import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorService } from '../../services/author.service';
import { Location, PlatformLocation } from '@angular/common';
import { ProductosService } from '../../services/productos.service';
import { ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../store/actions/appAction";
import { FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { StoreService } from "../../services/store.service";
import { UsuarioService } from "../../services/usuario.service";
import { CarritoService } from "../../services/carrito.service";
import { CapitalizeFirstPipe } from "../../pipes/capitalizefirst.pipe";
import { ColeccionesService } from "../../services/colecciones.service";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
declare var $:any;

@Component({
  selector: 'app-perfil-autor',
  templateUrl: './perfil-autor.component.html',
  styleUrls: ['./perfil-autor.component.css']
})
export class PerfilAutorComponent implements OnInit {

  author: any = null;
  authorBooks: any = [];
  noCoverImg: string = '';
  authorNoCoverImg: string = '';

  usuario:any;
  shopcar:any;
  busqueda: any = false
  addedToCart: any = {};
  detailsProduct: any = [];
  updateInProgress: boolean = false;
  active: number;
  icon:string = 'empty';
  status:any = false;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;
  constructor(
    private authorServices: AuthorService,
    private meta:Meta,
    private router:Router,
    private store: Store<any>,
    private location:Location,
    private titleService:Title,
    private storeService:StoreService,
    private activatedRoute:ActivatedRoute,
    private usuarioService:UsuarioService,
    private carritoService:CarritoService,
    private capitalizeFirst:CapitalizeFirstPipe,
    private productosService:ProductosService,
    public  platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';
    this.authorNoCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/autor.jpg';

    this.activatedRoute.params.subscribe(params => {

      let authorSlug = params.detalle;

      if(authorSlug != undefined && authorSlug != '') {

        this.authorServices.autor_get(authorSlug).toPromise().then( (res:any) => {
          if(res != null) {
            if('id' in res) {
              this.author = res;

              if(this.author != null) {
                this.productosService.productosListByAutor({autor_id:this.author.id}).toPromise().then( (res: any) => {
                  if(res != null) {
                    let books = res;

                    if(res.length <= 0) {
                      this.authorBooks = []
                      this.carouselLibrosAuthor()
                    }

                    books.forEach( book => {
                      if(book.promociones.length > 0) {
                        let promociones = [];
                        book.promociones.forEach(promo => {
                          promociones.push(promo.name);
                        });
                        book.promocionesText = promociones.join(', ');
                      }
                      if('autores' in book) {
                        book.autores.forEach( author => {
                          if(author.id == this.author.id) {
                            this.authorBooks.push(book);
                            this.carouselLibrosAuthor();
                          }
                        });
                      }
                    });
                  }
                });
              }
            } else {
              this.router.navigate(['/', '404']);
            }
          }
        });
      }

    });

    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
  }

  getMainAuthor(authors) {
    let author = '';
    if(authors.length > 0) {
      authors.forEach( obj => {
        if(obj.id == this.author.id) {
          author = obj.name;
        }
      });
      if(author == '') {
        authors.forEach( obj => {
          author = obj.name;
        });
      }
    }
    return author;
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  productToAddDetail(id) {
    this.addProductCarrito(id);
  }

  addProductCarrito(id){
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id     : null,
      grupo_id       : null,
      producto_id    : id,
      cantidad       : 1
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id){
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {

      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)

    }
  }

  addProductCarritoCrm(dataCrm)
  {
    this.carritoService.addProduct(dataCrm).subscribe((response:any) => {

      this.updateInProgress = false;

      if(response.hasErrors) {
        if(response.number == 2204) {
          // producto NO existe
        }
        else
        {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      }
      else
      {
        if(response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if(response.producto) {
          this.detailsProduct = response.producto;
        }

        if(response.factura.id){
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      }
    })
  }

  getNewBill(dataCrm)
  {

    let user = this.usuario;
    let producCurrent = dataCrm[1]

    let dataCrmNF:any;
    if(user){
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res:any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito){
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        //subtotal:   this.shopcar.totales.subtotal,
        subtotal:   this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  carouselLibrosAuthor(){
    $(function(){
      $('.carousel-libros-author').owlCarousel('destroy');
      $('.carousel-libros-author').owlCarousel({
        items: 5,
        nav: false,
        margin: 15,
        dots: false,
        autoplay: false,
        lazyLoad: true,
        responsive:{
          320: {
            items:2,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-libros-author'
          },
          768: {
            items:3,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-libros-author'
          },
          991: {
            items:3,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-libros-author'
          },
          1200: {
            items:4,
            nav: false
          }
        }
      });
    });
  }
}
