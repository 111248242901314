import { UPDATE_MENU_MOBILE_SEARCH, UPDATE_MENU_MOBILE, ACTION_LOADMENU, ACTION_LOGIN, ACTION_LOGOUT, ACTION_HEADER_STATE, ACTION_SHOPCAR, ACTION_ADDRESS, ACTION_UPDATE, ACTION_PAYMENT_ORDER, ACTION_SHOPCAR_COPY, ACTION_PAYMENT_RESPONSE, UPDATE_BUSCADOR, SET_SEDES, GET_MIS_BONOS, UPDATE_TOTALS_SHOPCAR, ADD_FAVORITOS, ACTION_DEL_SHOPCAR, ACTION_SET_DIR_SHOPCAR, ACTION_SET_REGISTER_FULL, LOAD_SHOPCAR  } from '../actions/appAction';

const initialState = {
	menus: {},
	user: {},
	shopcar: {},
	loadShopcar: false,
	address: {},
	payment_order: {},
	payment_response: {},
	shopcar_copy: {},
	modalLogin: false,
	modalAddCarrito: false,
	searchState: false,
  	headerFooterState: true,
	menuMobileSearchState: false,
	menuMobileState: false,
	sedes: [],
	misBonos: [],
};

export function AppReducer (state = initialState, action) {
	switch (action.type) {
		case ACTION_LOADMENU:
			return {
				...state,
				menus: action.payload
			};
		case ACTION_LOGIN:
			return {
				...state,
				user: action.payload
			};
		case ACTION_SET_REGISTER_FULL:
			return {
				...state,
				user: action.payload
			};
		case ACTION_LOGOUT:
			return {
				...state,
				user: {},
				shopcar: {},
				address: {},
				payment_order: {},
				payment_response: {},
				shopcar_copy: {},
				modalLogin: false,
				modalAddCarrito: false,
				searchState: false,
				headerFooterState: true,
				menuMobileSearchState: false,
				menuMobileState: false,
				misBonos: [],
			};
		case ACTION_HEADER_STATE:
			return {
				...state,
        		headerFooterState: action.payload
			};
		case ACTION_SHOPCAR:
			return {
				...state,
				shopcar: action.payload
			};
		case LOAD_SHOPCAR:
			return {
				...state,
				loadShopcar: action.payload
			};
		case ACTION_DEL_SHOPCAR:
			return {
				...state,
				shopcar: {}
			};
		case ACTION_SET_DIR_SHOPCAR:
			return state
		case UPDATE_TOTALS_SHOPCAR: {
			return {
				...state,
				shopcar: action.payload
			};
		}
		case ACTION_ADDRESS:
			return {
				...state,
				address: action.payload
			};
		case ACTION_PAYMENT_ORDER:
			return {
				...state,
				payment_order: action.payload
			};
		case ACTION_SHOPCAR_COPY:
			return {
				...state,
				shopcar_copy: action.payload
			};
		case ACTION_UPDATE:
			let currentUser :any = state.user;
			currentUser.name = action.payload.name;
			currentUser.nombres = action.payload.nombres;
			currentUser.apellidos = action.payload.apellidos;
			currentUser.tipo_identificacion = action.payload.tipo_identificacion;
			currentUser.identificacion = action.payload.identificacion;
			currentUser.telefono = action.payload.telefono;
			currentUser.telefono_movil = action.payload.telefono_movil;
			currentUser.fecha_nacimiento = action.payload.fecha_nacimiento;
			return {
		        ...state,
		        user: currentUser
		      };
		case UPDATE_BUSCADOR:
			return {
				...state,
				searchState: action.payload
			};
		case UPDATE_MENU_MOBILE:
			return {
				...state,
				menuMobileState: action.payload
			};
		case UPDATE_MENU_MOBILE_SEARCH:
			return {
				...state,
				menuMobileSearchState: action.payload
			};
		case ACTION_PAYMENT_RESPONSE:
			return {
				...state,
				payment_response: action.payload
			};
		case SET_SEDES:
			return {
				...state,
				sedes: action.payload
			};
		case GET_MIS_BONOS:
			return {
				...state,
				misBonos: action.payload
			};
		default:
			return state
	}
}
