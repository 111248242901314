import { Component, OnInit, AfterContentChecked, OnDestroy } from '@angular/core';
import { Store, State } from "@ngrx/store";
import { Location, PlatformLocation } from "@angular/common";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from "../../../services/usuario.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Usuario } from "../../../clases";
import { CarritoService } from "../../../services/carrito.service";
import { StoreService } from "../../../services/store.service";
import { WindowScrolling } from '../../../services/window-scrolling.service';
import { ACTION_LOGIN, ACTION_UPDATE } from "../../../store/actions/appAction";
import { UPDATE_BUSCADOR, UPDATE_MENU_MOBILE, UPDATE_MENU_MOBILE_SEARCH } from '../../../store/actions/appAction';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { expresionTelefono } from 'src/environments/global';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.css']
})

export class DatosPersonalesComponent implements OnInit, OnDestroy, AfterContentChecked {
  session:any;
  usuario:any;
  messageEdit:any;
  forma: FormGroup;
  formaPass: FormGroup;
  updateMessage:string = '';
  updateMessagePass:string = '';
  editAction:string='editar';
  submitVisible:boolean=false;
  editActionPass='editar';
  submitVisiblePass:boolean=false;
  tiposDocumento: any = [
  {
    value: 'CC',
    label: 'Cédula de ciudadanía'
  },
  {
    value: 'PASAPORTE',
    label: 'Pasaporte'
  },
  {
    value: 'NIT',
    label: 'NIT'
  }];

  private windowScrolling: WindowScrolling;

  showSearchModal: boolean;
  showMobileModal: boolean;

  constructor(
    public router:Router,
    private store: Store<any>,
    private state: State<any>,
    private location:Location,
    private route: ActivatedRoute,
    private storeService:StoreService,
    public carritoService:CarritoService,
    public usuarioService:UsuarioService,
    public platformLocation: PlatformLocation,
    windowScrolling: WindowScrolling,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
    this.windowScrolling = windowScrolling;
  }

  ngOnInit(){
    this.formaValidate();
    this.formaPassValidate();
    this.setFormaPassReadOnly();
    this.store.subscribe(response => {
      this.showMobileModal = response.appReducer.menuMobileState;
      this.showSearchModal = response.appReducer.menuMobileSearchState;
      this.usuario = response.appReducer.user;
      this.formaFillData();
      this.setFormaReadOnly();
    });

    if(this.route.snapshot.queryParamMap.get('message')){
      this.messageEdit = this.route.snapshot.queryParamMap.get('message');
    }

    //CAMBIAR DE ESTADO EL HEADER
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    });

    if(window.innerWidth < 991){
      if(this.showMobileModal || this.showSearchModal){
        this.windowScrolling.enable();
      }

      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false
      });

      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: false
      })
    }
  }

  ngOnDestroy(){
    this.submitVisible=false;
  }

  ngAfterContentChecked(){}

  formaPassValidate(){
    this.formaPass = new FormGroup({ 'contrasena' : new FormControl(), 'repeatContrasena' : new FormControl() });
    this.formaPass.controls['contrasena'].setValidators([ Validators.required, Validators.minLength(6) ]);
    this.formaPass.controls['repeatContrasena'].setValidators([ Validators.required, this.passwordIgual.bind(this.formaPass) ]);
  }

  passwordIgual(control:FormControl): { [s:string]: boolean }{
    let form : any = this;
    if (control.value !== form.controls['contrasena'].value){
      return { passwordigual: true }
    }
    return null;
  }

  formaValidate(){
    this.forma = new FormGroup({
      'email'					      : new FormControl({value:'', disabled:true},[ Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
      'fecha_nacimiento'	  : new FormControl(''),
      'nombres'				      : new FormControl('', Validators.required),
      'apellidos'				    : new FormControl('', Validators.required),
      'tipo_identificacion'	: new FormControl('', Validators.required),
      'identificacion'      : new FormControl('',[Validators.required, Validators.minLength(6), Validators.pattern(/^[0-9a-z\-A-Z]+$/)]),
      'telefono'				    : new FormControl('',[
        Validators.minLength(7),
        Validators.maxLength(20),
        Validators.pattern(expresionTelefono)
      ]),
      'telefono_movil'      : new FormControl('',[
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(20),
        Validators.pattern(expresionTelefono)
      ]),
      //'contrasena'			  : new FormControl(),
      //'repeatContrasena'  : new FormControl(),
    });
  }

  formaFillData(){
    if (this.usuario){
      this.forma.controls['nombres'].setValue(this.usuario.nombres);
      this.forma.controls['apellidos'].setValue(this.usuario.apellidos);
      this.forma.controls['tipo_identificacion'].setValue(this.usuario.tipo_identificacion);
      this.forma.controls['fecha_nacimiento'].setValue(this.usuario.fecha_nacimiento);
      this.forma.controls['identificacion'].setValue(this.usuario.identificacion);
      let phone = '';
      // if(this.usuario.telefono) {
      //   phone = this.usuario.telefono.substr(0, 7);
      // }
      this.forma.controls["telefono"].setValue(this.usuario.telefono);
      let mobilephone = '';
      // if(this.usuario.telefono_movil) {
      //   mobilephone = this.usuario.telefono_movil.substr(0, 10);
      // }
      this.forma.controls['telefono_movil'].setValue(this.usuario.telefono_movil);
      this.forma.controls['email'].setValue(this.usuario.email);
    }
  }

  actualizarPassword(){
    if(this.formaPass.valid == true){
      let data = {
        email  : this.usuario.email,
        session: this.usuario.sessionCrm,
        pass   : this.formaPass.controls['contrasena'].value,
        repass : this.formaPass.controls['repeatContrasena'].value
      };
      this.usuarioService.updateUserPassLogin(data).subscribe((response:any) => {
        if(response === true){
          this.setFormaPassReadOnly();
          this.updateMessagePass = 'La contraseña ha sido actualizada.';
        } else if(response.number =='1' || response.number == 1 ){
          this.updateMessagePass = response.description;
        }
      });
    } else {
      this.formaPass.controls['contrasena'].markAsTouched();
      this.formaPass.controls['repeatContrasena'].markAsTouched();
      return false;
    }
  }

  actualizarDatosCuenta(){
    if (this.forma.valid == true){
      let updateUsuario = {
        session_id          : this.usuario.sessionCrm,
        nombres				      : this.forma.controls['nombres'].value,
        telefono			      : this.forma.controls['telefono'].value,
        apellidos			      : this.forma.controls['apellidos'].value,
        fecha_nacimiento	  : this.forma.controls['fecha_nacimiento'].value,
        telefono_movil      : this.forma.controls['telefono_movil'].value,
        tipo_identificacion : this.forma.controls['tipo_identificacion'].value,
        identificacion		  : this.forma.controls['identificacion'].value
      };

      this.usuarioService.updateCliente(updateUsuario).subscribe(response => {
        if (response.cliente_id){
          let currentUser = new Usuario();
          currentUser.name                = response.name;
          currentUser.nombres             = response.nombres;
          currentUser.apellidos           = response.apellidos;
          currentUser.tipo_identificacion = response.tipo_identificacion;
          currentUser.identificacion      = response.identificacion;
          currentUser.fecha_nacimiento    = response.fecha_nacimiento;
          currentUser.telefono            = response.telefono;
          currentUser.telefono_movil      = response.telefono_movil;
          this.storeService.updateAppState({ action: ACTION_UPDATE, payload: currentUser });
          this.updateMessage = 'Se han actualizado los datos correctamente';
        } else if (response.error) {
          this.updateMessage = 'Ha ocurrido un error, no se han podido actualizar los datos: "'+response.error+'" inténtalo nuevamente.';
        }
      });
    } else {
      //ERROR CUANDO SE ENCUENTRA UN CAMPO NO VALIDO
      this.forma.controls['email'].markAsTouched();
      this.forma.controls['fecha_nacimiento'].markAsTouched();
      this.forma.controls['nombres'].markAsTouched();
      this.forma.controls['apellidos'].markAsTouched();
      this.forma.controls['tipo_identificacion'].markAsTouched();
      this.forma.controls['identificacion'].markAsTouched();
      this.forma.controls['telefono'].markAsTouched();
      this.forma.controls['telefono_movil'].markAsTouched();
      return false;
    }
  }

  setFormaReadOnly(){
    this.editAction='editar';
    this.submitVisible=false;
    for (const field in this.forma.controls) { // 'field' is a string
      this.forma.get(field).disable(); // 'control' is a FormControl
    }
    this.formaFillData();
    /*
      Object.keys(this.forma.controls).forEach(key => {
        this.forma.get(key).disable();
      });
    */
  }

  setFormaPassReadOnly(){
    this.editActionPass='editar';
    this.submitVisiblePass=false;
    for (const field in this.formaPass.controls) { // 'field' is a string
      this.formaPass.get(field).disable(); // 'control' is a FormControl
    }
    this.formaPass.controls['contrasena'].setValue('');
    this.formaPass.controls['repeatContrasena'].setValue('');
  }

  setFormaEditable(){
    this.editAction='cancelar';
    this.updateMessage = '';
    this.submitVisible=true;
    for (const field in this.forma.controls) { // 'field' is a string
      if(field != 'email') {
        this.forma.get(field).enable(); // 'control' is a FormControl
      }
    }
    /*
    Object.keys(this.forma.controls).forEach(key => {
      if(key != 'email'){
        this.forma.get(key).enable();
      }
    });
    */
  }

  setFormaPassEditable(){
    this.editActionPass='cancelar';
    this.updateMessagePass = '';
    this.submitVisiblePass=true;
    for (const field in this.formaPass.controls) { // 'field' is a string
      if(field != 'email') {
        this.formaPass.get(field).enable(); // 'control' is a FormControl
      }
    }
  }

  openLoginView(){
    this.usuarioService.updateLoginModalState(true);
    this.usuarioService.updateLoginModalOrigin('datos-personales');
  }

  goToLoginView(){
    this.router.navigate(['/', 'usuario', 'ingresar'],{ queryParams: { origin: 'datos-personales' }, skipLocationChange:true } );
  }
}
