import { Component, OnInit, OnDestroy } from '@angular/core';
import { CarritoService } from 'src/app/services/carrito.service';
import { Store } from '@ngrx/store';
import { PlatformLocation } from '@angular/common';
import { concat, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { CargarOrdenes, CargarOrdenesFail, CargarOrdenesSuccess, CerrarOrdenes } from 'src/app/store/ordenes/actions';

@Component({
  selector: 'app-historial-ordenes',
  templateUrl: './historial-ordenes.component.html',
  styleUrls: ['./historial-ordenes.component.css']
})
export class HistorialOrdenesComponent implements OnInit, OnDestroy {

  user: any = null;
  ordenes: any = [];
  noCoverImg: string = '';
  bonoImage : string = '';
  bonoVirtualImage : string = '';

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  susc: Subscription = new Subscription();

  cargando: boolean = false;

  constructor(
    private router: Router,
    private carritoService: CarritoService,
    private store: Store<any>,
    private platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnDestroy(): void {
    this.susc.unsubscribe();
    this.store.dispatch(new CerrarOrdenes());
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';

    this.susc = this.store.subscribe(store => {
      this.ordenes = store.ordenes.data;
      this.cargando = store.ordenes.loading;
      this.user = store.appReducer.user;
      if(Object.keys(this.user)){
        if(this.user) {
          if('estado_login' in this.user) {
            if(this.user.estado_login.toLowerCase() != 'parcial') {
              if(!Object.keys(store.ordenes.data).length && !this.cargando) {
                this.getHistorialOrdenes();
                // se quita por ahora, presenta fallas y se hizo un llamado al servicio desde el componente
                // this.store.dispatch(new CargarOrdenes([this.user.sessionCrm, {}]));
              }
            }
          }
        }
      }
    });
  }

  getHistorialOrdenes(){
    this.carritoService.get_billList([this.user.sessionCrm, {}]).subscribe((response:any) => {
      if (response && response.length) {
        this.store.dispatch(new CargarOrdenesSuccess(response));
      } else if(response.hasErrors){
        this.store.dispatch(new CargarOrdenesFail(response));
      }
    });
  }

  toggleBillDetails(bill) {

    if(!bill.products){
      bill.products = [];
    }
    if(!bill.guias){
      bill.guias = [];
    }

    if(!bill.showDetails) {
      if(!bill.products.length){
        this.carritoService.getFactura([null,{factura_id:bill.id}]).toPromise().then( (res: any) => {
          if(res != null) {
            if(res.id) {
              if('grupos' in res) {
                if(res.grupos.length > 0) {
                  if('lineas' in res.grupos[0]) {
                    if('productos' in res.grupos[0].lineas) {
                      if(res.grupos[0].lineas.productos.length > 0) {
                        bill.products = res.grupos[0].lineas.productos;
                        bill.products[0].address = res.direccion_envio_data;
                        bill.products[0].valor_envio = res.totales.envio ? res.totales.envio : 0;
                        bill.btnMessage = 'ocultar';
                        bill.showDetails = true;

                        try {
                          // recorremos los productos para obtener las guias
                          bill.products.map(prod => {
                            if(prod.despacho){
                              bill.guias.push(prod.despacho)
                            }
                            return prod;
                          });
                        } catch (error) {
                          console.log('Error controlado ', error);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }else{
        bill.showDetails = true;
        bill.btnMessage = 'Ocultar';
      }
    } else {
      bill.btnMessage = 'Ver detalles';
      bill.showDetails = false;
    }
  }
}
