import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UsuarioService } from 'src/app/services/usuario.service';
import { EliminarCuentaService } from 'src/app/services/eliminar-cuenta.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-eliminar-cuenta',
  templateUrl: './eliminar-cuenta.component.html',
  styleUrls: ['./eliminar-cuenta.component.scss'],
})
export class EliminarCuentaComponent implements OnInit {

  mensaje:string;
  usuario:any;
  usuarioDefault = { nombres: 'Usuario'}

  susc: Subscription = new Subscription();

  constructor(
    private store: Store<any>,
    public usuarioService:UsuarioService,
    private eliminarCuentaService: EliminarCuentaService,
    private sessionService: SessionService,
  ) {

  }

  async ngOnInit() {

    this.mensaje = "Un momento por favor, estamos eliminando tu cuenta...";

    this.susc = await this.store.subscribe(response => {
        this.usuario = Object.keys(response.appReducer.user).length === 0 ?  this.usuarioDefault : response.appReducer.user;
        this.checkUserName();
    });



    let session:string = await this.usuarioService.getUserLocal();

    if (session) {
        await this.eliminarCuentaService.eliminarCuenta(session);
        this.mensaje = "";

        setTimeout(() => {
           this.sessionService.closeSesion();
        }, 4000);
    }else{
        this.sessionService.closeSesion();
    }

  }


  async checkUserName() {

    if ( this.usuario.nombres == 'usuario_eliminado' ) {
         this.usuario =  this.usuarioDefault;
    }

  }



}
