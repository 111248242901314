import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable({
  providedIn: 'root'
})
export class AddTrakingTopleadsService {

  private crmUrl : string = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private csasr: CheckSessionAfterServiceResponseService,
    ) { }

  /**
   * Registra en topleads el historial de urls visitadas por el usuario en sesion
   * @param data ["session_id",{}] objeto JSON con la data que recibe el servicio
   */
  trakingTopleads( data ) {
    let formData = new FormData();
    formData.append('method', 'trakinEventTopleads');
    formData.append('rest_data', JSON.stringify(data));
    formData.append('input_type', 'JSON');
    formData.append('response_type', 'JSON');
    return this.http.post(this.crmUrl, formData).pipe(map((response:any) => {
      this.csasr.check(response);
      return response
    }));
  }


}
