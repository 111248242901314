import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import { ActualizarOrden } from 'src/app/store/ordenes/actions';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: "app-confirmadas",
  templateUrl: "./confirmadas.component.html",
})
export class ConfirmadasComponent implements OnInit, OnDestroy {
  user: any = null;
  ordenes: any = [];
  noCoverImg: string = "";
  bonoImage: string = "";
  bonoVirtualImage: string = "";

  // defaults images lazyload
  loaderImg = "";
  offset = 5;

  susc: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private platformLocation: PlatformLocation
  ) {
    this.bonoImage = (this.platformLocation as any)._location.origin + "/assets/images/bono-fisico.jpg";
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + "/assets/images/bono-virtual.jpg";
  }

  ngOnInit() {
    this.susc = this.store.select("ordenes").subscribe(({ data }) => {
      this.ordenes = data.confirmadas ? data.confirmadas : [];
    });
  }

  ngOnDestroy() {
    this.susc.unsubscribe();
  }

  toggleBillDetails(bill, i) {
    if (!bill.products) {
      bill.products = [];
    }
    if (!bill.guias) {
      bill.guias = [];
    }
    if (!bill.btnMessage) {
      bill.btnMessage = "";
    }
    if (!bill.showDetails) {
      bill.showDetails = false;
    }

    if (!bill.showDetails) {
      if (!bill.products.length) {
        this.store.dispatch(
          new ActualizarOrden([
            null,
            { factura_id: bill.id },
            { orden: bill, i, orden_tipo: "confirmadas" },
          ])
        );
        bill.btnMessage = "ocultar";
        bill.showDetails = true;
      } else {
        bill.showDetails = true;
        bill.btnMessage = "Ocultar";
      }
    } else {
      bill.btnMessage = "Ver detalles";
      bill.showDetails = false;
    }
  }
}
