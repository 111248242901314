import { StoreService } from './../../services/store.service';
import { Component, OnInit } from '@angular/core';
import { MouseEvent, GoogleMapsAPIWrapper } from '@agm/core';
import { SedesService } from '../../services/sedes.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { SET_SEDES } from '../../store/actions/appAction';

import { Title, Meta }     from '@angular/platform-browser';
import { CheckSessionAfterServiceResponseService } from 'src/app/services/check-session-after-service-response.service';

@Component({
  selector: 'app-nuestras-sedes',
  templateUrl: './nuestras-sedes.component.html',
  styleUrls: ['./nuestras-sedes.component.css']
})
export class NuestrasSedesComponent implements OnInit {
  firstBodegaCerca: any = [];
  listBodegasCerca: any = [];
  listBodegasCercaData: any = [];
  countBodegasCerca: any = [];
  myLocation: any = [];
  location: any;
  datamarkers: any = [];

  bodegas: any = [];

  datamarker: any = [];
  test: any = [];
  // google maps zoom level
  zoom: number = 12;
  // initial center position for the map Ciudad "Cali"
  latitud_c: number = 3.4333452;
  longitud_c: number = -76.5242183;

  markers: any = [];
  cities: any = [];

  sedes: any = [];
  protected map: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    private sedesService: SedesService,
    private store: Store<any>,
    private storeService: StoreService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private csasr: CheckSessionAfterServiceResponseService,
  )
  {

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)

    this.store.subscribe( ({ appReducer }) => {
      this.sedes = appReducer.sedes;
    });
    
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    let dataMetas = {
      title: 'Nuestras Sedes',
      description: 'Encuentra nuestras sedes en Colombia donde encontrarás los últimos lanzamientos de la literatura.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.sedesService.getMasCercana({ latitud: '3.3742825', longitud: '-76.5390207' }).subscribe((response:any) => {
      this.csasr.check(response);
      this.bodegas = response;
      this.listBodegasCercaData = [];

      for (let item of this.bodegas) {
        let dataItemServicios = item.servicios.split(',');
        
        for (let index = 0; index < dataItemServicios.length; index++) {
          dataItemServicios[index] = dataItemServicios[index].replace('^', '').replace('^', '');
        }

        item.servicios = dataItemServicios;

        this.listBodegasCercaData.push(item);
      }

      if(this.bodegas.length > 0){
        this.storeService.updateAppState({
          action: SET_SEDES,
          payload: this.bodegas
        });
        this.createMapa(this.bodegas);
      }
    })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        let data = {
          ciudad: '',
          latitud: position.coords.latitude,
          longitud: position.coords.longitude
        };
        this.myLocation = data;
      });
    }
  }

  setMetaData(data) {
    
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  mapReady(map) {
    this.map = map
  }

  clickedMarker(label: string, index: number) {
    this.map.setCenter({ lat: parseFloat(this.datamarker[index].latitud), lng: parseFloat(this.datamarker[index].longitud) })
    this.map.setZoom(16)
  }

  createMapa(data) {

    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i].value == obj.value) {
          return true;
        }
      }
      return false
    }

    function orderLabel(a, b) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    }

    for (let item of data) {
      item.latitud = item.latitud == '' ? 0 : parseFloat(item.latitud);
      item.longitud = item.longitud == '' ? 0 : parseFloat(item.longitud);

      if (!containsObject({ value: item.ciudad_id, name: item.ciudad_name }, this.cities)) {
        this.cities.push({ value: item.ciudad_id, name: item.ciudad_name });
      }
    }

    this.datamarker = data;
    this.cities.sort(orderLabel);
  }

  changeCity(ciudad) {
    this.listBodegasCercaData = [];
    for (let item of this.bodegas) {
      if (item.ciudad_id == ciudad) {
        this.listBodegasCercaData.push(item);
      }
    }

    this.map.setCenter({ lat: parseFloat(this.listBodegasCercaData[0].latitud), lng: parseFloat(this.listBodegasCercaData[0].longitud) })
    this.map.setZoom(12)

    //this.countBodegasCerca = this.firstBodegaCerca.length;
  }
}
