import { UnionAccionesModalRestriccionesCovid, AccionesModalRestriccionesCovid } from "./actions";

export interface ModalRestriccionesCovidStateI {
  mostrarModal: boolean;
}

const estadoInicial: ModalRestriccionesCovidStateI = {
  mostrarModal: true,
};

export function modalRestriccionesCovidReducer(
  state: any = estadoInicial,
  action: UnionAccionesModalRestriccionesCovid
) {
  switch (action.type) {
    case AccionesModalRestriccionesCovid.MODAL_RESTRICCIONES:{
      return {
        ...state,
        mostrarModal: action.payload,
      };
    }

    default:
      return state;
  }
}
