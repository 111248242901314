import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { UsuarioService } from './usuario.service';
import { Observable} from "rxjs";

@Injectable()
export class AuthNoGuardService implements CanActivate {
  constructor(private usuarioService : UsuarioService){
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    let sessionExists = (this.usuarioService.getUserLocal()) ? (true) : (false);
    let userState = !this.usuarioService.getEstadoRegistro(); // registro full
	  return (sessionExists || userState);
  }
}
