import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enmantenimiento',
  templateUrl: './enmantenimiento.component.html',
  styleUrls: ['./enmantenimiento.component.css']
})

export class EnmantenimientoComponent implements OnInit {
  constructor(){ }

  ngOnInit(){ }
}
