import { StoreService } from './../../../services/store.service';
import { ProductosService } from './../../../services/productos.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GET_MIS_BONOS, UPDATE_TOTALS_SHOPCAR, ACTION_SHOPCAR } from '../../../store/actions/appAction';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { CheckSessionAfterServiceResponseService } from 'src/app/services/check-session-after-service-response.service';

@Component({
  selector: 'app-misbonosvirtuales',
  templateUrl: './misbonosvirtuales.component.html',
  styleUrls: ['./misbonosvirtuales.component.scss']
})
export class MisbonosvirtualesComponent implements OnInit, OnDestroy {
  usuario: any;
  shopcar: any;
  misBonos: any = [];
  misBonosViewControl: boolean = false;
  menus: any = { TOP: { items: [] }, menu: '' };
  loadBonosList: boolean = false;
  @Input('showComponent') showComponent: boolean = false;

  formCodigoMisBonos: FormGroup;
  mensajeCodigo: any;

  constructor(
    private productosService: ProductosService,
    private store: Store<any>,
    private storeService: StoreService,
    private cp: CurrencyPipe,
    private userService: UsuarioService,
    private csasr: CheckSessionAfterServiceResponseService,
  ) {
  }

  ngOnInit() {

    this.store.subscribe(response => {

      let stateCurrent:any = this.store.select('appReducer');
      let actionCurrent:any = stateCurrent.actionsObserver.value.type;
      let payloadCurrent:any = stateCurrent.actionsObserver.value.payload;

      this.usuario = response.appReducer.user;
      this.misBonos = response.appReducer.misBonos;
      this.shopcar = response.appReducer.shopcar;
      this.menus = response.appReducer.menus;

      if (this.loadBonosList == false)
      {
        if(this.usuario.sessionCrm && !this.misBonosViewControl && this.misBonos.length == 0){
          this.getMisBonos();
        }
      }

      if(actionCurrent == 'LOGIN'){
        this.getMisBonos();
      }

    });
    this.formValidate();
  }

  ngOnDestroy() {
    this.misBonosViewControl = false;
    // this.storeService.updateAppState({ action: GET_MIS_BONOS, payload: [] });
  }

  formValidate(){
    this.formCodigoMisBonos = new FormGroup({
      'codigo': new FormControl('', (this.usuario.estado_registro == 'PARCIAL' || this.validateEmptyObject(this.usuario) ? null : Validators.required))
    });
  }

  getMisBonos(){
    this.misBonosViewControl = true;

    let data = [ this.usuario.sessionCrm, {
      estados: [ 'ACTIVO' ]
    }];

    this.productosService.getBonosVirtualesUsuario(data).toPromise().then((res: any) => {
      this.csasr.check(res);
      if(res.bonos){

        this.loadBonosList = true;
        this.storeService.updateAppState({ action: GET_MIS_BONOS, payload: res.bonos });

        for (let index = 0; index < this.misBonos.length; index++) {
          this.misBonos[index].mostrarForm = false;
          this.misBonos[index].mostrarErrorForm = false;
          this.misBonos[index].error = '';
          this.misBonos[index].saldo_redimir = parseInt(this.misBonos[index].saldo_redimir);
        }
      }
    });
  }

  mostrarFormRedimir(bono){
    bono.mostrarForm = !bono.mostrarForm;
  }

  redimirBono(bono, reset = false){
    bono.mostrarErrorForm = false;
    bono.error = '';

    if(reset) {
      bono.saldo_redimir = 0;
      let data = [
        this.usuario.sessionCrm,
        {
          factura_id: this.shopcar.id,
          bono_virtual_id: bono.id,
          valor_pago: bono.saldo_redimir
        }
      ];

      this.productosService.redimirBonoVirtual(data).subscribe((response: any) => {
        this.csasr.check(response);
        if(response){
          this.storeService.updateAppState({ action: UPDATE_TOTALS_SHOPCAR, payload: response.carrito });
          this.getMisBonos();
        }
      });
      return;
    }

    let maxRedimir = bono.saldo;
    if(bono.saldo_redimir < 0){
      // bono.mostrarErrorForm = true;
      bono.error = 'Ingrese un valor para el bono ';
      Swal.fire({
        title: 'Bonos Virtuales',
        text: bono.error,
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#B40001',
        confirmButtonText: 'Ok',
      });
    }else if(bono.saldo_redimir > maxRedimir){
      // bono.mostrarErrorForm = true;
      bono.error = 'El valor no puede ser mayor al saldo del bono '+ maxRedimir;
      Swal.fire({
        title: 'Bonos Virtuales',
        text: bono.error,
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#B40001',
        confirmButtonText: 'Ok',
      });
    }else if(bono.saldo_redimir > this.shopcar.totales.gran_total){
      // bono.mostrarErrorForm = true;
      bono.error = 'El valor no puede ser mayor al saldo de la factura $' + this.shopcar.totales.gran_total;
      Swal.fire({
        title: 'Bonos Virtuales',
        text: bono.error,
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#B40001',
        confirmButtonText: 'Ok',
      });
    }else{
      const data = [
        this.usuario.sessionCrm,
        {
          factura_id: this.shopcar.id,
          bono_virtual_id: bono.id,
          valor_pago: bono.saldo_redimir
        }
      ];

      this.productosService.redimirBonoVirtual(data).subscribe((res: any) => {
        this.csasr.check(res);
        if (res && !res.hasErrors) {
          this.storeService.updateAppState({ action: UPDATE_TOTALS_SHOPCAR, payload: res.carrito });
          this.getMisBonos();
        } else {
          bono.mostrarErrorForm = true;
          bono.error = `${res.name} - ${res.description}`;
        }
      });
    }
  }

  activarCodigo(){

    if(this.formCodigoMisBonos.valid) {
      let data = [ this.usuario.sessionCrm, {
        codigo: this.formCodigoMisBonos.value.codigo,
        factura_id: JSON.parse(localStorage.getItem('bill')),
      }];

      this.productosService.activarBonoPorCodigo(data).subscribe( (res: any) => {
        this.csasr.check(res);
        if(res) {
          if(res.hasErrors){
            this.mostrarMensajeCodigo(`${res.name} - ${res.description}`, true);
            return;
          }
          if(res.error == 0 && res.bono_id){
            this.mostrarMensajeCodigo(res.message);
            this.getMisBonos();
            this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: res.factura});
          } else if(res.error == 0 && res.bono_id == null){
            this.mostrarMensajeCodigo(res.message);
            this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: res.factura});
          }
          this.formValidate();
        }
      });

    } else {
      this.formCodigoMisBonos.controls['codigo'].markAsTouched();
    }
  }

  mostrarMensajeCodigo(mensaje: any, error: boolean = false){
    // this.mensajeCodigo = mensaje;
    // setTimeout(() => this.mensajeCodigo = '', 5000);
    Swal.fire({
      title: 'Bonos Virtuales',
      text: mensaje,
      type: error ? 'error' : 'success',
      showCancelButton: false,
      confirmButtonColor: '#B40001',
      confirmButtonText: 'Ok',
    });
  }

  validateEmptyObject(obj): boolean {
    return Object.keys(obj).length === 0 ? true : false;
  }

  openLoginView(){
    this.userService.updateLoginModalState(true);
    this.userService.updateLoginModalOrigin('informacion-pago');
  }

}
