import { Action } from '@ngrx/store';

export enum AccionesSchemaJsonLd {
  CARGAR_SCHEMAJSONLD = '[SCHEMAJSONLD] => Cargar',
  CARGA_SCHEMAJSONLD_EXITOSA = '[SCHEMAJSONLD] => Carga Exitosa',
  CARGA_SCHEMAJSONLD_FALLIDA = '[SCHEMAJSONLD] => Carga Fallida'
}

export class CargarSchemaJsonLd implements Action {
  readonly type = AccionesSchemaJsonLd.CARGAR_SCHEMAJSONLD;

  constructor(public payload?: any) { }
}

export class CargarSchemaJsonLdFail implements Action {
  readonly type = AccionesSchemaJsonLd.CARGA_SCHEMAJSONLD_FALLIDA;

  constructor(public payload: any) { }
}

export class CargarSchemaJsonLdSuccess implements Action {
  readonly type = AccionesSchemaJsonLd.CARGA_SCHEMAJSONLD_EXITOSA;

  constructor(public schemajsonld: any) { }
}

export type UnionAccionesSchemaJsonLd = CargarSchemaJsonLd | CargarSchemaJsonLdFail | CargarSchemaJsonLdSuccess;
