import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tyc-librero-mama',
  templateUrl: './tyc-librero-mama.component.html',
  styleUrls: ['./tyc-librero-mama.component.scss']
})
export class TycLibreroMamaComponent implements OnInit {
  enlaceLibreriaNacional = 'https://www.instagram.com/librerianacional/';
  enlacePlanetadeLibrosco = 'https://www.instagram.com/planetadelibrosco/';
  constructor() { }

  ngOnInit() {
  }

}
