import { AppState } from './../../appState';
import { UnionAccionesPromocionDetalle, AccionesPromocionDetalle } from './actions';
import { State } from '@ngrx/store';

export interface PromocionDetalleStateI {
  data: any;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: PromocionDetalleStateI = {
  data: {},
  loaded: false,
  loading: false,
  error: null
};

export function promocionDetalleReducer(state: any = estadoInicial, action: UnionAccionesPromocionDetalle) {
  switch (action.type) {
    case AccionesPromocionDetalle.CARGAR_PROMOCION_DETALLE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesPromocionDetalle.CARGA_PROMOCION_DETALLE_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {...action.promocion}
      };

    case AccionesPromocionDetalle.CARGA_PROMOCION_DETALLE_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    case AccionesPromocionDetalle.RESET_PROMOCION_DETALLE:
      return {
        data: {},
        loaded: false,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}
