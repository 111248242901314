import { Component, OnInit } from '@angular/core';
import { AyudaService } from '../../../services/ayuda.service';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta }     from '@angular/platform-browser';
import { CheckSessionAfterServiceResponseService } from 'src/app/services/check-session-after-service-response.service';

@Component({
  selector: 'app-politica-datos',
  templateUrl: './politica-datos.component.html',
  styleUrls: ['./politica-datos.component.css']
})
export class PoliticaDatosComponent implements OnInit {
  pagina: any = {};

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    private ayudaService: AyudaService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private csasr: CheckSessionAfterServiceResponseService,
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.ayudaService.getPaginaPorSlug({ tipo: 'ARTICULO', slug: 'politica-tratamiento-de-datos' }).subscribe((response:any) => {
      this.csasr.check(response);
      this.pagina = response;
    });

    let dataMetas = {
      title: 'Política de datos personales',
      description: '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }
  }

  setMetaData(data) {
    
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

}
