import { AppState } from './../appState';
import { UnionAccionesColeccionesHome, AccionesColeccionesHome } from './actions';
import { State } from '@ngrx/store';

export interface ColeccionesHomeStateI {
  data: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ColeccionesHomeStateI = {
  data: [],
  loaded: false,
  loading: false,
  error: null
};

export function coleccionesHomeReducer(state: any = estadoInicial, action: UnionAccionesColeccionesHome) {
  switch (action.type) {
    case AccionesColeccionesHome.CARGAR_COLECCIONES_HOME:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesColeccionesHome.CARGA_COLECCIONES_HOME_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {...action.home.colecciones}
      };

    case AccionesColeccionesHome.CARGA_COLECCIONES_HOME_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    default:
      return state;
  }
}
