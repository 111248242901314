import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UsuarioService } from './usuario.service';

@Injectable()
export class NoAuthGuardService implements CanActivate {
	constructor(
		private usuario : UsuarioService
	)
	{
	}

	canActivate (next:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
		if (this.usuario.getUserLocal()) {
			let userLoged = this.usuario.getUserLocal();
			if (userLoged.idCrm){
				return false
			}
			else {
				return true
			}
		} else {
			return true
		}
	}
}
