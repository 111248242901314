import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-block-modal',
  templateUrl: './block-modal.component.html',
  styleUrls: ['./block-modal.component.scss']
})
export class BlockModalComponent implements OnInit {

  @Input('stateModalBlock') stateModalBlock;
  @Input('originModalBlock') originModalBlock;

  constructor() { }

  ngOnInit() {
  }

}
