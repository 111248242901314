import { ColeccionesService } from './../../services/colecciones.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarColeccionesSuccess, CargarColeccionesFail, AccionesColecciones } from './actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class ColeccionesEffects {

    constructor(
        private actions$: Actions,
        public coleccionesService: ColeccionesService
    ) {}

    @Effect()
    cargarColecciones$ = this.actions$.pipe( ofType(AccionesColecciones.CARGAR_COLECCIONES) )
        .pipe(
            switchMap( (data: any) => {
                return this.coleccionesService.getColeccionDefault(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response) {
                                return new CargarColeccionesSuccess(response);
                            }
                            return new CargarColeccionesFail(response);
                        } ),
                        catchError( error => of(new CargarColeccionesFail(error)) )
                    );
            })
        );
}
