import { Component, OnInit, ElementRef } from '@angular/core';
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from '@angular/router';
import { ShareButtons } from '@ngx-share-pat/core';
import { PlatformLocation } from "@angular/common"
import { StoreService } from "../../services/store.service";
import { CarritoService } from '../../services/carrito.service'
import { UsuarioService } from '../../services/usuario.service'
import { ProductosService } from '../../services/productos.service'
import { ACTION_SHOPCAR, ADD_FAVORITOS, ACTION_DEL_SHOPCAR } from '../../store/actions/appAction'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { MetaTagsService } from '../../services/meta-tags.service';
import { DataLayerService } from '../../services/data-layer.service';
import { MetaData } from '../../interfaces/meta-data'
import { Product } from 'src/app/interfaces/product';
import { CheckSessionAfterServiceResponseService } from 'src/app/services/check-session-after-service-response.service';
import Swal from 'sweetalert2';

import { Fancybox } from '@fancyapps/ui';
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";

declare var $: any;
@Component({
  selector: 'app-libro',
  templateUrl: './libro.component.html',
  styleUrls: ['./libro.component.css']
})

export class LibroComponent implements OnInit {
  schema: any;
  usuario: any;
  shopcar: any;
  detailsProduct: any = {};
  addedToCart: any = {};
  galleryDetailsProduct: any = [];
  fichaTecnicaDetailsProduct: any = [];
  fichaTecnicaGarantiaProduct: any = [];
  fichaTecnicaImage: any = [];
  updateInProgress: boolean = false;
  countProduct: number = 1;
  noCoverImg: string = '';
  color: any;
  showHeartFill: boolean = false;
  addedToFavorites: boolean = false;
  addToFavMessage: any = '';
  showAgregarResenia: boolean;
  heartIcon: string = 'icon-heart';
  addToFavText: any = 'Agregar a favoritos';
  books: any = [];
  bookAuthors: any = [];
  authorBooks: any = [];
  author: string = '';
  formResenia: FormGroup;
  productFounded: string = '';
  showAddProductButtons: boolean = true;
  customDisabled: boolean = false;
  pluginCarosel = null;
  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private router: Router,
    private store: Store<any>,
    public share: ShareButtons,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private carritoService: CarritoService,
    private usuarioService: UsuarioService,
    public platformLocation: PlatformLocation,
    private productosService: ProductosService,
    private metaTagsService: MetaTagsService,
    private dataLayerService: DataLayerService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2GoogleAnalyticsEnhancedEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce,
    private csasr: CheckSessionAfterServiceResponseService,
    private elRef: ElementRef
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
  }

  ngOnInit() {
    // window.scrollTo(0,0);
    if (localStorage.getItem('canEnterDatosEnvio')) {
      localStorage.removeItem('canEnterDatosEnvio');
    }
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';
    this.activatedRoute.params.subscribe(params => {
      this.getDataDetailsProduct(params);
    });
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';

    this.store.subscribe(response => {

      const stateCurrent: any = this.store.select('appReducer');
      const actionCurrent: any = stateCurrent.actionsObserver.value.type;

      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

      if (actionCurrent === 'LOGIN' || actionCurrent === 'FAVORITOS') {
        this.isBookAddedToFav();
      }
    });

    this.validateFormResenia();
  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
    this.pluginCarosel.destroy();
  }

  validateFormResenia(): void {
    this.formResenia = new FormGroup({
      'calificacion': new FormControl('', Validators.required),
      'titulo': new FormControl('', Validators.required),
      'comentarios': new FormControl(''),
      'captcha': new FormControl(null, Validators.required)
    });
  }

  resolved(event): void {
    console.log(this.formResenia);
  }

  registrarResenia(): void {
    if (this.formResenia.valid === true) {
      const dataFormResenia = [this.usuario.sessionCrm, {
        producto_id: this.detailsProduct.id,
        calificacion: this.formResenia.controls['calificacion'].value,
        titulo: this.formResenia.controls['titulo'].value,
        descripcion: this.formResenia.controls['comentarios'].value,
      }];

      this.productosService.crearResenia(dataFormResenia).subscribe((response: any) => {
        this.csasr.check(response);
        if (response.id) {
          this.getReseniasLibro();
          this.showAgregarResenia = false;
        }
        this.validateFormResenia();
      });
    } else {
      console.log('invalid');
    }
  }

  getDataDetailsProduct(params): void {
    //SCROLL TOP
    window.scrollTo(0, 0);
    const data = {
      slug: params.slug,
      producto_id: null
    };

    this.bookAuthors = [];
    this.productosService.getDetailProductLN(data).subscribe((response: any) => {
      if (response.producto) {
        this.productFounded = '1';
        let autor = '';
        if (response.producto.autores.length > 0) {
          if (response.producto.autores[0]) {
            autor = response.producto.autores[0].name ? response.producto.autores[0].name : '';
          }
        }
        const title = response.producto.meta_title ? response.producto.meta_title : response.producto.name;
        let description = '';

        if (response.producto.descripcion.length <= 160) {
          description = response.producto.descripcion;
        } else {
          description = response.producto.descripcion.substring(0, 159);
        }

        const dataMetas: MetaData = {
          site: 'Librería Nacional',
          title: title + ' | ' + autor,
          keywords: 'libros,libreria,ebooks,' + response.producto.name + ',' + response.producto.meta_keywords,
          description: response.producto.meta_description ? response.producto.meta_description.replace(/<.*?>/g, '') : description.replace(/<.*?>/g, ''),
          image: response.producto.imagen ? response.producto.imagen : 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
          url: 'https://www.librerianacional.com' + this.router.url,
          brand: response.producto.marca_producto,
          category: response.producto.categoria_producto,
          available: 'Disponible',
          condition: 'Nuevo',
          price: response.producto.price_dcto,
          currency: 'COP',
          plu: response.producto.plu_sku_erp_code,
          id: response.producto.id
        }

        const productDataLayer: Product = {
          name: title,
          id: response.producto.plu_sku_erp_code,
          slug: response.producto.slug,
          plu: response.producto.plu_sku_erp_code,
          price: String(response.producto.price_dcto),
          brand: response.producto.marca_producto,
          category: response.producto.categoria_producto,
          type: (response.producto.lib_formato ? response.producto.lib_formato.valor : null)
        }

        try {
          this.metaTagsService.setMetaData(dataMetas);
          this.dataLayerService.detail(productDataLayer);
        } catch (error) { }

        if (response.recomendados) {
          if (response.recomendados.length > 0) {
            this.books = response.recomendados;
            this.books.forEach(obj => {
              if (obj.promociones.length > 0) {
                const promociones = [];
                obj.promociones.forEach(promo => {
                  promociones.push(promo.name);
                });
                obj.promocionesText = promociones.join(', ');
              }
            });
            this.carouselOtrosLibros();
          }
        }

        if (response.libros_autor) {
          this.authorBooks = response.libros_autor;
        }
        this.detailsProduct = response.producto;
        this.setDatajsonLd();
        if (this.detailsProduct.type.valor.toLowerCase() == 'ebook') {
          this.showAddProductButtons = false;
        }
        this.detailsProduct.resenias = response.resenas || [];
        if (response.resenas) {
          for (let index = 0; index < this.detailsProduct.resenias.length; index++) {
            this.detailsProduct.resenias[index].calificacion = parseInt(this.detailsProduct.resenias[index].calificacion);
          }
        }

        if ('autores' in this.detailsProduct) {
          if (this.detailsProduct.autores.length > 0) {
            this.author = this.detailsProduct.autores[0].name;
            this.detailsProduct.autores.forEach(author => {
              this.bookAuthors.push(author.name);
            });
          }
        }

        if (this.detailsProduct.promociones) {
          if (this.detailsProduct.promociones.length > 0) {
            const promociones = [];
            this.detailsProduct.promociones.forEach(promo => {
              promociones.push(promo.name);
            });
            this.detailsProduct.promocionesText = promociones.join(', ');
          }
        }

        this.fichaTecnicaDetailsProduct = response.producto.ficha_tecnica;
        this.fichaTecnicaImage = [];
        this.fichaTecnicaGarantiaProduct = response.producto.garantia_meses;
        this.galleryDetailsProduct = response.producto['galeria'] || [];

        if (this.galleryDetailsProduct.length > 0) {
          for (const itemGallery of this.galleryDetailsProduct) {
            if (itemGallery.tipo === 'VIDEO') {
              itemGallery.embed = itemGallery.url.replace('https://youtu.be/', '');
            }
          }
        }

        // Start tracking GTM
        this.angulartics2GoogleTagManager.pageTrack(this.router.url);

        // inicializo el carousel de la galeria de imagenes
        setTimeout(() => {
          this.iniciarCarousel();
        }, 1000);

      } else {
        this.productFounded = '0';
      }
    });
  }

  /**
   * @description inicializa el plugin de carousel de la galeria de imagenes
   * */
  iniciarCarousel() {
    // si ya esta inicializado elimino la instancia del carousel genera fallas al declararse mulriples veces
    if(this.pluginCarosel != null){
      this.pluginCarosel.destroy();
      this.pluginCarosel = null;
      this.iniciarCarousel();
    }

    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });
    const container = document.getElementById("carouselLibros");
    if (container) {
      const options = {
        Dots: false,
        // Thumbs: {
        //   type: "classic",
        //   minCount: 3
        // }
        Thumbs: {
          type: 'classic',
          Carousel: {
            slidesPerPage: 1,
            Navigation: true,
            center: true,
            fill: true,
            dragFree: true,
          },
        }
      };
      this.pluginCarosel = new Carousel(container, options, { Thumbs });
    } else {
      this.iniciarCarousel();
    }
  }
  /**
   * @description crea objeto JSON-LD formato que lee google para el seo de la pagina y datos estructurados,
   * lo agrega en la etiqueta <ngx-json-ld> que inserta un script
   */
  setDatajsonLd(){
    this.schema = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "breadcrumb": `Home > Libros > ${this.detailsProduct.name}`,
      "accessibilityControl": [
        "fullKeyboardControl",
        "fullMouseControl"
      ],
      "accessibilityFeature": [
        "largePrint/CSSEnabled",
        "highContrast/CSSEnabled",
        "resizeText/CSSEnabled",
        "displayTransformability",
        "longDescription",
        "alternativeText"
      ],
      "accessibilityHazard": [
        "noFlashingHazard",
        "noMotionSimulationHazard",
        "noSoundHazard"
      ],
      "mainEntity": {
        "@type": "Book",
        "author": this.detailsProduct.autores.length > 0 ? this.detailsProduct.autores[0].name : 'N/A',
        "bookFormat": this.detailsProduct.type.valor,
        "product_type": this.detailsProduct.type.valor,
        // "datePublished": "1991-05-01",
        "image": (this.detailsProduct.imagen != null && this.detailsProduct.imagen != '') ? this.detailsProduct.imagen : this.noCoverImg,
        "image_link": (this.detailsProduct.imagen != null && this.detailsProduct.imagen != '') ? this.detailsProduct.imagen : this.noCoverImg,
        "description": this.detailsProduct.descripcion,
        "genre": this.detailsProduct.categoria_producto,
        "inLanguage": this.detailsProduct.lib_eb_lenguaje.valor,
        // "isFamilyFriendly": "true",
        "id": this.detailsProduct.plu_sku_erp_code,
        "isbn": this.detailsProduct.lib_eb_isbn,
        "title": this.detailsProduct.name,
        "name": this.detailsProduct.name,
        "numberOfPages": this.detailsProduct.lib_num_pagina,
        "url": `https://www.librerianacional.com${this.router.url}`,
        "link": `https://www.librerianacional.com${this.router.url}`,
        "sale_price": this.detailsProduct.price_dcto,
        // "sale_price_effective_date": ((this.detailsProduct.promociones && this.detailsProduct.promociones.length > 0) ? this.detailsProduct.promociones[0].vigencia_fecha_hasta : null),
        "price": this.detailsProduct.price_dcto,
        "brand": this.detailsProduct.marca_producto,
        "product_width": `${this.detailsProduct.dim_x_centimetros} cm`,
        "product_height": `${this.detailsProduct.dim_y_centimetros} cm`,
        "product_length": `${this.detailsProduct.dim_z_centimetros} cm`,
        "offers": {
          "@type": "Offer",
          "availability": "https://schema.org/InStock",
          "price": this.detailsProduct.price_dcto,
          "priceCurrency": "COP"
        },
        "publisher": {
          "@type": "Organization",
          "name": this.detailsProduct.marca_producto
        },
        "copyrightHolder": {
          "@type": "Organization",
          "name": this.detailsProduct.marca_producto
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": this.detailsProduct.rc_prom_calificacion,
          "reviewCount": (this.detailsProduct.rc_cant_calificacion > 0) ? this.detailsProduct.rc_cant_calificacion : 1
        },
        // "review": [
        //   {
        //     "@type": "Review",
        //     "author": "John Doe",
        //     "datePublished": "2006-05-04",
        //     "name": "A masterpiece of literature",
        //     "reviewBody": "I really enjoyed this book. It captures the essential challenge people face as they try make sense of their lives and grow to adulthood.",
        //     "reviewRating": {
        //       "@type": "Rating",
        //       "ratingValue": "5"
        //     }
        //   },
        //   {
        //     "@type": "Review",
        //     "author": "Bob Smith",
        //     "datePublished": "2006-06-15",
        //     "name": "A good read.",
        //     "reviewBody": "Catcher in the Rye is a fun book. It's a good book to read.",
        //     "reviewRating": "4"
        //   }
        // ]
      }
    };
    // console.log( this.detailsProduct);
    if (this.detailsProduct.promociones && this.detailsProduct.promociones.length > 0) {
      this.schema.mainEntity.sale_price_effective_date = this.detailsProduct.promociones[0].vigencia_fecha_hasta;
      this.schema.mainEntity.promotion_id = this.detailsProduct.promociones[0].id;
    }
    // console.log( this.schema);
  }

  getReseniasLibro(): void {
    const dataResenia = {
      producto_id: this.detailsProduct.id,
      limit: 10,
      orden: [
        { campo: 'date_entered', direccion: 'DESC' },
        { campo: 'name', direccion: 'ASC' }
      ]
    };

    // traemos las reseñas del libro
    this.productosService.reseniasLibro(dataResenia).toPromise().then((res: any) => {
      this.csasr.check(res);
      this.detailsProduct.resenias = res || [];
      if (res) {
        for (let index = 0; index < this.detailsProduct.resenias.length; index++) {
          this.detailsProduct.resenias[index].calificacion = parseInt(this.detailsProduct.resenias[index].calificacion);
        }
      }
    });
  }

  addCountProduct(): void {
    this.countProduct++;
  }

  resCountProduct(): void {
    if (this.countProduct > 1) {
      this.countProduct--;
    }
  }

  addProductCarrito(): void {
    const user = this.usuario;
    const carritoLS = this.shopcar;

    // AGREGO EL ITEM DEL CARRO SELECCIONADO
    const producCurrent = {
      factura_id: null,
      grupo_id: null,
      producto_id: this.detailsProduct.id,
      cantidad: String(this.countProduct)
    };

    if (this.detailsProduct.type.valor.toLowerCase() == 'ebook') {
      producCurrent.cantidad = '1';
      this.customDisabled = true;
    }

    this.updateInProgress = true;
    // Hay usuario logueado
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      const dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      const dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm);
    }
  }

  addProductCarritoCrm(dataCrm): void {
    this.carritoService.addProduct(dataCrm).subscribe((response: any) => {

      this.updateInProgress = false;

      if (response.hasErrors) {
        if (response.number === 2204) {
          // producto NO existe
        } else {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
        Swal.fire({
          text: response.description,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#B40001',
          confirmButtonText: 'Ok',
        });
        this.customDisabled = false;
      } else {
        if (response.linea) {
          const productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if (response.producto) {
          this.detailsProduct = {...this.detailsProduct, ...response.producto};
        }

        if (response.factura.id) {
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      }
    });
  }

  getNewBill(dataCrm): void {

    const user = this.usuario;
    const producCurrent = dataCrm[1];

    let dataCrmNF: any;
    if (user) {
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res: any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm);
      } else {
        this.updateInProgress = false;
      }
    });
  }

  //UPDATE CARRITO LOCAL
  updateCarrito(carrito): void {
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    const dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        subtotal: this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }

  isBookAddedToFav(): void {
    //this.storeService.updateAppState({action: ACTION_ADDRESS, payload: address});
    if (this.usuario.estado_login === 'LOGIN_FULL') {
      const data = {
        session: this.usuario.sessionCrm
      };
      this.usuarioService.favListGet(data).subscribe((response: any) => {
        if (response.productos_lista) {
          const array_favorites = response.productos_lista;
          for (const favorite of array_favorites) {
            if (this.detailsProduct.id === favorite.producto.id && favorite.deleted !== '1') {
              this.showHeartFill = true;
              this.addedToFavorites = true;
              this.heartIcon = 'icon-fav-on';
              this.addToFavText = 'Agregado a favoritos';
            }
          }
        }
      });
    }
  }

  addToFavourites(): void {
    const data = {
      deleted: 0,
      producto_id: this.detailsProduct.id,
      session: this.usuario.sessionCrm
    };
    this.usuarioService.favListProductSet(data).subscribe((response: any) => {
      if (response.id) {
        this.storeService.updateAppState({ action: ADD_FAVORITOS, payload: {} });
        this.addedToFavorites = true;
        this.heartIcon = 'icon-heart-fill';
        this.addToFavText = 'Agregado a favoritos';
        this.addToFavMessage = 'El producto fue agregado a tu lista de favoritos';
      }
    });
  }

  delFromFavourites(): void {
    const data = {
      deleted: 1,
      producto_id: this.detailsProduct.id,
      session: this.usuario.sessionCrm
    };
    this.usuarioService.favListProductSet(data).subscribe((response: any) => {
      if (response.id) {
        this.addedToFavorites = false;
        this.heartIcon = 'icon-heart';
        this.addToFavText = 'Agregar a favoritos';
        this.addToFavMessage = 'El producto fue removido de tu lista de favoritos';
      }
    });
  }

  validarMostrarResenia(): void {
    if (!this.showAgregarResenia && this.usuario.userId) {
      this.showAgregarResenia = true;
    } else {
      if (!this.usuario.userId) {
        this.openLoginView();
      }
      this.showAgregarResenia = false;
    }
  }

  openLoginView(): void {
    this.usuarioService.updateLoginModalState(true);
    this.usuarioService.updateLoginModalOrigin('escribir-reseña');
  }

  carouselOtrosLibros(): void {
    $(function () {
      // ↓↓ https://stackoverflow.com/a/25348529
      $('.carousel-otros-libros').owlCarousel('destroy');
      $('.carousel-otros-libros').owlCarousel({
        items: 5,
        nav: true,
        margin: 15,
        dots: false,
        autoplay: false,
        lazyLoad: true,
        navContainer: '#nav-carousel-otros-libros',
        responsive: {
          320: {
            items: 2,
            nav: true,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          768: {
            items: 3,
            nav: true,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          991: {
            items: 3,
            nav: true,
            dots: true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          1200: {
            items: 5,
            nav: true
          }
        }
      });
    });
  }

  lbGoToDetail(route): void {
    console.log(route);
  }
}
