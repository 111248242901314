import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Subject } from 'rxjs'
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

@Injectable()
export class EliminarCuentaService {

	private crmUrl : string = environment.apiUrl;
    user: any = null;
	
	public newUserSubject = new Subject<any>();
	constructor(
		public http: HttpClient,
  ){
  }

	

	solicitudEliminarCuenta(session){


        const objectShowSwal = {
            title: 'Solicitud eliminar cuenta',
            text:  'Estas seguro que deseas eliminar tu cuenta?',
            type:  'warning',
            confirmButtonText: 'Si, seguro',
            showCancelButton: true,
            funcion: true,
            session: session,
            allowOutsideClick: false
        };

        this.mensajeSwal(objectShowSwal);   

	}


    solicitarEliminarCuenta(session){

        return new Promise((resolve, reject) => {

            
            /* parameters to sent */
            let rest_data =[session, { email: 'user.user.email' }];
  
            /* prepare the data */
            var formData = new FormData();
            formData.append('method', 'solicitar_darse_de_baja');
            formData.append('rest_data', JSON.stringify(rest_data));
            formData.append('input_type', 'JSON');
            formData.append('response_type', 'JSON');
            /* send data to API server */
            this.http.post(this.crmUrl, formData)
              .subscribe(res => {
                resolve(res);

                
                /* Got the request from server and parse */
                const api_res:any = res;
                if (api_res != null) {
  
  
                    const objectShowSwal = {
                        title: 'Solicitud eliminar cuenta',
                        text:  api_res.description ? api_res.description : 'Por favor, intentalo mas tarde',
                        type:  'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Entiendo',
                        funcion: false,
                        session: session,
                        allowOutsideClick: false
                    };
            
                    this.mensajeSwal(objectShowSwal); 
                    
                }
              }, (err) => {
                const objectShowSwal = {
                    title: 'Solicitud eliminar cuenta',
                    text:  'Por favor, intentalo mas tarde estamos presentando un error',
                    type:  'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Entiendo',
                    funcion: false,
                    session: session,
                    allowOutsideClick: false
                };
        
                this.mensajeSwal(objectShowSwal); 
                reject(err);
              }); // end suscribe 
        });
    }


    mensajeSwal({title, text, type, showCancelButton, confirmButtonText, allowOutsideClick, funcion, session}){
        Swal.fire({
            title,
            text,
            type,
            showCancelButton,
            confirmButtonColor: '#B40001',
            confirmButtonText,
            allowOutsideClick
        }).then((result) => {
            if (result.value) {

                if (funcion) {
                    this.solicitarEliminarCuenta(session);
                }
            }
        });
    }





    eliminarCuenta(session){

        return new Promise((resolve, reject) => {

            
            /* parameters to sent */
            let rest_data =[session, { email: 'user.user.email' }];
  
            /* prepare the data */
            var formData = new FormData();
            formData.append('method', 'confirmar_darse_de_baja');
            formData.append('rest_data', JSON.stringify(rest_data));
            formData.append('input_type', 'JSON');
            formData.append('response_type', 'JSON');
            /* send data to API server */
            this.http.post(this.crmUrl, formData)
              .subscribe(res => {
                resolve(res);

                
                /* Got the request from server and parse */
                const api_res:any = res;
                if (api_res != null) {
  
  
                    const objectShowSwal = {
                        title: 'Solicitud eliminar cuenta',
                        text:  api_res.description ? api_res.description : 'Por favor, intentalo mas tarde',
                        type:  'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Entiendo',
                        funcion: false,
                        session: session,
                        allowOutsideClick: false
                    };
            
                    this.mensajeSwal(objectShowSwal); 
                    
                }
              }, (err) => {
                const objectShowSwal = {
                    title: 'Solicitud eliminar cuenta',
                    text:  'Por favor, intentalo mas tarde estamos presentando un error',
                    type:  'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Entiendo',
                    funcion: false,
                    session: session,
                    allowOutsideClick: false
                };
        
                this.mensajeSwal(objectShowSwal); 
                reject(err);
              }); // end suscribe 
        });
    }

   

 
}
