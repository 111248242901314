import { Injectable } from '@angular/core'
import { Router } from "@angular/router";
import { UsuarioService } from "./usuario.service";
import { CarritoService } from "./carrito.service";
import { StoreService } from "./store.service";

@Injectable()
export class FacturaService {
	constructor(
	  public  router:Router,
	  private usuarioService:UsuarioService,
    private carritoService:CarritoService,
    private storeService:StoreService
  ){
  }

  checkCarrito(){
    if(this.carritoService.getCarritoLocal()){
      //this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    } else {
      //
    }
  }
}
