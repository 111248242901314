import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { UsuarioService } from "../../../services/usuario.service";
import { FavoritosService } from "../../../services/favoritos.service";
import { ACTION_SHOPCAR, ADD_FAVORITOS, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { CarritoService } from "../../../services/carrito.service";
import { StoreService } from "../../../services/store.service";
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mis-favoritos',
  templateUrl: './mis-favoritos.component.html',
  styleUrls: ['./mis-favoritos.component.css']
})
export class MisFavoritosComponent implements OnInit {
  usuario:any;
  shopcar:any;
  carrito: any = [];
  noCoverImg:string='';
  addedToCart: any = {};
  detailsProduct: any = [];
  countProduct: number = 1;
  productosListaFavoritos:any = [];
  countCarritoProducts: number = 0;
  updateInProgress: boolean = false;
  showDeletePrdModal:boolean = false;

  totals: any = {
    total: 0,
    descuento: 0,
    subtotal: 0,
    iva: 0,
    gran_total: 0,
  };

  paginador: number = 6;
  paginador_offset_incremento = 6;
  paginador_offset = 0;
  paginador_validador = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    private storeService:StoreService,
    private carritoService:CarritoService,
    private usuarioService:UsuarioService,
    private favoritesService:FavoritosService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);

    this.storeService.updateAppState({action: ADD_FAVORITOS, payload: {}});

    this.store.pipe(
      filter(({ appReducer }) => appReducer.user.sessionCrm !== undefined)
    ).subscribe(response => {
      let stateCurrent:any = this.store.select('appReducer');
      let actionCurrent:any = stateCurrent.actionsObserver.value.type;
      let payloadCurrent:any = stateCurrent.actionsObserver.value.payload;
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
      if((actionCurrent == 'FAVORITOS' || actionCurrent == 'LOGIN') && this.productosListaFavoritos.length == 0) {
        this.getFavoritesList();
      }
    });
  }

  cargarMasFavoritos(){
    this.paginador_offset = this.paginador_offset_incremento;
    this.paginador_offset_incremento = this.paginador_offset_incremento + this.paginador;
    //this.paginador = this.paginador + this.paginador;

    this.getFavoritesList();
  }

  getFavoritesList(){
    let data = [
      this.usuario.sessionCrm,
      {
        producto_limit: this.paginador,
        producto_offset: this.paginador_offset,
      }
    ];
    this.favoritesService.getFavorites(data).subscribe((response:any) => {
      if(response)
      {
        if(response.productos_lista){

          // validamos si ya tiene elementos
          if(this.productosListaFavoritos.length > 0){
            this.productosListaFavoritos = [ ...this.productosListaFavoritos, ...response.productos_lista ];

            if(response.productos_lista.length === 0){
              this.paginador_validador = true;
            }
          }else{
            this.productosListaFavoritos = [];
            
            let array_favorites = response.productos_lista;
            for (let favorite of array_favorites){
              if(favorite.deleted != '1'){
                this.productosListaFavoritos.push(favorite);
              }
            }
          }
        } else {
          this.paginador_validador = true;
          this.productosListaFavoritos = [];
        }
      }
    });
  }

  delFromFavourites(id){
    if(this.usuario.sessionCrm) {
      let data = {
        deleted     : 1,
        producto_id : id,
        session     : this.usuario.sessionCrm
      };
      this.usuarioService.favListProductSet(data).subscribe((response:any) => {
        if(response.id){
          this.getFavoritesList();
        }
      });
    }
  }

  addCountProduct(){
    this.countProduct++
  }

  resCountProduct() {
    if (this.countProduct > 1) {
      this.countProduct--
    }
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  productToAddDetail(id) {
    this.addProductCarrito(id);
  }

  addProductCarrito(id){
    let user = this.usuario;
    let carritoLS = this.shopcar;

    //AGREGO EL ITEM DEL CARRO SELECCIONADO
    let producCurrent = {
      factura_id     : null,
      grupo_id       : null,
      producto_id    : id,
      cantidad       : String(this.countProduct)
    };
    
    this.updateInProgress = true;
    // Hay usuario logueado
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)
    }
  }

  addProductCarritoCrm(dataCrm)
  {
    this.carritoService.addProduct(dataCrm).subscribe((response:any) => {

      this.updateInProgress = false;

      if(response.hasErrors) {
        if(response.number == 2204) {
          // producto NO existe
        }
        else
        {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      }
      else
      {
        if(response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if(response.producto) {
          this.detailsProduct = response.producto;
        }

        if(response.factura.id){
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      } 
    })
  }

  getNewBill(dataCrm)
  {

    let user = this.usuario;
    let producCurrent = dataCrm[1]

    let dataCrmNF:any;
    if(user){
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res:any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //UPDATE CARRITO LOCAL
  updateCarrito(carrito) {
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        subtotal:   this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }
}
