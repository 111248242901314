import { EbooksService } from './../../../services/ebooks.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import {Store} from "@ngrx/store";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { CheckSessionAfterServiceResponseService } from 'src/app/services/check-session-after-service-response.service';

@Component({
  selector: 'app-ebooks',
  templateUrl: './ebooks.component.html',
  styleUrls: ['./ebooks.component.css']
})
export class EbooksComponent implements OnInit, OnDestroy {
  usuario: any;
  forma: FormGroup;
  formaTagus: FormGroup;
  ebooks: any = [];
  ebookState: boolean = false;
  userTagus: boolean = false;
  tagusPassMessage: string = '';
  tagusLoginMessage: string = '';
  tagusHasErrorsMsg: Array<string> = [];
  tagusEbooksListError: boolean = false;
  tagusToggleEbooksListNotification: string = '';

  constructor(
    private store: Store<any>,
  	private router: Router,
  	public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private ebooksService: EbooksService,
    private csasr: CheckSessionAfterServiceResponseService,
  )
  {
  	//Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.formaValidate();
    this.validateFormLoginTagus();
    this.store.subscribe(response => {

      this.usuario = response.appReducer.user

      if(this.usuario.sessionCrm && !this.ebookState){
        this.ebookState = true;
        let data = [ this.usuario.sessionCrm ];

        this.ebooksService.getEbooks(data).subscribe((response: any) => {
          this.csasr.check(response);
          if(response){
            if(response.hasErrors) {
              this.tagusEbooksListError = true;
              this.tagusToggleEbooksListNotification = '0';
            } else {
              if(response.length > 0){
                this.ebooks = response;
                this.tagusToggleEbooksListNotification = '1';
                this.tagusHasErrorsMsg = [];
                this.ebooks.forEach(element => {
                  this.tagusHasErrorsMsg.push('');
                });
              }
            }
          }
        });


        let dataCheck = [
        this.usuario.sessionCrm,
        {
          email: this.usuario.email
        }];
        this.ebooksService.checkEmailTagus(dataCheck).subscribe((response: any) => {
          this.csasr.check(response);
          if(response){
            if(response == true)
            {
              this.userTagus = true
            }
            else
            {
              this.userTagus = false
            }
          }
        });
        this.formaTagus.controls['usuario'].setValue(this.usuario.email)

      }
    });
  }

  ngOnDestroy(){
    this.ebookState = false;
  }

  formaValidate(){
    this.forma = new FormGroup({
      'email' : new FormControl({value:'', disabled:true})
    });
  }

  validateFormLoginTagus(){
    this.formaTagus = new FormGroup({
      'contrasena': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'usuario'  : new FormControl('', [
        //Validators.email,
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ])
    })
  }

  loginUsuarioTagus()
  {

    this.tagusLoginMessage='';
    if (this.formaTagus.valid == true) {

      let userData = [
        this.usuario.sessionCrm,
        {
        email    : this.formaTagus.controls['usuario'].value,
        password : this.formaTagus.controls['contrasena'].value
      }];

      //LOGIN EN EL CRM
      this.ebooksService.loginTagus(userData).subscribe( (response:any) => {
        this.csasr.check(response);
        if(response == true) {
          this.tagusLoginMessage = '* Las credenciales de acceso se han actualizado correctamente, ya puedes acceder al listado de eBooks.';

          let data = [ this.usuario.sessionCrm ];
          this.ebooksService.getEbooks(data).subscribe((response: any) => {
            this.csasr.check(response);
            if(response){
              if(response.hasErrors) {
                this.tagusEbooksListError = true;
                this.tagusToggleEbooksListNotification = '0';
              } else {
                if(response.length > 0){
                  this.ebooks = response;
                  this.tagusToggleEbooksListNotification = '1';
                  this.tagusHasErrorsMsg = [];
                  this.ebooks.forEach(element => {
                    this.tagusHasErrorsMsg.push('');
                  });
                }
              }
            }
          });

        }
        else
        {
          this.tagusLoginMessage = '* La validación del usuario de Tagus no se ha procesado correctamente, inténtalo de nuevo.';
        }

      })
    }
    else {
      //ERROR CUANDO NO SE HAN VALIDADO TODOS LOS CAMPOS
      this.formaTagus.controls['usuario'].markAsTouched();
      this.formaTagus.controls['contrasena'].markAsTouched();
    }

  }

  recuperarClaveTagus(){
    let data = [ this.usuario.sessionCrm ];
    this.tagusPassMessage = '';
    this.ebooksService.recuperarClaveTagus(data).subscribe((response: any) => {
      this.csasr.check(response);
      if(response.header.code == 200) {
        this.tagusPassMessage = '* Revisa tu correo electrónico, Tagus ha enviado un email para restablecer la contraseña de tu cuenta.'
      }
      else
      {
        this.tagusPassMessage = '* La solicitud de recuperar contraseña no se ha procesado correctamente, inténtalo de nuevo.'
      }
    });
  }

  descargarEbook(ebook, index: number){
    let a: HTMLElement = document.getElementById('tagusdownload_' + index.toString()) as HTMLElement;
    let data = [ this.usuario.sessionCrm, { lib_eb_isbn: ebook.lib_eb_isbn } ];
    this.ebooksService.descargarEbook(data).subscribe((response: any) => {
      this.csasr.check(response);
      if(response.hasErrors) {
        this.tagusHasErrorsMsg[index] = 'Error al validar usuario de Tagus. Sigue las instrucciones descritas al inicio de la página.';
      } else {
        var urlStreaming = response.url_descarga;
        a.attributes['href'].nodeValue = urlStreaming;
        a.click();
      }
    });
  }
}
