import { Injectable, OnDestroy } from '@angular/core'
import { Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { Usuario } from "../clases";
import { ACTION_LOGIN, ACTION_SHOPCAR, ACTION_ADDRESS, ACTION_LOGOUT, ACTION_DEL_SHOPCAR, LOAD_SHOPCAR } from "../store/actions/appAction";
import { StoreService } from "./store.service";
import { UsuarioService } from "./usuario.service";
import { CarritoService } from "./carrito.service";
import { AddressService } from "./address.service";
import { ResetDirecciones } from '../store/direcciones/actions';
import { ResetOrdenes } from '../store/ordenes/actions';
import { Subscription } from 'rxjs';

@Injectable()
export class SessionService implements OnDestroy{
  session:any;
  usuario:any;
  shopcar:any;
  susc: Subscription = new Subscription();

	constructor(
	  public  router:Router,
    private store: Store<any>,
	  private usuarioService:UsuarioService,
    private carritoService:CarritoService,
    private storeService:StoreService,
    private addressService: AddressService
  ){
    this.susc = this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
  }

  ngOnDestroy(){
    this.susc.unsubscribe();
  }

  checkSession(){

    if(this.usuarioService.getUserLocal()){

      let sessionDate = new Date( String(this.usuarioService.getUserLocalDate()) );
      let currentDate = new Date();

      let diffHours = currentDate.getTime() - sessionDate.getTime();
      let hours = Math.abs( currentDate.getTime() - sessionDate.getTime() ) / 36e5;

      if(hours > 2) {

        this.usuarioService.deleteUserLocal();
        this.carritoService.deleteCarritoLocal();
        this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: {} });
        this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: {} });
      }
      else
      {

        this.session = this.usuarioService.getUserLocal();
        if(this.session) {

          let currentUser = new Usuario();
          currentUser.login = true;
          currentUser.sessionCrm = this.usuarioService.getUserLocal();

          let dataCrm = {
            session_id: currentUser.sessionCrm
          };

          this.usuarioService.getUser(dataCrm).subscribe( (resp:any) => {

            if(resp.number == 11)
            {
              this.usuarioService.deleteUserLocal();
              this.carritoService.deleteCarritoLocal();
              this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: {} });
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: {} });
            }else{
              if (resp.status == 'ok') {
                currentUser.userId = resp.config.id;
                currentUser.clienteId = resp.cuenta.cliente_id;
                currentUser.tokenCrm = resp.config.token;
                currentUser.estado_registro = resp.config.estado_registro;
                currentUser.estado_login = resp.config.estado_login;
                currentUser.nombres = resp.config.nombres;
                currentUser.apellidos = resp.config.apellidos;
                currentUser.tipo_identificacion = resp.config.tipo_identificacion;
                currentUser.identificacion = resp.config.identificacion;
                currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
                currentUser.email = resp.config.email;
                currentUser.telefono = resp.config.telefono;
                currentUser.telefono_movil = resp.cuenta.telefono_movil;
                this.storeService.updateAppState({action: ACTION_LOGIN, payload: currentUser});

                let crmData = [
                  (currentUser.sessionCrm  ? currentUser.sessionCrm  : null), {}
                ];
                this.carritoService.newFactura(crmData).subscribe((response:any) => {
                  if(response.id){
                    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
                  }
                });
              }
            }
          });
        }
        else
        {
          this.usuarioService.deleteUserLocal();
          this.carritoService.deleteCarritoLocal();
          this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: {} });
          this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: {} });
        }

      }
    } else {

      if(this.carritoService.getCarritoLocal()){

        let crmData = [
          (null),
          {
            factura_id: this.carritoService.getCarritoLocal()
          }
        ];

        this.carritoService.getFactura(crmData).subscribe((response:any) => {
          if(response.id){
            this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
          }
          else
          {
            this.carritoService.deleteCarritoLocal()
            this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
          }

        });
      }

    }
  }

  closeSesion(url: string = '/'){
    let sessionCrm = this.usuario.sessionCrm;
    this.usuarioService.logOut(sessionCrm).subscribe(response => {
    });
    this.usuario = {};
    this.shopcar = {};
    this.usuarioService.deleteUserLocal();
    this.carritoService.deleteCarritoLocal();
    this.usuarioService.updateEnviromentUser(null);
    this.store.dispatch(new ResetDirecciones());
    this.store.dispatch(new ResetOrdenes());
    this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: null });
    this.router.navigate([url]);
  }
}
