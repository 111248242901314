import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { Store } from '@ngrx/store';
import { CardService } from '../../../services/card.service';
import { PseService } from '../../../services/pse.service';
import { StoreService } from '../../../services/store.service';
import { ACTION_PAYMENT_RESPONSE, ACTION_SHOPCAR, ACTION_SET_REGISTER_FULL } from '../../../store/actions/appAction';
import { CarritoService } from '../../../services/carrito.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PlatformLocation } from '@angular/common';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { Product } from 'src/app/interfaces/product';
import { Bill } from 'src/app/interfaces/bill';
import { DataLayerService } from '../../../services/data-layer.service';
import { environment } from '../../../../environments/environment';
import { data } from 'jquery';

@Component({
  selector: 'app-informacion-pago',
  templateUrl: './informacion-pago.component.html',
  styleUrls: ['./informacion-pago.component.css']
})
export class InformacionPagoComponent implements OnInit, OnDestroy {
  @ViewChild('formPagoWebCheckoutViewChild') formPagoWebCheckoutViewChild: ElementRef | undefined;

  user: any = null;
  shopcar: any = null;
  products:any = [];
  totals: any = {
    total: 0,
    descuento: 0,
    subtotal: 0,
    iva: 0,
    gran_total: 0,
    envio: 0
  };
  toggleByGranTotal: boolean = false;
  showGiftForm: boolean = false;
  cards: any = [];
  // cardTypes: any = [];
  showCardsForm: boolean = false;
  formCard: FormGroup;
  formPayCreditCard: FormGroup;
  currentCard: any = null;
  showFee: boolean = false;
  // pseDocs: any = [];
  // pseBanks: any = [];
  // psePersonTypes: any = [];
  pseDocumentNumber: string = '';
  psePayerName: string = '';
  showMainPayButton: boolean = true;
  currentPayMethodSelected: string = '';
  formPSE: FormGroup;
  paymentOrder:any = null;
  paymentResponse: any = {
    paymentMethod: '',
    response: null
  };
  addresses: any = [];
  showWarningMessage: boolean = false;
  warningMessage: string = '';
  showWarningMessageCard: boolean = false;
  warningMessageCard: string = '';
  bonoImage : string = '';
  bonoVirtualImage : string = '';
  methods: any = [];
  errorMessage: string = '';
  listCardsFlag: boolean = false
  statusPrivacidadManejoDatos: boolean = true;
  statusTerminosCondiciones: boolean = false;
  ebookInProducts: boolean = false;
  // defaults images lazyload
  loaderImg = '';
  offset = 5;
  totalesloco: Observable<any>;
  showComponentBonosVirtuales: boolean = false;
  facturaID: string = '';

  formPagoWebCheckout: FormGroup = this.formBuilder.group({
    merchantId: [''],
    accountId: [''],
    description: [''],
    referenceCode: [''],
    amount: [''],
    tax: [''],
    taxReturnBase: [''],
    currency: [''],
    signature: [''],
    test: [''],
    responseUrl: [''],
    confirmationUrl: [''],
    buyerFullName: [''],
    buyerEmail: [''],
    shippingAddress: [''],
    shippingCity: [''],
    shippingCountry: [''],
    telephone: [''],
    extra1: [''],
    extra2: [''],
  });
  urlActionFormPayu = environment.urlActionFormPayu;


  constructor(
    private meta: Meta,
    private titleService: Title,
    private store: Store<any>,
    private cardService: CardService,
    private pseService: PseService,
    private storeService: StoreService,
    private carrito: CarritoService,
    private dataLayerService: DataLayerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public  platformLocation: PlatformLocation,
    private userService: UsuarioService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public formBuilder: FormBuilder,

  )
  {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  private setEbookInProducts(shopcar: any) {
    if(shopcar == undefined || shopcar == null) return;

    if(!shopcar.grupos) return;

    if(shopcar.grupos.length == 0) return;

    if(!shopcar.grupos[0].lineas) return;

    if(!shopcar.grupos[0].lineas.productos) return;

    if(shopcar.grupos[0].lineas.productos.length == 0) return;

    this.ebookInProducts = false;
    shopcar.grupos[0].lineas.productos.forEach(product => {
      if(product.producto.type.valor.toLowerCase() == 'ebook') {
        this.ebookInProducts = true;
      }
    });
  }

  ngOnDestroy() {
    if(localStorage.getItem('canEnterDatosEnvio')) {
      localStorage.removeItem('canEnterDatosEnvio');
    }
  }

  ngOnInit() {
    window.scrollTo(0,0);

    this.showComponentBonosVirtuales = localStorage.getItem('bill') ? true : false;

    this.totalesloco = this.store.select('appReducer');

    let dataMetas = {
      title: 'Proceso de compra, información de pago',
      description: 'En nuestro portal encontrarás diferentes métodos de pago como tarjeta débito, crédito, PSE o cancelar tu compra vía Efecty.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.formCardValidate();
    this.formPayCreditCardValidate();
    this.formPSEValidate();

    this.activatedRoute.params.subscribe( params => {
      if(params != null) {
        if('billId' in params) {
          this.facturaID = params.billId;
          this.carrito.getFactura([null,{factura_id:params.billId}]).toPromise().then( (bill: any) => {
            if(bill != null) {
              if(bill.id) {
                if(bill.error) {
                    this.errorMessage = bill.error.message + ':';
                  if('metodos' in bill.error) {
                    this.methods = bill.error.metodos;
                    this.errorMessage += ' ' + this.methods.join(', ');
                  }
                }
                this.setShopcar(bill);
              }
            }
          });
        }
      }
    });

    this.store.subscribe(response => {
      const stateCurrent: any = this.store.select('appReducer');
      const actionCurrent: any = stateCurrent.actionsObserver.value.type;

      this.addresses = response.appReducer.addresses;
      this.totals = response.appReducer.shopcar.totales;

      this.user = response.appReducer.user;

      if (this.user) {
        this.statusPrivacidadManejoDatos = this.user.estado_registro === 'REGISTRO_FULL' ? true : false;
      }

      this.setEbookInProducts(response.appReducer.shopcar);

      if(actionCurrent === 'UPDATE_TOTALS_SHOPCAR'){

        this.toggleByGranTotal = this.totals.gran_total == 0 ? true : false;
        this.shopcar = response.appReducer.shopcar;

        if(this.shopcar.error) {
            this.errorMessage = this.shopcar.error.message + ':';
          if('metodos' in this.shopcar.error) {
            this.methods = this.shopcar.error.metodos;
            this.errorMessage += ' ' + this.methods.join(', ');
          }
        } else {
          this.errorMessage = '';
          this.methods = [];
        }

      }

      if(actionCurrent == 'LOGIN'){
        this.listCardsFlag = false;
      }

      if(this.user != null) {

        if(this.listCardsFlag == false) {
          this.listCards();
        }

        if ('estado_login' in this.user) {
          if (this.user.estado_login.toLowerCase() == 'login_parcial') {
            this.formCardValidate(true);
          } else {
            this.formCardValidate(false);
          }
        }
      }
    });

    // se deja en comentario, este WS ya que se utilizaba anteriormente en el checkout por medio de API
    // ahora es con el web cheeckout y no se hace uso de este servicio
    // this.cardService.getDataPayu().toPromise().then( res => {
    //   if(res.tipos_tarjetas != null) {
    //     this.cardTypes = res.tipos_tarjetas;
    //   }
    //   if(res.pse != null) {
    //     this.pseBanks = res.pse.bancos;
    //     this.pseDocs = res.pse.tipos_documento.N;
    //     this.psePersonTypes = res.pse.tipos_persona;
    //   }
    // });

  }

  setMetaData(data) {
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }

  setShopcar(bill) {
    const products: Array<Product> = [];
    this.shopcar = bill;
    this.setEbookInProducts(this.shopcar);
    this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: this.shopcar});

    if (this.shopcar.grupos) {
      this.products = this.shopcar.grupos[0].lineas.productos;
      this.totals = this.shopcar.totales;
      this.toggleByGranTotal = this.totals.gran_total === 0 ? true : false;

      this.shopcar.grupos[0].lineas.productos.forEach(product => {
        const productDataLayer: Product = {
          name: product.producto.name,
          id: product.producto.plu_sku_erp_code,
          plu: product.producto.plu_sku_erp_code,
          slug: product.producto.slug,
          price: product.producto.price_dcto,
          type: product.producto.type.valor,
          quantity: product.cantidad
        };
        products.push(productDataLayer);
      });

      this.dataLayerService.onCheckout(4, 'Información de pago', products);
      this.dataLayerService.customEvent('checkout_step_04');
    }
  }

  showCardForm() {
    if (this.user.estado_login.toLowerCase() == 'login_parcial') {
      this.showCardsForm = false;
      this.openLoginView();
    } else {
      this.showWarningMessageCard = false;
      this.showCardsForm = !this.showCardsForm;
    }
  }

  formCardValidate(isDisabled = false) {
    this.formCard = new FormGroup({
      'number': new FormControl({value: '', disabled: isDisabled}, Validators.required),
      'payer_name': new FormControl({value: '', disabled: isDisabled}, Validators.required),
      'end_date': new FormControl({value: '', disabled: isDisabled}, [Validators.required, Validators.pattern(/^([0-9]{4})\/(0[1-9]|1[0-2])$/)]),
      'paymentMethod': new FormControl({value: '', disabled: isDisabled}, Validators.required),
      'identificationNumber': new FormControl({value: '', disabled: isDisabled}, Validators.required),
    });
  }

  formPayCreditCardValidate() {
    this.formPayCreditCard = new FormGroup({
      'numero_cuotas': new FormControl('1', [Validators.required, Validators.min(1), Validators.max(36), Validators.pattern("^[0-9]*$")]),
      'codigo_seguridad': new FormControl('', [Validators.required, Validators.pattern(/^([0-9]{3,4})$/)]),
    });
  }

  formPSEValidate() {
    this.formPSE = new FormGroup({
      'bank': new FormControl('', Validators.required),
      'person_type': new FormControl('', Validators.required),
      'document_type': new FormControl('', Validators.required),
      'document_number': new FormControl('', Validators.required),
      'account_holder': new FormControl('', Validators.required),
    });

    this.formPSE.controls['bank'].setValue('0');
    this.formPSE.controls['person_type'].setValue('N');
  }

  listCards() {
    if (this.user.sessionCrm && this.user.estado_login == 'LOGIN_FULL') {
      this.listCardsFlag = true;
      const cardsData = {
        session_id: this.user.sessionCrm,
        start_date: '2000-01-01T12:00:00',
        end_date: '9999-01-01T12:00:00',
      };
      /* this.cardService.search(cardsData).subscribe( res => {

        if (res != null && 'tokens_credit_card' in res) {
          this.cards = res.tokens_credit_card;
          if(this.cards.length > 0) {
            this.cards.forEach( obj => {
              obj.selected = 'text-muted';
              obj.bgSelected = 'border shadow-bx';
            });
            this.cards[0].selected = 'text--blue-medium';
            this.cards[0].bgSelected = 'border-left border-right-0 border-bottom-0 border-width-4 border-blue-medium bg-gray-light';
            this.currentCard = this.cards[0];
            this.showFee = true;
            this.showCardsForm = false;
          } else {
            this.showCardsForm = true;
            this.showFee = false;
          }
        }
      }); */
    }

  }

  createCard() {
    if(this.user.estado_login.toLowerCase() == 'parcial')
      return false;

    if (this.formCard.valid) {
      const card = {
        session_id: this.user.sessionCrm,
        name: this.formCard.value.payer_name,
        identification_number: this.formCard.value.identificationNumber,
        number: this.formCard.value.number,
        expirationDate: this.formCard.value.end_date,
        payment_method: this.formCard.value.paymentMethod
      };

      this.cardService.create(card).subscribe( res => {
        if (res != null) {
          if('id' in res) {
            this.listCards();
          } else {
            this.showWarningMessageCard = true;
            let d = res.description;
            if(d.includes('message:')) {
              d = d.split('message:')[1];
            }
            this.warningMessageCard = d;
          }
        }
      });
    } else {
      this.formCard.controls['payer_name'].markAsTouched();
      this.formCard.controls['number'].markAsTouched();
      this.formCard.controls['end_date'].markAsTouched();
      this.formCard.controls['paymentMethod'].markAsTouched();
      this.formCard.controls['identificationNumber'].markAsTouched();
      return false;
    }
  }

  deleteCard(card) {
    if(this.user.estado_login.toLowerCase() == 'parcial')
      return false;

    this.showCardsForm = false;
    this.showFee = false;
    let deletecard = {
      session_id: this.user.sessionCrm,
      tokenId: card.credit_card_token
    };
    this.cardService.delete(deletecard).subscribe( res => {
      if(res != null) {
        this.listCards();
      }
    });
    return false;
  }

  selectCard(card) {
    this.cards.forEach( obj => {
      obj.selected = 'text-muted';
      obj.bgSelected = 'border shadow-bx';
    });
    card.selected = 'text--blue-medium';
    card.bgSelected = 'border-left border-right-0 border-bottom-0 border-width-4 border-blue-medium bg-gray-light';
    this.showCardsForm = false;
    this.showFee = true;
    this.currentCard = card;
    return false;
  }

  setPayMethod(payMethod, showMainPayButton) {
    if(payMethod.toLowerCase() == 'tc') {
      if(this.cards.length == 0) {
        if(this.user.estado_login.toLowerCase() == 'login_parcial') {
          this.showCardsForm = false;
          this.openLoginView();
        }
      }
    }
    this.currentPayMethodSelected = payMethod;
    this.showMainPayButton = showMainPayButton;
  }

  payOrder() {

    this.showWarningMessage = false;
    this.warningMessage = '';

    if(this.currentPayMethodSelected == '' && !this.toggleByGranTotal)
    {
      this.warningMessage = '*Debes seleccionar un medio de pago o si activaste un bono, redime un saldo válido.'
      this.showWarningMessage = true;
      return false;
    }

    if(!this.statusPrivacidadManejoDatos) {
      this.warningMessage = '*Debes aceptar las políticas de privacidad y el manejo de datos.'
      this.showWarningMessage = true;
      return false;
    }

    if(this.ebookInProducts) {
      if(!this.statusTerminosCondiciones) {
        this.warningMessage = '*Debes aceptar los términos y condiciones'
        this.showWarningMessage = true;
        return false;
      }
    }

    this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: {}});
    this.carrito.deleteCarritoLocal();

    this.payOrderHelper();
  }

  private getNewOrdenPago(car, callback: Function) {
    this.carrito.getNewOrdenPago(car).toPromise().then(res => {
      if(res != null) {
        if(res.alerta && res.alerta != null) {
          Swal.fire({
            title: '¿Deseas continuar con la compra?',
            text: res.alerta,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B40001',
            cancelButtonColor: '#223158',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.value) {
              this.paymentOrder = res;
              this.paymentOrder.metodo_pago_id = this.currentPayMethodSelected;
              callback(res);
            }
          })
        } else if(res.hasErrors && res.number == 2201) {
          Swal.fire({
            text: 'El valor de algunos productos de tu carrito de compra han cambiado, revisa los términos y condiciones de las promociones, descuentos aplicados y el total de tu compra.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#B40001',
            confirmButtonText: 'Continuar',
          }).then((result) => {
            if (result.value) {
              callback(res);
            }
          });
          this.carrito.getFactura([null,{factura_id: this.facturaID}]).toPromise().then( (bill: any) => {
            if(bill != null) {
              if(bill.id) {
                if(bill.error) {
                    this.errorMessage = bill.error.message + ':';
                  if('metodos' in bill.error) {
                    this.methods = bill.error.metodos;
                    this.errorMessage += ' ' + this.methods.join(', ');
                  }
                }
                this.setShopcar(bill);
              }
            }
          });
        } else {
          this.paymentOrder = res;
          this.paymentOrder.metodo_pago_id = this.currentPayMethodSelected;
          callback(res);
        }

        return false;
      }
    });
  }

  private payOrderHelper() {

    this.setRegisterFull();

    if(this.toggleByGranTotal) {
      console.log('oscar');

          let car = [
            this.user.sessionCrm,
            {
              factura_id: this.shopcar.id
            }
          ];

          this.getNewOrdenPago(car, paymentOrder => {
            this.carrito.stpPay_confirmarTransaccion([this.user.sessionCrm,{orden_pago_id: paymentOrder.id}]).toPromise().then( (res: any) => {
              if (res != null) {
                if ('hasErrors' in res) {
                  if (res.hasErrors == 1) {
                    throw res.description;
                  } else {
                    this.setPurchaseDataLayer();
                    this.storePaymentResponse(res);
                    // this.router.navigate(['/', 'carrito', 'confirmacion-pago', this.shopcar.id, paymentOrder.id]);
                  }
                }
              }
            });
          });
          return false;
    } else {
      this.setPurchaseDataLayer();
      let payMethodSelectedForService = this.currentPayMethodSelected == 'EFECTIVO' ? "libnal_efectivo" : "libnal_tcpse";

      let car = [
        this.user.sessionCrm,
        {
          factura_id: this.shopcar.id,
        }
      ];
      this.carrito.get_createOrden(car).toPromise().then((res: any) => {
          let data = [
            this.user.sessionCrm,
            {
              orden_pago_id : res.id,
              cuenta: payMethodSelectedForService
            },
          ];

          this.carrito.getPayuWebCheckout(data).toPromise().then( (res: any) => {
            this.formPagoWebCheckout.patchValue({
              merchantId: res.web_form.merchantId,
              accountId: res.web_form.accountId,
              description: res.web_form.description,
              referenceCode: res.web_form.referenceCode,
              amount:  res.web_form.amount,
              tax:  res.web_form.tax,
              taxReturnBase:  res.web_form.taxReturnBase,
              currency:  res.web_form.currency,
              signature:  res.web_form.signature,
              test:  res.web_form.isTest,
              paymentMethods: res.web_form.paymentMethods,
              buyerEmail:  res.web_form.buyerEmail,
              responseUrl: res.web_form.responseUrl,
              confirmationUrl: res.web_form.confirmationUrl,
            });
            setTimeout(() => {
              this.formPagoWebCheckoutViewChild.nativeElement.submit();
            }, 1500);
          })

        });
    }
  }

  /**
   * @description registra en el data layer el evento de conversion
   */
  setPurchaseDataLayer() {
    const products: Array<Product> = [];
    const bill: Bill = {
      id: this.shopcar.id,
      affiliation: 'Ecommerce - CERO',
      revenue: this.shopcar.totales.gran_total,
      tax: '0',
      shipping: this.shopcar.totales.envio,
      coupon: this.shopcar.totales.descuento
    };
    this.shopcar.grupos[0].lineas.productos.forEach(product => {
      const productDataLayer: Product = {
        name: product.producto.name,
        id: product.producto.plu_sku_erp_code,
        plu: product.producto.plu_sku_erp_code,
        slug: product.producto.slug,
        price: product.producto.price_dcto,
        type: product.producto.type.valor,
        quantity: product.cantidad
      };
      products.push(productDataLayer);
    });
    this.dataLayerService.purchase(bill, products);
  }

  // private payOrderHelper() {

  //   this.setRegisterFull();

  //   if(this.toggleByGranTotal) {

  //     let car = [
  //       this.user.sessionCrm,
  //       {
  //         factura_id: this.shopcar.id
  //       }
  //     ];

  //     this.getNewOrdenPago(car, paymentOrder => {
  //       this.carrito.stpPay_confirmarTransaccion([this.user.sessionCrm,{orden_pago_id: paymentOrder.id}]).toPromise().then( (res: any) => {
  //         if (res != null) {
  //           if ('hasErrors' in res) {
  //             if (res.hasErrors == 1) {
  //               throw res.description;
  //             } else {

  //               const products: Array<Product> = [];
  //               const bill: Bill = {
  //                 id: this.shopcar.id,
  //                 affiliation: 'Ecommerce - CERO',
  //                 revenue: this.shopcar.totales.gran_total,
  //                 tax: '0',
  //                 shipping: this.shopcar.totales.envio,
  //                 coupon: this.shopcar.totales.descuento
  //               };
  //               this.shopcar.grupos[0].lineas.productos.forEach(product => {
  //                 const productDataLayer: Product = {
  //                   name: product.producto.name,
  //                   id: product.producto.plu_sku_erp_code,
  //                   plu: product.producto.plu_sku_erp_code,
  //                   slug: product.producto.slug,
  //                   price: product.producto.price_dcto,
  //                   type: product.producto.type.valor,
  //                   quantity: product.cantidad
  //                 };
  //                 products.push(productDataLayer);
  //               });
  //               this.dataLayerService.purchase(bill, products);

  //               this.storePaymentResponse(res);
  //               this.router.navigate(['/', 'carrito', 'confirmacion-pago', this.shopcar.id, paymentOrder.id]);

  //             }
  //           }
  //         }
  //       });
  //     });
  //     return false;
  //   } else {

  //     switch(this.currentPayMethodSelected) {
  //       case 'TC': {
  //         this.payWithTC();
  //         break;
  //       }
  //       case 'PSE': {
  //         this.payWithPSE();
  //         break;
  //       }
  //       case 'BALOTO': {
  //         this.payWithBaloto();
  //         break;
  //       }
  //       case 'EFECTY': {
  //         this.payWithEfecty();
  //         break;
  //       }
  //     }
  //     return false;
  //   }
  // }

  setRegisterFull() {
    if(this.user.estado_registro != 'REGISTRO_FULL')
    {
      this.userService.setRegisterFull(this.user.sessionCrm).toPromise().then( (res: any) => {
        if(res.status == 'ok')
        {
          let dataCrm = {
            session_id: this.user.sessionCrm
          };
          this.userService.getUser(dataCrm).subscribe(resp => {
            if (resp.status == 'ok'){

              let currentUser: any = {};
              currentUser.login = true;
              currentUser.sessionCrm = this.user.sessionCrm;
              currentUser.userId = resp.config.id;
              currentUser.clienteId = resp.cuenta.cliente_id;
              currentUser.tokenCrm = resp.config.token;
              currentUser.estado_registro = resp.config.estado_registro;
              currentUser.estado_login = resp.config.estado_login;
              currentUser.nombres = resp.config.nombres;
              currentUser.apellidos = resp.config.apellidos;
              currentUser.tipo_identificacion = resp.config.tipo_identificacion;
              currentUser.identificacion = resp.config.identificacion;
              currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
              currentUser.email = resp.config.email;
              currentUser.telefono = resp.config.telefono;
              currentUser.telefono_movil = resp.cuenta.telefono_movil;

              //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
              this.userService.setUserLocal(currentUser);
              this.storeService.updateAppState({ action: ACTION_SET_REGISTER_FULL, payload: currentUser });
            }
          });
        }
      });
    }
  }

  payWithTC() {
    const numeroCuotas = this.formPayCreditCard.controls.numero_cuotas.value;
    const cvvNumber = this.formPayCreditCard.controls.codigo_seguridad.value;
    if(numeroCuotas == '' || numeroCuotas == '0') {
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor seleccione Tarjeta de Crédito y seleccione cantidad de cuotas.';
      return false;
    }

    if(cvvNumber == '' || cvvNumber == '0') {
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor ingrese el código CVV de la tarjeta';
      return false;
    }

    if(isNaN(cvvNumber)){
      this.showWarningMessage = true;
      this.warningMessage = 'El código CVV de la tarjeta debe ser numérico';
      return false;
    }

    const cvvNumberLength = cvvNumber.length;
    if(cvvNumberLength < 3 || cvvNumberLength > 4){
      this.showWarningMessage = true;
      this.warningMessage = 'El código CVV de la tarjeta debe tener de 3 a 4 caracteres';
      return false;
    }

    let car = [
      this.user.sessionCrm,
      {
        factura_id: this.shopcar.id
      }
    ];

    this.getNewOrdenPago(car, paymentOrder => {
      if(!('id' in paymentOrder)) {
        this.showWarningMessage = true;
        this.warningMessage = paymentOrder.description;
        return false;
      }

      this.setRegisterFull();

      let payment = {
        session_id: this.user.sessionCrm,
        orden_pago_id: paymentOrder.id,
        token_id: this.currentCard.credit_card_token,
        numero_cuotas: this.formPayCreditCard.controls.numero_cuotas.value,
        codigo_seguridad: this.formPayCreditCard.controls.codigo_seguridad.value
      };

      this.cardService.tcPayment(payment).subscribe( res => {

        if(res != null) {

          if(res.hasErrors == true) {
            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'

          } else if(res.code == 'SUCCESS') {

            const products: Array<Product> = [];
            const bill: Bill = {
              id: this.shopcar.id,
              affiliation: 'Ecommerce - TC',
              revenue: this.shopcar.totales.gran_total,
              tax: '0',
              shipping: this.shopcar.totales.envio,
              coupon: this.shopcar.totales.descuento
            };
            this.shopcar.grupos[0].lineas.productos.forEach(product => {
              const productDataLayer: Product = {
                name: product.producto.name,
                id: product.producto.plu_sku_erp_code,
                plu: product.producto.plu_sku_erp_code,
                slug: product.producto.slug,
                price: product.producto.price_dcto,
                type: product.producto.type.valor,
                quantity: product.cantidad
              };
              products.push(productDataLayer);
            });
            this.dataLayerService.purchase(bill, products);

            this.storePaymentResponse(res);
            this.router.navigate(['/', 'carrito', 'confirmacion-pago', this.shopcar.id, paymentOrder.id]);
          } else {
            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
          }
        } else {
          this.showWarningMessage = true;
          this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
        }

      });
    });
    return false;
  }

  payWithPSE() {
    if(this.formPSE.valid) {

      let car = [
        this.user.sessionCrm,
        {
          factura_id: this.shopcar.id
        }
      ];
      this.getNewOrdenPago(car, paymentOrder => {
        if(!('id' in paymentOrder)) {
          this.showWarningMessage = true;
          this.warningMessage = paymentOrder.description;
          return false;
        }

        this.setRegisterFull();
        let payment = {
          session_id: this.user.sessionCrm,
          orden_pago_id: paymentOrder.id,
          pse_banco_codigo: this.formPSE.value.bank,
          pse_tipo_persona: this.formPSE.value.person_type,
          pse_tipo_documento: this.formPSE.value.document_type,
          pse_pagador_dni: this.formPSE.value.document_number,
          pse_pagador_nombre: this.formPSE.value.account_holder,
        };
        this.pseService.psePayment(payment).subscribe( (res:any) => {

          if(res != null) {

            if(res.hasErrors == true) {

              this.showWarningMessage = true;
              this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'

            } else if(res.code == 'SUCCESS') {
              this.storePaymentResponse(res);
              const products: Array<Product> = [];
              const bill: Bill = {
                id: this.shopcar.id,
                affiliation: 'Ecommerce - PSE',
                revenue: this.shopcar.totales.gran_total,
                tax: '0',
                shipping: this.shopcar.totales.envio,
                coupon: this.shopcar.totales.descuento
              };
              this.shopcar.grupos[0].lineas.productos.forEach(product => {
                const productDataLayer: Product = {
                  name: product.producto.name,
                  id: product.producto.plu_sku_erp_code,
                  plu: product.producto.plu_sku_erp_code,
                  slug: product.producto.slug,
                  price: product.producto.price_dcto,
                  type: product.producto.type.valor,
                  quantity: product.cantidad
                };
                products.push(productDataLayer);
              });
              this.dataLayerService.purchase(bill, products);

              if (res.bank_url) {
                window.open(res.bank_url, '_self');
              } else {
                if (res.state_id == 'DECLINED' && res.response_code == "ANTIFRAUD_REJECTED") {
                  this.showWarningMessage = true;
                  this.warningMessage = 'Aparentemente tu pago ha sido declarado como intento de fraude, por favor comunícate con tu entidad bancaria.';
                } else {
                  this.showWarningMessage = true;
                  this.warningMessage = 'No se logró establecer comunicación con la entidad bancaria, inténtalo nuevamente.';
                }
              }
            } else {
              this.showWarningMessage = true;
              this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
            }
          } else {
            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
          }
        });
      });
    } else {
      this.formPSE.controls['bank'].markAsTouched();
      this.formPSE.controls['person_type'].markAsTouched();
      this.formPSE.controls['document_type'].markAsTouched();
      this.formPSE.controls['document_number'].markAsTouched();
      this.formPSE.controls['account_holder'].markAsTouched();
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor complete toda la información relacionada a PSE.';
      return false;
    }
  }

  payWithBaloto() {

    let car = [
      this.user.sessionCrm,
      {
        factura_id: this.shopcar.id
      }
    ];
    this.getNewOrdenPago(car, paymentOrder => {
      if(!('id' in paymentOrder)) {
        this.showWarningMessage = true;
        this.warningMessage = paymentOrder.description;
        return false;
      }

      this.setRegisterFull();
      let payment = [
        this.user.sessionCrm,
        {
          orden_pago_id: paymentOrder.id
        }
      ];
      this.carrito.set_pagarBaloto(payment).subscribe( (res:any) => {

        if(res != null) {

          if(res.hasErrors == true) {

            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'

          } else if (res.code == 'SUCCESS') {

            const products: Array<Product> = [];
            const bill: Bill = {
              id: this.shopcar.id,
              affiliation: 'Ecommerce - BALOTO',
              revenue: this.shopcar.totales.gran_total,
              tax: '0',
              shipping: this.shopcar.totales.envio,
              coupon: this.shopcar.totales.descuento
            };
            this.shopcar.grupos[0].lineas.productos.forEach(product => {
              const productDataLayer: Product = {
                name: product.producto.name,
                id: product.producto.plu_sku_erp_code,
                plu: product.producto.plu_sku_erp_code,
                slug: product.producto.slug,
                price: product.producto.price_dcto,
                type: product.producto.type.valor,
                quantity: product.cantidad
              };
              products.push(productDataLayer);
            });

            this.dataLayerService.purchase(bill, products);
            this.storePaymentResponse(res);

            if (res.state_id == 'DECLINED') {
              Swal.fire({
                text: 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#B40001',
                confirmButtonText: 'Ok',
              }).then((result) => {});
            } else {
              this.router.navigate(['/', 'carrito', 'confirmacion-pago', this.shopcar.id, paymentOrder.id, res.reference, res.url_payment_receipt_html]);
            }

          }
          else
          {
            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
          }

        }
        else
        {
          this.showWarningMessage = true;
          this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
        }

      });
    });
    return false;
  }

  payWithEfecty() {
    let car = [
      this.user.sessionCrm,
      {
        factura_id: this.shopcar.id
      }
    ];
    this.getNewOrdenPago(car, paymentOrder => {
      if(!('id' in paymentOrder)) {
        this.showWarningMessage = true;
        this.warningMessage = paymentOrder.description;
        return false;
      }
      this.setRegisterFull();
      let payment = [
        this.user.sessionCrm,
        {
          orden_pago_id: paymentOrder.id
        }
      ];
      this.carrito.set_pagarEfecty(payment).subscribe( (res:any) => {

        if(res != null) {

          if(res.hasErrors == true) {

            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'

          } else if(res.code == 'SUCCESS') {
            const products: Array<Product> = [];
            const bill: Bill = {
              id: this.shopcar.id,
              affiliation: 'Ecommerce - EFECTY',
              revenue: this.shopcar.totales.gran_total,
              tax: '0',
              shipping: this.shopcar.totales.envio,
              coupon: this.shopcar.totales.descuento
            };
            this.shopcar.grupos[0].lineas.productos.forEach(product => {
              const productDataLayer: Product = {
                name: product.producto.name,
                id: product.producto.plu_sku_erp_code,
                plu: product.producto.plu_sku_erp_code,
                slug: product.producto.slug,
                price: product.producto.price_dcto,
                type: product.producto.type.valor,
                quantity: product.cantidad
              };
              products.push(productDataLayer);
            });
            this.dataLayerService.purchase(bill, products);
            this.storePaymentResponse(res);

            if(res.state_id == 'DECLINED') {
              Swal.fire({
                text: 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#B40001',
                confirmButtonText: 'Ok',
              }).then((result) => {});
            } else {
              this.router.navigate(['/', 'carrito', 'confirmacion-pago', this.shopcar.id, paymentOrder.id, res.reference, res.url_payment_receipt_html]);
            }
          } else {
            this.showWarningMessage = true;
            this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
          }
        } else {
          this.showWarningMessage = true;
          this.warningMessage = 'No se logró establecer comunicación con la plataforma de pago seleccionada, inténtalo nuevamente.'
        }
      });
    });
    return false;
  }

  private storePaymentResponse(res) {
    this.storeService.updateAppState({action: ACTION_PAYMENT_RESPONSE, payload: res});
  }

  openLoginView(){
    this.userService.updateLoginModalState(true);
    this.userService.updateLoginModalOrigin('informacion-pago');
  }

  togglePrivacidadManejoDatos(event)
  {
    if ( event.target.checked ) {
         this.statusPrivacidadManejoDatos = true
         this.warningMessage = ''
         this.showWarningMessage = false
    }
    else
    {
       this.statusPrivacidadManejoDatos = false
    }
  }

  toggleTerminosCondiciones(event)
  {
    if ( event.target.checked ) {
         this.statusTerminosCondiciones = true;
         this.warningMessage = '';
         this.showWarningMessage = false;
    } else {
       this.statusTerminosCondiciones = false;
    }
  }

}
