import { ColeccionesHomeEffects } from "./home/effects";
import { NovedadesHomeEffects } from "./home/novedades/effects";
import { MasVendidosHomeEffects } from "./home/masvendidos/effects";
import { RecomendadosHomeEffects } from "./home/recomendados/effects";
import { ColeccionesEffects } from "./colecciones/effects";
import { ColeccionDetalleEffects } from "./colecciones/detalle/effects";
import { PromocionesEffects } from "./promociones/effects";
import { PromocionDetalleEffects } from "./promociones/detalle/effects";
import { EventosEffects } from "./eventos/effects";
import { OrdenesEffects } from "./ordenes/effects";
import { DireccionesEffects } from "./direcciones/effects";

export const effectsRoot = [
  ColeccionesEffects,
  ColeccionDetalleEffects,
  PromocionesEffects,
  PromocionDetalleEffects,
  ColeccionesHomeEffects,
  NovedadesHomeEffects,
  MasVendidosHomeEffects,
  RecomendadosHomeEffects,
  EventosEffects,
  OrdenesEffects,
  DireccionesEffects,
];
