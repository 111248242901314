import { Title, Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { MetaData } from '../interfaces/meta-data';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(
    private meta: Meta,
    private titleService: Title,
  ){}

  setMetaData(data:MetaData): void {
    this.titleService.setTitle(data.title + ' | ' + data.site);
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title + ' | ' + data.category });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content': data.title + ' | ' + data.category });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title + ' | ' + data.category });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': data.brand });
    this.meta.updateTag({ 'property': 'product:availability', 'content': data.available });
    this.meta.updateTag({ 'property': 'product:condition', 'content': data.condition });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': data.price });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': data.currency });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': data.plu });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }
}
