import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()

export class ContenidosService {

	private crmUrl : string = environment.apiUrl;

	constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,){
	}

	getList(data){
		var crmData =  [data];

		let formData = new FormData();
		formData.append('method', 'contenidos_list');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
    	return this.http.post(this.crmUrl+'?ws=noload', formData).pipe( map(response => {
				this.csasr.check(response);
			return response
		}))
	}

	getDetalle(data) {
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'contenidos_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
    	return this.http.post(this.crmUrl, formData).pipe( map(response => {
				this.csasr.check(response);
			return response
		}))
	}
}
