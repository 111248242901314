import { ProductosService } from './../../../services/productos.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarRecomendadosHomeSuccess, CargarRecomendadosHomeFail, AccionesRecomendadosHome } from './actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class RecomendadosHomeEffects {

    constructor(
        private actions$: Actions,
        public productosService: ProductosService
    ) {}

    @Effect()
    cargarRecomendadosHome$ = this.actions$.pipe( ofType(AccionesRecomendadosHome.CARGAR_RECOMENDADOS_HOME) )
        .pipe(
            switchMap( (data: any) => {
                return this.productosService.productSearch(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response) {
                                return new CargarRecomendadosHomeSuccess(response);
                            }
                            return new CargarRecomendadosHomeFail(response);
                        } ),
                        catchError( error => of(new CargarRecomendadosHomeFail(error)) )
                    );
            })
        );
}
