import { Component, OnInit, Input } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/appState';
import { Observable } from 'rxjs';
import { CambiarEstadoModalRestricciones } from '../../../store/modalRestriccionesCovid/actions';
import { ModalRestriccionesCovidStateI } from '../../../store/modalRestriccionesCovid/reducer';
@Component({
  selector: 'app-modal-restricciones-por-covid',
  templateUrl: './modal-restricciones-por-covid.component.html',
  styleUrls: ['./modal-restricciones-por-covid.component.css']
})
export class ModalRestriccionesPorCovidComponent implements OnInit {
  // defaults images lazyload
  loaderImg = '';
  stateModal$: Observable<ModalRestriccionesCovidStateI> = this.store.select(state => state.modalRestriccionesCovid);
  constructor (
    private store: Store<AppState>,
    private platformLocation: PlatformLocation
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
  }

  ngOnInit(){
  }

  /**
   * @description manda a cerrar la modal por medio de una accion en redux
   */
  close(){
    this.store.dispatch(new CambiarEstadoModalRestricciones(false));
  }
}
