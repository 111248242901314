import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public newStateModal = new Subject<any>();
  constructor() { }

  updateModalGenerica (state){
    this.newStateModal.next(state)
  }
}
