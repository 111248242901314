import { Component, OnInit } from '@angular/core';
import { CardService } from '../../../services/card.service';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-metodos-pago',
  templateUrl: './metodos-pago.component.html',
  styleUrls: ['./metodos-pago.component.css']
})

export class MetodosPagoComponent implements OnInit {
  user: any = null;
  cards: any = [];
  cardTypes: any = [];
  currentCard: any = null;
  showCardsForm: boolean = false;
  showDeleteCardModal:boolean = false;
  status:boolean = false;
  formCard: FormGroup;
  showWarningMessage: boolean = false;
  warningMessage: string = '';
  constructor(
    private cardService: CardService,
    private store: Store<any>,
    private router: Router,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    this.store.subscribe(response => {
      this.user = response.appReducer.user;
      if(this.user != null && this.cards.length == 0) {
        this.listCards();
      }
    });
    this.cardService.getCardTypes().toPromise().then(res => {
      if(res != null) {
        this.cardTypes = res;
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.formCardValidate();
  }

  listCards(){
    let cardsData = {
      session_id: localStorage.session ? JSON.parse(localStorage.session) : null,
      payerId: this.user.userId,
      start_date: '2000-01-01T12:00:00',
      end_date: '9999-01-01T12:00:00',
    };
    /* this.cardService.search(cardsData).subscribe( res => {
      if(res != null && 'tokens_credit_card' in res) {
        this.cards = res.tokens_credit_card;
        if(this.cards.length > 0) {
          this.currentCard = this.cards[0];
          this.showCardsForm = false;
        } else {
          this.showCardsForm = true;
        }
      }
    }); */
  }

  showCardForm() {
    this.showCardsForm = !this.showCardsForm;
    this.showWarningMessage = false;
    this.formCard.reset();
    this.formCard.controls['payment_method'].setValue('');
    return false;
  }

  formCardValidate() {
    this.formCard = new FormGroup({
      'masked_number': new FormControl('', Validators.required),
      'payer_name': new FormControl('', Validators.required),
      'end_date': new FormControl('', [Validators.required, Validators.pattern(/^([0-9]{4})\/(0[1-9]|1[0-2])$/)]),
      'payment_method': new FormControl('', Validators.required),
      'identification_number': new FormControl('', Validators.required),
    });
    this.formCard.controls['payment_method'].setValue('');
  }

  deleteCard(card) {
    this.showCardsForm = false;
    let deletecard = {
        session_id: this.user.sessionCrm,
        tokenId: card.credit_card_token
    };
    this.cardService.delete(deletecard).subscribe( res => {
      if(res != null) {
        this.listCards();
      }
    });
    return false;
  }

  createCard(){
    if(this.formCard.valid) {
      let card = {
        session_id: this.user.sessionCrm,
        name: this.formCard.value.payer_name,
        identification_number: this.formCard.value.identification_number,
        number: this.formCard.value.masked_number,
        expirationDate: this.formCard.value.end_date,
        payment_method: this.formCard.value.payment_method
      };
      this.cardService.create(card).subscribe( res => {
        if(res != null) {
          if('id' in res) {
            this.listCards();
          } else {
            this.showWarningMessage = true;
            let d = res.description;
            if(d.includes('message:')) {
              d = d.split('message:')[1];
            }
            this.warningMessage = d;
          }
        }
      });
    } else {
      this.formCard.controls['masked_number'].markAsTouched();
      this.formCard.controls['payer_name'].markAsTouched();
      this.formCard.controls['end_date'].markAsTouched();
      this.formCard.controls['payment_method'].markAsTouched();
      this.formCard.controls['identification_number'].markAsTouched();
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor complete datos de Método de pago.';
      return false;
    }
  }
}
