import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CloseModalsService {
	public dataModalStateSubject = new Subject<any>();
	public loginModalStateSubject = new Subject<any>();
	public loginModalOriginSubject = new Subject<any>();
	public blockModalStateSubject = new Subject<any>();
	public blockModalOriginSubject = new Subject<any>();
	public notificacionesModalSubject = new Subject<any>();

  constructor() { 
    this.dataModalStateSubject = new Subject();
    this.loginModalStateSubject = new Subject();
    this.loginModalOriginSubject = new Subject();
    this.blockModalStateSubject = new Subject();
    this.blockModalOriginSubject = new Subject();
    this.notificacionesModalSubject = new Subject();
  }
}
