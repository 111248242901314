import { Action } from '@ngrx/store';

export enum AccionesColeccionesHome {
    CARGAR_COLECCIONES_HOME = '[COLECCIONES HOME] => Cargar',
    CARGA_COLECCIONES_HOME_EXITOSA = '[COLECCIONES HOME] => Carga Exitosa',
    CARGA_COLECCIONES_HOME_FALLIDA = '[COLECCIONES HOME] => Carga Fallida'
}

export class CargarColecciones implements Action {
    readonly type = AccionesColeccionesHome.CARGAR_COLECCIONES_HOME;

    constructor( public payload?: any ) {}
}

export class CargarColeccionesFail implements Action {
    readonly type = AccionesColeccionesHome.CARGA_COLECCIONES_HOME_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarColeccionesSuccess implements Action {
    readonly type = AccionesColeccionesHome.CARGA_COLECCIONES_HOME_EXITOSA;

    constructor( public home: any ) {}
}

export type UnionAccionesColeccionesHome = CargarColecciones | CargarColeccionesFail | CargarColeccionesSuccess;
