import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { CheckSessionAfterServiceResponseService } from './check-session-after-service-response.service';

@Injectable()

export class ColeccionesService {
	
	private crmUrl : string = environment.apiUrl;

	constructor(public http: HttpClient, private csasr: CheckSessionAfterServiceResponseService,){
	}

	getColeccionBanner (data) {
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'colecciones_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');

		return this.http.post(this.crmUrl+'?ws=noload', formData).pipe(map((response:any) => {
      this.csasr.check(response);
			
			let movil: any[] = [];
			let web: any[] = [];

			if (response.items.length > 0) {
				for (let banner of response.items) {
					if (banner.relacionado){
		            	if (banner.relacionado.imagen_web) {
		              		web.push(banner.relacionado)
		            	}
		            	
		            	if (banner.relacionado.imagen_movil) {
		              		movil.push(banner.relacionado)
		            	}
		          	}
				}
			}

			return {
				movil: movil,
				web: web
			}
		}))
	}

	getColeccionDefault (data){
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'colecciones_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl+'?ws=noload', formData).pipe( map ( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	getColeccionList (data){
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'colecciones_list');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl+'?ws=noload', formData).pipe( map ( (response:any) => {
      this.csasr.check(response);
			return response
		}))
	}

	getColeccionBannerAll (data) {
		var crmData =  [data];
		let formData = new FormData();
		formData.append('method', 'banners_get');
		formData.append('rest_data', JSON.stringify(crmData));
		formData.append('input_type', 'JSON');
		formData.append('response_type', 'JSON');
		return this.http.post(this.crmUrl, formData).pipe( map(response => {
      this.csasr.check(response);
			return response
		}))
	}
}
