import { Action } from '@ngrx/store';

export enum AccionesEventoDestacado {
    CARGAR_EVENTO_DESTACADO = '[EVENTO DESTACADO] => Cargar',
    CARGA_EVENTO_DESTACADO_EXITOSA = '[EVENTO DESTACADO] => Carga Exitosa',
    CARGA_EVENTO_DESTACADO_FALLIDA = '[EVENTO DESTACADO] => Carga Fallida'
}

export class CargarEventoDestacado implements Action {
    readonly type = AccionesEventoDestacado.CARGAR_EVENTO_DESTACADO;

    constructor( public payload?: any ) {}
}

export class CargarEventoDestacadoFail implements Action {
    readonly type = AccionesEventoDestacado.CARGA_EVENTO_DESTACADO_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarEventoDestacadoSuccess implements Action {
    readonly type = AccionesEventoDestacado.CARGA_EVENTO_DESTACADO_EXITOSA;

    constructor( public evento: any ) {}
}

export type UnionAccionesEventoDestacado = CargarEventoDestacado | CargarEventoDestacadoFail | CargarEventoDestacadoSuccess;
