import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Usuario } from '../../../clases/index';
import { UsuarioService } from '../../../services/usuario.service';
import { CarritoService } from '../../../services/carrito.service';
import { ACTION_LOGIN, ACTION_SHOPCAR, ACTION_HEADER_STATE, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";
import { StoreService } from "../../../services/store.service";
import { Store } from "@ngrx/store";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-iniciar-sesion',
  templateUrl: './iniciar-sesion.component.html',
  styleUrls: ['./iniciar-sesion.component.css']
})
export class IniciarSesionComponent implements OnInit, AfterViewInit, OnDestroy {
  usuario:any;
  origin:any;
  loginMessage = '';
  forma: FormGroup;
  dir: boolean = false;
  shopcar:any;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private store: Store<any>,
    private route: ActivatedRoute,
    private storeService:StoreService,
    private usuarioService:UsuarioService,
    private carritoService:CarritoService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit() {
    window.scrollTo(0,0);

    this.store.subscribe(response => {

      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

    });

    if(this.route.snapshot.queryParamMap.get('origin')){
      this.origin = this.route.snapshot.queryParamMap.get('origin');
    }

    this.validateFormLogin();

    let dataMetas = {
      title: 'Iniciar Sesión',
      description: 'Escribe tu correo electrónico y contraseña para ingresar a nuestro portal de libros.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }
    
  }

  setMetaData(data) {
    
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  ngOnDestroy() {
    localStorage.removeItem('last-url');
  }

  ngAfterViewInit(){
    setTimeout( () => {
      //CAMBIAR DE ESTADO EL HEADER
      this.storeService.updateAppState({
        action: ACTION_HEADER_STATE,
        payload: false
      })
    })
  }

  validateFormLogin(){
    this.forma = new FormGroup({
      'contrasena': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'usuario'	: new FormControl('', [
        //Validators.email,
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ])
    })
  }

  loginUsuarioEmail() {
    this.loginMessage='';
    if (this.forma.valid == true) {
      let userData = {
        provider	: 'email',
        email 		: this.forma.controls['usuario'].value,
        password 	: this.forma.controls['contrasena'].value
      };

      //LOGIN EN EL CRM
      this.usuarioService.setLogin(userData).subscribe(response => {
        if (response.number == 10) {
          this.loginMessage='Intento de login fallido, compruebe el nombre de usuario y contraseña';
        } else if (response.id) {
          this.loginUserCrmSuccess(response);
        }
      })
    }
    else {
      //ERROR CUANDO NO SE HAN VALIDADO TODOS LOS CAMPOS
      this.forma.controls['usuario'].markAsTouched();
      this.forma.controls['contrasena'].markAsTouched();
    }
  }

  loginUserCrmSuccess(response) {
    let currentUser = new Usuario();
    currentUser.login = true;
    currentUser.sessionCrm = response.id;
    let dataCrm = {
      session_id: response.id
    };

    //LOGIN EXITOSO, TRAER TODA LA CONFIGURACIÓN DEL USUARIO
    this.usuarioService.getUser(dataCrm).subscribe(resp => {
      if (resp.status == 'ok'){
        currentUser.userId = resp.config.id;
        currentUser.clienteId = resp.cuenta.cliente_id;
        currentUser.tokenCrm = resp.config.token;
        currentUser.estado_registro = resp.config.estado_registro;
        currentUser.estado_login = resp.config.estado_login;
        currentUser.nombres = resp.config.nombres;
        currentUser.apellidos = resp.config.apellidos;
        currentUser.tipo_identificacion = resp.config.tipo_identificacion;
        currentUser.identificacion = resp.config.identificacion;
        currentUser.fecha_nacimiento = resp.cuenta.fecha_nacimiento;
        currentUser.email = resp.config.email;
        currentUser.telefono = resp.config.telefono;
        currentUser.telefono_movil = resp.cuenta.telefono_movil;

        //SETEAR SESSION ID EN LOCALSTORAGE Y DATA USUARIO EN REDUX
        this.usuarioService.setUserLocal(currentUser);
        this.storeService.updateAppState({ action: ACTION_LOGIN, payload: currentUser });

        //TRAER EL CARRITO PARA MEZCLARLO O PEDIR UNO NUEVIO SI NO TIENE
        let carritoLS = this.carritoService.getCarritoLocal();
        if (carritoLS && (this.shopcar.cliente_user_id == '' || this.shopcar.cliente_user_id == null) ){

          //MERGE DE CARRITOS CUANDO SI HAY LOCAL
          let dataCrmMergeCarrito = [
            currentUser.sessionCrm,
            {
              factura_merge_id  : carritoLS
            }
          ];
          
          this.carritoService.mergeFactura(dataCrmMergeCarrito).subscribe((response:any) => {
            if(response.id){
              this.updateCarritoLocal(response);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
            }
            else 
            {
              this.carritoService.deleteCarritoLocal()
              this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
            }
          })

        } else {
          let dataCrmCarrito = {
            session_id : currentUser.sessionCrm
          };
          this.carritoService.newFactura(dataCrmCarrito).subscribe((dataCarrito:any) => {
            if(dataCarrito.id){
              this.updateCarritoLocal(dataCarrito);
              this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: dataCarrito });
            }
          });
        }
        console.log('origin', this.origin);
        if(this.origin=='datos-personales'){
          let msg = 'Ahora puede editar sus datos personales';
          this.router.navigate(['/', 'mi-cuenta', 'datos-personales'], { queryParams: { message: msg }, skipLocationChange:true });
        } else if(this.origin=='datos-envio'){
          this.router.navigate(['/', 'carrito', 'datos-envio']);
        } else {
          if(localStorage.getItem('last-url')) {
            this.router.navigate(JSON.parse(localStorage.getItem('last-url')));
          } else {
            this.router.navigate(['/', 'mi-cuenta']);
          }
        }
      }
    })
  }

  updateCarritoLocal(dataCarrito){
    this.carritoService.setCarritoLocal(dataCarrito);
  }
}
