import { ColeccionesService } from './../../services/colecciones.service';
import { ContenidosService } from './../../services/contenidos.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarEventosSuccess, CargarEventosFail, AccionesEventos } from './actions';
import { CargarEventoDestacadoSuccess, CargarEventoDestacadoFail, AccionesEventoDestacado } from './destacado/actions';
import { CargarEventoDetalleSuccess, CargarEventoDetalleFail, AccionesEventoDetalle } from './detalle/actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class EventosEffects {

    constructor(
        private actions$: Actions,
        public contenidosService: ContenidosService,
        private coleccionesService: ColeccionesService,
    ) {}

    @Effect()
    cargarEventos$ = this.actions$.pipe( ofType(AccionesEventos.CARGAR_EVENTOS) )
        .pipe(
            switchMap( (data: any) => {
                return this.contenidosService.getList(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response && response.length > 0) {
                                return new CargarEventosSuccess(response);
                            }
                            return new CargarEventosFail(response.length == 0 ? { id: 21 } : response);
                        } ),
                        catchError( error => of(new CargarEventosFail(error)) )
                    );
            })
        );

    @Effect()
    cargarEventoDestacado$ = this.actions$.pipe( ofType(AccionesEventoDestacado.CARGAR_EVENTO_DESTACADO) )
        .pipe(
            switchMap( (data: any) => {
                return this.coleccionesService.getColeccionDefault(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response && !response.hasErrors && response.items.length > 0) {
                                return new CargarEventoDestacadoSuccess(response);
                            }
                            return new CargarEventoDestacadoFail(response);
                        } ),
                        catchError( error => of(new CargarEventoDestacadoFail(error)) )
                    );
            })
        );

    @Effect()
    cargarEventoDetalle$ = this.actions$.pipe( ofType(AccionesEventoDetalle.CARGAR_EVENTO_DETALLE) )
        .pipe(
            switchMap( (data: any) => {
                return this.coleccionesService.getColeccionDefault(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response && !response.hasErrors && response.length > 0) {
                                return new CargarEventoDetalleSuccess(response);
                            }
                            return new CargarEventoDetalleFail(response);
                        } ),
                        catchError( error => of(new CargarEventoDetalleFail(error)) )
                    );
            })
        );
}
