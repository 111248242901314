import { Action } from "@ngrx/store";

export enum AccionesDirecciones {
  CARGAR_DIRECCIONES = "[DIRECCIONES] => Cargar",
  CARGA_DIRECCIONES_EXITOSA = "[DIRECCIONES] => Carga Exitosa",
  CARGA_DIRECCIONES_FALLIDA = "[DIRECCIONES] => Carga Fallida",

  AGREGAR_DIRECCION = "[DIRECCIONES] => Agregar",
  AGREGAR_DIRECCION_EXITOSA = "[DIRECCIONES] => Agregar Exitosa",
  AGREGAR_DIRECCION_FALLIDA = "[DIRECCIONES] => Agregar Fallida",

  ACTUALIZAR_DIRECCION = "[DIRECCIONES] => Actualizar",
  ACTUALIZAR_DIRECCION_EXITOSA = "[DIRECCIONES] => Actualizar Exitosa",
  ACTUALIZAR_DIRECCION_FALLIDA = "[DIRECCIONES] => Actualizar Fallida",

  ELIMINAR_DIRECCION = "[DIRECCIONES] => Eliminar",
  ELIMINAR_DIRECCION_EXITOSA = "[DIRECCIONES] => Eliminar Exitosa",
  ELIMINAR_DIRECCION_FALLIDA = "[DIRECCIONES] => Eliminar Fallida",

  CANCELAR = '[DIRECCIONES] CANCELAR',

  SET_DIRECCION = "[DIRECCIONES] => Dirección set",
  RESET_DIRECCIONES = "[DIRECCIONES] => Reset",
}

export class CargarDirecciones implements Action {
  readonly type = AccionesDirecciones.CARGAR_DIRECCIONES;

  constructor(public payload?: any) {}
}

export class CargarDireccionesFail implements Action {
  readonly type = AccionesDirecciones.CARGA_DIRECCIONES_FALLIDA;

  constructor(public payload: any) {}
}

export class CargarDireccionesSuccess implements Action {
  readonly type = AccionesDirecciones.CARGA_DIRECCIONES_EXITOSA;

  constructor(public direcciones: any) {}
}

export class AgregarDireccion implements Action {
  readonly type = AccionesDirecciones.AGREGAR_DIRECCION;

  constructor(public direccion?: any) {}
}

export class AgregarDireccionFail implements Action {
  readonly type = AccionesDirecciones.AGREGAR_DIRECCION_FALLIDA;

  constructor(public payload: any) {}
}

export class AgregarDireccionSuccess implements Action {
  readonly type = AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA;

  constructor(public direccion: any) {}
}

export class ActualizarDireccion implements Action {
  readonly type = AccionesDirecciones.ACTUALIZAR_DIRECCION;

  constructor(public direccion?: any) {}
}

export class ActualizarDireccionFail implements Action {
  readonly type = AccionesDirecciones.ACTUALIZAR_DIRECCION_FALLIDA;

  constructor(public payload: any) {}
}

export class ActualizarDireccionSuccess implements Action {
  readonly type = AccionesDirecciones.ACTUALIZAR_DIRECCION_EXITOSA;

  constructor(public direccion: any) {}
}

export class EliminarDireccion implements Action {
  readonly type = AccionesDirecciones.ELIMINAR_DIRECCION;

  constructor(public direccion?: any) {}
}

export class EliminarDireccionFail implements Action {
  readonly type = AccionesDirecciones.ELIMINAR_DIRECCION_FALLIDA;

  constructor(public payload: any) {}
}

export class EliminarDireccionSuccess implements Action {
  readonly type = AccionesDirecciones.ELIMINAR_DIRECCION_EXITOSA;

  constructor(public direccion: any) {}
}

export class SetDireccion implements Action {
  readonly type = AccionesDirecciones.SET_DIRECCION;

  constructor(public direccion: any) {}
}

export class ResetDirecciones implements Action {
  readonly type = AccionesDirecciones.RESET_DIRECCIONES;
}

export class CancelarDireccion implements Action {
  readonly type = AccionesDirecciones.CANCELAR;
}

export type UnionAccionesDirecciones =
  | CargarDirecciones
  | CargarDireccionesFail
  | CargarDireccionesSuccess
  | AgregarDireccion
  | AgregarDireccionFail
  | AgregarDireccionSuccess
  | ActualizarDireccion
  | ActualizarDireccionFail
  | ActualizarDireccionSuccess
  | EliminarDireccion
  | EliminarDireccionFail
  | EliminarDireccionSuccess
  | SetDireccion
  | CancelarDireccion
  | ResetDirecciones;
