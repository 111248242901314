import { Component, OnInit } from '@angular/core';

import { Location, PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { ContenidosService } from '../../../services/contenidos.service';
import { ShareButtons } from '@ngx-share-pat/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Store } from "@ngrx/store";
import { StoreService } from "../../../services/store.service";
import { UsuarioService } from "../../../services/usuario.service";
import { CarritoService } from "../../../services/carrito.service";
import { CapitalizeFirstPipe } from "../../../pipes/capitalizefirst.pipe";
import { ProductosService } from "../../../services/productos.service";
import { ACTION_SHOPCAR, ACTION_DEL_SHOPCAR } from "../../../store/actions/appAction";


@Component({
  selector: 'app-detalle-evento',
  templateUrl: './detalle-evento.component.html',
  styleUrls: ['./detalle-evento.component.css']
})
export class DetalleEventoComponent implements OnInit {
  eventoData:any;
  eventosList:any = [];
  currentURL:string;
  noCoverImg:string='';
  productosList:any;

  usuario:any;
  shopcar:any;
  busqueda: any = false
  addedToCart: any = {};
  detailsProduct: any = [];
  updateInProgress: boolean = false;
  active: number;
  icon:string = 'empty';
  status:any = false;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor(
    private titleService:Title,
    private meta:Meta,
    private getEventosListObj:ContenidosService,
    private getEventoObj: ContenidosService,
    private activatedRoute:ActivatedRoute,
    private location:Location,
    private router: Router,
    private store: Store<any>,
    private storeService:StoreService,
    private usuarioService:UsuarioService,
    private carritoService:CarritoService,
    private productosService:ProductosService,
    public  platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)

  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.currentURL = (this.platformLocation as any)._location.origin + this.location.path(false);
    this.activatedRoute.params.subscribe( params => {
      this.getEventoData(params);
    });
    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
    this.getEventosList();
  }

   setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  getEventosList(){
    let data = {
      offset: '0',
      limit: '4',
      tipo: 'EVENTO'
    };

    this.getEventosListObj.getList(data).subscribe((response:any) => {
      if(response.length > 0) {
        for(let obj of response){
          if(this.eventoData.id != obj.id ){
            this.eventosList.push(obj);
          }
        }
      }
    });
  }

  getEventoData(params){
    let data = {
      slug: params.slug
    };
    this.getEventoObj.getDetalle(data).subscribe((response:any) => {
      this.eventoData = response;
      this.productosList = response.productos_relacionados;

      let dataMetas = {
        title: response.name,
        description: response.description ? response.description : '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
        keywords: 'libros,libreria,ebooks',
        image: response.imagen1 ? response.imagen1 : 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
        url: 'https://www.librerianacional.com'+this.router.url
      }
      try {
        this.setMetaData(dataMetas);
      } catch (error) { }

    });
  }

  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/

  productToAddDetail(id) {
    this.addProductCarrito(id);
  }

  addProductCarrito(id){
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id     : null,
      grupo_id       : null,
      producto_id    : id,
      cantidad       : 1
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id){
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.addProductCarritoCrm(dataCrm)
    } else {
      let dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        producCurrent
      ];
      this.getNewBill(dataCrm)
    }
  }

  addProductCarritoCrm(dataCrm)
  {
    this.carritoService.addProduct(dataCrm).subscribe((response:any) => {

      this.updateInProgress = false;

       if(response.hasErrors) {
        if(response.number == 2204) {
          // producto NO existe
        }
        else
        {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      } else {
        if(response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price: (productItem.producto.price_dcto < productItem.producto.price) ? productItem.producto.price_dcto : productItem.producto.price
          };
        }

        if(response.producto) {
          this.detailsProduct = response.producto;
        }

        if(response.factura.id){
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal()
          this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
        }
      }
    })
  }

  getNewBill(dataCrm)
  {

    let user = this.usuario;
    let producCurrent = dataCrm[1]


    let dataCrmNF:any;
    if(user){
      dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res:any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [
          (user.sessionCrm ? user.sessionCrm : null),
          producCurrent
        ];
        this.addProductCarritoCrm(dataCrm)
      } else {
        this.updateInProgress = false
      }
    })
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito){
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: carrito });
    let dataModal = {
      tipo: 'quickView',
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        //subtotal:   this.shopcar.totales.subtotal,
        subtotal:   this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds: (this.shopcar.resumen_x_productos.length > 0) ? this.shopcar.resumen_x_productos.length : 1
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }
  /***************************************/
  /********** ADD TO CART ****************/
  /***************************************/
}
