import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario.service';

import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-modal-carrito',
  templateUrl: './modal-carrito.component.html',
  styleUrls: ['./modal-carrito.component.css']
})
export class ModalCarritoComponent implements OnInit {
  usuario:any;
  shopcar:any;

  @Input('dataModal') dataModal;
  @Input('stateModal') stateModal;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  constructor (
    public usuarioService: UsuarioService,
    private router: Router,
    private platformLocation: PlatformLocation
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
  }

  ngOnInit(){
  }

  close(){
    this.usuarioService.updateDataModalState(false)
  }

  confirm(){
    this.usuarioService.updateDataModalState(false);
    this.router.navigate(['/', 'carrito', 'mi-carrito']);
  }

  closeAndGo(slug){
    this.usuarioService.updateDataModalState(false);
    this.router.navigate(['/', 'producto', slug]);
  }
}
