import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AddressService } from '../../../services/address.service';
import { StoreService } from '../../../services/store.service';
import { ACTION_CLIENTE_SET, ACTION_SET_DIR_SHOPCAR } from '../../../store/actions/appAction';
import { LocationService } from '../../../services/location.service';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PlatformLocation } from '@angular/common';

import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

import { Product } from 'src/app/interfaces/product';
import { DataLayerService } from '../../../services/data-layer.service';
import { CargarDirecciones, AccionesDirecciones } from 'src/app/store/direcciones/actions';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'app-datos-envio',
  templateUrl: './datos-envio.component.html',
  styleUrls: ['./datos-envio.component.css']
})
export class DatosEnvioComponent implements OnInit, OnDestroy {
  shopcar:any = null;
  user:any = null;
  currentPath:any;
  formUserInfo: FormGroup;
  formInfoEnvio: FormGroup;
  status: boolean = false
  addresses: any = [];
  showAddAddressForm: boolean = false;
  currentAddressId: string = '';
  currentAddressSelected = null;
  countries: any = [];
  departments:any = [];
  cities:any = [];
  products:any = [];
  billAddress: any = '';
  sendAddress: any = '';
  totals: any = {
    total: 0,
    descuento: 0,
    subtotal: 0,
    iva: 0,
    gran_total: 0
  };
  tiposDocumento: any = [
  {
    value: 'CC',
    label: 'Cédula de ciudadanía'
  },
  {
    value: 'PASAPORTE',
    label: 'Pasaporte'
  },
  {
    value: 'NIT',
    label: 'NIT'
  }];
  showWarningMessage: boolean = false;
  warningMessage: string = '';
  showOthers: boolean = false;
  bonoImage : string = '';
  bonoVirtualImage : string = '';
  loadDirecciones: boolean = false;
  initInfouser: any = '';

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  totalesloco: Observable<any>;

  direcciones$ = this.store.select(store => store.direcciones);
  susA: Subscription = new Subscription();

  constructor(
    private meta: Meta,
    private titleService: Title,
    private store: Store<any>,
    private addressService: AddressService,
    private storeService: StoreService,
    private router: Router,
    public  platformLocation: PlatformLocation,
    private locationSercice: LocationService,
    private userService: UsuarioService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private dataLayerService: DataLayerService,
    private actions: Actions
  ){
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';

    // Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);

    if (localStorage.getItem('canEnterDatosEnvio')) {
      localStorage.removeItem('canEnterDatosEnvio');
    }

    if (this.router.url.indexOf("agregar") > 0) {
      this.showAddAddressForm = true;
    }

    this.susA = this.actions.subscribe((action: any) => {
      if (
        action.type === AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA ||
        action.type === AccionesDirecciones.ACTUALIZAR_DIRECCION_EXITOSA ||
        action.type === AccionesDirecciones.CANCELAR
      ) {
        this.showAddAddressForm = false;
        window.scrollTo(0,0);
      }
      if ( action.type === AccionesDirecciones.SET_DIRECCION && this.router.url.indexOf('/eliminar') ) {
        this.showAddAddressForm = false;
      }
      if ( action.type === AccionesDirecciones.SET_DIRECCION) {
        this.selectAddress(action.direccion);
      }
    });

  }

  ngOnDestroy(): void {
    this.susA.unsubscribe();
  }

  ngOnInit(){
    window.scrollTo(0,0);

    this.totalesloco = this.store.select('appReducer');

    const dataMetas = {
      title: 'Proceso de compra, datos de envío',
      description: '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com' + this.router.url
    }

    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.locationSercice.search({
        tipo: 'PAIS',
        ubicacion_id: null
    }).toPromise().then( country => {
      if(country != null) {
        this.countries = country.sort(function(a,b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0;
        });
      }
    });

    this.formUserInfo = new FormGroup({
      'nombres'             : new FormControl({value:'', disabled:true}, Validators.required),
      'apellidos'           : new FormControl({value:'', disabled:true}, Validators.required),
      'email'               : new FormControl({value:'', disabled:true}, Validators.required),
      'tipo_identificacion' : new FormControl({value:'', disabled:true}, Validators.required),
      'identificacion'      : new FormControl({value:'', disabled:true}, Validators.required),
      'telefono'            : new FormControl({value:'', disabled:true}, Validators.required),
    });
    this.initInfouser = this.formUserInfo.value;

    this.formInfoEnvio = new FormGroup({
      'name'                  : new FormControl({value: '', disabled: true}, Validators.required),
      'tipo_direccion'        : new FormControl({value: '', disabled: true}, Validators.required),
      'preguntar_por_name'    : new FormControl({value: '', disabled: true}, Validators.required),
      'preguntar_por_lastname': new FormControl({value: '', disabled: true}, Validators.required),
      'direccion'             : new FormControl({value: '', disabled: true}, Validators.required),
      'direccion_postalcode'  : new FormControl({value: '', disabled: true}, Validators.required),
      'pais'                  : new FormControl({value: '', disabled: true}, Validators.required),
      'departamento'          : new FormControl({value: '', disabled: true}),
      'ciudad'                : new FormControl({value: '', disabled: true}),
      'departamento_otro'     : new FormControl({value: '', disabled: true}),
      'ciudad_otro'           : new FormControl({value: '', disabled: true}),
      'telefono_fijo'         : new FormControl({value: '', disabled: true}),
      'telefono_celular'      : new FormControl({value: '', disabled: true}, Validators.required),
    });

    this.store.subscribe( (response:any) => {

      const stateCurrent: any = this.store.select('appReducer');
      const actionCurrent: any = stateCurrent.actionsObserver.value.type;

      const currentUser = this.user;
      this.user = response.appReducer.user;
      this.totals = response.appReducer.shopcar.totales;

      if (actionCurrent === 'SHOPCAR' || currentUser == null) {
        this.shopcar = response.appReducer.shopcar;
        if(this.shopcar) {
          this.setShopcar(this.shopcar);
        }
      }

      if (actionCurrent === 'LOGIN' || currentUser == null) {
        this.loadDirecciones = false;
        if(this.user.estado_login) {
          if(this.user.estado_login.toLowerCase() === 'login_parcial') {
            this.formUserInfoValidate(true);
            this.formInfoEnvioValidate(true);
          } else {
            this.formUserInfoValidate(false);
            this.formInfoEnvioValidate(false);
          }
        }
        this.formUserInfofillData();
      }
    });
  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }

  setShopcar(shopcar): void {
    const products: Array<Product> = [];
    this.shopcar = shopcar;

    if (this.shopcar.grupos) {
      this.products = this.shopcar.grupos[0].lineas.productos;
      this.totals = this.shopcar.totales;

      this.shopcar.grupos[0].lineas.productos.forEach(product => {
        const productDataLayer: Product = {
          name: product.producto.name,
          id: product.producto.plu_sku_erp_code,
          plu: product.producto.plu_sku_erp_code,
          slug: product.producto.slug,
          price: product.producto.price_dcto,
          type: product.producto.type.valor,
          quantity: product.cantidad
        };
        products.push(productDataLayer);
      });

      this.dataLayerService.onCheckout(3, 'Datos de envío', products);
      this.dataLayerService.customEvent('checkout_step_03');

    }

  }

  private setValidators(flag) {
    if(!flag) {
      this.formInfoEnvio.controls['departamento'].setValidators([Validators.required]);
      this.formInfoEnvio.controls['ciudad'].setValidators([Validators.required]);
      this.formInfoEnvio.controls['departamento_otro'].setValidators([]);
      this.formInfoEnvio.controls['ciudad_otro'].setValidators([]);
      this.formInfoEnvio.controls['direccion_postalcode'].setValidators([]);
    } else {
      this.formInfoEnvio.controls['departamento'].setValidators([]);
      this.formInfoEnvio.controls['ciudad'].setValidators([]);
      this.formInfoEnvio.controls['departamento_otro'].setValidators([Validators.required]);
      this.formInfoEnvio.controls['ciudad_otro'].setValidators([Validators.required]);
      this.formInfoEnvio.controls['direccion_postalcode'].setValidators([Validators.required]);
    }

    this.formInfoEnvio.controls['departamento'].reset()
    this.formInfoEnvio.controls['ciudad'].reset()
    this.formInfoEnvio.controls['departamento_otro'].reset()
    this.formInfoEnvio.controls['ciudad_otro'].reset()
    this.formInfoEnvio.controls['direccion_postalcode'].reset()
  }

  filterCountry(e) {
    let countryId = e.target.value;

    if(!countryId){
      this.departments = [];
      this.cities = [];
      return;
    }

    if(countryId == '770c0c90-01f3-31df-0f00-59cc0f745717') {
      this.setValidators(false);
      this.showOthers = false;
    } else {
      this.setValidators(true);
      this.showOthers = true;
    }

    this.locationSercice.search( {
        tipo: 'DPTO',
        ubicacion_id: countryId
    }).toPromise().then( res => {
      if(res != null) {
        this.departments = res;
        this.departments = res.sort(function(a,b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0;
        });
      }
    });
  }

  filterCities(e) {
    let departmentId = e.target.value;
    this.locationSercice.search({
      tipo: 'CIUDAD',
      ubicacion_id: departmentId
    }).toPromise().then( res => {
      if(res != null) {
        this.cities = res.sort(function(a,b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0;
        });
      }
    });
  }

  listAddresses() {
    this.store.dispatch(
      new CargarDirecciones({ session_id: JSON.parse(localStorage.session) })
    );
  }

  submitUserInfo()
  {

  }

  formUserInfoValidate(isDisabled = false){
    if(isDisabled) {
      this.formUserInfo.controls['nombres'].disable()
      this.formUserInfo.controls['apellidos'].disable()
      this.formUserInfo.controls['tipo_identificacion'].disable()
      this.formUserInfo.controls['identificacion'].disable()
      this.formUserInfo.controls['telefono'].disable()
      this.formUserInfo.controls['email'].disable()
    }
    else {
      this.formUserInfo.controls['nombres'].enable()
      this.formUserInfo.controls['apellidos'].enable()
      this.formUserInfo.controls['tipo_identificacion'].enable()
      this.formUserInfo.controls['identificacion'].enable()
      this.formUserInfo.controls['telefono'].enable()
      this.formUserInfo.controls['email'].enable()
    }
    this.formUserInfo.markAsTouched()
  }

  formInfoEnvioValidate(isDisabled = false){

    if(isDisabled) {
      this.formInfoEnvio.controls['name'].disable()
      this.formInfoEnvio.controls['tipo_direccion'].disable()
      this.formInfoEnvio.controls['preguntar_por_name'].disable()
      this.formInfoEnvio.controls['preguntar_por_lastname'].disable()
      this.formInfoEnvio.controls['direccion'].disable()
      this.formInfoEnvio.controls['direccion_postalcode'].disable()
      this.formInfoEnvio.controls['pais'].disable()
      this.formInfoEnvio.controls['departamento'].disable()
      this.formInfoEnvio.controls['ciudad'].disable()
      this.formInfoEnvio.controls['departamento_otro'].disable()
      this.formInfoEnvio.controls['ciudad_otro'].disable()
      this.formInfoEnvio.controls['telefono_fijo'].disable()
      this.formInfoEnvio.controls['telefono_celular'].disable()
    }
    else
    {
      this.formInfoEnvio.controls['name'].enable()
      this.formInfoEnvio.controls['tipo_direccion'].enable()
      this.formInfoEnvio.controls['preguntar_por_name'].enable()
      this.formInfoEnvio.controls['preguntar_por_lastname'].enable()
      this.formInfoEnvio.controls['direccion'].enable()
      this.formInfoEnvio.controls['direccion_postalcode'].enable()
      this.formInfoEnvio.controls['pais'].enable()
      this.formInfoEnvio.controls['departamento'].enable()
      this.formInfoEnvio.controls['ciudad'].enable()
      this.formInfoEnvio.controls['departamento_otro'].enable()
      this.formInfoEnvio.controls['ciudad_otro'].enable()
      this.formInfoEnvio.controls['telefono_fijo'].enable()
      this.formInfoEnvio.controls['telefono_celular'].enable()
    }
  }

  formUserInfofillData(){
    if (this.user){
      this.formUserInfo.controls['nombres'].setValue(this.user.nombres);
      this.formUserInfo.controls['apellidos'].setValue(this.user.apellidos);
      this.formUserInfo.controls['tipo_identificacion'].setValue(this.user.tipo_identificacion);
      this.formUserInfo.controls['identificacion'].setValue(this.user.identificacion);
      this.formUserInfo.controls['telefono'].setValue(this.user.telefono);
      this.formUserInfo.controls['email'].setValue(this.user.email);
      this.initInfouser = this.formUserInfo.value;
    }
  }

  gotoInformacionPago(){
    if(this.currentAddressSelected == null) {
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor seleccione dirección para continuar.';
      return false;
    }

    if(!this.formUserInfo.valid && this.user.estado_login.toLowerCase() == 'login_full') {
      this.showWarningMessage = true;
      this.formUserInfo.controls['nombres'].markAsTouched();
      this.formUserInfo.controls['apellidos'].markAsTouched();
      this.formUserInfo.controls['tipo_identificacion'].markAsTouched();
      this.formUserInfo.controls['identificacion'].markAsTouched();
      this.formUserInfo.controls['telefono'].markAsTouched();
      this.formUserInfo.controls['email'].markAsTouched();
      this.warningMessage = 'Por favor complete Información Personal';
      return false;
    }

    //console.log(this.formUserInfo)
    if(this.formUserInfo.valid && this.user.estado_login.toLowerCase() == 'login_full') {
      if( JSON.stringify(this.initInfouser) != JSON.stringify(this.formUserInfo.value) ){
        let user = {
          session_id: this.user.sessionCrm,
          nombres: this.formUserInfo.controls['nombres'].value,
          apellidos: this.formUserInfo.controls['apellidos'].value,
          telefono: this.formUserInfo.controls['telefono'].value,
          tipo_identificacion: this.formUserInfo.controls['tipo_identificacion'].value,
          identificacion: this.formUserInfo.controls['identificacion'].value,
          email: this.formUserInfo.controls['email'].value
        };

        //ACTUALIZAR CLIENTE
        this.userService.updateCliente(user).toPromise().then( updatedUser => {
          if(updatedUser != null) {
            this.user.identificacion = updatedUser.identificacion;
            this.user.telefono = updatedUser.telefono;
            this.user.tipo_identificacion = updatedUser.tipo_identificacion;
            this.storeService.updateAppState({action: ACTION_CLIENTE_SET, payload: this.user});
          }
        });
      }

      //SET DIRECCION ENVIO
      let address = {
        session_id: this.user.sessionCrm,
        factura_id: this.shopcar.id,
        direccion_id: this.currentAddressSelected.id,
        billAddress_set: true
      };

      this.addressService.setSendAddress(address).toPromise().then( res => {
        if(res.hasErrors == true) {
          this.showWarningMessage = true;
          // this.warningMessage = res.description;
          this.warningMessage = 'Por favor actualice el Pais y Ciudad en la dirección seleccionada, estos campos no pueden ser vacios.';
          if(res.number == 2009){
            this.warningMessage = res.description;
          }
          return false;
        } else if(res != null) {
          this.billAddress = res;
          this.sendAddress = res;
          this.shopcar.direccion_facturacion_id = this.billAddress.id;
          this.shopcar.direccion_facturacion_name = this.billAddress.name;
          this.shopcar.direccion_envio_id = this.sendAddress.id;
          this.shopcar.direccion_envio_name = this.sendAddress.name;
          this.storeService.updateAppState({action: ACTION_SET_DIR_SHOPCAR, payload: this.shopcar});
          this.router.navigate(['/', 'carrito', 'informacion-pago', this.shopcar.id]);
        }
      });
    } else if(this.user.estado_login.toLowerCase() == 'login_parcial') {
      if(this.user.sessionCrm) {
        let address = {
          session_id: this.user.sessionCrm,
          factura_id: this.shopcar.id,
          direccion_id: this.currentAddressSelected.id,
          billAddress_set: true
        };
        this.addressService.setSendAddress(address).toPromise().then( res => {
          if(res.hasErrors == true) {
            this.showWarningMessage = true;
            // this.warningMessage = res.description;
            this.warningMessage = 'Por favor actualice el Pais y Ciudad en la drección seleccionada, estos campos no pueden ser vacios.';
            return false;
          } else if(res != null) {
            this.billAddress = res;
            this.sendAddress = res;
            this.shopcar.direccion_facturacion_id = this.billAddress.id;
            this.shopcar.direccion_facturacion_name = this.billAddress.name;
            this.shopcar.direccion_envio_id = this.sendAddress.id;
            this.shopcar.direccion_envio_name = this.sendAddress.name;
            this.storeService.updateAppState({action: ACTION_SET_DIR_SHOPCAR, payload: this.shopcar});
            this.router.navigate(['/', 'carrito', 'informacion-pago', this.shopcar.id]);
          }
        });
      }
    }

    if(this.user.estado_registro == 'PARCIAL') {
      this.userService
        .setRegisterFull(this.user.sessionCrm)
        .toPromise()
        .then((res: any) => {
          if (res.status == "ok") {
            console.log('UserRegisterFull succesfully!');
          }
        });
    }
  }

  selectAddress(address) {
    this.addresses.forEach(obj => {
      obj.selected = 'text-muted';
      obj.bgSelected = 'border shadow-bx';
    });
    address.selected = 'text--blue-medium';
    address.bgSelected = 'border-left border-right-0 border-bottom-0 border-width-4 border-blue-medium bg-gray-light';
    this.showAddAddressForm = false;
    this.currentAddressSelected = address;
    return false;
  }

  editAddress(address) {
    if(this.user.estado_login.toLowerCase() == 'login_parcial'){
      return;
    }

    let countryId = address.pais_id;
    let departmentId = address.dpto_id;
    this.locationSercice.search({
        tipo: 'PAIS',
        ubicacion_id: null
    }).toPromise().then( country => {
      if(country != null) {
        this.countries = country.sort(function(a,b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0;
        });

        this.locationSercice.search({
            tipo: 'DPTO',
            ubicacion_id: countryId
        }).toPromise().then( departs => {
          if(departs != null) {
            this.departments = departs.sort(function(a,b) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0;
            });

            this.locationSercice.search({
                tipo: 'CIUDAD',
                ubicacion_id: departmentId
            }).toPromise().then( city => {
              if(city != null) {
                this.cities = city.sort(function(a,b) {
                  if (a.name < b.name) {
                    return -1
                  }
                  if (a.name > b.name) {
                    return 1
                  }
                  return 0;
                });
                this.formInfoEnvioData(address);
                this.showAddAddressForm = true;
                this.currentAddressId = address.id;
              }
            });
          }
        });
      }
    });
  }

  formInfoEnvioData(address){
    let fullname = address.preguntar_por.split(' ');
    let name = fullname[0];
    fullname.shift();
    let lastname = fullname.length > 0 ? fullname.toString().replace(',', ' ') : '';

    this.formInfoEnvio.controls['name'].setValue(address.name);
    this.formInfoEnvio.controls['tipo_direccion'].setValue(address.tipo_direccion);
    this.formInfoEnvio.controls['preguntar_por_name'].setValue(name);
    this.formInfoEnvio.controls['preguntar_por_lastname'].setValue(lastname);
    this.formInfoEnvio.controls['direccion'].setValue(address.direccion);
    this.formInfoEnvio.controls['direccion_postalcode'].setValue(address.direccion_postalcode);
    this.formInfoEnvio.controls['pais'].setValue(address.pais_id);
    this.formInfoEnvio.controls['departamento'].setValue(address.dpto_id);
    this.formInfoEnvio.controls['ciudad'].setValue(address.ubicacion_id);
    this.formInfoEnvio.controls['departamento_otro'].setValue(address.departamento_otro);
    this.formInfoEnvio.controls['ciudad_otro'].setValue(address.ciudad_otro);
    this.formInfoEnvio.controls['telefono_fijo'].setValue(address.telefono_fijo);
    this.formInfoEnvio.controls['telefono_celular'].setValue(address.telefono_celular);
  }

  deleteAddress(address){
    if(this.user.estado_login.toLowerCase() == 'login_parcial'){
      return;
    }

    this.showAddAddressForm = false;
    let deleteAddress = {
        session_id: this.user.sessionCrm,
        direccion_id: address.id
    };
    this.addressService.delete(deleteAddress).subscribe( res => {
      if(res != null) {
        this.listAddresses();
      }
    });
  }

  toggleForm(){
    this.showAddAddressForm = !this.showAddAddressForm;
    this.currentAddressId = "";
    this.currentAddressSelected = null;
    return false;
  }

  createUpdateAddress() {
    if(this.user.estado_login.toLowerCase() == 'login_parcial')
      return false;

    if(this.formInfoEnvio.valid) {
      let address = {
        session_id: this.user.sessionCrm,
        id: this.currentAddressId,
        name: this.formInfoEnvio.value.name,
        preguntar_por: this.formInfoEnvio.value.preguntar_por_name + ' ' + this.formInfoEnvio.value.preguntar_por_lastname,
        direccion: this.formInfoEnvio.value.direccion,
        tipo_direccion: this.formInfoEnvio.value.tipo_direccion,
        direccion_postalcode: this.formInfoEnvio.value.direccion_postalcode,
        pais_id: this.formInfoEnvio.value.pais,
        dpto_id: this.formInfoEnvio.value.departamento,
        departamento_otro: this.formInfoEnvio.value.departamento_otro,
        ciudad_otro: this.formInfoEnvio.value.ciudad_otro,
        ubicacion_id: this.formInfoEnvio.value.ciudad,
        telefono_fijo: this.formInfoEnvio.value.telefono_fijo,
        telefono_celular: this.formInfoEnvio.value.telefono_celular,
      };

      this.addressService.createUpdate(address).subscribe( res => {
        if(res != null && 'id' in res) {
          this.listAddresses();
        }
      });

    } else {
      this.formInfoEnvio.controls['name'].markAsTouched();
      this.formInfoEnvio.controls['tipo_direccion'].markAsTouched();
      this.formInfoEnvio.controls['preguntar_por_name'].markAsTouched();
      this.formInfoEnvio.controls['preguntar_por_lastname'].markAsTouched();
      this.formInfoEnvio.controls['direccion'].markAsTouched();
      this.formInfoEnvio.controls['direccion_postalcode'].markAsTouched();
      this.formInfoEnvio.controls['pais'].markAsTouched();
      this.formInfoEnvio.controls['departamento'].markAsTouched();
      this.formInfoEnvio.controls['ciudad'].markAsTouched();
      this.formInfoEnvio.controls['telefono_fijo'].markAsTouched();
      this.formInfoEnvio.controls['telefono_celular'].markAsTouched();
      this.showWarningMessage = true;
      this.warningMessage = 'Por favor complete datos de dirección.';
      return false;
    }
  }

  openLoginView(){
    this.userService.updateLoginModalState(true);
    this.userService.updateLoginModalOrigin('datos-envio');
  }
}
