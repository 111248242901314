import { Action } from '@ngrx/store';

export enum AccionesEventoDetalle {
    CARGAR_EVENTO_DETALLE = '[EVENTO DETALLE] => Cargar',
    CARGA_EVENTO_DETALLE_EXITOSA = '[EVENTO DETALLE] => Carga Exitosa',
    CARGA_EVENTO_DETALLE_FALLIDA = '[EVENTO DETALLE] => Carga Fallida',
    RESET_EVENTO_DETALLE = '[EVENTO DETALLE] => Reset',
}

export class CargarEventoDetalle implements Action {
    readonly type = AccionesEventoDetalle.CARGAR_EVENTO_DETALLE;

    constructor( public payload?: any ) {}
}

export class CargarEventoDetalleFail implements Action {
    readonly type = AccionesEventoDetalle.CARGA_EVENTO_DETALLE_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarEventoDetalleSuccess implements Action {
    readonly type = AccionesEventoDetalle.CARGA_EVENTO_DETALLE_EXITOSA;

    constructor( public evento: any ) {}
}

export class ResetEventoDetalle implements Action {
    readonly type = AccionesEventoDetalle.RESET_EVENTO_DETALLE;
}

export type UnionAccionesEventoDetalle = CargarEventoDetalle | CargarEventoDetalleFail | CargarEventoDetalleSuccess | ResetEventoDetalle;
