import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import {
  CargarDireccionesSuccess,
  CargarDireccionesFail,
  AccionesDirecciones,
  AgregarDireccionSuccess,
  AgregarDireccionFail,
  EliminarDireccionSuccess,
  EliminarDireccionFail,
  ActualizarDireccionSuccess,
  ActualizarDireccionFail,
} from "./actions";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { AddressService } from "src/app/services/address.service";

@Injectable()
export class DireccionesEffects {
  constructor(
    private actions$: Actions,
    public addressService: AddressService
  ) {}

  @Effect()
  cargarDirecciones$ = this.actions$
    .pipe(ofType(AccionesDirecciones.CARGAR_DIRECCIONES))
    .pipe(
      switchMap((data: any) => {
        return this.addressService.read(data.payload).pipe(
          map((response: any) => {
            if (response && response.direcciones.length) {
              return new CargarDireccionesSuccess(response.direcciones);
            }
            return new CargarDireccionesFail(response);
          }),
          catchError((error) => of(new CargarDireccionesFail(error)))
        );
      })
    );

  @Effect()
  agregarDireccion$ = this.actions$
    .pipe(ofType(AccionesDirecciones.AGREGAR_DIRECCION))
    .pipe(
      switchMap((data: any) => {
        return this.addressService.createUpdate(data.direccion).pipe(
          map((response: any) => {
            if (response && response.id) {
              return new AgregarDireccionSuccess(response.direccion);
            }
            return new AgregarDireccionFail(response);
          }),
          catchError((error) => of(new AgregarDireccionFail(error)))
        );
      })
    );

  @Effect()
  eliminarDireccion$ = this.actions$
    .pipe(ofType(AccionesDirecciones.ELIMINAR_DIRECCION))
    .pipe(
      switchMap((data: any) => {
        return this.addressService.delete(data.direccion).pipe(
          map((response: any) => {
            if (response) {
              return new EliminarDireccionSuccess(response);
            }
            return new EliminarDireccionFail(response);
          }),
          catchError((error) => of(new EliminarDireccionFail(error)))
        );
      })
    );

  @Effect()
  ActualizarDireccion$ = this.actions$
    .pipe(ofType(AccionesDirecciones.ACTUALIZAR_DIRECCION))
    .pipe(
      switchMap((data: any) => {
        return this.addressService.createUpdate(data.direccion).pipe(
          map((response: any) => {
            if (response && response.id) {
              return new ActualizarDireccionSuccess(response.direccion);
            }
            return new ActualizarDireccionFail(response);
          }),
          catchError((error) => of(new ActualizarDireccionFail(error)))
        );
      })
    );
}
