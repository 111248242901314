import { AppState } from './../appState';
import { UnionAccionesPromociones, AccionesPromociones } from './actions';
import { State } from '@ngrx/store';

export interface PromocionesStateI {
  data: any[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: PromocionesStateI = {
  data: [],
  loaded: false,
  loading: false,
  error: null
};

export function promocionesReducer(state: any = estadoInicial, action: UnionAccionesPromociones) {
  switch (action.type) {
    case AccionesPromociones.CARGAR_PROMOCIONES:
      return {
        ...state,
        loading: true,
        error: null
      };

    case AccionesPromociones.CARGA_PROMOCIONES_EXITOSA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...action.promociones]
      };

    case AccionesPromociones.CARGA_PROMOCIONES_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload }
      };

    default:
      return state;
  }
}
