import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from "@ngrx/store";
import { Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { CargarDirecciones, AccionesDirecciones } from 'src/app/store/direcciones/actions';
import { AppState } from 'src/app/store/appState';
import { Subscription } from 'rxjs';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'app-libreta-direcciones',
  templateUrl: './libreta-direcciones.component.html',
  styleUrls: ['./libreta-direcciones.component.css']
})
export class LibretaDireccionesComponent implements OnInit, OnDestroy {

  user: any = null;
  showDeleteDirModal:boolean = false;
  countries: any = [];
  departments: any = [];
  cities: any = [];
  addresses: any = [];
  currentAddressSelected: any = null;
  showAddAddressForm: boolean = false;
  currentAddressId: string = '';
  showWarningMessage: boolean = false;
  warningMessage: string = '';
  showOthers: boolean = false;

  direcciones$ = this.store.select(store => store.direcciones);
  susA: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private actions: Actions
  )
  {
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)

    if(this.router.url.indexOf('agregar') > 0){
      this.showAddAddressForm = true;
    }

    this.susA = this.actions.subscribe((action: any) => {
      if (
        action.type === AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA ||
        action.type === AccionesDirecciones.ACTUALIZAR_DIRECCION_EXITOSA ||
        action.type === AccionesDirecciones.CANCELAR
      ) {
        this.showAddAddressForm = false;
        window.scrollTo(0,0);
      }
      if ( action.type === AccionesDirecciones.SET_DIRECCION && this.router.url.indexOf('/eliminar') ) {
        this.showAddAddressForm = false;
      }
    });
  }

  ngOnDestroy(){
    this.susA.unsubscribe();
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.listAddresses();
  }

  listAddresses() {
    this.store.dispatch(
      new CargarDirecciones({ session_id: JSON.parse(localStorage.session) })
    );
  }

  toggleForm() {
    this.showAddAddressForm = !this.showAddAddressForm;
    this.currentAddressId = '';
    this.currentAddressSelected = null;
    return false;
  }
}
