import { ProductosService } from './../../../services/productos.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { CargarNovedadesHomeSuccess, CargarNovedadesHomeFail, AccionesNovedadesHome } from './actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class NovedadesHomeEffects {

    constructor(
        private actions$: Actions,
        public productosService: ProductosService
    ) {}

    @Effect()
    cargarNovedadesHome$ = this.actions$.pipe( ofType(AccionesNovedadesHome.CARGAR_NOVEDADES_HOME) )
        .pipe(
            switchMap( (data: any) => {
                return this.productosService.productSearch(data.payload)
                    .pipe(
                        map( (response: any) => {
                            if (response) {
                                return new CargarNovedadesHomeSuccess(response);
                            }
                            return new CargarNovedadesHomeFail(response);
                        } ),
                        catchError( error => of(new CargarNovedadesHomeFail(error)) )
                    );
            })
        );
}
