import { Component } from '@angular/core';

@Component({
  selector: 'app-datos-envio-carrito',
  template: `
     <!-- <div class="row mx-0 py-3">
        <div class="col-md-9 mx-auto">
          <p class="text--blue text--light text--sml-normal text--lgxs text--lh-1 text-center">*Los tiempos de despacho pueden presentar retrasos debido a temas de orden público que se presentan en algunas ciudades del país y a la emergencia sanitaria.</p>
        </div>
      </div>-->

      <!-- <div class="row mx-0 py-3">
        <div class="col-md-3">
          <div class="row mx-0 mb-3">
            <span class="col-12 px-0 px-md-3 text--blue text--light text--sml text--lh-1">Detalles</span>
            <span class="col-12 px-0 px-md-3 text--blue text--extrabold text--lgxs text--lh-1">de envío</span>
          </div>
        </div>
        -->
        <!--
        <div class="col-md-9">
          <p class="text--blue text--light text--lh-normal text--xm">Si la dirección de envío es Bogotá, Cali, Medellín
            (no aplica para municipios del área metropolitana), Barranquilla, Cartagena o Pereira, se hará un cobro de
            $2.650 COP por despachos de 1 o 2 libros y $300 COP por cada libro adicional. Para las demás ciudades,
            municipios y poblaciones la tarifa de envío corresponde a la tarifa de la transportadora coordinadora
            mercantil.</p>
          <p class="text--blue text--light text--lh-normal text--xm">* Debido a al cierre programado en la línea de lunes a jueves en horas de la
            noche durante 6 meses, es posible que el tiempo de entrega varíe y se tome
            más tiempo la entrega de los pedidos para las ciudades y localidades que se
            pueden ver afectadas.</p>
        </div>
      </div> -->
      
      <!-- <div class="row mx-0 py-3">
        <div class="col-md-3">
          <div class="row mx-0 mb-3">
            <span class="col-12 px-0 px-md-3 text--blue text--light text--sml text--lh-1">Tiempo aproximado</span>
            <span class="col-12 px-0 px-md-3 text--blue text--extrabold text--lgxs text--lh-1">de entrega</span>
          </div>
        </div>
        <div class="col-md-9">
          <p class="text--x text--red text--semibold">*Por afectación de orden público en las vías que comunican al departamento del Valle del Cauca con los departamentos del sur del país, sus pedidos podrían tardar más tiempo del indicado o no ser despachados.</p>
          <p class="text--blue text--light text--xm mb-0"><strong>Ciudades principales</strong>: 3 días hábiles.</p>
          <p class="text--blue text--light text--xm mb-0"><strong>Ciudades principales</strong>: 5 días hábiles (ciudades
            y poblaciones que requieren reexpedición 8 días hábiles)</p>
          <p class="text--blue text--light text--xm mb-0"><strong>Envíos internacionales</strong>: 8 días hábiles.</p>
          <p class="text--blue text--light text--lh-normal text--xm mt-3">Los días hábiles son de Lunes a Viernes. Tener
            en cuenta que las compras realizadas después de las 3:00 pm se despachan el día hábil siguiente.</p>
        </div>
      </div> -->
  `,
})
export class DatosEnvioCarritoComponent {}