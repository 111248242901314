import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from "../../../services/usuario.service";
import { Store } from "@ngrx/store";
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-acceso-rapido',
  templateUrl: './acceso-rapido.component.html',
  styleUrls: ['./acceso-rapido.component.css']
})

export class AccesoRapidoComponent implements OnInit {
  usuario:any;
  origin:any;
  forma:FormGroup;
  dir:boolean = false;
  invalidRegisterMessages: String = '';
  invalidRegisterState: boolean = false;
  emailUser:any;
  constructor(
    private router:Router,
    private store: Store<any>,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ){
    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
    });
    if(this.route.snapshot.queryParamMap.get('origin')){
      this.origin = this.route.snapshot.queryParamMap.get('origin');
      console.log(this.origin);
    }
    this.validateFormRegistro();
  }

  validateFormRegistro(){
    this.forma = new FormGroup({
      'email'					  : new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')
      ])
    });
  }


  formCodigoAccesoRapido(){
    //console.log(this.forma);
    this.invalidRegisterState = false;
    if (this.forma.valid == true){
      let email = this.forma.controls['email'].value;
      this.emailUser = email;
      this.usuarioService.enviarCodigoAcceso(email).subscribe(response => {
        if(response && response.status == 'ok'){
          this.invalidRegisterMessages = response.msg;
          let params = { emailUser: this.emailUser };
          this.router.navigate(['/', 'usuario', 'confirmar-codigo'],{ queryParams: { emailUser: this.emailUser, origin: this.origin }, skipLocationChange:true } );
    } else if (response.number == 2010){
          this.invalidRegisterState = true;
          this.invalidRegisterMessages = response.description;
        }
      });
    } else {
      //ERROR CUANDO SE ENCUENTRA UN CAMPO NO VALIDO
      this.forma.controls['email'].markAsTouched();
      return false;
    }
  }
}
