import { UnionAccionesOrdenes, AccionesOrdenes } from "./actions";

export interface ColeccionesStateI {
  data: any[];
  orden: {};
  loaded: boolean;
  loading: boolean;
  loading_orden: boolean;
  error: any;
}

const estadoInicial: ColeccionesStateI = {
  data: [],
  orden: {},
  loaded: false,
  loading: false,
  loading_orden: false,
  error: null,
};

export function ordenesReducer(
  state: any = estadoInicial,
  action: UnionAccionesOrdenes
) {
  switch (action.type) {
    case AccionesOrdenes.CARGAR_ORDENES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case AccionesOrdenes.CARGA_ORDENES_EXITOSA:{

      let confirmadas: any =  [];
      let pendientes: any =  [];
      let canceladas: any =  [];

      try {
        if (action.ordenes != null) {
          if (action.ordenes.length > 0) {
            action.ordenes.forEach((bill) => {
              let invoice_date = bill.invoice_date.split("-");
              // yyyy-mm-dd to dd/mm/yyyy
              bill.invoice_date =
                invoice_date[2] + "/" + invoice_date[1] + "/" + invoice_date[0];
              bill.showDetails = false;
              bill.btnMessage = "Ver detalles";

              if (bill.status) {
                if (bill.status.toLowerCase() == "paid") {
                  confirmadas.push(bill);
                }
                if (bill.status.toLowerCase() == "unpaid") {
                  pendientes.push(bill);
                }
                if (bill.status.toLowerCase() == "cancelled") {
                  canceladas.push(bill);
                }
              }
            });
          }
        }
      } catch (error) {
        console.log("error controlado => ", error);
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        data: { confirmadas, pendientes, canceladas },
      };
    }

    case AccionesOrdenes.CARGA_ORDENES_FALLIDA:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: { ...action.payload },
      };

    case AccionesOrdenes.ACTUALIZAR_ORDEN: {
      let { i, orden, orden_tipo } = action.data[2];
      let ordenes = [];

      if (orden_tipo === "confirmadas") {
        ordenes = [...state.data.confirmadas];
      }
      if (orden_tipo === "pendientes") {
        ordenes = [...state.data.pendientes];
      }
      if (orden_tipo === "canceladas") {
        ordenes = [...state.data.canceladas];
      }

      if (!orden.products) {
        orden.products = [];
      }
      if (!orden.guias) {
        orden.guias = [];
      }

      // encontramos el indice de la orden
      let index = ordenes.findIndex((ord) => ord.id === orden.id);
      let ordenState = ordenes.find((ord) => ord.id === orden.id);

      orden.cargando = true;

      if (orden_tipo === "confirmadas") {
        state.data.confirmadas[index] = { ...ordenState, ...orden };
      }
      if (orden_tipo === "pendientes") {
        state.data.pendientes[index] = { ...ordenState, ...orden };
      }
      if (orden_tipo === "canceladas") {
        state.data.canceladas[index] = { ...ordenState, ...orden };
      }

      return { ...state };
    }
    case AccionesOrdenes.ACTUALIZAR_ORDEN_EXITOSA: {
      let { response } = action.data;
      let { orden_tipo } = action.data.dataOrden;
      let orden_log = action.data.dataOrden.orden;
      let ordenes = [];

      // console.clear();
      // console.warn('ORDEN EN REDUCER', orden_log);

      if(!orden_log){
        orden_log = {
          products: [],
        };
      }

      if (orden_tipo === "confirmadas") {
        ordenes = [...state.data.confirmadas];
      }
      if (orden_tipo === "pendientes") {
        ordenes = [...state.data.pendientes];
      }
      if (orden_tipo === "canceladas") {
        ordenes = [...state.data.canceladas];
      }

      if (!orden_log.products.length) {
        orden_log.products = [];
      }
      if (!orden_log.guias) {
        orden_log.guias = [];
      }
      if (!orden_log.guias_copy) {
        orden_log.guias_copy = [];
      }

      // encontramos el indice de la orden_log
      let index = ordenes.findIndex((ord) => ord.id === orden_log.id);
      let ordenState = ordenes.find((ord) => ord.id === orden_log.id);

      try {
        if(response.grupos[0].lineas.productos.length){
          orden_log.products = response.grupos[0].lineas.productos;

          if (!orden_log.products[0].address) {
            orden_log.products[0].address = "";
          }
          if (!orden_log.products[0].valor_envio) {
            orden_log.products[0].valor_envio = "";
          }

          orden_log.products[0].address = response.direccion_envio_data;
          orden_log.products[0].valor_envio = response.totales.envio
            ? response.totales.envio
            : 0;
        }else{
          orden_log.products =response.grupos[0].lineas.productos;
        }
      } catch (error) {

      }

      // pruebas
      // orden.products = orden.products.map((prod, i) => {
      //   if(i === 1){
      //     prod.despacho.guia = 'asdasd'
      //   }
      //   if(i === 2){
      //     prod.despacho.guia = 'asdasd963255'
      //   }

      //   return prod;
      // });

      orden_log.btnMessage = "ocultar";
      orden_log.showDetails = true;
      orden_log.cargando = false;

      let a: any = [];

      try {
        // recorremos los productos para obtener las guias

        if(orden_log.products.length){

          if(orden_log.products.length === 1){
            orden_log.products.map((prod, i) => {

              if (
                prod.despacho &&
                prod.despacho.guia &&
                prod.despacho.guia !== "-1"
              ) {
                orden_log.guias.push(prod.despacho);
                a[i] = { ...prod };
                // array[i].despacho = prod.despacho;
                a[i].despacho = prod.despacho;
                return prod;
              }
            });
          }else{
            orden_log.products
              .reduce((prod, prodN, indice, array) => {
                if(!prod){
                  return;
                }

                // let prodEnGuias = orden.guias.find(
                //   (guia) => guia.guia === prod.despacho.guia || guia.guia === prodN.despacho.guia
                // );

                if (
                  prod.despacho &&
                  prod.despacho.guia &&
                  prod.despacho.guia !== "-1"
                  // !prodEnGuias
                ) {
                  // orden.guias.push(prod.despacho);
                  a[indice - 1] = { ...prod };
                  array[indice - 1].despacho = prod.despacho;
                  a[indice - 1].despacho = prod.despacho;
                  return prodN;
                }
              });

              orden_log.products = orden_log.products.map((p, i) => {
                // if(!orden_log.guias_copy.length && i === 0){
                //   return p;
                // }

                if (p.despacho.guia && p.despacho.guia !== "-1") {
                  orden_log.guias_copy.push(p.despacho);
                }

                // let prodEnGuias = orden.guias_copy.find(
                //   (guia) =>
                //     guia.guia === p.despacho.guia || p.despacho.guia !== "-1"
                // );debugger
                // if (prodEnGuias) {
                //   orden.guias_copy.push(p.despacho);
                // }
                // p.guias.push(p.despacho);
                return p;
              });

              if (orden_log.guias_copy.length) {
                orden_log.guias = orden_log.guias_copy;
              }

              try {
                var uniqueArray = removeDuplicates(orden_log.guias, "codigo");
                // var uniqueArray2 = removeDuplicates(orden_log.products.guias, "codigo");
                // console.log("uniqueArray is: " + JSON.stringify(uniqueArray));

                orden_log.guias = uniqueArray.filter(i => {
                  if(typeof i === "object"){
                    return i;
                  }
                })
              } catch (error) {

              }
          }


          if(a.length){
            // orden.products = a;
            orden_log.products_guias = a;
          }else{
            orden_log.products_guias = [];
          }
        }


      } catch (error) {
        console.log("Error controlado ", error);
      }

      if (orden_tipo === "confirmadas") {
        state.data.confirmadas[index] = { ...ordenState, ...orden_log };
      }
      if (orden_tipo === "pendientes") {
        state.data.pendientes[index] = { ...ordenState, ...orden_log };
      }
      if (orden_tipo === "canceladas") {
        state.data.canceladas[index] = { ...ordenState, ...orden_log };
      }

      return { ...state };
    }
    case AccionesOrdenes.CERRAR_ORDENES: {
      // encontramos el indice de la orden
      state.data.confirmadas.map(resetOrdenes);
      state.data.canceladas.map(resetOrdenes);
      state.data.pendientes.map(resetOrdenes);

      return { ...state };
    }

    case AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN: {
      return {
        ...state,
        loading_orden: true,
        orden: {},
        error: {}
      };
    }

    case AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN_EXITOSA: {
      return {
        ...state,
        orden: action.data,
        loading_orden: false
      };
    }

    case AccionesOrdenes.ACTUALIZAR_DIRECCION_ENVIO_ORDEN_FALLIDA: {
      return {
        ...state,
        error: action.data,
        loading_orden: false
      };
    }

    case AccionesOrdenes.RESET_ORDENES:
      return {...estadoInicial};

    default:
      return state;
  }
}

const resetOrdenes = (prd) => {
  prd.btnMessage = "Ver detalles";
  prd.showDetails = false;
  return prd;
}

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject  = {};

  for(var i in originalArray) {
  lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for(i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
