import { NotFound404Component } from './components/not-found404/not-found404.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";
import { AuthNoGuardService } from "./services/auth-noguard.service";
import { AuthGuardDatosEnvioService } from "./services/auth-guard-datos-envio.service";
import { HomeComponent } from './components/home/home.component';
import { LibrosComponent } from './components/libros/libros.component';
import { LibroComponent } from './components/libro/libro.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { SolicitudCorreoComponent } from "./components/carrito/solicitud-correo/solicitud-correo.component";
import { DatosEnvioComponent } from "./components/carrito/datos-envio/datos-envio.component";
import { InformacionPagoComponent } from "./components/carrito/informacion-pago/informacion-pago.component";
import { ConfirmacionPagoComponent } from "./components/carrito/confirmacion-pago/confirmacion-pago.component";
import { MiCarritoComponent } from "./components/carrito/mi-carrito/mi-carrito.component";
import { IniciarSesionComponent } from "./components/login/iniciar-sesion/iniciar-sesion.component";
import { IngresoComponent } from "./components/login/ingreso/ingreso.component";
import { AccesoRapidoComponent } from "./components/login/acceso-rapido/acceso-rapido.component";
import { ConfirmarCodigoComponent } from "./components/login/confirmar-codigo/confirmar-codigo.component";
import { LoginComponent } from "./components/login/login.component";
import { RegistroComponent } from "./components/login/registro/registro.component";
import { MiCuentaComponent } from "./components/mi-cuenta/mi-cuenta.component";
import { CambiarClaveComponent } from "./components/login/cambiar-clave/cambiar-clave.component";
import { DatosPersonalesComponent } from './components/mi-cuenta/datos-personales/datos-personales.component';
import { MetodosPagoComponent } from './components/mi-cuenta/metodos-pago/metodos-pago.component';
import { LibretaDireccionesComponent } from './components/mi-cuenta/libreta-direcciones/libreta-direcciones.component';
import { HistorialOrdenesComponent } from './components/mi-cuenta/historial-ordenes/historial-ordenes.component';
import { MisFavoritosComponent } from './components/mi-cuenta/mis-favoritos/mis-favoritos.component';
import { EbooksComponent } from './components/mi-cuenta/ebooks/ebooks.component';
import { ColeccionesComponent } from "./components/colecciones/colecciones.component";
import { DetalleColeccionComponent } from "./components/colecciones/detalle-coleccion/detalle-coleccion.component";
import { PromocionesComponent } from "./components/promociones/promociones.component";
import { DetallePromocionComponent } from "./components/promociones/detalle-promocion/detalle-promocion.component";
import { EventosComponent } from "./components/eventos/eventos.component";
import { DetalleEventoComponent } from "./components/eventos/detalle-evento/detalle-evento.component";
import { QuienesSomosComponent } from "./components/quienes-somos/quienes-somos.component";
import { NuestrasSedesComponent } from "./components/nuestras-sedes/nuestras-sedes.component";
import { RegalaUnBonoComponent } from "./components/regala-un-bono/regala-un-bono.component";
import { PerfilAutorComponent } from "./components/perfil-autor/perfil-autor.component";
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { PqrsComponent } from './components/ayuda/pqrs/pqrs.component';
import { PreguntasFrecuentesComponent } from './components/ayuda/preguntas-frecuentes/preguntas-frecuentes.component';
import { PoliticaDatosComponent } from './components/ayuda/politica-datos/politica-datos.component';
import { PoliticaMercanciaComponent } from './components/ayuda/politica-mercancia/politica-mercancia.component';
import { ManualesComponent } from './components/ayuda/manuales/manuales.component';
import { RecuperarClaveComponent } from "./components/login/recuperar-clave/recuperar-clave.component";
import { AuthGuardRegisterStateService } from './services/auth-guard-register-state.service';
import { AuthGuardInverseService } from './services/auth-guard-inverse.service';
import { ComunicadosLibreriaNacionalComponent } from "./components/comunicados-libreria-nacional/comunicados-libreria-nacional.component";
import { TerminosYCondicionesComponent } from './components/ayuda/terminos-y-condiciones/terminos-y-condiciones.component';
import { ReversionesComponent } from './components/ayuda/reversiones/reversiones.component';
import { TycLibreroMamaComponent } from './components/ayuda/tyc-librero-mama/tyc-librero-mama.component';
import { ConfirmadasComponent } from './components/mi-cuenta/historial-ordenes/confirmadas/confirmadas.component';
import { PendientesComponent } from './components/mi-cuenta/historial-ordenes/pendientes/pendientes.component';
import { CanceladasComponent } from './components/mi-cuenta/historial-ordenes/canceladas/canceladas.component';
import { AgregarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/agregar-direccion/agregar-direccion.component';
import { EditarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/editar-direccion/editar-direccion.component';
import { EliminarDireccionComponent } from './components/mi-cuenta/libreta-direcciones/eliminar-direccion/eliminar-direccion.component';
import { ConfirmarDireccionComponent } from './components/mi-cuenta/historial-ordenes/confirmar-direccion/confirmar-direccion.component';
import { NewsLetterComponent } from './components/gracias/news-letter/news-letter.component';
import { EliminarCuentaComponent } from './components/eliminar-cuenta/eliminar-cuenta.component';

const APP_ROUTES: Routes = [
  /* { path: '', component: EnmantenimientoComponent }, */
  { path: '', component: HomeComponent },
  { path: 'categoria/:tipo', component: LibrosComponent },
  { path: 'categoria/:tipo/:categoria', component: LibrosComponent },
  { path: 'categoria/:tipo/:categoria/:tipobusqueda/:search', component: LibrosComponent },
  { path: 'producto/:slug', component: LibroComponent },
  {
    path: 'usuario',
    component: LoginComponent,
    children: [
      { path: 'ingresar', component: IngresoComponent, canActivate: [AuthNoGuardService] },
      { path: 'registrarme', component: RegistroComponent, canActivate: [AuthNoGuardService] },
      { path: 'acceso-rapido', component: AccesoRapidoComponent, canActivate: [AuthNoGuardService] },
      { path: 'confirmar-codigo', component: ConfirmarCodigoComponent, canActivate: [AuthNoGuardService] },
      { path: 'iniciar-sesion', component: IniciarSesionComponent, canActivate: [AuthNoGuardService] },
      { path: 'recuperar-clave', component: RecuperarClaveComponent },
      { path: 'cambiar-clave', component: CambiarClaveComponent },
      { path: 'cambiar-clave/:guid/:email', component: CambiarClaveComponent },
      { path: '**', pathMatch: 'full', redirectTo: '/usuario/ingresar' }
    ]
  },
  {
    path: 'carrito',
    component: CarritoComponent,
    children: [
      { path: 'mi-carrito', component: MiCarritoComponent },
      { path: 'solicitud-correo', component: SolicitudCorreoComponent, canActivate: [AuthGuardInverseService] },
      { path: 'datos-envio', component: DatosEnvioComponent, canActivate: [AuthGuardDatosEnvioService, AuthGuardService],
        children: [
          { path: 'direccion/agregar', component: AgregarDireccionComponent, pathMatch: 'full' },
          { path: 'direccion/editar/:direccion', component: EditarDireccionComponent },
          { path: 'direccion/eliminar/:direccion', component: EliminarDireccionComponent },
        ]
      },
      { path: 'informacion-pago', component: InformacionPagoComponent, canActivate: [AuthGuardService] },
      { path: 'informacion-pago/:billId', component: InformacionPagoComponent, canActivate: [AuthGuardService] },
      { path: 'confirmacion-pago', component: ConfirmacionPagoComponent },
      { path: 'confirmacion-pago/:billId', component: ConfirmacionPagoComponent },
      { path: 'confirmacion-pago/:billId/:orderId', component: ConfirmacionPagoComponent },
      { path: 'confirmacion-pago/:billId/:orderId/:codePayu/:urlPayuPayment', component: ConfirmacionPagoComponent },
      { path: '**', pathMatch: 'full', redirectTo: 'mi-carrito' }
    ]
  },
  {
    path: 'mi-cuenta',
    component: MiCuentaComponent,
    canActivate: [AuthGuardRegisterStateService],
    children: [
      { path: 'datos-personales', component: DatosPersonalesComponent },
      // { path: 'metodos-pago', component: MetodosPagoComponent },
      { path: 'libreta-direcciones', component: LibretaDireccionesComponent,
      children: [
          { path: 'agregar', component: AgregarDireccionComponent, pathMatch: 'full' },
          { path: 'editar/:direccion', component: EditarDireccionComponent },
          { path: 'eliminar/:direccion', component: EliminarDireccionComponent },
        ]
      },
      { path: 'mis-favoritos', component: MisFavoritosComponent },
      { path: 'historial-ordenes', component: HistorialOrdenesComponent,
        children: [
          { path: 'confirmadas', component: ConfirmadasComponent, children: [
            { path: 'direccion/:direccion/orden/:orden', component: ConfirmarDireccionComponent },
          ]},
          { path: 'pendientes', component: PendientesComponent },
          { path: 'canceladas', component: CanceladasComponent },
          { path: '**', pathMatch: 'full', redirectTo: 'confirmadas' }
        ],
      },
      { path: 'historial-ordenes/:billId', component: HistorialOrdenesComponent },
      { path: 'historial-ordenes/:billId/:orderId', component: HistorialOrdenesComponent },
      { path: 'ebooks', component: EbooksComponent },
      { path: '**', pathMatch: 'full', redirectTo: 'datos-personales' }
    ]
  },
  {
    path: 'eliminar-cuenta/:id',
    component: EliminarCuentaComponent
  },
  {
    path: 'contenido/pagina/ayuda',
    component: AyudaComponent,
    children: [
      { path: 'pqrs', component: PqrsComponent },
      { path: 'preguntas-frecuentes', component: PreguntasFrecuentesComponent },
      { path: 'politica-tratamiento-de-datos', component: PoliticaDatosComponent },
      { path: 'terminos-y-condiciones', component: TerminosYCondicionesComponent },
      { path: 'politica-cambio-de-mercancia', component: PoliticaMercanciaComponent },
      /* { path: 'manuales', component: ManualesComponent }, */
      { path: 'reversiones', component: ReversionesComponent },
      { path: 'librero-de-mama', component: TycLibreroMamaComponent },
      { path: '**', pathMatch: 'full', redirectTo: 'pqrs' }
    ]
  },
  { path: 'contenido/pagina/sedes', component: NuestrasSedesComponent },
  { path: 'contenido/pagina/quienes-somos', component: QuienesSomosComponent },
  { path: 'contenido/pagina/regala-un-bono', component: RegalaUnBonoComponent },
  { path: 'perfil-autor/:detalle', component: PerfilAutorComponent },
  { path: 'contenido/pagina/colecciones', component: ColeccionesComponent },
  { path: 'coleccion/:slug', component: DetalleColeccionComponent },
  { path: 'contenido/pagina/promociones', component: PromocionesComponent },
  { path: 'promocion/:slug', component: DetallePromocionComponent },
  { path: 'contenido/pagina/eventos', component: EventosComponent },
  { path: 'eventos/:slug', component: DetalleEventoComponent },
  { path: 'comunicados-libreria-nacional', component: ComunicadosLibreriaNacionalComponent },
  { path: 'newsletter/gracias', component: NewsLetterComponent },
  { path: '404', component: NotFound404Component },
  { path: '**', pathMatch: 'full', redirectTo: '/404' }
  /* { path: '404', component: EnmantenimientoComponent },
  { path: '**', pathMatch: 'full', component: EnmantenimientoComponent } */
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { enableTracing: false, useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
