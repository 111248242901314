import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Location, PlatformLocation } from "@angular/common"
import { Title, Meta } from '@angular/platform-browser'

import { StoreService } from '../../services/store.service'
import { UsuarioService } from '../../services/usuario.service'
import { CarritoService } from '../../services/carrito.service'
import { ProductosService } from '../../services/productos.service'
import { ColeccionesService } from '../../services/colecciones.service'
import { ACTION_SHOPCAR, UPDATE_BUSCADOR, UPDATE_MENU_MOBILE, UPDATE_MENU_MOBILE_SEARCH, ACTION_DEL_SHOPCAR } from '../../store/actions/appAction'
import { WindowScrolling } from '../../services/window-scrolling.service';
import { CapitalizeFirstPipe } from "../../pipes/capitalizefirst.pipe";
import { Filtro } from '../../interfaces/filtro.interface'

import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import Swal from 'sweetalert2'
//import { categories_data } from './categories';
//import { libros_novedades_data } from './libros_novedades_data';
//import { ebooks_novedades_data } from './ebooks_novedades_data';
//import { category_data } from './categoria_data';

declare var $: any;

@Component({
  selector: 'app-libros',
  templateUrl: './libros.component.html',
  styleUrls: ['./libros.component.css']
})

export class LibrosComponent implements OnInit {
  usuario: any;
  shopcar: any;
  formaOrder: FormGroup;
  listProduct: any = [];
  dataProducts: any = [];
  dataProductsCopy: any = [];
  filters: any = [];
  activeFilter: any = [];
  busqueda: any = null
  tipobusqueda: any = null
  categoryData: any = {};
  categoriesList: any = [];
  addedToCart: any = {};
  pages: any = [];
  pageCurrent: number = 1;
  pagesPerPage: number = 20;
  detailsProduct: any = [];
  updateInProgress: boolean = false;
  breadcrumb: any = [];
  searchText: string;
  paramsUrl: any = {};
  cargandoProductos: boolean = false;
  cargaProductos: boolean = false;
  dataDestacados: any = [];
  showTopFiltrar: boolean = false;
  active: number;
  showFiltersSidebar: boolean = true;
  showFilterModal: boolean = false;
  filterText: string = 'Ocultar filtros';
  filterIcon: string = 'icon-filter';
  icon: string = 'empty';
  status: any = false;
  currentURL: string;
  noCoverImg: string = '';
  noBannerImg: string = '';
  noBannerImgMovil: string = '';
  bannerSearch: string = '';
  bannerSearchMovil: string = '';
  tipoProducto: string = '';

  showSearchModal: boolean;
  showMobileModal: boolean;

  alturaHeader: number = 0;

  private windowScrolling: WindowScrolling;

  // defaults images lazyload
  loaderImg = '';
  limit = 20;
  offset = 0;

  params: any = {
    tipo: null
  };

  constructor(
    private meta: Meta,
    private router: Router,
    private store: Store<any>,
    private location: Location,
    private titleService: Title,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private carritoService: CarritoService,
    private productosService: ProductosService,
    public platformLocation: PlatformLocation,
    private coleccionesService: ColeccionesService,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    windowScrolling: WindowScrolling
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)

    this.windowScrolling = windowScrolling;
  }

  ngOnInit() {
    //VALIDATE ORDER INIT
    this.formaOrder = new FormGroup({
      productsOrder: new FormControl()
    });

    this.formaOrder.controls["productsOrder"].setValue("defecto");

    if (localStorage.getItem("canEnterDatosEnvio")) {
      localStorage.removeItem("canEnterDatosEnvio");
    }
    this.activatedRoute.params.subscribe((params) => {
      // se deja este redireccionamiento temporal por solicitud de el cliente libreria, por el momento no venden ebooks y se debe redireccionar a la seccion de libros
      if(params.tipo === 'ebooks'){
        this.router.navigate(["/", "categoria", 'libros']);
        return;
      }
      // console.log(this.params);
      if(params.tipo !== this.params.tipo){
        this.offset = 0;
        this.pageCurrent = 1;
        // console.log('entro');
      }
      let categoria: string = "novedades";
      this.paramsUrl.tipo = params.tipo ? params.tipo : null;
      this.paramsUrl.categoria = params.categoria ? params.categoria : null;
      this.paramsUrl.tipobusqueda = params.tipobusqueda
        ? params.tipobusqueda
        : null;
      this.paramsUrl.search = params.search ? params.search : null;

      if (this.paramsUrl.tipo == "libros" || this.paramsUrl.tipo == "ebooks") {
        console.log(this.paramsUrl);
        this.tipoProducto = this.paramsUrl.tipo;

        if (this.paramsUrl.categoria != null) {
          if (this.paramsUrl.categoria == "busqueda") {
            if (
              this.paramsUrl.tipobusqueda == "editoriales" ||
              this.paramsUrl.tipobusqueda == "autores"
            ) {
              this.tipobusqueda = this.paramsUrl.tipobusqueda;
            }
            this.busqueda = this.paramsUrl.search;
          }
        } else {
          this.paramsUrl.categoria = categoria;
        }
      }

      this.getListProducts(this.paramsUrl);
      this.getInfoCategory(this.paramsUrl);
      this.getListcategories(this.paramsUrl);
    });

    this.noCoverImg =
      (this.platformLocation as any)._location.origin +
      "/assets/images/no-cover.jpg";
    this.bannerSearch =
      (this.platformLocation as any)._location.origin +
      "/assets/images/banner-search.jpg-1920x400.jpg";
    this.bannerSearchMovil =
      (this.platformLocation as any)._location.origin +
      "/assets/images/banner-search.jpg-768x300.jpg";
    this.currentURL =
      (this.platformLocation as any)._location.origin +
      this.location.path(false);

    this.store.subscribe((response) => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

      this.showMobileModal = response.appReducer.menuMobileState;
      this.showSearchModal = response.appReducer.menuMobileSearchState;
    });

    //CAMBIAR DE ESTADO EL HEADER
    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false,
    });

    if (window.innerWidth < 991) {
      if (this.showMobileModal || this.showSearchModal) {
        this.windowScrolling.enable();
      }

      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false,
      });

      //CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: false,
      });
    }
    window.scrollTo(0, 0);

    if (window.innerWidth < 998) {
      this.showFiltersSidebar = false;
    }
    if (
      document.querySelector("app-header header") &&
      window.innerWidth < 998
    ) {
      setTimeout(() => {
        try {
          this.alturaHeader = document
            .querySelector("app-header header")
            .getBoundingClientRect().height;
        } catch (error) {
          console.log('Error controlado => ', error);
        }
        // console.error(document.querySelector('app-header header').getBoundingClientRect().height);
      }, 1000);
    }
  }

  setMetaData(data) {
    this.titleService.setTitle(data.title + " | " + "Libreria Nacional");
    this.meta.updateTag({ name: "keywords", content: data.keywords });
    this.meta.updateTag({ name: "description", content: data.description });
    this.meta.updateTag({
      name: "twitter:card",
      content: "summary_large_image",
    });
    this.meta.updateTag({ name: "twitter:title", content: data.title });
    this.meta.updateTag({ name: "twitter:text:title", content: data.title });
    this.meta.updateTag({
      name: "twitter:description",
      content: data.description,
    });
    this.meta.updateTag({ name: "twitter:image", content: data.image });
    this.meta.updateTag({ name: "twitter:image:alt", content: data.title });
    this.meta.updateTag({ property: "og:title", content: data.title });
    this.meta.updateTag({ property: "og:url", content: data.url });
    this.meta.updateTag({ property: "og:image", content: data.image });
    this.meta.updateTag({ property: "og:image:alt", content: data.title });
    this.meta.updateTag({
      property: "og:description",
      content: data.description,
    });
    this.meta.updateTag({ property: "product:brand", content: "" });
    this.meta.updateTag({ property: "product:availability", content: "" });
    this.meta.updateTag({ property: "product:condition", content: "" });
    this.meta.updateTag({ property: "product:price:amount", content: "" });
    this.meta.updateTag({ property: "product:price:currency", content: "" });
    this.meta.updateTag({ property: "product:retailer_item_id", content: "" });
    this.meta.updateTag({ property: "fb:app_id", content: "529648729383744" });
    this.meta.updateTag({ property: "fb:admins", content: "99999999" });
  }

  calculateFilterH() {
    if (window.innerWidth < 998) {
      return `calc(100vh - ${this.alturaHeader}px`;
    }
  }

  filterProductsByCategory(category) {
    if (window.innerWidth < 998) {
      this.showFiltersSidebar = false;
    }

    this.offset = 0;
    this.pageCurrent = 1;
    this.router.navigate(["/", "categoria", this.paramsUrl.tipo, category]);
  }

  getListProducts(params?, filter = false) {
    this.listProduct = [];
    this.cargaProductos = false;

    if(params){
      this.params = params;
    }

    if (!filter) {
      this.activeFilter = [];
      this.filters = [];
    }

    // this.storeService.updateAppState({
    //   action: UPDATE_BUSCADOR,
    //   payload: false
    // })

    params.search ? this.searchText = params.search : null;
    let data = {
      // search_text : params.search && params.categoria == 'busqueda'  ? params.search : null,
      search_text: params.search && params.categoria == 'busqueda' ? params.search : null,
      categoria_slug: (params.categoria == 'novedades' && params.tipo != 'libros') ? null : params.categoria,
      tipo: params.tipo == 'libros' ? 'LIBRO' : 'EBOOK',
      tipo_busqueda: (params.tipobusqueda == 'editoriales' || params.tipobusqueda == 'autores') ? params.tipobusqueda : null,
      filtros: this.activeFilter.length > 0 ? this.activeFilter : null,
      //limite: params.search && params.categoria == 'busqueda' ? 40 : 120,
      limite: this.limit,
      offset: this.offset,
    };

    /**
     * ! Codigo para cargar data local, cuando se requiera en un futuro
     * * Descomentar lineas
     */

    /* if ((this.params.categoria === 'novedades' && this.params.tipo === 'libros') || (this.params.categoria === 'novedades' && this.params.tipo === 'ebooks')) {
      let products = [];

      if (this.params.categoria === 'novedades' && this.params.tipo === 'libros') {
        products = libros_novedades_data.productos;
      } else {
        products = ebooks_novedades_data.productos;
      }
      products.forEach((obj: any) => {
        if (obj.promociones.length > 0) {
          let promociones = [];
          obj.promociones.forEach(promo => {
            promociones.push(promo.name);
          });
          obj.promocionesText = promociones.join(', ');
        }
      });
      this.dataProductsCopy = products;
      this.orderArray(products);
      //CARGAR FILTROS UNA ÚNICA VEZ, CUANDO CARGA INICIALMENTE LA PÁGINA
      if (!filter) {
        this.filtersLoad(libros_novedades_data.filtros);
      }
    } else { */
    //CARGADOR DE PRODUCTOS
    this.cargandoProductos = true;
    this.productosService.productSearch(data).subscribe((response: any) => {
      //ORDENAR PRODUCTOS
      let products = response.productos;
      products.forEach((obj) => {
        if (obj.promociones.length > 0) {
          let promociones = [];
          obj.promociones.forEach((promo) => {
            promociones.push(promo.name);
          });
          obj.promocionesText = promociones.join(", ");
        }
      });
      this.dataProductsCopy = products;
      this.orderArray(products, true);
      //CARGAR FILTROS UNA ÚNICA VEZ, CUANDO CARGA INICIALMENTE LA PÁGINA
      if (!filter) {
        this.filtersLoad(response.filtros);
      }
      this.cargandoProductos = false;
      this.cargaProductos = true;

      window.scrollTo(0, 0);
    });
    //}
  }

  getInfoCategory(params) {
    if (
      params.categoria &&
      params.categoria != "" &&
      params.categoria != "busqueda"
    ) {
      let dataCrm = {
        slug: params.categoria,
        id: null,
      };

      /**
       * ! Codigo para cargar data local, cuando se requiera en un futuro
       * * Descomentar lineas
       */
      /* if ((params.categoria === 'novedades' && params.tipo === 'libros') || (params.categoria === 'novedades' && params.tipo === 'ebooks')) {
        if (!category_data.imagen && category_data.is_parent == '0') {
          this.searchCategoryImage(category_data.parent_category_id)
        }

        this.categoryData = category_data;

        let dataMetas = {
          title: category_data.name,
          description: category_data.descripcion_corta ? category_data.descripcion_corta : '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
          keywords: 'libros,libreria,ebooks,' + category_data.name,
          image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
          url: 'https://www.librerianacional.com' + this.router.url
        }
        try {
          this.setMetaData(dataMetas);
        } catch (error) { }
      } else { */
      this.productosService
        .getCategorydata(dataCrm)
        .subscribe((response: any) => {
          if (!response.imagen && response.is_parent == "0") {
            this.searchCategoryImage(response.parent_category_id);
          }

          this.categoryData = response;

          let dataMetas = {
            title: response.name,
            description: response.descripcion_corta
              ? response.descripcion_corta
              : "¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual",
            keywords: "libros,libreria,ebooks," + response.name,
            image:
              "https://www.librerianacional.com/assets/images/libreria_share.jpg",
            url: "https://www.librerianacional.com" + this.router.url,
          };
          try {
            this.setMetaData(dataMetas);
          } catch (error) {}
        });
      //}
    } else {
      let dataMetas = {
        title: "Resultados de búsqueda",
        description:
          "¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual",
        keywords: "libros,libreria,ebooks,busqueda,resultados de busqueda",
        image:
          "https://www.librerianacional.com/assets/images/libreria_share.jpg",
        url: "https://www.librerianacional.com" + this.router.url,
      };
      try {
        this.setMetaData(dataMetas);
      } catch (error) {}
    }
  }

  searchCategoryImage(idParent) {
    if (idParent && idParent != "" && idParent != "busqueda") {
      let dataCrm = {
        slug: idParent,
        id: null,
      };

      this.productosService
        .getCategorydata(dataCrm)
        .subscribe((response: any) => {
          if (!response.imagen) {
            if (response.is_parent == 0) {
              this.searchCategoryImage(response.parent_category_id);
            } else {
              this.noBannerImg =
                (this.platformLocation as any)._location.origin +
                "/assets/images/libros-bg.jpg";
              this.noBannerImgMovil =
                (this.platformLocation as any)._location.origin +
                "/assets/images/libros-bg-movil.jpg";
            }
          } else {
            this.categoryData.imagen = response.imagen;
            this.categoryData.imagen_movil = response.imagen_movil;
          }
        });
    } else {
      this.noBannerImg =
        (this.platformLocation as any)._location.origin +
        "/assets/images/libros-bg.jpg";
      this.noBannerImgMovil =
        (this.platformLocation as any)._location.origin +
        "/assets/images/libros-bg-movil.jpg";
    }
  }

  getListcategories(params: any = {}) {
    let dataCrm = {
      tipo_producto: params.tipo == "ebooks" ? "EBOOK" : "LIBRO",
    };
    this.productosService
      .getCategoryList(dataCrm)
      .subscribe((response: any) => {
        if (response) {
          let data = response;
          if (data.length > 0) {
            this.categoriesList = data;
          }
        }
      });
    /* let data = categories_data[params.tipo === 'ebooks' ? 'ebooks' : 'libros'];
    if (data.length > 0) {
      this.categoriesList = data;
    } */
  }

  paginarProductos(data) {
    let paginas = 0;
    this.pages = [];
    this.listProduct = data;
    for (var i = 0; i < data.length; ++i) {
      if (i % this.pagesPerPage == 0) {
        paginas++;
        this.pages.push(paginas);
      }
    }
  }

  paginacion(index, scrollTop = false) {
    // console.log(this.pageCurrent);
    if (index == "next") {
      // if (this.pageCurrent < this.pages.length) {
      if (this.listProduct.length === 0 || this.dataProducts.length < this.limit) {
        Swal.fire('No se encontraron más productos', '', 'info');
        return;
      }
      this.pageCurrent++;
      this.offset += this.limit;
      // console.log(this.pageCurrent);
      // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
      // this.listProduct = newListData
      this.getListProducts(this.params);

      this.scroll(scrollTop);
      // }
    } else if (index == "prev") {
      // if (this.pageCurrent > 1) {
      this.pageCurrent--;
      // console.log(this.pageCurrent);
      if (this.pageCurrent <= 0) {
        this.pageCurrent = 1;
        return;
      }
      // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
      // this.listProduct = newListData
      this.offset -= this.limit;
      this.getListProducts(this.params);

      this.scroll(scrollTop);
      // }
    } else {
      this.pageCurrent = parseInt(index);
      this.offset = 0;

      this.getListProducts(this.params);

      this.scroll(scrollTop);
      // let newListData = this.dataProducts.slice((this.pageCurrent * this.pagesPerPage) - this.pagesPerPage, this.pageCurrent * this.pagesPerPage)
      // this.listProduct = newListData
    }
  }

  scroll(scrollTop) {
    try {
      if (scrollTop) {
        window.scrollTo(0, 0);
        // document.querySelector('.sidebar-promociones-detalle').getBoundingClientRect().height)
      }
    } catch (error) {}
  }

  searchProductCarrito(id) {
    for (let producto of this.listProduct) {
      if (id == producto.id) {
        this.detailsProduct = producto;
        this.addProductCarrito(id);
        break;
      }
    }
  }

  addProductCarrito(id) {
    let user = this.usuario;
    let carritoLS = this.shopcar;
    let producCurrent = {
      factura_id: null,
      grupo_id: null,
      producto_id: id,
      cantidad: 1,
    };

    this.updateInProgress = true;
    // Hay Carrito Huerfano
    if (carritoLS && carritoLS.id) {
      // Hay usuario logueado con carrito registrado
      producCurrent.factura_id = carritoLS.id;
      producCurrent.grupo_id = carritoLS.grupos[0].id;
      let dataCrm = [user.sessionCrm ? user.sessionCrm : null, producCurrent];
      this.addProductCarritoCrm(dataCrm);
    } else {
      let dataCrm = [user.sessionCrm ? user.sessionCrm : null, producCurrent];
      this.getNewBill(dataCrm);
    }
  }

  addProductCarritoCrm(dataCrm) {
    this.carritoService.addProduct(dataCrm).subscribe((response: any) => {
      this.updateInProgress = false;

      if (response.hasErrors) {
        if (response.number == 2204) {
          // producto NO existe
        } else {
          // Carrito NO coincide con el usuario o estado borrador
          // this.getNewBill(dataCrm)
        }
      } else {
        if (response.linea) {
          let productItem = response;
          this.addedToCart = {
            cantidad: productItem.cantidad,
            slug: productItem.producto.slug ? productItem.producto.slug : null,
            name: productItem.producto.name,
            image: productItem.producto.imagen,
            price:
              productItem.producto.price_dcto < productItem.producto.price
                ? productItem.producto.price_dcto
                : productItem.producto.price,
          };
        }
        if (response.factura.id) {
          this.updateCarrito(response.factura);
        } else {
          this.carritoService.deleteCarritoLocal();
          this.storeService.updateAppState({
            action: ACTION_DEL_SHOPCAR,
            payload: {},
          });
        }
      }
    });
  }

  getNewBill(dataCrm) {
    let user = this.usuario;
    let producCurrent = dataCrm[1];

    let dataCrmNF: any;
    if (user) {
      dataCrmNF = [
        user ? user.sessionCrm : null,
        { session: user ? user.sessionCrm : null },
      ];
    } else {
      dataCrmNF = [null, { session: null }];
    }

    // No Hay Carrito Huerfano
    // let dataCrmNF = [user ? user.sessionCrm : null, { session: user ? user.sessionCrm : null }];

    this.carritoService.newFactura(dataCrmNF).subscribe((res: any) => {
      if (res.id) {
        this.updateCarrito(res);
        producCurrent.factura_id = res.id;
        producCurrent.grupo_id = res.grupos[0].id;

        let dataCrm = [user.sessionCrm ? user.sessionCrm : null, producCurrent];
        this.addProductCarritoCrm(dataCrm);
      } else {
        this.updateInProgress = false;
      }
    });
  }

  //CREAR CARRITO LOCAL
  updateCarrito(carrito) {
    this.carritoService.setCarritoLocal(carrito);
    this.storeService.updateAppState({
      action: ACTION_SHOPCAR,
      payload: carrito,
    });
    let dataModal = {
      tipo: "quickView",
      data: this.detailsProduct,
      productToAdd: this.addedToCart,
      shop_total: {
        //subtotal:   this.shopcar.totales.subtotal,
        subtotal: this.addedToCart.price,
        gran_total: this.shopcar.totales.gran_total,
        total_prds:
          this.shopcar.resumen_x_productos.length > 0
            ? this.shopcar.resumen_x_productos.length
            : 1,
      },
      noCoverImg: this.noCoverImg,
    };
    this.usuarioService.updateNotificacionesModal(dataModal);
    this.usuarioService.updateDataModalState(true);
  }

  //ORDER ARRAY
  orderArray(productos, first = false) {
    // this.pageCurrent = 1;
    let currentOrder = this.formaOrder.controls["productsOrder"].value;
    if (currentOrder == "nameAZ" || currentOrder == "nameZA") {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.name < b.name) {
          if (currentOrder == "nameAZ") {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.name > b.name) {
          if (currentOrder == "nameAZ") {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    } else if (currentOrder == "priceAZ" || currentOrder == "priceZA") {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.price_dcto < b.price_dcto) {
          if (currentOrder == "priceAZ") {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.price_dcto > b.price_dcto) {
          if (currentOrder == "priceAZ") {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    } else if (currentOrder == "descuento") {
      this.dataProducts = productos.sort(function (a, b) {
        if (a.dcto_porcentaje < b.dcto_porcentaje) {
          return -1;
        }
        if (a.dcto_porcentaje > b.dcto_porcentaje) {
          return -1;
        }
        return 0;
      });
    } else {
      if (productos.length > 0) {
        this.dataProducts = Object.assign([{}], this.dataProductsCopy);
      } else {
        this.dataProducts = productos;
      }
    }

    this.paginarProductos(this.dataProducts);
    // if (first) {
    //   this.paginacion(1);
    // }
  }

  //FILTROS

  filtersLoad(allFilters) {
    for (let filtro of allFilters) {
      for (let f in filtro) {
        console.log(filtro[f]);
        const filtro2 = Object.assign({},filtro)
        console.log(filtro2)
        //Object.freeze(filtro[f]);
        /* console.log("filtro[f]: ",Object.freeze(filtro[f])); */

        if (filtro[f].control == "rango_campos_num") {
          //filtro[f].min_init = filtro[f].min;
          //filtro2[f].min_init = filtro[f].min;
          //filtro[f].max_init = filtro[f].max;
          //filtro2[f].max_init = filtro[f].max;
        } else if (
          filtro[f].control == "checks" ||
          filtro[f].control == "rango_fechas_checks"
        ) {
          for (let value of filtro[f].values) {
            value.checked = false;
          }
        } else if (filtro[f].control == "select") {
          //filtro[f].selected = "";
          //filtro2[f].selected = "";
        }

        if (
          filtro[f].control == "select" ||
          filtro[f].control == "checks" ||
          filtro[f].control == "rango_fechas_checks"
        ) {
          filtro[f].values.sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });
        }
      }
    }

    this.filters = allFilters.sort(function (a, b) {
      if (a.orden < b.orden) {
        return -1;
      }
      if (a.orden > b.orden) {
        return 1;
      }
      return 0;
    });
  }

  changeRange(event, index) {
    this.filters[index].min = event.from;
    this.filters[index].max = event.to;
  }

  newFilter(filtro, params, event) {
    if (window.innerWidth < 998) {
      this.showFiltersSidebar = false;
    }

    let type = filtro.control;
    let slug = filtro.filtro_codigo;
    let label = filtro.filtro_label;

    if (type == "checks") {
      for (let filter of this.filters) {
        for (let f in filter) {
          if (filter[f].filtro_codigo == slug) {
            for (let value of filter[f].values) {
              if (value.value == params.values[0]) {
                value.checked = !value.checked;
              }
            }
          }
        }
      }
    } else if (type == "rango_fechas_checks") {
      for (let filter of this.filters) {
        for (let f in filter) {
          if (filter[f].filtro_codigo == slug) {
            for (let value of filter[f].values) {
              if (value.min == params[0].min && value.max == params[0].max) {
                value.checked = !value.checked;
              }
            }
          }
        }
      }
    } else if (type == "select") {
      for (let filter of this.filters) {
        for (let f in filter) {
          if (filter[f].filtro_codigo == slug) {
            filter[f].selected = event.target.value;
          }
        }
      }
    }

    if (this.activeFilter.length > 0) {
      let counter = 0;
      let currentFilters = this.activeFilter;

      for (let filtro of currentFilters) {
        if (filtro.filtro_codigo == slug) {
          // code ya existe un filtro aplicado del mismo tipo

          if (type == "checks") {
            // if (event.target.checked == true)
            if (!event.target.className.includes("icon-check-rounded")) {
              this.activeFilter[counter].params.values.push(params.values[0]);
            } else {
              let currentValues = 0;
              for (let val of this.activeFilter[counter].params.values) {
                if (val == params.values[0]) {
                  this.activeFilter[counter].params.values.splice(
                    currentValues,
                    1
                  );

                  if (this.activeFilter[counter].params.values.length <= 0) {
                    this.activeFilter.splice(counter, 1);
                  }
                }
                currentValues++;
              }
            }
          } else if (type == "rango_fechas_checks") {
            if (!event.target.className.includes("icon-check-rounded")) {
              this.activeFilter[counter].params.push(params[0]);
            } else {
              let currentValues = 0;
              for (let val of this.activeFilter[counter].params) {
                if (val.min == params[0].min && val.max == params[0].max) {
                  this.activeFilter[counter].params.splice(currentValues, 1);

                  if (this.activeFilter[counter].params.length <= 0) {
                    this.activeFilter.splice(counter, 1);
                  }
                }
                currentValues++;
              }
            }
          } else if (type == "rango_campos_num") {
            params.min = event.from;
            params.max = event.to;
            this.activeFilter[counter].params = params;
          } else if (type == "select") {
            this.activeFilter[counter].params = params;
          }

          this.getListProducts(this.paramsUrl, true); //CARGAR NUEVAMENTE LOS PRODUCTOS YA FILTRADOS

          return false;
        } else if (counter + 1 >= currentFilters.length) {
          // code ya existen filtros aplicados pero no del mismo tipo

          let currentFilter: any = {};
          currentFilter.filtro_codigo = slug;
          currentFilter.filtro_label = label;
          currentFilter.params = params;

          this.activeFilter.push(currentFilter);
          this.getListProducts(this.paramsUrl, true); //CARGAR NUEVAMENTE LOS PRODUCTOS YA FILTRADOS

          return false;
        }

        counter++;
      }
    } else {
      // code no existen filtro aplicados de ningun tipo
      let currentFilter: any = {};
      currentFilter.filtro_codigo = slug;
      currentFilter.filtro_label = label;
      currentFilter.params = params;

      this.activeFilter.push(currentFilter);
      this.getListProducts(this.paramsUrl, true); //CARGAR NUEVAMENTE LOS PRODUCTOS YA FILTRADOS
    }
  }

  clearFilter(slugFilter) {
    if (window.innerWidth < 998) {
      this.showFiltersSidebar = false;
    }
    if (slugFilter == "all") {
      this.activeFilter = [];
      for (let filter of this.filters) {
        if (filter.control == "checks") {
          for (let value of filter.values) {
            value.checked = false;
          }
        } else if (filter.control == "rango_campos_num") {
          filter.min_init = filter.min;
          filter.max_init = filter.max;
        } else if (filter.control == "select") {
          filter.selected = "";
        }
      }
      this.getListProducts(this.paramsUrl, true); //CARGAR NUEVAMENTE LOS PRODUCTOS YA FILTRADOS
    } else {
      for (let filter of this.filters) {
        if (filter.filtro_codigo == slugFilter) {
          if (filter.control == "checks") {
            for (let value of filter.values) {
              value.checked = false;
            }
          } else if (filter.control == "rango_campos_num") {
            filter.min_init = filter.min;
            filter.max_init = filter.max;
          } else if (filter.control == "select") {
            filter.selected = "";
          }
          break;
        }
      }

      let currentFilter = 0;
      let activeFiltersCurrent = this.activeFilter;
      for (let val of activeFiltersCurrent) {
        if (val.filtro_codigo == slugFilter) {
          activeFiltersCurrent.splice(currentFilter, 1);
          this.getListProducts(this.paramsUrl, true); //CARGAR NUEVAMENTE LOS PRODUCTOS YA FILTRADOS
          break;
        }
        currentFilter++;
      }
    }
  }

  getDestacados() {
    let dataCrm = {
      slug: "destacados-home",
    };
    this.coleccionesService
      .getColeccionDefault(dataCrm)
      .subscribe((response: any) => {
        for (let item of response) {
          if (item.relacionado) {
            this.dataDestacados.push(item.relacionado);
          }
        }
      });
  }

  toggleFilter() {
    if (this.showFiltersSidebar == false) {
      this.showFiltersSidebar = true;
      this.filterText = "Ocultar filtros";
      this.filterIcon = "icon-close";
    } else {
      this.showFiltersSidebar = false;
      this.filterText = "Mostrar filtros";
      this.filterIcon = "icon-filter";
    }
  }

  toggleIcon() {
    if (this.icon == "empty") {
      this.icon = "rounded";
    } else {
      this.icon = "empty";
    }
  }

  thirdLevelValidation(categoria) {
    let res = false;
    if ("categorias" in categoria) {
      if (categoria.categorias.length > 0) {
        categoria.categorias.forEach((cat) => {
          if (cat.id == this.categoryData.parent_category_id) {
            res = true;
          }
        });
      }
    }
    return res;
  }
}
