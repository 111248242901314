import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario.service';
import { CarritoService } from '../../../services/carrito.service';
import { StoreService } from '../../../services/store.service';
import { WindowScrolling } from '../../../services/window-scrolling.service';
import { PlatformLocation } from '@angular/common';
import { ACTION_SHOPCAR, UPDATE_BUSCADOR, UPDATE_MENU_MOBILE, UPDATE_MENU_MOBILE_SEARCH, ACTION_DEL_SHOPCAR } from '../../../store/actions/appAction';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Product } from 'src/app/interfaces/product';
import { DataLayerService } from '../../../services/data-layer.service';

declare var $:any;
@Component({
  selector: 'app-mi-carrito',
  templateUrl: './mi-carrito.component.html',
  styleUrls: ['./mi-carrito.component.scss']
})
export class MiCarritoComponent implements OnInit {
  usuario: any;
  shopcar: any;
  shopcarState: boolean = true;
  nextStep: any;
  carrito: any = [];
  resumen: any = [];
  listaProductos: any = [];
  countCarritoProducts: number = 0;
  countCarritoservicies: number = 0;
  updateInProgress: boolean = false;
  showGiftForm: any = {};
  totals: any = {
    total: 0,
    descuento: 0,
    subtotal: 0,
    iva: 0,
    gran_total: 0
  };
  countP: any;
  producto: any;
  productos: any = [];
  opciones_regalo: any = [];

  showWarningMessage: boolean = false;
  warningMessage: string = '';
  gift:boolean = false;
  fields = { de: '', para: '' };
  showSearchModal: boolean;
  showMobileModal: boolean;
  bonoImage: string = '';
  bonoVirtualImage: string = '';
  listProductFlag: boolean = false;
  private windowScrolling: WindowScrolling;

  totalesloco: Observable<any>;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;
  constructor(
    private meta: Meta,
    private titleService: Title,
    private usuarioService: UsuarioService,
    private carritoService : CarritoService,
    private storeService: StoreService,
    private store: Store<any>,
    private router: Router,
    private platformLocation: PlatformLocation,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private dataLayerService: DataLayerService,
    windowScrolling: WindowScrolling,
  ) {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';
    // Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url);
    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';
    this.windowScrolling = windowScrolling;

    this.store.subscribe(({ appReducer }) => {
      this.showMobileModal = appReducer.menuMobileState;
      this.showSearchModal = appReducer.menuMobileSearchState;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.totalesloco = this.store.select('appReducer');

    const dataMetas = {
      title: 'Carrito de compras',
      description: 'En tu carrito de compras encontrarás todos los libros incluidos en tu recorrido por nuestra tienda virtual.',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com' + this.router.url
    };

    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.storeService.updateAppState({
      action: UPDATE_BUSCADOR,
      payload: false
    });

    if(window.innerWidth < 991){
      if(this.showMobileModal || this.showSearchModal){
        this.windowScrolling.enable();
      }

      // CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE,
        payload: false
      });

      // CAMBIAR DE ESTADO EL MENU MOBILE
      this.storeService.updateAppState({
        action: UPDATE_MENU_MOBILE_SEARCH,
        payload: false
      });
    }

    if (this.usuarioService.getUserLocal() || this.carritoService.getCarritoLocal()) {
      this.shopcarState = true;
    } else {
      this.shopcarState = false;
    }

    this.store.subscribe(response => {
      const stateCurrent: any = this.store.select('appReducer');
      const actionCurrent: any = stateCurrent.actionsObserver.value.type;
      const payloadCurrent: any = stateCurrent.actionsObserver.value.payload;

      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;

      if(this.shopcar) {
        if (this.shopcar.grupos) {
          this.carrito = this.shopcar.grupos[0].lineas.productos;
          this.shopcarState = false;
        }
        if (this.shopcar.totales) {
          this.totals = this.shopcar.totales;
        }
      }
      this.nextStep = this.usuario.estado_login == 'LOGIN_FULL' || this.usuario.estado_login == 'LOGIN_PARCIAL'  ? 'datos-envio': 'solicitud-correo';
      if ((actionCurrent === 'SHOPCAR' || this.listaProductos.length === 0) && !this.listProductFlag ) {
        this.listProduct();
      }
    });

    // ACTUALIZAR CARRITO CON EL LOCAL
    if (this.shopcar) {
      const carrito = this.shopcar;
      if (carrito.grupos) {
        this.carrito = [];
        this.countCarritoProducts = 0;
        for (let linea of carrito.grupos[0].lineas.productos) {
          const cantidadCurrent = parseInt(linea.cantidad);
          this.countCarritoProducts = this.countCarritoProducts + cantidadCurrent;
          this.carrito.push(linea);
        }
      }
    }
  }

  setMetaData(data): void  {
    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });
  }

  listProduct(): void {
    window.scrollTo(0, 0);

    this.listaProductos = [];
    const carritosLs = this.shopcar;
    const products: Array<Product> = [];

    if (carritosLs.grupos) {
      carritosLs.grupos[0].lineas.productos.forEach(product => {
        if (product.cantidad > 0) {
          this.listaProductos.push(product);
        }

        const productDataLayer: Product = {
          name: product.producto.name,
          id: product.producto.plu_sku_erp_code,
          plu: product.producto.plu_sku_erp_code,
          slug: product.producto.slug,
          price: product.producto.price_dcto,
          type: product.producto.type.valor,
          quantity: product.cantidad
        };
        products.push(productDataLayer);
      });

      this.dataLayerService.onCheckout(2, 'Ver Carrito', products);
      this.dataLayerService.customEvent('checkout_step_02');
    }

    // recorre el array de libros para agregarle la cantidad de productos a empacar para regalo en 0
    // y activar por defecto el primer libro al empaque para regalo
    this.listaProductos.forEach( prd => {
      prd.count = 0;
      prd.forms = prd.forms ? prd.forms : [];
      this.showGiftForm[prd.producto_id] = false;
      // si ya existe un empaque configurado se manda a seleccionar con los datos de remitente y destinatario
      if(prd.forms.length > 0){
        this.addOtherBookForGiftFirst(prd);
      }
    });

    const dataGifts = {
      session    : this.usuario.sessionCrm ? this.usuario.sessionCrm : null,
      factura_id : this.shopcar.id
    };

    if(this.shopcar.id) {
      this.carritoService.getBooksAsaGift(dataGifts).subscribe((response:any) => {

        this.listProductFlag = true;

        if (response != null) {
          for (let prdCart = 0; prdCart < this.listaProductos.length; prdCart++) {
            for (let prdRes = 0; prdRes < response.length; prdRes++) {
              if (this.listaProductos[prdCart].producto.id === response[prdRes].producto_id) {
                this.listaProductos[prdCart].forms = [];
                this.listaProductos[prdCart].count = 0;
                if (response[prdRes].opciones_regalo.length > 0) {
                  const cantidad = this.listaProductos[prdCart].cantidad;
                  for (let f = 0; f < cantidad; f++) {
                    if (response[prdRes].opciones_regalo[f]) {
                      this.showGiftForm[this.listaProductos[prdCart].id] = true;
                      this.listaProductos[prdCart].count++;
                      const obj = { de: response[prdRes].opciones_regalo[f].de, para: response[prdRes].opciones_regalo[f].para };
                      this.listaProductos[prdCart].forms.push(obj);
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
  }

  /**
   * @description agrega el primer item de los libros para que salga el formulario de empaque para regalo
   * adicionalmenete agrega campos count y forms al producto
   */
  addOtherBookForGiftFirst(item): void {
    this.showGiftForm[item.producto_id] = !(this.showGiftForm[item.producto_id]);
    if(this.showGiftForm[item.producto_id]) {
      if (item.forms.length === 0) {
        item.count++;
        const f = Object.assign({}, this.fields);
        item.forms.push(f);
      }
    } else {
      item.count = 0;
      item.forms = [];
    }
  }

  addOtherBookForGift(count, item): void {
    if(count < item.cantidad){
      item.count++;
      const f = Object.assign({}, this.fields);
      item.forms.push(f);
    }
  }

  /**
   * @description elimina empaque para regalo del libro recibido por parametro
   * @param count es la posición del index del objeto para removerlo del listado de libros que van a ser empacados para regalo
   * @param item data completa individual del libro
   */
  removeOtherBookForGift(count: number, item: any): void {
    item.count--;
    item.forms.splice(count, 1)
  }

  uncheckBooksForGift(item): void {
    this.showGiftForm[item.id] = !(this.showGiftForm[item.id]);
  }

  getDe(item, pos, e): void {
    item.forms[pos].de = e.target.value;
  }

  getPara(item, pos, e): void {
    item.forms[pos].para = e.target.value;
  }

  addCountProduct(item, index): void {
    this.actualizarProducto(item, 1);
  }

  resCountProduct(item, index): void {
    this.actualizarProducto(item, -1);
  }

  actualizarProducto(producto,cantidad,flag = false) {
    this.updateInProgress = true;
    const user = this.usuario;
    const carritoLS = this.shopcar;
    if (carritoLS.id) {
      const dataCrm = [
        (user.sessionCrm ? user.sessionCrm : null),
        {
          factura_id     : carritoLS.id,
          grupo_id       : carritoLS.grupos[0].id,
          producto_id    : producto.producto_id,
          cantidad       : String(cantidad),
          cantidad_set   : flag
        }
      ];
      this.carritoService.addProduct(dataCrm).subscribe((res:any) => {
        if (res.hasErrors) {
          if (res.number === 2204) {
            // producto NO existe
          } else {
            // Carrito NO coincide con el usuario o estado borrador
            // this.carritoService.deleteCarritoLocal()
            // this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
          }
        } else {
          if (res.factura.id) {
            this.getCarritoCrm(res.factura);
          } else {
            this.carritoService.deleteCarritoLocal();
            this.storeService.updateAppState({ action: ACTION_DEL_SHOPCAR, payload: {} });
          }
        }
      });
    }
  }

  getCarritoCrm (response): void {
    this.storeService.updateAppState({ action: ACTION_SHOPCAR, payload: response });
    this.carrito = response.grupos[0].lineas.productos;
    this.resumen = response.totales;
    this.listProduct();
  }

  continueToNextStep() {
    let valid = true;
    let validGiftPackaging = true;
    this.showWarningMessage = false;
    this.listaProductos.forEach( obj => {
      // validación para identificar si tiene productos seleccionados para empacar para regalo sin diligenciar el formulario
      if(obj.forms.length > 0){
        obj.forms.forEach( obj => {
          if(obj.de === '' || obj.para === '' ){
            validGiftPackaging = false;
            return;
          }
        });
      }

      if (obj.error !== false) {
        valid = false;
      }
    });

    if (!valid) {
      this.showWarningMessage = true;
      this.warningMessage = 'Tiene productos en su carrito que no tienen inventario. Por favor verificar su carro de compras.';
    }
    // validacion para evitar seguir si se tiene seleccionado empaque para regalo sin diligenciar datos
    else if (!validGiftPackaging) {
      this.showWarningMessage = true;
      this.warningMessage = 'Tienes productos en su carrito por empacar pero faltan los campos de remitente o destinatario.';
    } else {
      for (let p = 0; p < this.listaProductos.length; p++) {

        if (this.listaProductos[p] && this.listaProductos[p].forms) {

          if (this.listaProductos[p].forms.length > 0) {
            for(let f = 0; f < this.listaProductos[p].forms.length; f++) {
              const deypara = { de:this.listaProductos[p].forms[f].de, para:this.listaProductos[p].forms[f].para, empacar:'' };
              deypara.empacar = this.showGiftForm[this.listaProductos[p].producto_id] ? 'SI' : 'NO';
              this.opciones_regalo.push(deypara);
            }
            this.producto = { producto_id: this.listaProductos[p].producto.id, opciones_regalo: this.opciones_regalo };
            this.opciones_regalo = [];
            const filter = this.productos.filter( obj => {
              return obj.producto_id == this.listaProductos[p].producto.id;
            })[0];
            if(filter == undefined){
              if(this.producto.opciones_regalo.length > 0){
                this.productos.push(this.producto);
              }
            } else {
              this.producto.opciones_regalo.forEach(obj => {
                filter.opciones_regalo.push(obj);
              });
            }
            this.producto = {};
          }
        }
      }

      const dataGifts = {
        session    : this.usuario.sessionCrm ? this.usuario.sessionCrm : null,
        factura_id : this.shopcar.id,
        productos : this.productos
      };

      this.carritoService.registerBookAsaGift(dataGifts).subscribe((response:any) => {
        if (response) {
          if (this.usuario.estado_registro === 'PARCIAL' || !this.usuario.estado_registro) {
            this.router.navigate(['/', 'carrito', 'solicitud-correo']);
          } else if (this.usuario.estado_login === 'LOGIN_FULL' || this.usuario.estado_login === 'LOGIN_PARCIAL') {
            this.router.navigate(['/', 'carrito', this.nextStep]);
          } else {
            this.router.navigate(['/', 'carrito', 'solicitud-correo']);
          }
        }
      });
    }
  }

  carouselOtrosLibros(): void {
    $(function(){
      $('.carousel-otros-libros').owlCarousel({
        items: 5,
        nav: false,
        margin: 0,
        dots: false,
        autoplay: false,
        lazyLoad: true,
        navContainer: '#nav-carousel-otros-libros',
        responsive:{
          320: {
            items:2,
            margin:15,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          768: {
            items:3,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          991: {
            items:3,
            nav:false,
            dots:true,
            autoplay: true,
            dotsContainer: '#dots-carousel-otros-libros'
          },
          1200: {
            items:5,
            nav: false
          }
        }
      });
    });
  }
}
