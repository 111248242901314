import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocationService } from 'src/app/services/location.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appState';
import {
  AgregarDireccion,
  ActualizarDireccion,
  AccionesDirecciones,
  CargarDirecciones,
  SetDireccion,
  CancelarDireccion
} from "src/app/store/direcciones/actions";
import { Actions } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: "app-formulario-direcciones",
  templateUrl: "./formulario.component.html",
})
export class FormularioComponent implements OnInit, OnDestroy {
  formAddress: FormGroup;
  currentAddressId: string = "";
  showWarningMessage: boolean = false;
  warningMessage: string = "";

  countries: any = [];
  departments: any = [];
  cities: any = [];

  showOthers: boolean = false;

  direccion: any;
  direcciones: any;

  url = '/mi-cuenta/libreta-direcciones';

  @Input("id") id;
  @Input("modal") modal: boolean;
  susA: Subscription = new Subscription();
  susD: Subscription = new Subscription();

  constructor(
    private locationSercice: LocationService,
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router
  ) {
    this.susA = this.actions.subscribe((action: any) => {
      // console.log(action);

      if (action.type === AccionesDirecciones.AGREGAR_DIRECCION_EXITOSA) {
        this.formAddress.reset();
        this.store.dispatch(
          new CargarDirecciones({
            session_id: JSON.parse(localStorage.session),
          })
        );
        Swal.fire("Dirección agregada", "", "success");
        this.router.navigateByUrl(this.url);
      }

      if (action.type === AccionesDirecciones.ACTUALIZAR_DIRECCION_EXITOSA) {
        this.formAddress.reset();
        this.store.dispatch(
          new CargarDirecciones({
            session_id: JSON.parse(localStorage.session),
          })
        );
        this.direccion = action.direccion || {};
        this.store.dispatch(new SetDireccion(action.direccion));
        this.editAddress(action.direccion);
        Swal.fire("Dirección actualizada", "", "success");
        this.router.navigateByUrl(this.url);
      }

      if(action.type === AccionesDirecciones.SET_DIRECCION){
        console.log(action);
        if(action.direccion){
          this.direccion = action.direccion || {};
          // this.store.dispatch(new SetDireccion(action.direccion));
          this.editAddress(action.direccion);
        }
      }
    });

    this.susD = this.store
      .select("direcciones")
      .subscribe(({ data, direccion }) => {
        console.log(data, direccion);

        this.direccion = direccion;
        this.direcciones = data;

        if(!this.direccion.id && this.id){

          let dir = this.direcciones.find(dirA => dirA.id === this.id);
          this.direccion = dir;
          this.editAddress(this.direccion);
          // filtramos la direccion por el id
          this.store.dispatch(new SetDireccion(dir));

          this.susD.unsubscribe();
        }
      });

    if(this.router.url.indexOf('/carrito') >= 0){
      this.url = '/carrito/datos-envio';
    }
  }

  ngOnDestroy() {
    this.susA.unsubscribe();
    this.susD.unsubscribe();
  }

  ngOnInit() {
    // console.log(this.id);

    if(this.id && this.direccion.id){
      this.editAddress(this.direccion);
    }

    this.locationSercice
      .search({
        tipo: "PAIS",
        ubicacion_id: null,
      })
      .toPromise()
      .then((country) => {
        if (country != null) {

          this.countries = country;

          //ESTE CODIGO ORGANIZA LA LISTA DE LOS PAISES COMO VIENE COLOMBIA DE PRIMERO SE COMENTA
          // this.countries = country.sort(function (a, b) {
          //   if (a.name < b.name) {
          //     return -1;
          //   }
          //   if (a.name > b.name) {
          //     return 1;
          //   }
          //   return 0;
          // });
        }
      });

    this.formAddressValidate();
  }

  formAddressValidate() {
    this.formAddress = new FormGroup({
      name: new FormControl("", Validators.required),
      tipo_direccion: new FormControl("", Validators.required),
      preguntar_por_name: new FormControl("", Validators.required),
      preguntar_por_lastname: new FormControl("", Validators.required),
      direccion: new FormControl("", Validators.required),
      direccion_descripcion: new FormControl("", Validators.required),
      direccion_postalcode: new FormControl("", Validators.required),
      pais: new FormControl("", Validators.required),
      departamento: new FormControl("", Validators.required),
      ciudad: new FormControl("", Validators.required),
      departamento_otro: new FormControl(""),
      ciudad_otro: new FormControl(""),
      telefono_fijo: new FormControl(""),
      telefono_celular: new FormControl(""),
    });
  }

  createUpdateAddress() {
    if (this.formAddress.valid) {
      let { direccion, direccion_descripcion } = this.formAddress.value;

      let address = {
        session_id: JSON.parse(localStorage.session),
        id: this.currentAddressId,
        name: this.formAddress.value.name,
        preguntar_por:
          this.formAddress.value.preguntar_por_name +
          " " +
          this.formAddress.value.preguntar_por_lastname,
        direccion: `${direccion} :: ${direccion_descripcion}`,
        direccion_descripcion: direccion_descripcion,
        tipo_direccion: this.formAddress.value.tipo_direccion,
        direccion_postalcode: this.formAddress.value.direccion_postalcode,
        pais_id: this.formAddress.value.pais,
        dpto_id: this.formAddress.value.departamento,
        ubicacion_id: this.formAddress.value.ciudad,
        departamento_otro: this.formAddress.value.departamento_otro,
        ciudad_otro: this.formAddress.value.ciudad_otro,
        telefono_fijo: this.formAddress.value.tipo_direccion,
        telefono_celular: this.formAddress.value.telefono_celular,
      };

      if(this.id){
        this.store.dispatch(new ActualizarDireccion(address));
      }else{
        this.store.dispatch(new AgregarDireccion(address));
      }
    } else {
      this.formAddress.controls["name"].markAsTouched();
      this.formAddress.controls["tipo_direccion"].markAsTouched();
      this.formAddress.controls["preguntar_por_name"].markAsTouched();
      this.formAddress.controls["preguntar_por_lastname"].markAsTouched();
      this.formAddress.controls["direccion_descripcion"].markAsTouched();
      this.formAddress.controls["direccion"].markAsTouched();
      this.formAddress.controls["direccion_postalcode"].markAsTouched();
      this.formAddress.controls["pais"].markAsTouched();
      this.formAddress.controls["departamento_otro"].markAsTouched();
      this.formAddress.controls["departamento"].markAsTouched();
      this.formAddress.controls["ciudad"].markAsTouched();
      this.formAddress.controls["ciudad_otro"].markAsTouched();
      this.showWarningMessage = true;
      this.warningMessage = "Por favor complete datos de dirección.";
      return false;
    }
  }

  filterCountry(e) {
    let countryId = e.target.value;

    if (!countryId) {
      this.departments = [];
      this.cities = [];
      return;
    }

    if (countryId == "770c0c90-01f3-31df-0f00-59cc0f745717") {
      this.setValidators(false);
      this.showOthers = false;
    } else {
      this.setValidators(true);
      this.showOthers = true;
    }

    this.locationSercice
      .search({
        tipo: "DPTO",
        ubicacion_id: countryId,
      })
      .toPromise()
      .then((res) => {
        if (res != null) {
          this.departments = res.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
      });
  }

  filterCities(e) {
    let departmentId = e.target.value;
    this.locationSercice
      .search({
        tipo: "CIUDAD",
        ubicacion_id: departmentId,
      })
      .toPromise()
      .then((res) => {
        if (res != null) {
          this.cities = res.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
      });
  }

  private setValidators(flag) {
    if (!flag) {
      this.formAddress.controls["departamento"].setValidators([
        Validators.required,
      ]);
      this.formAddress.controls["ciudad"].setValidators([Validators.required]);
      this.formAddress.controls["departamento_otro"].setValidators([]);
      this.formAddress.controls["ciudad_otro"].setValidators([]);
      this.formAddress.controls["direccion_postalcode"].setValidators([]);
    } else {
      this.formAddress.controls["departamento"].setValidators([]);
      this.formAddress.controls["ciudad"].setValidators([]);
      this.formAddress.controls["departamento_otro"].setValidators([
        Validators.required,
      ]);
      this.formAddress.controls["ciudad_otro"].setValidators([
        Validators.required,
      ]);
      this.formAddress.controls["direccion_postalcode"].setValidators([
        Validators.required,
      ]);
    }

    this.formAddress.controls["departamento"].reset();
    this.formAddress.controls["departamento"].updateValueAndValidity();
    this.formAddress.controls["ciudad"].reset();
    this.formAddress.controls["ciudad"].updateValueAndValidity();
    this.formAddress.controls["departamento_otro"].reset();
    this.formAddress.controls["departamento_otro"].updateValueAndValidity();
    this.formAddress.controls["ciudad_otro"].reset();
    this.formAddress.controls["ciudad_otro"].updateValueAndValidity();
    this.formAddress.controls["direccion_postalcode"].reset();
    this.formAddress.controls["direccion_postalcode"].updateValueAndValidity();
  }

  editAddress(address) {
    try {
      let countryId = address.pais_id;
      let departmentId = address.dpto_id;

      try {
        let direccionTemporal = address.direccion.split(' :: ');
        // cortamos la direccion y su descripcion
        address.direccion = direccionTemporal[0];
        address.direccion_descripcion = direccionTemporal[1];
      } catch (error) {
        console.log('No tiene el formato nuevo de la direccion');
      }

      this.locationSercice
        .search({
          tipo: "PAIS",
          ubicacion_id: null,
        })
        .toPromise()
        .then((country) => {
          if (country != null) {
            this.countries = country;

            this.locationSercice
              .search({
                tipo: "DPTO",
                ubicacion_id: countryId,
              })
              .toPromise()
              .then((departs) => {
                if (departs != null) {
                  this.departments = departs;

                  this.locationSercice
                    .search({
                      tipo: "CIUDAD",
                      ubicacion_id: departmentId,
                    })
                    .toPromise()
                    .then((city) => {
                      if (city != null) {
                        this.cities = city;

                        this.formAddressData(address);
                        this.currentAddressId = address.id;
                      }
                    });
                }
              });
          }
        });
      return false;
    } catch (error) {
      console.log('error controlado => ', error);
    }
  }

  formAddressData(address) {
    let fullname = address.preguntar_por.split(" ");
    let name = fullname[0];
    fullname.shift();
    let lastname =
      fullname.length > 0 ? fullname.toString().replace(",", " ") : "";

    this.formAddress.controls["name"].setValue(address.name);
    this.formAddress.controls["tipo_direccion"].setValue(
      address.tipo_direccion
    );
    this.formAddress.controls["preguntar_por_name"].setValue(name);
    this.formAddress.controls["preguntar_por_lastname"].setValue(lastname);
    this.formAddress.controls["direccion"].setValue(address.direccion);
    this.formAddress.controls["direccion_descripcion"].setValue(address.direccion_descripcion);
    this.formAddress.controls["direccion_postalcode"].setValue(
      address.direccion_postalcode
    );
    this.formAddress.controls["pais"].setValue(address.pais_id);
    this.formAddress.controls["departamento"].setValue(address.dpto_id);
    this.formAddress.controls["ciudad"].setValue(address.ubicacion_id);
    this.formAddress.controls["telefono_fijo"].setValue(address.telefono_fijo);
    this.formAddress.controls["telefono_celular"].setValue(
      address.telefono_celular
    );
  }

  cancelar(){
    this.store.dispatch(new CancelarDireccion());
  }
}
