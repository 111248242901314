import { Action } from '@ngrx/store';

export enum AccionesEventos {
    CARGAR_EVENTOS = '[EVENTOS] => Cargar',
    CARGA_EVENTOS_EXITOSA = '[EVENTOS] => Carga Exitosa',
    CARGA_EVENTOS_FALLIDA = '[EVENTOS] => Carga Fallida'
}

export class CargarEventos implements Action {
    readonly type = AccionesEventos.CARGAR_EVENTOS;

    constructor( public payload?: any ) {}
}

export class CargarEventosFail implements Action {
    readonly type = AccionesEventos.CARGA_EVENTOS_FALLIDA;

    constructor( public payload: any ) {}
}

export class CargarEventosSuccess implements Action {
    readonly type = AccionesEventos.CARGA_EVENTOS_EXITOSA;

    constructor( public eventos: any ) {}
}

export type UnionAccionesEventos = CargarEventos | CargarEventosFail | CargarEventosSuccess;
