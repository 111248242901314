import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { StoreService } from "../../services/store.service";
import { UsuarioService } from '../../services/usuario.service';
import { CarritoService } from '../../services/carrito.service';
import { ACTION_LOGOUT } from "../../store/actions/appAction";
import { Subscription } from 'rxjs';
import { ResetDirecciones } from 'src/app/store/direcciones/actions';
import { ResetOrdenes } from 'src/app/store/ordenes/actions';
import { SessionService } from 'src/app/services/session.service';
import { EliminarCuentaService } from 'src/app/services/eliminar-cuenta.service';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MiCuentaComponent implements OnInit, OnDestroy {
  usuario:any;
  shopcar:any;
  activeIco:any;
  currentView:any;

  susc: Subscription = new Subscription();

  constructor(
    private meta: Meta,
    public router: Router,
    private store: Store<any>,
    private titleService: Title,
    private storeService:StoreService,
    public usuarioService:UsuarioService,
    public carritoService:CarritoService,
    private sessionService: SessionService,
    private eliminarCuentaService: EliminarCuentaService
  ){
    this.susc = this.store.subscribe(response => {
      this.usuario = response.appReducer.user;
      this.shopcar = response.appReducer.shopcar;
    });
  }

  ngOnInit(){
    window.scrollTo(0,0);
    this.currentView = 'Cuenta';
    this.activeIco = 'icon-user-login';
    this.titleService.setTitle('Mi Cuenta | Libreria Nacional');
    this.meta.addTag({ name: 'description', content: 'Información de la cuenta' });
  }

  ngOnDestroy(){
    this.susc.unsubscribe();
  }

  setCurrentView(selected, icon){
    this.activeIco = icon;
    this.currentView = selected;
  }

  closeSesion(){
    this.sessionService.closeSesion();
    // let sessionCrm = this.usuario.sessionCrm;
    // this.usuarioService.logOut(sessionCrm);
    // this.usuario = {};
    // this.shopcar = {};
    // this.usuarioService.deleteUserLocal();
    // this.carritoService.deleteCarritoLocal();
    // this.usuarioService.updateEnviromentUser(null);
    // this.store.dispatch(new ResetDirecciones());
    // this.store.dispatch(new ResetOrdenes());
    // this.storeService.updateAppState({ action: ACTION_LOGOUT, payload: null });
    // this.router.navigate(['/']);
  }


  solicitarEliminarCuenta(){
     let session:string = this.usuarioService.getUserLocal();
     this.eliminarCuentaService.solicitudEliminarCuenta(session);
  }
}
