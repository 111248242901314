import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { PlatformLocation } from "@angular/common"
import { CarritoService } from '../../../services/carrito.service';
import { StoreService } from "../../../services/store.service";
import { ACTION_SHOPCAR } from "../../../store/actions/appAction";
import { Router, ActivatedRoute } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Title, Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirmacion-pago',
  templateUrl: './confirmacion-pago.component.html',
  styleUrls: ['./confirmacion-pago.component.css']
})
export class ConfirmacionPagoComponent implements OnInit {
  shopcar:any = null;
  localShopcar: any = null;
  products:any = [];
  totals: any = {
    total: 0,
    descuento: 0,
    subtotal: 0,
    iva: 0,
    gran_total: 0,
  };
  user:any = null;
  paymentMethod: string = '';
  paymentResponseOrderId: string = '';
  paymentResponse: any = null;
  paymentOrder: any = null;
  userFullName: string = '';
  userPhone: string = '';
  paymentRecipt: string = '';
  address:any = null;
  addressDescription: string = '';
  paymentError: string = '';
  localShopcarFlag: boolean = true;
  paramsUrl: any = {};
  noCoverImg: string = ''
  bonoImage : string = '';
  bonoVirtualImage : string = '';
  dataPaymentOrder: boolean = false;

  // defaults images lazyload
  loaderImg = '';
  offset = 5;

  totalesloco: Observable<any>;

  lapTransactionState: string;
  transactionState: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private store: Store<any>,
    private carrito: CarritoService,
    private storeService: StoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public  platformLocation: PlatformLocation,
    public angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  )
  {
    this.loaderImg = (this.platformLocation as any)._location.origin + '/assets/images/loader.svg';

    this.bonoImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-fisico.jpg';
    this.bonoVirtualImage = (this.platformLocation as any)._location.origin + '/assets/images/bono-virtual.jpg';

    //Start tracking GTM
    this.angulartics2GoogleTagManager.pageTrack(this.router.url)
    this.noCoverImg = (this.platformLocation as any)._location.origin + '/assets/images/no-cover.jpg';


  }

  ngOnInit() {

    this.totalesloco = this.store.select('appReducer');

    let dataMetas = {
      title: 'Proceso de compra, confirmación de pago',
      description: '¡Más de 200.000 títulos! Ahora puedes comprar tus libros por internet desde la comodidad de tu casa u oficina a través de nuestra Tienda Virtual',
      keywords: 'libros,libreria,ebooks',
      image: 'https://www.librerianacional.com/assets/images/libreria_share.jpg',
      url: 'https://www.librerianacional.com'+this.router.url
    }
    try {
      this.setMetaData(dataMetas);
    } catch (error) { }

    this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: {}});

    this.activatedRoute.params.subscribe( params => {
      if(params) {
        this.paramsUrl = params;
      }
    });

    this.store.subscribe(response => {

      let stateCurrent:any = this.store.select('appReducer');
      let actionCurrent:any = stateCurrent.actionsObserver._value.type;
      let currentUser = this.user
      this.user = response.appReducer.user;
      this.totals = response.appReducer.shopcar.totales;

      if(actionCurrent == 'PAYMENT_RESPONSE')
      {
        this.paymentResponse = response.appReducer.payment_response;
        if(this.paymentResponse.name == 'Error al pagar') {
          this.paymentError = 'DECLINED'
        }
      }

      if( (actionCurrent == 'LOGIN' || currentUser == null) && !this.dataPaymentOrder )
      {
        this.user = response.appReducer.user;
        if(this.user.sessionCrm)
        {
          this.dataPaymentOrder = true;

          this.userFullName = this.user.nombres + ' ' + this.user.apellidos;
          this.userPhone = this.user.telefono + (this.user.telefono_movil ? ' - ' + this.user.telefono_movil : '');

          this.carrito.getFactura([this.user.sessionCrm, {factura_id: this.paramsUrl.billId}]).toPromise().then( (res: any) => {
            if(res.id)
            {
              this.shopcar = res;
              this.products = this.shopcar.grupos[0].lineas.productos;
              this.totals = res.totales;

              let obj: any = { totales: res.totales };
              this.storeService.updateAppState({action: ACTION_SHOPCAR, payload: obj});

              this.carrito.get_statusOrden([this.user.sessionCrm, {orden_pago_id: this.paramsUrl.orderId}]).toPromise().then( (res: any) => {
                if(res.id)
                {
                  this.paymentOrder = res;
                  this.paymentMethod = this.paymentOrder.metodo_pago_id;
                }

                window.scrollTo(0,0);

              });
            }
          });
        }
      }

    });

    this.lapTransactionState = this.router.parseUrl(this.router.url).queryParams['lapTransactionState'];
    const statessss = {
      APPROVED: 'Aprobado',
      DECLINED: 'Declinad',
      ERROR: 'Error',
      EXPIRED: 'Expirado',
      PENDING : 'PENDIENTE',
    }
    this.transactionState = statessss[this.lapTransactionState] || '';
  }

  setMetaData(data) {

    this.titleService.setTitle(data.title + ' | ' + 'Libreria Nacional');
    this.meta.updateTag({ 'name': 'keywords', 'content': data.keywords });
    this.meta.updateTag({ 'name': 'description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:card', 'content': 'summary_large_image' });
    this.meta.updateTag({ 'name': 'twitter:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:text:title', 'content': data.title });
    this.meta.updateTag({ 'name': 'twitter:description', 'content': data.description });
    this.meta.updateTag({ 'name': 'twitter:image', 'content': data.image });
    this.meta.updateTag({ 'name': 'twitter:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:title', 'content' : data.title });
    this.meta.updateTag({ 'property': 'og:url', 'content': data.url });
    this.meta.updateTag({ 'property': 'og:image', 'content': data.image });
    this.meta.updateTag({ 'property': 'og:image:alt', 'content': data.title });
    this.meta.updateTag({ 'property': 'og:description', 'content': data.description });
    this.meta.updateTag({ 'property': 'product:brand', 'content': '' });
    this.meta.updateTag({ 'property': 'product:availability', 'content': '' });
    this.meta.updateTag({ 'property': 'product:condition', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:amount', 'content': '' });
    this.meta.updateTag({ 'property': 'product:price:currency', 'content': '' });
    this.meta.updateTag({ 'property': 'product:retailer_item_id', 'content': '' });
    this.meta.updateTag({ 'property': 'fb:app_id', 'content': '529648729383744' });
    this.meta.updateTag({ 'property': 'fb:admins', 'content': '99999999' });

  }

  setShopcar(bill) {
    this.shopcar = bill;
    if(this.shopcar.grupos) {
      this.products = this.shopcar.grupos[0].lineas.productos;
      // to delete
      if(this.products.length > 0) {
        this.products.forEach(obj => {
          if(!('imagen' in obj)) {
            obj.imagen = 'assets/images/no-cover.jpg';
          }
        });
      }
      // to delete
    }
    if(this.shopcar.totales) {
      this.totals = this.shopcar.totales;
    }
  }


}
